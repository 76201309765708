import { dateManager, avoidBankHoliday } from '@/helpers/date'

export const issueMonthsConverter = {
  fromBoolsToNums(issueMonths) {
    // [true, false, true, ...] => [1, 3, ..]
    let converted = []
    issueMonths.forEach((value, index) => {
      if (value) {
        converted.push(index + 1)
      }
    })
    return converted
  },
  fromNumsToBools(issueMonths) {
    // [1, 3, ..] => [true, false, true, ...]
    let converted = [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ]
    for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
      let monthValue = true
      if (issueMonths) {
        monthValue = issueMonths.includes(monthIndex + 1)
      }
      converted[monthIndex] = monthValue
    }
    return converted
  },
}

export const scheduledInvoicesGetter = {
  get({
    startAt,
    endAt,
    issueDay,
    issueMonths,
    paymentTermMonth,
    paymentTermDay,
    invoicesByIssueYearMonth,
    isAvoidBankHoliday,
  }) {
    const scheduledInvoices = this.getByIssueMonth({
      startAt,
      endAt,
      issueDay,
      issueMonths,
      paymentTermMonth,
      paymentTermDay,
      invoicesByIssueYearMonth,
      isAvoidBankHoliday,
    })

    // convert to array
    let issueYearMonths = Object.keys(scheduledInvoices)
    issueYearMonths.sort()
    const scheduledInvoicesList = issueYearMonths.map((yearMonth) => {
      return scheduledInvoices[yearMonth]
    })
    return scheduledInvoicesList
  },

  getByIssueMonth({
    startAt,
    endAt,
    issueDay,
    issueMonths,
    paymentTermMonth,
    paymentTermDay,
    invoicesByIssueYearMonth,
    isAvoidBankHoliday,
  }) {
    const MAX_MONTH_RANGE = 48

    const _startAt = new Date(startAt)
    const _endAt = endAt ? new Date(endAt) : new Date('2999-12-31')
    const _issueMonths = issueMonthsConverter.fromBoolsToNums(issueMonths)

    const monthDiff = dateManager.calculateMonthDiff(
      _startAt,
      _endAt,
      MAX_MONTH_RANGE
    )

    let invoicesCopy = Object.assign({}, invoicesByIssueYearMonth)

    let scheduledInvoices = {}

    for (let i = 0; i <= monthDiff; i++) {
      let issueDate, payDate, invoice, issueYearMonth, created, approved, paid

      issueDate = dateManager.addDate(_startAt, i, issueDay)
      payDate = dateManager.addDate(issueDate, paymentTermMonth, paymentTermDay)
      if (isAvoidBankHoliday) {
        payDate = avoidBankHoliday(payDate)
      }
      created = false
      approved = false
      paid = false

      // check endAt
      if (dateManager.compareDate(issueDate, _endAt, '>')) {
        break
      }

      // check issue month
      if (!_issueMonths.includes(issueDate.getMonth() + 1)) {
        continue
      }

      // check invoice statuses
      issueYearMonth = dateManager.format(issueDate, 'Y-m')
      invoice = invoicesCopy[issueYearMonth]

      if (invoice) {
        delete invoicesCopy[issueYearMonth]
        issueDate = dateManager.toDate(invoice.issueDate)
        payDate = dateManager.toDate(invoice.payDate)
        created = true
        approved = invoice.approved
        paid = invoice.paid
      }

      scheduledInvoices[issueYearMonth] = {
        issueDate: dateManager.format(issueDate, 'Y/M/D'),
        payDate: dateManager.format(payDate, 'Y/M/D'),
        created: created,
        approved: approved,
        paid: paid,
      }
    }

    // add other scheduled invocies
    Object.keys(invoicesCopy).forEach((issueYearMonth) => {
      const invoice = invoicesCopy[issueYearMonth]
      const issueDate = dateManager.toDate(invoice.issueDate)
      const payDate = dateManager.toDate(invoice.payDate)
      scheduledInvoices[issueYearMonth] = {
        issueDate: dateManager.format(issueDate, 'Y/M/D'),
        payDate: dateManager.format(payDate, 'Y/M/D'),
        created: true,
        approved: invoice.approved,
        paid: invoice.paid,
      }
    })

    return scheduledInvoices
  },
}
