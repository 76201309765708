<template>
  <base-dialog
    ref="dialog"
    :title="$t('message.confirm')"
    :message="'未読状態にしますか?'"
    :cancel-text="$t('message.modal_cancel_btn')"
    :submit-text="'実行'"
    max-width="350"
    @submit="submit()"
  />
</template>

<script>
export default {
  methods: {
    open() {
      this.$refs.dialog.open()
    },
    close() {
      this.$refs.dialog.close()
    },
    submit() {
      this.$emit('submit')
      this.close()
    },
  },
}
</script>
