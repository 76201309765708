<template>
  <v-row no-gutters>
    <v-col cols="2">
      <v-text-field
        :value="value.gensenTotalTargets"
        type="number"
        min="1"
        label="対象人数"
        placeholder=" "
        dense
        filled
        outlined
        class="ma-1"
        hide-details
        :readonly="!editable"
        @change="value.gensenTotalTargets = $event ? parseInt($event) : 0"
      />
    </v-col>
    <v-col cols="10">
      <v-autocomplete
        v-model="value.gensenCode"
        :items="$store.getters.gensenType"
        label="源泉の区分"
        placeholder=" "
        dense
        filled
        outlined
        class="ma-1"
        hide-details
        item-text="name"
        item-value="code"
        :readonly="!editable"
        style="white-space: nowrap"
      />
    </v-col>
    <v-col cols="6">
      <amount-input
        v-model="value.reward_amount"
        label="報酬額"
        dense
        filled
        outlined
        class="my-2 mx-1 inputPrice"
        :readonly="!editable"
        :class="!editable ? 'reading' : 'py-1'"
      />
    </v-col>
    <v-col cols="6">
      <amount-input
        v-model="value.gensen_amount"
        label="源泉税額"
        dense
        filled
        outlined
        class="my-2 mx-1 inputPrice"
        :readonly="!editable"
        :class="!editable ? 'reading' : 'py-1'"
      />
    </v-col>
  </v-row>
</template>

<script>
import AmountInput from 'Components/Input/AmountInput'

export default {
  components: {
    AmountInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      foo: null,
    }
  },
}
</script>
