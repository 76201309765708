<template>
  <div>
    <v-row>
      <!-- 発行会社 -->
      <v-col ref="company_name" cols="8">
        <v-text-field
          v-model="companyName"
          label="請求元"
          placeholder=" "
          dense
          hide-details
          readonly
          tabindex="1"
        />
      </v-col>
      <!-- インボイス番号 -->
      <v-col ref="invoice_issuer_number" cols="4">
        <v-text-field
          v-model="invoiceIssuerNumber"
          label="インボイス番号"
          prefix="T"
          placeholder=" "
          dense
          hide-details
          readonly
          tabindex="1"
        />
      </v-col>
    </v-row>

    <v-row>
      <!-- 源泉対象 -->
      <v-col ref="gensenzei" cols="2">
        <v-chip
          small
          label
          :color="gensenzei ? 'primary' : 'grey'"
          :outlined="!gensenzei"
          :disabled="!gensenzei"
          v-text="'源泉'"
        />
      </v-col>

      <v-col ref="gensen_total_targets" cols="2">
        <v-text-field
          :value="gensenTotalTargets"
          v-tooltip="''"
          label="対象人数"
          placeholder=" "
          type="number"
          dense
          hide-details
          readonly
          hide-detail
        />
      </v-col>

      <v-col ref="gensen_code" cols="8">
        <v-autocomplete
          v-model="gensenCode"
          :items="$store.getters.gensenType"
          label="源泉の区分"
          placeholder=" "
          dense
          hide-details
          item-text="name"
          item-value="code"
          readonly
          style="white-space: nowrap"
        />
      </v-col>
    </v-row>
    <v-row>
      <!-- 支払方法 -->
      <v-col ref="pay_type" cols="3">
        <v-text-field
          v-model="payTypeName"
          label="支払方法"
          placeholder=" "
          dense
          hide-details
          readonly
          tabindex="1"
        />
      </v-col>

      <!-- 支払サイト -->
      <v-col v-if="learnPaySite" ref="pay_site" cols="3">
        <v-text-field
          v-model="paySiteName"
          label="支払サイト"
          placeholder=" "
          dense
          hide-details
          readonly
          tabindex="1"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    companyName: {
      type: String,
      required: true,
    },
    invoiceIssuerNumber: {
      type: String,
      required: true,
    },
    payTypeName: {
      type: String,
      required: true,
    },
    paySiteName: {
      type: String,
      required: true,
    },
    gensenzei: {
      type: Boolean,
      required: false,
    },
    markNames: {
      type: Array,
      default: () => [],
    },
    gensenTotalTargets: {
      type: Number,
      default: 0,
    },
    gensenCode: {
      type: String,
      default: '',
    },
    learnPaySite: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      markedElements: [],
    }
  },
  watch: {
    markNames: {
      handler: function (arr) {
        this.initalizeMark().then(() => {
          this.markChanged()
        })
      },
      immediate: true,
    },
  },
  methods: {
    markChanged() {
      // 各要素の背景色を変更
      try {
        let refs = this.$refs
        this.markNames.forEach((name) => {
          if (refs[name] !== undefined) {
            refs[name].style.backgroundColor = '#bce2e8'
            this.markedElements.push(refs[name])
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    initalizeMark() {
      let t = this
      return new Promise((resolve) => {
        // 各要素の背景色をリセット
        t.markedElements.forEach((elem) => {
          elem.style.backgroundColor = ''
        })
        t.markedElements = []
        resolve(true)
      })
    },
  },
}
</script>
