<template>
  <v-card tile flat>
    <base-dialog
      ref="dialogRevokeEdit"
      :title="'確認'"
      :message="'現在、編集中の情報が破棄されますがよろしいでしょうか？'"
      :cancel-text="'いいえ'"
      :submit-text="'はい'"
      @submit="acceptRevokeEdit"
    />
    <v-toolbar flat height="56" style="border-top: thin #ddd solid">
      <v-btn
        v-if="isDeleted"
        v-tooltip="'復元'"
        small
        icon
        fab
        tabindex="1"
        class="mr-2"
        @click="$emit('restore')"
      >
        <v-icon x-small v-text="'feather-corner-down-left'" />
      </v-btn>
      <v-btn v-else small outlined class="mr-2" @click="$emit('delete')">
        <v-icon x-small class="mr-2" v-text="'feather-trash-2'" />
        削除
      </v-btn>
      <!--v-menu
        v-model="menuMemo"
        offset-y
        top
        nudge-top="20"
        nudge-left="30"
        z-index="99"
        transition="slide-y-transition"
        :close-on-click="false"
        :close-on-content-click="false"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-tooltip="note"
            icon
            small
            fab
            v-bind="attrs"
            v-on="on"
            @click.prevent="onOpenMenu()"
          >
            <v-icon
              small
              :color="note ? 'blue':'grey'"
              v-text="note ? 'mdi-chat' : 'mdi-chat-outline'"
            />
          </v-btn>
        </template>

        <v-card
          width="400"
          class="sweeep-popover"
        >
          <v-card-title
            v-text="'管理メモ'"
          />

          <v-card-text>
            <v-textarea
              ref="noteTextArea"
              v-model="tempNote"
              outlined
              class="fs-12"
              hide-details
              :readonly="!editableNote"
            />
          </v-card-text>
          <template functional>
            <v-card-actions class="pt-0">
              <v-spacer />
              <v-btn
                small
                depressed
                @click="menuMemo=false"
                v-text="'キャンセル'"
              />
              <v-btn
                small
                depressed
                color="primary"
                :disabled="!editableNote"
                @mousedown.native="submit(tempNote)"
                v-text="'更新'"
              />
            </v-card-actions>
          </template>
        </v-card>
      </v-menu-->

      <v-menu
        v-if="displayTotal"
        v-model="menuTotal"
        offset-y
        top
        nudge-top="30"
        nudge-left="70"
        z-index="99"
        transition="slide-y-transition"
        :close-on-content-click="false"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" class="ml-4" v-on="on">
            <v-chip x-small label outlined class="px-1 mr-1" v-text="'Total'" />
            <span>{{ totalDebit | currency }}</span>
            <v-chip x-small label outlined class="px-1 mx-2" v-text="'税額'" />
            <span>
              {{ totalDebitTax | currency }}
            </span>
          </div>
        </template>

        <v-card width="400" class="sweeep-popover">
          <v-card-title v-text="'Total'" />
          <v-card-text>
            <v-row>
              <v-col>
                借方合計
                <span class="right fw-bold">{{ totalDebit | currency }}</span>
              </v-col>
              <v-col>
                貸方合計
                <span class="right fw-bold">{{ totalCredit | currency }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                差額
                <span class="right fw-bold">{{
                  (totalDebit - totalCredit) | currency
                }}</span>
              </v-col>
              <v-col>
                差額
                <span class="right fw-bold">{{
                  (totalCredit - totalDebit) | currency
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                借方消費税
                <span class="right fw-bold">{{
                  totalDebitTax | currency
                }}</span>
              </v-col>
              <v-col>
                貸方消費税
                <span class="right fw-bold">{{
                  totalCreditTax | currency
                }}</span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              depressed
              color="grey lighten-2"
              @click="menuTotal = false"
              v-text="'閉じる'"
            />
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-chip v-show="totalDebit != totalCredit" small class="ml-2 px-2" label>
        差 {{ (totalDebit - totalCredit) | currency }}
      </v-chip>
      <v-spacer />
      <v-btn
        v-show="updatable"
        v-shortkey="['ctrl', 'shift', 'enter']"
        v-tooltip="
          '<span class=v-tooltip_shortcutkey>Ctrl</span> <span class=v-tooltip_shortcutkey>Shift</span> <span class=v-tooltip_shortcutkey>Enter</span>'
        "
        small
        depressed
        color="primary"
        class="px-5"
        :loading="loadingUpdate"
        :disabled="!updatable"
        @click="
          loadingUpdate = true
          $emit('click:update')
        "
        @shortkey="displayMode == 2 && updatable ? checkUpdatable() : ''"
      >
        更新
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import TextParser from 'Components/Journal/TextParser.vue'

export default {
  components: {
    TextParser,
  },
  model: {
    prop: 'note',
    event: 'input',
  },
  props: {
    note: {
      validator: (prop) => typeof prop === 'string' || prop === null,
      required: true,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    updatable: {
      type: Boolean,
      required: true,
    },
    totalDebit: {
      type: Number,
      required: true,
    },
    totalCredit: {
      type: Number,
      required: true,
    },
    totalDebitTax: {
      type: Number,
      required: true,
    },
    totalCreditTax: {
      type: Number,
      required: true,
    },
    displayTotal: {
      type: Boolean,
      required: true,
    },
    displayMode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      menuTotal: false,
      tempNote: '',
      loadingUpdate: false,
    }
  },
  computed: {
    menuMemo: {
      get() {
        return this.$store.getters.showMemo
      },
      set(value) {
        this.$store.commit('changeShowMemo', value)
      },
    },
    editing: {
      get() {
        return this.$store.getters.editingMemo
      },
      set(value) {
        this.$store.commit('changeEditingMemo', value)
      },
    },
  },
  methods: {
    submit(tempNote) {
      this.editing = false
      this.startLoad()
      this.menuMemo = false
      this.$emit('click:update-note', tempNote)
      this.stopLoad()
    },
    switchDialog(boolean) {
      const dialog = this.$refs.dialogRevokeEdit
      if (boolean && this.editing) {
        dialog.open()
      } else {
        dialog.close()
      }
    },
    acceptRevokeEdit() {
      this.onOpenMenu()
      this.toggleEditing(false)
      this.switchDialog(false)
    },
    onOpenMenu() {
      this.tempNote = this.note
    },
    startLoad() {
      this.loadingUpdate = true
    },
    stopLoad() {
      this.loadingUpdate = false
    },
    toggleEditing(boolean) {
      this.editing = boolean
      if (boolean) {
        this.$nextTick(() => {
          this.$refs.noteTextArea.focus()
        })
      }
    },
    checkUpdatable() {
      document.activeElement.blur()
      this.loadingUpdate = true
      this.$emit('click:update')
    },
  },
}
</script>
