<template>
  <v-card class="sweeep-dialog">
    <v-card-title class="fs-12">
      {{ name }}
      <v-spacer />
      <span class="fs-10">
        このパターンが適用される条件：
        <!--
            <v-chip
            v-tooltip="'読取り時の所管部門'"
            label
            small
            color="blue lighten-4"
            >
            <v-icon
                x-small
                left
                v-text="'feather-briefcase'"
            />
            {{ pattern.department.name }}
            </v-chip>
        -->
        <s-chip-dark
          class="ml-2 px-3 py-1 has has-tw-tooltip"
          color="green-300"
          textColor="gray-900"
        >
          <v-icon x-small left v-text="'feather-user'" />
          <span class="tw-tooltip">アップロードユーザ</span>
          {{ userOrganization.name ? userOrganization.name : '指定なし' }}
        </s-chip-dark>
        <s-chip-dark
          class="ml-2 px-3 py-1 has has-tw-tooltip"
          color="yellow-400"
          textColor="gray-900"
        >
          <v-icon x-small left v-text="'feather-file-text'" />
          <span class="tw-tooltip">請求書内のキーワード</span>
          {{ keyword ? keyword : '指定なし' }}
        </s-chip-dark>
      </span>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th width="50" style="z-index: 0">貸借</th>
              <th style="z-index: 0">
                {{ definition.account_title }}
              </th>
              <th style="z-index: 0">
                {{ definition.department }}
              </th>
              <th style="z-index: 0">
                {{ definition.tax }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <s-chip-dark color="blue-500" class="px-3"> 借方 </s-chip-dark>
              </td>
              <td>
                {{ getJounralName(journals[0], 'debit_account_title') }}
              </td>
              <td>
                {{ getJounralName(journals[0], 'debit_department') }}
              </td>
              <td>
                {{ getJounralName(journals[0], 'debit_tax') }}
              </td>
            </tr>
            <tr>
              <td>
                <s-chip-dark color="green-500" class="px-3"> 貸方 </s-chip-dark>
              </td>
              <td>
                {{ getJounralName(journals[0], 'credit_account_title') }}
              </td>
              <td>
                {{ getJounralName(journals[0], 'credit_department') }}
              </td>
              <td>
                {{ getJounralName(journals[0], 'credit_tax') }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { getJounralName } from 'Helpers/company'
import { mapGetters } from 'vuex'

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    userOrganization: {
      type: Object,
      required: true,
    },
    journals: {
      type: Array,
      required: true,
    },
    keyword: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['definition']),
  },
  methods: {
    getJounralName: (journal, attrName) => getJounralName(journal, attrName),
  },
}
</script>
