<template>
  <span v-tooltip="tooltipText">
    <s-chip :color="chipColor">
      {{ statusText }}
    </s-chip>
  </span>
</template>

<script>
export default {
  props: {
    item: Object,
    payConfirmSettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    statusText: function () {
      if (this.item.pay_export_count > 0) {
        return '出力済'
      }
      if (this.item.paid_user.id) {
        return '支払済'
      }
      if (this.item.pay_approve_user.id) {
        return '連携済'
      }
      if (this.payConfirmSettings.pay_confirm3.display && this.item.pay_confirm_user3.id) {
        return this.payConfirmSettings.pay_confirm3.name
      }
      if (this.payConfirmSettings.pay_confirm2.display && this.item.pay_confirm_user2.id) {
        return this.payConfirmSettings.pay_confirm2.name
      }
      if (this.payConfirmSettings.pay_confirm1.display && this.item.pay_confirm_user.id) {
        return this.payConfirmSettings.pay_confirm1.name
      }
      if (this.item.user_update_bank.id) {
        return '更新済'
      }
      return '未更新'
    },
    chipColor: function () {
      if (this.item.pay_export_count > 0) {
        return 'blue-500'
      }
      if (this.item.paid_user.id) {
        return 'red-500'
      }
      if (this.item.pay_approve_user.id) {
        return 'pink-500'
      }
      if (this.payConfirmSettings.pay_confirm3.display && this.item.pay_confirm_user3.id) {
        return 'yellow-500'
      }
      if (this.payConfirmSettings.pay_confirm2.display && this.item.pay_confirm_user2.id) {
        return 'yellow-500'
      }
      if (this.payConfirmSettings.pay_confirm1.display && this.item.pay_confirm_user.id) {
        return 'yellow-500'
      }
      if (this.item.user_update_bank.id) {
        return 'green-500'
      }
      return 'gray-500'
    },
    tooltipText: function () {
      let text = ''

      text += `更新 ${this.item.date_update_bank} ${this.getFullName(
        this.item.user_update_bank
      )}<br>`

      if (this.payConfirmSettings.pay_confirm1.display) {
        text += `
          ${this.payConfirmSettings.pay_confirm1.name}
          ${this.item.date_pay_confirm}
          ${this.getFullName(this.item.pay_confirm_user)}
          <br>
        `
      }
      if (this.payConfirmSettings.pay_confirm2.display) {
        text += `
          ${this.payConfirmSettings.pay_confirm2.name}
          ${this.item.date_pay_confirm2}
          ${this.getFullName(this.item.pay_confirm_user2)}
          <br>
        `
      }
      if (this.payConfirmSettings.pay_confirm3.display) {
        text += `
          ${this.payConfirmSettings.pay_confirm3.name}
          ${this.item.date_pay_confirm3}
          ${this.getFullName(this.item.pay_confirm_user3)}
          <br>
        `
      }
      text += `連携 ${this.item.date_pay_approve} ${this.getFullName(
        this.item.pay_approve_user
      )}<br>`
      text += `支払 ${this.item.date_paid} ${this.getFullName(
        this.item.paid_user
      )}<br>`
      text += `支払仕訳エクスポート ${this.item.pay_export_count}回 ${
        this.item.date_pay_export
      } ${this.getFullName(this.item.pay_export_user)}`
      return text
    },
  },
  methods: {
    getFullName(item) {
      try {
        return item.full_name ? item.full_name : ''
      } catch {
        return ''
      }
    },
  },
}
</script>
