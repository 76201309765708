<template>
  <base-dialog
    ref="dialog"
    :title="$t('message.send_email')"
    :message="$t('message.confirm_send_invoice_email')"
    :cancel-text="$t('message.modal_cancel_btn')"
    :submit-text="$t('message.modal_send_btn')"
    :disabled-submit="!comment"
    :loading="loading"
    max-width="300"
    @submit="submit()"
  >
    <template #card-content>
      <v-textarea
        v-model="comment"
        outlined
        tabindex="1"
        class="fs-11 more-dense mb-3"
        hide-details
        :label="$t('message.comment')"
        required
      />
    </template>
  </base-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      comment: ""
    }
  },
  methods: {
    open() {
      this.comment = ""
      this.$refs.dialog.open()
    },
    close() {
      this.$refs.dialog.close()
    },
    setLoading(v) {
      this.loading = v
    },
    submit() {
      this.$emit('submit', this.comment)
    },
  },
}
</script>
