<template>
  <v-container fluid pa-0 :class="showFullScreen ? 'full-screen' : ''">
    <confirm-dialog ref="confirmDialog" />
    <no-privilege-dialog ref="noPrivilegeDialog" />
    <invalid-form-dialog ref="invalidForm" />
    <invoice-email-dialog
      ref="emailDialog"
      @submit="onSendEmail"
    />
    <discard-edit-dialog
      ref="discardEdit"
      @submit="changeInvoice(selectedId, fillOffset)"
    />
    <force-edit-dialog
      ref="forceEditDialog"
      :editing-users="editingUsers"
      @submit="$store.dispatch('onEditMode')"
    />
    <delete-invoice-viewed-dialog
      ref="deleteInvoiceViewedDialog"
      :user-viewed="formInvoice.userViewed"
      @submit="deleteInvoiceViewed"
    />
    <change-department-dialog
      ref="changeDepartmentDialog"
      @submit="submitDepartment"
    />
    <change-department-warn-dialog
      ref="changeDepartmentWarnDialog"
      @submit="submitDepartment($event, false)"
    />
    <freee-post-dialog
      ref="freeePostDialog"
      :freee-separate-approval="freeeSeparateApproval"
      @posted:deal="getInvoiceDetail(false)"
    />
    <request-next-approval-dialog
      ref="requestNextApprovalDialog"
      :users="nextApprovalUsers"
      :loading="loadingRequestNextApproval"
      @submit="requestNextApproval($event)"
    />
    <confirm-learning-dialog
      v-if="checkPrivilege('company:update')"
      :dialog="showUpdateDialog"
      :form-type="formType"
      :user-organizations="userList"
      :pdf-url="invoiceFile.cachedUrl"
      :show-user-bank="userBankList.length > 1"
      v-bind="updateDialogConfig"
      @close="showUpdateDialog = false"
      @submit="learningInvoice($event)"
    />
    <template v-if="!loadingInvoiceModule">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="1"
          class="flex-grow-1 flex-shrink-0"
          style="min-width: 205px"
        >
          <invoice-list
            ref="invoiceList"
            :data="invoiceList"
            :table-loader="tableLoader"
            :display-mode="displayMode"
            :history-open="historyOpen"
            :approve-settings="approveSettings"
            :pay-confirm-settings="payConfirmSettings"
            :add-table-data="addTableData"
            :show-full-screen="showFullScreen"
            @update:selected_invoice_array="selectedInvoiceArray = $event"
            @select:invoice="selectInvoice"
            @toggle-screen="showFullScreen = !showFullScreen"
          />
        </v-col>

        <v-col v-if="!$route.params.id">
          <p class="pa-4 ma-auto">請求書が選択されていません。</p>
        </v-col>

        <v-col
          v-show="$route.params.id && invoicePrivilege.read"
          cols="1"
          class="flex-grow-1 flex-shrink-0"
          style="min-width: 500px"
        >
          <invoice-info
            ref="invoiceInfo"
            :form-invoice="formInvoice"
            :recurring-schedule="recurringSchedule"
            :invoice-list="invoiceList"
            :table-data="tableData"
            :filter-result-count="filterResultCount"
            :invoice-id-rendered="invoiceIdRendered"
            :invoice-journal-length-rendering="invoiceJournalLengthRendering"
            :payment-journal-length-rendering="paymentJouranlLengthRendering"
            :learning-candidates="learningCandidates"
            :display-mode="displayMode"
            :user-bank-list="userBankList"
            :learning-amount-menu-setting="learningAmountMenuSetting"
            :form-type="formType"
            :company-list="companyList"
            :show-full-screen="showFullScreen"
            :setting-foreign-currency="organization.settingForeignCurrency"
            @click:edit="onClickToEditMode"
            @select:invoice="selectInvoice"
            @get:table="getTableData"
            @get:invoice="getInvoiceDetail"
            @deleted="onDeletedInvoice"
            @restored="onRestoredInvoice"
            @change:formInvoice="onChangeFormInvoice($event)"
            @click:department="onClickDepartment"
            @click-create-recurring-schedule="onClickCreateRecurringSchedule"
            @click-update-recurring-schedule="onClickUpdateRecurringSchedule"
            @change:selected-amount="selectedCandidatesAmount($event)"
            @updated:invoice="onUpdatedInvoice()"
            @submit:allocation="setAllocationJournal($event)"
          />
        </v-col>

        <v-col
          v-show="$route.params.id && invoicePrivilege.read"
          cols="10"
          class="flex-grow-0 flex-shrink-1"
          style="min-width: 0px"
        >
          <invoice-area
            :loading="loadingImage || loadingPdf"
            :invoice-form="formInvoice"
            :invoice-file="invoiceFile"
            :action-logs="actionLogs"
            :match-company-bank="matchCompanyBank"
            :invoice-area-bank-icon-color="invoiceAreaBankIconColor"
            :freee-separate-approval="freeeSeparateApproval"
            :show-full-screen="showFullScreen"
            @import-image="importImage"
            @version-down="downVersion"
            @version-up="upVersion"
            @open-history="openhistory"
            @download-file="downloadFile"
            @click:sendEmail="onClickSendEmail"
            @click:freeeDeal="onClickFreeeDeal"
            @click:updateNote="onClickUpdateNote"
            @click:cancelUserViewed="onClickCancelUserViewed"
            @click:approveInvoice="onClickApproveInvoice"
            @click:approvePayment="onClickApprovePayment"
            @click:cancelApproveInvoice="onClickCancelApproveInvoice"
            @click:cancelApprovePayment="onClickCancelApprovePayment"
          />
        </v-col>
      </v-row>

      <invoice-history
        ref="invoiceHistory"
        :display="historyOpen"
        :form-invoice="formInvoice"
        :approve-settings="approveSettings"
        :pay-confirm-settings="payConfirmSettings"
        @close-history="closehistory"
      />
    </template>
    <template v-else>
      <div class="app-flex justify-center align-center h-vh-100">
        <invoice-module-loader />
      </div>
    </template>
  </v-container>
</template>

<style scoped>
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  background: #fff;
  width: 100% !important;
}
</style>

<script>
import axios from 'axios'
import Vue from 'vue'
import { mapGetters } from 'vuex'

import Snackbar from 'Helpers/snackbar/index'
import { getAbilityLevel } from 'Helpers/role/index'
import { consoleLog, deepCopy } from 'Helpers/helpers'
import { diffInvoiceForm } from 'Helpers/invoice/diff'
import { changeDateStringFormat } from 'Helpers/date'
import { diffInvoiceCompany } from 'Helpers/invoice/diff'
import { getCompanyJournalModel } from '@/objects/company/companyPattern'
import {
  companyService,
  getCompanyListContent,
} from '@/services/company/company'
import { invoiceEmailService } from '@/services/invoice/invoiceEmail'
import { companyListService } from '@/services/company/companyList'
import { learningInvoice } from '@/services/company/learningInvoice'
import companyCandidatesService from '@/services/company/companyCandidatesService'
import { isString } from 'Helpers/string'
import {
  checkNull,
  currencyToNumber,
  numberToCurrency,
  floatToCurrency,
  asyncSetJournals,
} from 'Helpers/journal'
import ConfirmLearningDialog from 'Views/invoice/components/Dialog/ConfirmLearningDialog'
import {
  createCompanyParam,
  createCompanyPatternParam,
} from '@/services/company/createCompanyParams'

import InvoiceList from './components/InvoiceList'
import InvoiceInfo from './components/InvoiceInfo/InvoiceInfo'
import InvoiceArea from './components/InvoiceArea/InvoiceArea'
import InvoiceHistory from './components/InvoiceHistory/InvoiceHistory'
import InvoiceModuleLoader from './components/InvoiceModuleLoader'

// Dialogs
import InvoiceEmailDialog from './components/Dialog/InvoiceEmailDialog'
import DiscardEditDialog from './components/Dialog/DiscardEditDialog'
import ForceEditDialog from './components/Dialog/ForceEditDialog'
import DeleteInvoiceViewedDialog from './components/Dialog/DeleteInvoiceViewedDialog'
import ChangeDepartmentDialog from 'Views/invoice/components/Dialog/ChangeDepartmentDialog'
import ChangeDepartmentWarnDialog from 'Views/invoice/components/Dialog/ChangeDepartmentWarnDialog'
import FreeePostDialog from 'Views/invoice/components/Dialog/FreeePostDialog'
import InvalidFormDialog from 'Views/invoice/components/Dialog/InvalidFormDialog.vue'
import RequestNextApprovalDialog from 'Views/invoice/components/Dialog/RequestNextApprovalDialog.vue'

export default {
  components: {
    InvoiceList,
    InvoiceInfo,
    InvoiceArea,
    InvoiceHistory,
    InvoiceModuleLoader,
    InvoiceEmailDialog,
    DiscardEditDialog,
    ForceEditDialog,
    DeleteInvoiceViewedDialog,
    ChangeDepartmentDialog,
    ChangeDepartmentWarnDialog,
    FreeePostDialog,
    InvalidFormDialog,
    RequestNextApprovalDialog,
    ConfirmLearningDialog,
  },
  props: {
    nextApprovalUsers: {
      type: Array,
      default: null,
    },
    learningAmountMenuSetting: {
      type: Object,
      required: true,
    },
    userList: {
      type: Array,
      required: true,
    },
    filterResultCount: Object,
    organization: null,
    mode: null,
    tableData: null,
    invoiceList: null,
    tableLoader: null,
    removeInvoice: Function,
    displayMode: null,
    side_filter_width: '',
    user: null,
    bankData: Object,
    getSubjectList: Function,
    loading_bank: null,
    approveLevel: null,
    approveSettings: Object,
    resportSetting: null,
    addTableData: Function,
    payTypes: Array,
    getRecentUseList: Function,
    getTableData: Function,
    freeeSeparateApproval: Boolean,
    userBankList: {
      type: Array,
      required: true,
    },
    payConfirmSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      actionLogs: [],
      splitSize: 0,
      clientHeight: 0,
      isAllowChange: false,
      resizerTop: '',
      resizerRoot: '',
      loadingImage: true,
      loadingPDF: true,
      loadingRequestNextApproval: false,
      selectedInvoiceArray: '',

      historyOpen: false,
      fillOffset: false,
      invoiceJournalLengthRendering: 0,
      paymentJouranlLengthRendering: 0,

      invoiceIdRendered: 0,

      formInvoiceBefore: {},

      selectedId: '',
      stateJournalRendering: [],

      showFullScreen: false,

      invoiceFile: {
        imageUris: [],
        fileName: '',
        pdfUrl: '',
        cachedUrl: '',
        filePage: 1,
        currentVersion: 1,
        maxVersion: 1,
        displayPdf: false,
        isStamped: false,
        isExpired: false,
      },
      learningCandidates: {
        amountList: [],
      },
      formInvoice: {
        //created setInvoiceForm
        department: {},
        pdf_file_path: '',
        approved: 0,
        select: null,
        checkbox: false,
        valid: true,
        loading: false,
        learned: false,
        learning: '',
        learning_pay_site: null,
        changed: 0,
        editingDate: null,
        copied: null,
        scoreMaxAmount: null,
        isDeleted: false,
        idFreee: null,

        gensenCode: '',
        gensenTotalTargets: 0,

        invoice_id: null,
        date: null,
        summary: '',
        name: '',
        isInvoiceIssuer: '',
        invoiceIssuerNumber: '',
        tel: '',
        email: '',
        description: '',
        gensenzei: null,

        note_1: '',
        note_2: '',
        note_3: '',
        inboxPurpose: '',
        inboxComment: '',

        amount: null,
        tax_amount: null,
        pay_amount: null,
        gensen_amount: null,
        reward_amount: null,
        foreign_currency_amount: '0.00',

        isForeignCurrency: false,

        currency_code: {},

        approved1: '',
        approved2: '',
        approved3: '',
        approved4: '',

        approve_user1: '',
        approve_user2: '',
        approve_user3: '',
        approve_user4: '',
        approve_user5: '',

        pay_confirm_user: '',
        pay_confirm_user2: '',
        pay_confirm_user3: '',
        pay_approve_user: '',
        paid_user: '',

        date_pay_confirm: '',
        date_pay_confirm2: '',
        date_pay_confirm3: '',
        date_pay_approved: '',
        date_paid: '',

        userUploaded: {},
        userUpdated: {},
        userUpdatedPay: {},
        userViewed: {},
        userExported: {},
        dateUploaded: '',
        dateUpdated: '',
        dateUpdatedPay: '',
        dateViewed: '',
        dateExported: '',

        pay_confirm: 0,
        pay_approved: 0,
        paid: 0,
        pay_date: null,
        pay_type: 1,
        pay_month: null,
        pay_day: null,
        payment_note1: '',
        payment_note2: '',
        payment_note3: '',

        user_bank: {},
        paid_by_customer: false,

        bank_name: '',
        bank_code: '',
        bank_branch_name: '',
        bank_branch_code: '',
        bank_account_type: '',
        bank_account_number: '',
        bank_account_name: '',
        bank_list: [{}],
        bank_read_list: [{}],

        inboxId: '',

        image_summary_journal: [
          {
            debit_amount_input_mode: 2,
            debit_amount: '0',
            debit_amount_without_tax: '0',
            debit_tax_amount: '0',
            debit_foreign_currency_amount: '0',
            debit_amount_without_tax_after_adjustment: '0',
            debit_tax_amount_after_adjustment: '0',
            debit_is_keikasochi: false,

            credit_amount_input_mode: 2,
            credit_amount: '0',
            credit_tax_amount: '0',
            credit_amount_without_tax: '0',
            credit_foreign_currency_amount: '0',
            credit_amount_without_tax_after_adjustment: '0',
            credit_tax_amount_after_adjustment: '0',
            credit_is_keikasochi: false,

            description: '',
            free_text_1: '',
            free_text_2: '',

            debit_account_title: {},
            debit_sub_account: {},
            debit_department: {},
            debit_vendor: {},
            debit_project: {},
            debit_segment: {},
            debit_item: {},
            debit_tag: {},
            debit_walletable: {},
            debit_tax: {},

            credit_account_title: {},
            credit_sub_account: {},
            credit_department: {},
            credit_vendor: {},
            credit_project: {},
            credit_segment: {},
            credit_item: {},
            credit_tag: {},
            credit_walletable: {},
            credit_tax: {},
          },
        ],

        payment_journals: [
          {
            debit_amount_input_mode: 2,
            debit_amount: '0',
            debit_amount_without_tax: '0',
            debit_tax_amount: '0',

            credit_amount_input_mode: 2,
            credit_amount: '0',
            credit_tax_amount: '0',
            credit_amount_without_tax: '0',

            description: '',
            free_text_1: '',
            free_text_2: '',

            debit_account_title: {},
            debit_sub_account: {},
            debit_department: {},
            debit_vendor: {},
            debit_project: {},
            debit_segment: {},
            debit_item: {},
            debit_tag: {},
            debit_walletable: {},
            debit_tax: {},

            credit_account_title: {},
            credit_sub_account: {},
            credit_department: {},
            credit_vendor: {},
            credit_project: {},
            credit_segment: {},
            credit_item: {},
            credit_tag: {},
            credit_walletable: {},
            credit_tax: {},
          },
        ],

        company: {
          user_bank: {},
          paid_by_customer: false,
          bank_name: '',
          bank_branch_name: '',
          bank_account_name: '',
          bank_account_type: '',
          bank_account_number: '',
        },
        companyPatterns: [],
        company_journal: null,
        matchingWay: '',
        scannedPhoneNumber: '',
        scannedCompanyName: '',
        scannedCompanyAddress: '',
      },
      showUpdateDialog: false,
      updateDialogConfig: {
        newCompany: false,
        invoice: {},
        company: {},
        diffObjects: [],
        loading: false,
      },
      companyList: [],
    }
  },
  computed: {
    ...mapGetters({
      editMode: 'editMode',
      editingUsers: 'editingUsers',
      loadingInvoiceModule: 'loadingInvoiceModule',
      invoicePrivilege: 'invoicePrivilege',
      editedInvoiceForm: 'editedInvoiceForm',
      recurringSchedule: 'recurringInvoiceSchedule',
      sendApprovalRequest: 'sendApprovalRequest',
      settingJournal: 'settingJournal',
      settingPayJournal: 'settingPayJournal',
      authToken: 'getAuthToken',
      organizationId: 'getOrganizationID',
    }),
    formType() {
      if (this.settingJournal && this.settingPayJournal) {
        // 仕訳 & 支払仕訳 & 支払
        return 1
      } else if (this.settingJournal) {
        // 仕訳 & 支払
        return 2
      } else {
        // 支払
        return 3
      }
    },
    invoiceSidebar: {
      get() {
        return this.$store.getters.invoiceSidebar
      },
      set(val) {
        this.$store.dispatch('toggleInvoiceSidebar', val)
      },
    },
    matchCompanyBank() {
      const checkCompanyBankDiff = () => {
        try {
          const { company, learned } = this.formInvoice

          if (!(learned && company && company.id)) {
            return false
          }
          const keys = [
            'bank_name',
            'bank_branch_name',
            'bank_account_name',
            'bank_account_type',
            'bank_account_number',
          ]
          const hasDiff = keys.some(
            (key) => this.formInvoice[key] !== company[key]
          )
          return !hasDiff
        } catch (e) {
          console.log(e)
        }
      }
      return checkCompanyBankDiff()
    },
    invoiceAreaBankIconColor() {
      const formInvoice = this.formInvoice
      const appliedCompanyBankSinceNoReadBank =
        formInvoice.bank_read_list.length === 0 &&
        formInvoice.bank_list.length === 1 &&
        !formInvoice.dateUpdatedPay
      const hasLearnedBank =
        formInvoice.bank_read_list.length !== formInvoice.bank_list.length

      if (appliedCompanyBankSinceNoReadBank) {
        return 'yellow'
      } else if (this.matchCompanyBank) {
        return 'green'
      } else if (!this.matchCompanyBank && hasLearnedBank) {
        return 'red'
      } else {
        return 'grey'
      }
    },
  },
  watch: {
    '$route.params.id': function (to) {
      if (to) {
        this.getPdfUrl()
        this.getActionLogs()
        this.$store
          .dispatch('getEditingUsers', to)
          .then((exists) => {
            if (exists) {
              this.alertEditingWarn()
            } else {
              Snackbar.close()
            }
            return this.getInvoiceDetail()
          })
          .then((success) => {
            if (!success) {
              return
            }
            if (this.editMode && this.$refs.invoiceInfo.editable) {
              this.$store.dispatch('onEditMode')
            } else {
              this.$store.dispatch('offEditMode')
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.$store.dispatch('clearEditMode')
      }
    },
    editMode: function (to) {
      if (!to) {
        this.$store.dispatch('offEditMode')
      }
    },
    formInvoice: {
      handler() {
        this.onChangedInvoiceForm()
      },
      deep: true,
    },
  },
  updated() {
    consoleLog('Render', 'InvoiceDetail')
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.leaveHandler)
  },
  mounted() {
    try {
      this.getInvoiceDetail()
      this.getPdfUrl()
      this.getActionLogs()
      if (this.$route.params.id) {
        this.$store.dispatch('offEditMode')
      }
      this.setCompanyList()
    } catch (e) {
      console.log(e)
    }
  },
  beforeDestroy() {
    try {
      this.$store.dispatch('clearEditMode')
      window.removeEventListener('beforeunload', this.leaveHandler)
      window.removeEventListener('resize', this.onResizeWindow)
    } catch (error) {
      console.log(error)
    }
  },
  destroyed() {
    URL.revokeObjectURL(this.invoiceFile.cachedUrl)
    const defaultLayout = document.querySelector(
      '.app-default-layout .v-content__wrap'
    )
    if (defaultLayout) {
      defaultLayout.style.overflow = 'auto'
    }
  },
  methods: {
    onClickCancelUserViewed() {
      try {
        if (!this.formInvoice.userViewed || !this.formInvoice.userViewed.id) {
          return
        }
        const checkId = function (obj) {
          if (typeof obj !== 'object' || !obj) {
            return false
          }
          if (obj.id) {
            return true
          } else {
            return false
          }
        }
        const userKeys = [
          'userUpdated',
          'approve_user1',
          'approve_user2',
          'approve_user3',
          'approve_user4',
          'approve_user5',
          'pay_confirm_user',
          'pay_confirm_user2',
          'pay_confirm_user3',
          'pay_approve_user',
          'paid_user',
        ]

        const form = this.formInvoice
        const cannotCanelViewd = userKeys.some((key) => checkId(form[key]))
        if (cannotCanelViewd) {
          this.openInterruptedCancelViewedDialog()
          return
        }
        const userOrgIdSelf = this.$store.getters.getUserOrganizationID
        const userOrgIdViewer = this.formInvoice.userViewed.id

        if (userOrgIdSelf === userOrgIdViewer) {
          this.$refs.deleteInvoiceViewedDialog.open()
        }
      } catch (err) {
        console.log(err)
      }
    },
    onClickApproveInvoice(level) {
      if (this.editMode && this.editedInvoiceForm) {
        this.openInterruptedApproveDialog()
        return
      }
      if (level < 5) {
        this.approveInvoice(level)
      } else if (level == 5) {
        this.finalApproveInvoice()
      } else if (level == 'freee') {
        this.freeeApproveInvoice()
      }
    },
    onClickCancelApproveInvoice(level) {
      if (this.editMode && this.editedInvoiceForm) {
        this.openInterruptedApproveDialog()
        return
      }
      if (level < 5) {
        this.cancelApproveInvoice(level)
      } else if (level == 5) {
        this.cancelFinalApproveInvoice()
      }
    },
    onClickApprovePayment(level) {
      if (this.editMode && this.editedInvoiceForm) {
        this.openInterruptedApproveDialog()
        return
      }
      if (level < 4) {
        this.confirmPayment(level)
      } else if (level == 4) {
        this.approvePayment()
      } else if (level == 5) {
        this.approvePaid()
      }
    },
    onClickSendEmail() {
      this.$refs.emailDialog.open()
    },
    async onSendEmail(comment) {
      const getUserOrgId = (obj) => {
        return obj? obj.id : undefined
      }
      const token = this.$store.getters.getAuthToken
      const toUserOrganizationIds = [
        getUserOrgId(this.formInvoice.approve_user1),
        getUserOrgId(this.formInvoice.approve_user2),
        getUserOrgId(this.formInvoice.approve_user3),
        getUserOrgId(this.formInvoice.approve_user4),
        getUserOrgId(this.formInvoice.approve_user5),
        getUserOrgId(this.formInvoice.userUploaded),
        getUserOrgId(this.formInvoice.userUpdated),
      ].filter(v => v)

      if (toUserOrganizationIds.length === 0) {
        // アップロードユーザは必ず存在するのでこのケースはない想定
        this.$refs.emailDialog.close()
        return
      }
      this.$refs.emailDialog.setLoading(true)

      try {
        await invoiceEmailService.sendInvoiceEmail(token, {
          localId:this.formInvoice.local_id,
          toUserOrganizationIds,
          subject: "[sweeep] 仕訳承認時コメント",
          comment: comment,
        })
        Snackbar.success('メール送信が完了しました')
      } catch (e) {
        Snackbar.error('メール送信に失敗しました')
      }
      this.$refs.emailDialog.setLoading(false)
      this.$refs.emailDialog.close()
    },
    onClickCancelApprovePayment(level) {
      if (this.editMode && this.editedInvoiceForm) {
        this.openInterruptedApproveDialog()
        return
      }
      if (level < 4) {
        this.cancelConfirmPayment(level)
      } else if (level == 4) {
        this.cancelApprovePayment()
      } else if (level == 5) {
        this.cancelApprovePaid()
      }
    },
    onClickFreeeDeal() {
      const dealId = this.formInvoice.idFreee
      this.$store.dispatch('openFreeeDeal', dealId)
    },
    onPostedFreeeDeal({ localId, freeeDealId }) {
      if (localId === this.$route.params.id) {
        this.formInvoice.idFreee = freeeDealId
      }
    },
    onClickUpdateNote({ number, text }) {
      const data = {
        image_summary_id: this.formInvoice.invoice_id,
      }
      const field = `note_${number}`
      const value = text
      data[field] = value
      this.$store.dispatch('updateInvoiceNote', data).then((response) => {
        if (response.data.status === 'success') {
          Snackbar.success('更新しました')
          const payload = {}
          payload[field] = value
          this.onChangeFormInvoice(payload)
        } else {
          Snackbar.error('更新に失敗しました')
        }
      })
    },
    onClickCreateRecurringSchedule(form) {
      const payload = {
        invoiceId: this.formInvoice.invoice_id,
        startAt: form.startAt,
        endAt: form.endAt,
        issueMonths: form.issueMonths,
        issueDay: form.issueDay,
        paymentTermDay: form.paymentTermDay,
        paymentTermMonth: form.paymentTermMonth,
      }
      this.$store
        .dispatch('createRecurringInvoiceSchedule', payload)
        .then((schedule) => {
          Snackbar.success('定期払いを登録しました')
          this.onChangeFormInvoice({ schedule: schedule })
        })
        .catch((err) => {
          Snackbar.error(err.message)
        })
    },
    onClickUpdateRecurringSchedule(form) {
      const payload = {
        scheduleId: form.id,
        endAt: form.endAt,
        issueMonths: form.issueMonths,
        issueDay: form.issueDay,
        paymentTermDay: form.paymentTermDay,
        paymentTermMonth: form.paymentTermMonth,
      }
      this.$store
        .dispatch('updateRecurringInvoiceSchedule', payload)
        .then((schedule) => {
          Snackbar.success('定期払いを更新しました')
        })
        .catch((err) => {
          Snackbar.error(err.message)
        })
    },
    alertEditingWarn() {
      let msg
      let editingUsers = this.$store.getters.editingUsers
      editingUsers = editingUsers.map((obj) => obj.name).join('さん、')
      if (this.editMode) {
        msg =
          editingUsers +
          'さんが編集中です。更新するとそれぞれの修正が反映される場合があります。'
      } else {
        msg = editingUsers + 'さんが編集中です。'
      }
      Snackbar.error(msg, { centered: true, top: true })
    },
    onChangedInvoiceForm() {
      try {
        if (!this.$route.params.id) {
          return
        }
        if (this.$store.getters.loadingInvoiceDetail) {
          return
        }
        const editedFields = diffInvoiceForm(
          this.formInvoiceBefore,
          this.formInvoice
        )
        this.$store.commit('setEditedInvoiceFormFields', editedFields)
      } catch (e) {
        console.log(e)
      }
    },
    setLayout() {
      this.clientHeight = document.documentElement.clientHeight
      this.splitSize = this.clientHeight
      this.resizerTop = document.getElementsByClassName('Resizer rowsres')[0]
      this.resizerRoot = document.getElementsByClassName('pane-rs root rows')[0]
      document.querySelector('html').style.overflow = 'hidden'
      const defaultLayout = document.querySelector(
        '.app-default-layout .v-content__wrap'
      )
      if (defaultLayout) {
        defaultLayout.style.overflow = 'hidden'
      }
      if (defaultLayout) {
        defaultLayout.style.overflow = 'hidden'
      } else {
        document.querySelector(
          '.app-horizontal-layout .v-content__wrap'
        ).style.overflow = 'hidden'
      }
      window.addEventListener('resize', this.onResizeWindow)
    },
    leaveHandler() {
      this.$store.dispatch('clearEditMode')
    },
    onResizeWindow() {
      this.clientHeight = document.documentElement.clientHeight
    },
    openDialog(ref) {
      this.$refs[ref].open()
    },
    closeDialog(ref) {
      this.$refs[ref].close()
    },
    checkMemoEditing() {
      if (this.$store.getters.editingMemo) {
        return true
      } else {
        this.$store.commit('changeShowMemo', false)
        return false
      }
    },
    selectInvoice(id, fillOffset = false) {
      try {
        this.fillOffset = fillOffset
        if (this.editedInvoiceForm && this.editMode) {
          this.selectedId = id
          this.openDialog('discardEdit')
        } else if (this.checkMemoEditing()) {
          Snackbar.error('管理メモを閉じるかまたは更新してください')
          return
        } else {
          this.changeInvoice(id, this.fillOffset)
        }
      } catch (e) {}
    },
    changeInvoice(id, fillOffset = false) {
      this.invoiceFile.imageUris = []
      this.$router.replace('/invoice/' + id)
      if (fillOffset) {
        this.$refs.invoiceList.changeListOffset(id)
      }
    },
    setInvoiceForm(response) {
      return new Promise((resolve, reject) => {
        try {
          const getUserInfo = (user) => {
            if (!user) {
              return null
            }
            const userInfo = {
              id: user.id || '',
              full_name: user.user.full_name || '',
              code: user.code || '',
              department_name: user.department ? user.department.name : '',
              department_code: user.department ? user.department.code : '',
            }
            return userInfo
          }
          const resetFormHeight = (elm) => {
            if (elm) {
              elm.scrollTop = 0
            }
          }
          var invoice = response.invoice_summary
          var invoice_journals = response.invoice_journals
          var payment_journals = response.payment_journals

          this.invoiceJournalLengthRendering = invoice_journals.length
          this.paymentJouranlLengthRendering = payment_journals.length
          let promisesForRendering = []
          if (invoice_journals) {
            for (let x = 0; x < invoice_journals.length; x++) {
              var invoice_journal = invoice_journals[x]
              invoice_journal.debit_amount = numberToCurrency(
                invoice_journal.debit_amount
              )
              invoice_journal.debit_amount_without_tax = numberToCurrency(
                invoice_journal.debit_amount_without_tax
              )
              invoice_journal.debit_tax_amount = numberToCurrency(
                invoice_journal.debit_tax_amount
              )
              // 経過措置の場合, APIとUIで持ち方が異なるため, ここで変換
              if (invoice_journal.debit_is_keikasochi) {
                invoice_journal.debit_amount_without_tax_after_adjustment = invoice_journal.debit_amount_without_tax 
                invoice_journal.debit_tax_amount_after_adjustment = invoice_journal.debit_tax_amount
                invoice_journal.debit_amount_without_tax = numberToCurrency(
                  invoice_journal.debit_amount_without_tax_before_adjustment
                )
                invoice_journal.debit_tax_amount = numberToCurrency(
                  invoice_journal.debit_tax_amount_before_adjustment
                )
              }
              invoice_journal.credit_amount = numberToCurrency(
                invoice_journal.credit_amount
              )
              invoice_journal.credit_amount_without_tax = numberToCurrency(
                invoice_journal.credit_amount_without_tax
              )
              invoice_journal.credit_tax_amount = numberToCurrency(
                invoice_journal.credit_tax_amount
              )
              // 経過措置の場合, APIとUIで持ち方が異なるため, ここで変換
              if (invoice_journal.credit_is_keikasochi) {
                invoice_journal.credit_amount_without_tax_after_adjustment = invoice_journal.credit_amount_without_tax 
                invoice_journal.credit_tax_amount_after_adjustment = invoice_journal.credit_tax_amount
                invoice_journal.credit_amount_without_tax = numberToCurrency(
                  invoice_journal.credit_amount_without_tax_before_adjustment
                )
                invoice_journal.credit_tax_amount = numberToCurrency(
                  invoice_journal.credit_tax_amount_before_adjustment
                )
              }

              invoice_journal.debit_foreign_currency_amount = floatToCurrency(
                invoice_journal.debit_foreign_currency_amount
              )
              invoice_journal.credit_foreign_currency_amount = floatToCurrency(
                invoice_journal.credit_foreign_currency_amount
              )

              invoice_journal.description = checkNull(
                invoice_journal.description
              )
              invoice_journal.free_text_1 = checkNull(
                invoice_journal.free_text_1
              )
              invoice_journal.free_text_2 = checkNull(
                invoice_journal.free_text_2
              )
            }
            promisesForRendering = asyncSetJournals(
              [...invoice_journals],
              this.formInvoice.image_summary_journal
            )
          }

          if (payment_journals) {
            // this.formInvoice.payment_journals = payment_journals
            for (let x = 0; x < payment_journals.length; x++) {
              var pay_journal = payment_journals[x]
              pay_journal.debit_amount = numberToCurrency(
                pay_journal.debit_amount
              )
              pay_journal.debit_amount_without_tax = numberToCurrency(
                pay_journal.debit_amount_without_tax
              )
              pay_journal.debit_tax_amount = numberToCurrency(
                pay_journal.debit_tax_amount
              )
              pay_journal.credit_amount = numberToCurrency(
                pay_journal.credit_amount
              )
              pay_journal.credit_amount_without_tax = numberToCurrency(
                pay_journal.credit_amount_without_tax
              )
              pay_journal.credit_tax_amount = numberToCurrency(
                pay_journal.credit_tax_amount
              )
              pay_journal.description = checkNull(pay_journal.description)
              pay_journal.free_text_1 = checkNull(pay_journal.free_text_1)
              pay_journal.free_text_2 = checkNull(pay_journal.free_text_2)
            }
            promisesForRendering.push(
              ...asyncSetJournals(
                [...payment_journals],
                this.formInvoice.payment_journals
              )
            )
          }
          const diffInvoice =
            this.invoiceJournalLengthRendering -
            this.formInvoice.image_summary_journal.length
          const diffPayment =
            this.paymentJouranlLengthRendering -
            this.formInvoice.payment_journals.length
          if (!(diffInvoice >= 0 && diffPayment >= 0)) {
            const jouranlFormElement = document.getElementsByClassName(
              'sweeep-invoice-info-detail'
            )[0]
            resetFormHeight(jouranlFormElement)
          }
          this.stateJournalRendering = promisesForRendering

          this.formInvoice.name = invoice.company_name
          this.formInvoice.isInvoiceIssuer = invoice.is_invoice_issuer
          this.formInvoice.invoiceIssuerNumber = invoice.invoice_issuer_number
          this.formInvoice.pdf_file_path = invoice.pdf_file_path
          this.formInvoice.date = invoice.issue_date
          this.formInvoice.amount = currencyToNumber(invoice.invoice_max_amount)
          this.formInvoice.tax_amount = currencyToNumber(invoice.invoice_tax)
          this.formInvoice.gensen_amount = currencyToNumber(
            invoice.gensen_amount
          )
          this.formInvoice.foreign_currency_amount =
            invoice.foreign_currency_amount
          this.formInvoice.currency_code = invoice.currency_code || {}
          this.formInvoice.isForeignCurrency =
            this.organization.settingForeignCurrency &&
            !!invoice.currency_code &&
            invoice.currency_code.currency_code !== 'JPY'

          this.formInvoice.reward_amount = currencyToNumber(
            invoice.reward_amount
          )
          this.formInvoice.pay_amount = currencyToNumber(invoice.pay_amount)

          this.formInvoice.pay_type = invoice.pay_type
          this.formInvoice.pay_date = invoice.pay_date
          this.formInvoice.pay_month = invoice.pay_month
          this.formInvoice.pay_day = invoice.pay_day
          this.formInvoice.learning_pay_site = invoice.learning_pay_site
          this.formInvoice.summary = invoice.summary
          this.formInvoice.gensenzei = invoice.gensenzei
          this.formInvoice.gensenCode = invoice.gensen_code
          this.formInvoice.gensenTotalTargets = invoice.gensen_total_targets

          this.formInvoice.scoreMaxAmount = invoice.score_max_amount
          this.formInvoice.learned = invoice.learned
          this.formInvoice.department = invoice.department

          this.formInvoice.user_bank = invoice.user_bank
          this.formInvoice.paid_by_customer = invoice.paid_by_customer
          this.formInvoice.bank_name = invoice.bank_name
          this.formInvoice.bank_code = invoice.bank_code
          this.formInvoice.bank_branch_code = invoice.bank_branch_code
          this.formInvoice.bank_branch_name = invoice.bank_branch_name
          this.formInvoice.bank_account_name = invoice.bank_account_name
          this.formInvoice.bank_account_number = invoice.bank_account_number
          this.formInvoice.bank_account_type = invoice.bank_account_type

          this.formInvoice.bank_list = invoice.bank_list
          this.formInvoice.bank_read_list = invoice.bank_list.filter((v) => {
            return v.type == 'extract'
          })

          this.formInvoice.flag_new_bank = invoice.flag_new_bank
          this.formInvoice.approved1 = invoice.confirm_user1 ? true : false
          this.formInvoice.approved2 = invoice.confirm_user2 ? true : false
          this.formInvoice.approved3 = invoice.confirm_user3 ? true : false
          this.formInvoice.approved4 = invoice.confirm_user ? true : false

          this.formInvoice.approve_user1 = invoice.confirm_user1
            ? getUserInfo(invoice.confirm_user1)
            : ''
          this.formInvoice.approve_user2 = invoice.confirm_user2
            ? getUserInfo(invoice.confirm_user2)
            : ''
          this.formInvoice.approve_user3 = invoice.confirm_user3
            ? getUserInfo(invoice.confirm_user3)
            : ''
          this.formInvoice.approve_user4 = invoice.confirm_user
            ? getUserInfo(invoice.confirm_user)
            : ''
          this.formInvoice.approve_user5 = invoice.approve_user
            ? getUserInfo(invoice.approve_user)
            : ''

          this.formInvoice.date_approve1 = invoice.date_approve1
          this.formInvoice.date_approve2 = invoice.date_approve2
          this.formInvoice.date_approve3 = invoice.date_approve3
          this.formInvoice.date_approve4 = invoice.date_approve4
          this.formInvoice.date_approve5 = invoice.date_approve5

          this.formInvoice.userUploaded = invoice.user_uploaded
          this.formInvoice.userViewed = invoice.user_viewed
          this.formInvoice.userUpdated = invoice.user_updated
          this.formInvoice.userUpdatedPay = invoice.user_updated_pay
          this.formInvoice.userExported = invoice.user_exported

          this.formInvoice.dateUploaded = invoice.date_uploaded
          this.formInvoice.dateViewed = invoice.date_viewed
          this.formInvoice.dateUpdated = invoice.date_updated
          this.formInvoice.dateUpdatedPay = invoice.date_updated_pay
          this.formInvoice.dateExported = invoice.date_exported

          this.formInvoice.pay_confirm = invoice.pay_confirm_user ? 1 : 0
          this.formInvoice.approved = invoice.approve_user ? 1 : 0
          this.formInvoice.pay_approved = invoice.pay_approve_user ? 1 : 0
          this.formInvoice.paid = invoice.paid_user ? 1 : 0

          this.formInvoice.pay_confirm_user = getUserInfo(
            invoice.pay_confirm_state.pay_confirm_user
          )
          this.formInvoice.pay_confirm_user2 = getUserInfo(
            invoice.pay_confirm_state.pay_confirm_user2
          )
          this.formInvoice.pay_confirm_user3 = getUserInfo(
            invoice.pay_confirm_state.pay_confirm_user3
          )
          this.formInvoice.pay_approve_user = getUserInfo(
            invoice.pay_approve_user
          )
          this.formInvoice.paid_user = getUserInfo(invoice.paid_user)

          this.formInvoice.date_pay_confirm =
            invoice.pay_confirm_state.date_pay_confirm
          this.formInvoice.date_pay_confirm2 =
            invoice.pay_confirm_state.date_pay_confirm2
          this.formInvoice.date_pay_confirm3 =
            invoice.pay_confirm_state.date_pay_confirm3
          this.formInvoice.date_pay_approve = invoice.date_pay_approve
          this.formInvoice.date_paid = invoice.date_paid

          this.formInvoice.note_1 = invoice.note_1 ? invoice.note_1 : ''
          this.formInvoice.note_2 = invoice.note_2 ? invoice.note_2 : ''
          this.formInvoice.note_3 = invoice.note_3 ? invoice.note_3 : ''
          this.formInvoice.payment_note1 = invoice.payment_note1 || ''
          this.formInvoice.payment_note2 = invoice.payment_note2 || ''
          this.formInvoice.payment_note3 = invoice.payment_note3 || ''
          this.formInvoice.invoice_id = invoice.id
          this.formInvoice.local_id = parseInt(invoice.local_id)
          this.formInvoice.inboxPurpose = invoice.inbox_purpose
          this.formInvoice.inboxComment = invoice.inbox_comment

          this.formInvoice.company_pattern_id = invoice.company_pattern_id
          this.formInvoice.company = this.$set(
            this.formInvoice,
            'company',
            invoice.company || {}
          )
          this.$set(
            this.formInvoice,
            'companyPatterns',
            invoice.company ? invoice.company.patterns : []
          )

          this.formInvoice.company_address = invoice.scanned_company_address
          this.formInvoice.company_phonenumber = invoice.company_phonenumber

          this.formInvoice.copied = invoice.copied
          this.formInvoice.idFreee = invoice.freee_deal_id

          let editingUserOrg = invoice.editing_user_organization
          if (editingUserOrg) {
            this.formInvoice.editingUserOrgId = editingUserOrg.id
            this.formInvoice.editingUserName = editingUserOrg.user.full_name
          } else {
            this.formInvoice.editingUserOrgId = ''
            this.formInvoice.editingUserName = ''
          }
          if (invoice.date_start_edit) {
            this.formInvoice.editingDate = invoice.date_start_edit
          } else {
            this.formInvoice.editingDate = null
          }

          if (invoice.learning == null) {
            this.formInvoice.learning = false
          } else {
            this.formInvoice.learning = invoice.learning
          }
          this.formInvoice.past_transaction = invoice.past_transaction
          this.formInvoice.isDeleted = invoice.is_deleted

          this.formInvoice.inboxId = invoice.inbox_item_id

          this.formInvoice.matchingWay = invoice.matching_way

          this.formInvoice.scannedCompanyName = invoice.scanned_company_name
          this.formInvoice.scannedPhoneNumber =
            invoice.company_phonenumber.replace(/[-ー]/g, '')
          this.formInvoice.scannedCompanyAddress =
            invoice.scanned_company_address

          this.setRecurringSchedule({
            schedule: response.recurring_schedule,
            defaultIssueDate: invoice.issue_date,
            defaultPaymentTermMonth: invoice.pay_month,
            defaultPaymentTermDay: invoice.pay_day,
          })

          Vue.nextTick(() => {
            this.formInvoiceBefore = deepCopy(this.formInvoice)
            this.$set(
              this.formInvoiceBefore,
              'image_summary_journal',
              deepCopy(invoice_journals)
            )
            this.$set(
              this.formInvoiceBefore,
              'payment_journals',
              deepCopy(payment_journals)
            )
            this.formInvoice.changed += 1
            Promise.all(promisesForRendering).then(() => {
              // finish rendering
              this.$store.commit('setLoadingInvoiceDetail', false)
              this.paymentJouranlLengthRendering = 0
              this.invoiceJournalLengthRendering = 0
              this.stateJournalRendering.length = 0
              this.invoiceIdRendered = this.formInvoice.invoice_id
            })
          })
          resolve(true)
        } catch (error) {
          console.log(error)
          reject(new Error('falied to set invoice form'))
        }
      })
    },
    setRecurringSchedule({
      schedule,
      defaultIssueDate,
      defaultPaymentTermMonth,
      defaultPaymentTermDay,
    }) {
      this.$store.dispatch('setRecurringInvoiceSchedule', {
        schedule,
        defaultIssueDate,
        defaultPaymentTermMonth,
        defaultPaymentTermDay,
      })
    },
    postInvoiceViewed(id) {
      try {
        if (this.formInvoice.userViewed.id) {
          return
        }
        this.$store.dispatch('postInvoiceViewed', id).then((response) => {
          if (response) {
            this.formInvoice.dateViewed = response.date
            this.formInvoice.userViewed = response.user

            Vue.nextTick(() => {
              var index = this.tableData.findIndex((x) => x.id === id)
              if (index >= 0) {
                this.tableData[index].date_viewed = response.date
                this.tableData[index].viewed_user = response.user
              }
            })
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    deleteInvoiceViewed() {
      try {
        const id = this.formInvoice.invoice_id
        if (!this.formInvoice.userViewed.id) {
          return
        }
        this.$store.dispatch('deleteInvoiceViewed', id).then((response) => {
          if (response) {
            Snackbar.success('未読にしました')
            this.formInvoice.dateViewed = ''
            this.formInvoice.userViewed = {}

            Vue.nextTick(() => {
              var index = this.tableData.findIndex((x) => x.id === id)
              if (index >= 0) {
                this.tableData[index].date_viewed = ''
                this.tableData[index].viewed_user = {}
              } else {
                Snackbar.error('error')
              }
            })
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getActionLogs() {
      try {
        const localId = this.$route.params.id
        if (!localId) {
          return
        }
        this.$store
          .dispatch('getInvoiceActionLogs', localId)
          .then((response) => {
            this.actionLogs = response.logs.map((obj) => {
              if (!isString(obj.user_name)) {
                obj.user_name = ''
              }
              obj.date_created = changeDateStringFormat(obj.date_created)
              return obj
            })
          })
      } catch (err) {
        console.log(err)
      }
    },
    async getPdfUrl(version = null) {
      try {
        // reset pdf params
        const start = new Date()
        this.loadingPdf = true
        let local_id = this.$route.params.id
        let org_id = this.organizationId
        let user_org_id = this.$store.getters.getUserOrganizationID

        if (!this.$route.params.id) {
          return
        }
        const response = await axios.get(this.$store.getters.apiInvoicePDFUrl, {
          params: {
            local_id: this.$route.params.id,
            organization_id: org_id,
            user_organization_id: user_org_id,
            version: version,
          },
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        if (local_id !== this.$route.params.id) {
          return
        }
        if (response.data.status === 'success') {
          this.invoiceFile.pdfUrl = response.data.url
          this.invoiceFile.currentVersion = response.data.version
          this.invoiceFile.maxVersion = response.data.max_version
          this.invoiceFile.isStamped = response.data.is_stamped
          this.invoiceFile.isExpired = response.data.is_expired
          this.invoiceFile.displayPdf = response.data.url ? true : false
        } else {
          this.invoiceFile.currentVersion = 1
          this.invoiceFile.displayPDF = false
          Snackbar.error('Error occured on get pdf token.')
        }
        this.loadingPdf = false

        consoleLog('API', 'getPdfUrl', start)
      } catch (err) {
        console.log(err)
      }
    },
    downVersion() {
      this.invoiceFile.currentVersion -= 1
      this.getPdfUrl(this.invoiceFile.currentVersion)
    },
    upVersion() {
      this.invoiceFile.currentVersion += 1
      this.getPdfUrl(this.invoiceFile.currentVersion)
    },
    getImage() {
      try {
        const start = new Date()
        let url = this.$store.getters.apiInvoiceImageUrl
        let params_id = this.$route.params.id
        axios
          .get(url, {
            params: {
              id: this.$route.params.id,
              org_id: this.organizationId,
              version: 1,
            },
            headers: { Authorization: this.$store.getters.getAuthToken },
          })
          .then((response) => {
            if (this.$route.params.id == params_id) {
              if (response.data.status) {
                this.invoiceFile.imageUris = response.data.data
                this.invoiceFile.fileName = response.data.filename
                this.invoiceFile.filePage = response.data.filepage
                this.loadingImage = false
                //Snackbar.success('Success Load Image.')
              } else if (response.data.error.slice(0, 7) == '404 GET') {
                this.loadingImage = false
              }
            }
            consoleLog('API', 'getImage', start)
          })
      } catch (err) {
        console.log(err)
      }
    },
    downloadFile() {
      try {
        const localId = this.$route.params.id
        const version = this.invoiceFile.currentVersion
        if (localId) {
          const payload = {
            localId: localId,
            version: version,
          }
          this.$store.dispatch('downloadInvoicePdfFile', payload)
        }
      } catch (err) {
        console.log(err)
      }
    },
    onClickToEditMode() {
      // トリガー
      // 請求書画面移動(編集モードで)
      // 編集モードオン
      try {
        this.$store
          .dispatch('getEditingUsers', this.$route.params.id)
          .then((exists) => {
            if (exists) {
              this.$refs.forceEditDialog.open()
            } else {
              this.$store.dispatch('onEditMode')
            }
          })
      } catch (err) {
        console.log(err)
      }
    },
    async getInvoiceDetail(loadingImage = true) {
      try {
        const localId = this.$route.params.id
        if (!localId) {
          return false
        }

        this.loadingImage = loadingImage
        this.$store.commit('setLoadingInvoiceDetail', true)
        this.$store.commit('setEditedInvoiceFormFields', [])

        const url = this.$store.getters.apiInvoiceInstanceFunction
        const response = await axios.get(url, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
          params: {
            id: localId,
            organization_id: this.organizationId,
          },
        })
        if (this.$route.params.id !== localId) {
          return false
        }
        if (response.data.error) {
          Snackbar.error(response.data.error)
          return false
        }

        this.$store.commit('setInvoicePrivilege', response.data.privilege)

        await Promise.all(this.stateJournalRendering)
        await this.setInvoiceForm(response.data)

        this.$refs.invoiceInfo.resetValidation()
        this.postInvoiceViewed(response.data.invoice_summary.id)
        this.invoiceFile.fileName = response.data.invoice_summary.file_name
        for (let [key, value] of Object.entries(
          response.data.invoice_summary.learing_candicates
        )) {
          const keyName = {
            // "phone_number_list": "phoneNumberList",
            amount_list: 'amountList',
          }
          this.$set(this.learningCandidates, keyName[key], value)
        }
        if (loadingImage) {
          this.getImage()
        }
        return true
      } catch (e) {
        console.log(e)
        throw new Error(e)
      }
    },
    openhistory() {
      this.historyOpen = true
    },
    closehistory() {
      this.historyOpen = false
    },
    importImage(event) {
      try {
        let t = this
        this.loadingImage = true
        let f = event.target.files[0]
        let reader = new FileReader()

        let params_id = this.$route.params.id
        reader.onload = (function (f) {
          return function (e) {
            let data = {
              local_id: params_id,
              image: e.target.result,
              fileName: f.name,
            }
            t.$store.dispatch('importImageFile', { data }).then((response) => {
              if (response.data.error) {
                Snackbar.error(response.data.error)
              } else {
                Snackbar.success(
                  '成功しました。<br>ファイルのみ差し替わります。<br>登録済みのデータに変更は加わりません。'
                )
                t.getInvoiceDetail().then((response) => {
                  if (response) {
                    const payload = {}
                    payload['date_upload'] = t.formInvoice.dateUploaded
                    payload['upload_user'] = t.formInvoice.userUploaded
                    t.onChangeFormInvoice(payload)
                  }
                })
                t.getActionLogs()
                t.getPdfUrl()
              }
              t.loadingImage = false
            })
          }
        })(f)
        t.setBlanktoFileInput()
        reader.readAsDataURL(f)
      } catch (e) {}
    },
    setBlanktoFileInput() {
      document.querySelector('#import-image-file').value = ''
    },
    onDeletedInvoice(invoiceId) {
      this.$emit('deleted', invoiceId)
      this.$router.push('/invoice')
    },
    onRestoredInvoice(invoiceId) {
      this.$emit('restored', invoiceId)
      this.$router.push('/invoice')
    },
    onChangeFormInvoice(payload) {
      Object.keys(payload).forEach((key) => {
        this.$set(this.formInvoice, key, payload[key])
      })
      const imageSummaryId = this.formInvoice.invoice_id
      const attributes = payload
      const event = { imageSummaryId, attributes }
      this.$emit('change:form-invoice', event)
    },
    openInterruptedApproveDialog() {
      this.$refs.confirmDialog.open(
        this.$t('message.interrupted_on_editing_invoice')
      )
    },
    openInterruptedCancelViewedDialog() {
      this.$refs.confirmDialog.open(
        this.$t('message.interrupted_on_cancel_viewed_invoice')
      )
    },
    requestNextApproval({ selectedUsers, resource, level }) {
      try {
        this.loadingRequestNextApproval = true
        const userOrgIds = selectedUsers.map(function (user) {
          return user.id
        })
        if (userOrgIds.length == 0) {
          Snackbar.error('承認依頼を送るユーザを選択してください')
          this.loadingRequestNextApproval = false
          return
        }
        let isApproval = true
        if (resource == 'payment') {
          isApproval = false
        }
        const payload = {
          userOrgIds: userOrgIds,
          localId: this.formInvoice.local_id,
          isApproval: isApproval,
          nextLevel: Number(level) + 1,
        }
        this.$store
          .dispatch('requestNextApproval', payload)
          .then((response) => {
            if (response.data.status == 'success') {
              Snackbar.success('承認依頼を送りました。')
              this.$refs.requestNextApprovalDialog.close()
              this.loadingRequestNextApproval = false
            } else {
              Snackbar.error(response.data.error)
              this.loadingRequestNextApproval = false
            }
          })
      } catch (err) {
        console.log(err)
        this.loadingRequestNextApproval = false
      }
    },
    approveInvoice(level) {
      try {
        if (!this.invoicePrivilege[`approve${level}`]) {
          this.$refs.noPrivilegeDialog.open()
          return
        }
        const payload = {
          id: this.formInvoice.invoice_id,
          level: level,
        }
        this.$store.dispatch('approveInvoice', payload).then((response) => {
          if (response.data.status == 'success') {
            Snackbar.success('承認しました。')
            this.onApprovedInvoice({
              level: level,
              status: true,
              user: response.data.user_info,
              date: response.data.date,
            })
            if (this.sendApprovalRequest) {
              this.$refs.requestNextApprovalDialog.open('invoice', level)
            }
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    cancelApproveInvoice(level) {
      try {
        if (!this.invoicePrivilege[`approve${level}`]) {
          this.$refs.noPrivilegeDialog.open()
          return
        }
        const payload = {
          id: this.formInvoice.invoice_id,
          level: level,
        }
        this.$store
          .dispatch('cancelApproveInvoice', payload)
          .then((response) => {
            if (response.data.status == 'success') {
              Snackbar.success('承認を取り消しました。')
              this.onApprovedInvoice({
                level: level,
                status: false,
              })
            } else {
              Snackbar.error(response.data.error)
            }
          })
      } catch (err) {
        console.log(err)
      }
    },
    finalApproveInvoice() {
      try {
        if (!this.invoicePrivilege.approve5) {
          this.$refs.noPrivilegeDialog.open()
          return
        }
        const payload = {
          id: this.formInvoice.invoice_id,
        }
        this.$store
          .dispatch('finalApproveInvoice', payload)
          .then((response) => {
            if (response.data.status == 'success') {
              Snackbar.success(this.$t('message.invoice_approve_success'))

              this.$store.dispatch('reloadInvoiceSchedule')

              this.onApprovedInvoice({
                level: 5,
                status: true,
                user: response.data.user_info,
                date: response.data.date,
              })
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    cancelFinalApproveInvoice() {
      try {
        if (!this.invoicePrivilege.approve5) {
          this.$refs.noPrivilegeDialog.open()
          return
        }
        const payload = {
          id: this.formInvoice.invoice_id,
        }
        this.$store
          .dispatch('cancelFinalApproveInvoice', payload)
          .then((response) => {
            if (response.data.status == 'success') {
              Snackbar.success(
                this.$t('message.invoice_cancel_approve_success')
              )

              this.onApprovedInvoice({
                level: 5,
                status: false,
              })
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    freeeApproveInvoice() {
      const hasPrivilege = this.freeeSeparateApproval
        ? this.invoicePrivilege.approve_freee
        : this.invoicePrivilege.approve5
      if (!hasPrivilege) {
        this.$refs.noPrivilegeDialog.open()
        return
      }
      this.$refs.freeePostDialog.open({
        localId: this.$route.params.id,
        invoiceId: this.formInvoice.invoice_id,
        multiple: false,
      })
    },
    confirmPayment(level) {
      if (!this.invoicePrivilege[`pay_approve${level}`]) {
        this.$refs.noPrivilegeDialog.open()
        return
      }
      const id = this.formInvoice.invoice_id
      this.$store.dispatch('confirmPayment', { id, level }).then((response) => {
        if (response.data.status == 'success') {
          Snackbar.success('承認しました')

          this.onApprovedPayment({
            level: level,
            status: true,
            user: response.data.user,
            date: response.data.date,
          })
          if (this.sendApprovalRequest && level <= 2) {
            this.$refs.requestNextApprovalDialog.open('payment', level)
          }
        }
      })
    },
    cancelConfirmPayment(level) {
      if (!this.invoicePrivilege[`pay_approve${level}`]) {
        this.$refs.noPrivilegeDialog.open()
        return
      }
      const id = this.formInvoice.invoice_id
      this.$store
        .dispatch('cancelConfirmPayment', { id, level })
        .then((response) => {
          if (response.data.status == 'success') {
            Snackbar.success('承認を取り消しました')

            this.onApprovedPayment({
              level: level,
              status: false,
            })
          }
        })
    },
    approvePayment() {
      if (!this.checkPaymentBankFormIsFilled()) {
        const dialogProps = {
          message: this.$t('message.bank_not_entered_message'),
          hideCancelBtn: true,
        }
        this.$confirm(dialogProps)
        return
      }
      const valid = this.$refs.invoiceInfo.validatePaymentBankForm()
      if (!valid) {
        this.$refs.invoiceInfo.toggleTarget = 3
        this.$refs.invalidForm.open()
        return
      }
      if (!this.invoicePrivilege.pay_approve4) {
        this.$refs.noPrivilegeDialog.open()
        return
      }
      const id = this.formInvoice.invoice_id
      this.$store.dispatch('approvePayment', { id }).then((response) => {
        if (response.data.status == 'success') {
          Snackbar.success(this.$t('message.payment_approve_success'))

          this.onApprovedPayment({
            level: 4,
            status: true,
            user: response.data.user,
            date: response.data.date,
          })
        }
      })
    },
    cancelApprovePayment() {
      if (!this.invoicePrivilege.pay_approve4) {
        this.$refs.noPrivilegeDialog.open()
        return
      }
      const id = this.formInvoice.invoice_id
      this.$store.dispatch('cancelApprovePayment', { id }).then((response) => {
        if (response.data.status == 'success') {
          Snackbar.success(this.$t('message.payment_cancel_approve_success'))

          this.onApprovedPayment({
            level: 4,
            status: false,
          })
        }
      })
    },
    approvePaid() {
      if (!this.invoicePrivilege.pay_approve5) {
        this.$refs.noPrivilegeDialog.open()
        return
      }
      if (
        !this.formInvoice.pay_approved &&
        !this.invoicePrivilege.pay_approve4
      ) {
        return
      }

      const id = this.formInvoice.invoice_id
      this.$store.dispatch('approvePaid', { id }).then((response) => {
        if (response.data.status == 'success') {
          Snackbar.success('支払済みに変更しました。')

          this.$store.dispatch('reloadInvoiceSchedule')

          this.onApprovedPayment({
            level: 5,
            status: true,
            user: response.data.user,
            date: response.data.date,
          })
        }
      })
    },
    cancelApprovePaid() {
      const hasPrivilege = this.invoicePrivilege.pay_approve5
      if (!hasPrivilege) {
        this.$refs.noPrivilegeDialog.open()
        return
      }
      const id = this.formInvoice.invoice_id
      this.$store.dispatch('cancelApprovePaid', { id }).then((response) => {
        if (response.data.status == 'success') {
          Snackbar.success('支払済みを解除しました。')

          this.onApprovedPayment({
            level: 5,
            status: false,
          })
        }
      })
    },
    onApprovedInvoice({ level, status, user, date }) {
      const userField = `approve_user${level}`
      const dateField = `date_approve${level}`
      const approvedField = level === 5 ? `approved`: `approved${level}`
      const payload = {}

      if (status) {
        payload[userField] = user
        payload[dateField] = date
        payload[approvedField] = 1
        if (level === 5) {
          const freeeTokenExists = this.$store.getters.freeeTokenExists
          if (!this.freeeSeparateApproval && freeeTokenExists) {
            this.freeeApproveInvoice()
          }
          if (this.filterResultCount.not_approved) {
            this.filterResultCount.not_approved -= 1
          }
        }
      } else {
        payload[userField] = {}
        payload[dateField] = ''
        payload[approvedField] = 0
        if (level === 5) {
          if (this.filterResultCount.not_approved) {
            this.filterResultCount.not_approved += 1
          }
        }
      }
      this.onChangeFormInvoice(payload)
    },
    onApprovedPayment({ level, status, user, date }) {
      let userField
      let dateField
      switch (level) {
        case 1:
          userField = 'pay_confirm_user'
          dateField = 'date_pay_confirm'
          break
        case 2:
          userField = 'pay_confirm_user2'
          dateField = 'date_pay_confirm2'
          break
        case 3:
          userField = 'pay_confirm_user3'
          dateField = 'date_pay_confirm3'
          break
        case 4:
          userField = 'pay_approve_user'
          dateField = 'date_pay_approve'
          break
        case 5:
          userField = 'paid_user'
          dateField = 'date_paid'
          break
      }
      const payload = {}

      if (status) {
        payload[userField] = user
        payload[dateField] = date
        if (level == 4) {
          payload['pay_approved'] = 1
        }
        if (level === 5) {
          payload['paid'] = 1
          if (!this.formInvoice.pay_approve_user) {
            payload['pay_approved'] = 1
            payload['pay_approve_user'] = user
            payload['date_pay_approve'] = date
          }
          if (this.filterResultCount.not_paid) {
            this.filterResultCount.not_paid -= 1
          }
        }
      } else {
        payload[userField] = {}
        payload[dateField] = ''
        if (level == 4) {
          payload['pay_approved'] = 0
        }
        if (level === 5) {
          payload['paid'] = 0
          if (this.filterResultCount.not_paid) {
            this.filterResultCount.not_paid += 1
          }
        }
      }
      this.onChangeFormInvoice(payload)
    },
    onClickDepartment() {
      try {
        const level = getAbilityLevel('invoice:read')
        if (level !== 3) {
          this.$refs.noPrivilegeDialog.open()
          return
        }
        this.$refs.changeDepartmentDialog.open(this.formInvoice.department)
      } catch (err) {
        console.log(err)
      }
    },
    submitDepartment(department, checkWarn = true) {
      if (checkWarn) {
        this.$refs.changeDepartmentWarnDialog.checkWarn(this.formInvoice)
        if (this.$refs.changeDepartmentWarnDialog.hasWarn()) {
          this.$refs.changeDepartmentWarnDialog.open(department)
          return
        }
      }
      this.$store
        .dispatch('changeInvoiceDepartment', {
          invoiceId: this.formInvoice.invoice_id,
          departmentId: department.id,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            Snackbar.success('部門情報を更新しました')
            this.formInvoice.department = department
            this.onChangeFormInvoice({ department })
          } else {
            Snackbar.error('エラーが発生しました')
          }
        })
    },
    updateNote(payload) {
      const field = `note_${payload.number}`
      const value = payload.value
      this.formInvoice[field] = value
    },
    checkPaymentBankFormIsFilled() {
      try {
        const form = this.formInvoice
        if (
          form.pay_type == 1 &&
          (form.bank_name == '' ||
            form.bank_branch_name == '' ||
            form.bank_account_type == '' ||
            form.bank_account_number == '' ||
            form.bank_account_name == '')
        ) {
          return false
        } else {
          return true
        }
      } catch (e) {
        console.log(e)
      }
    },
    selectedCandidatesAmount(payload) {
      for (let i = 0; i < this.learningCandidates.amountList.length; i++) {
        if (
          this.learningCandidates.amountList[i].image_summary_journal_id ===
            payload.journal.id ||
          this.learningCandidates.amountList[i].image_summary_journal ===
            payload.journal
        ) {
          let instance = this.learningCandidates.amountList[i]
          instance.image_summary_journal_id = ''
          instance.image_summary_journal = ''
          this.learningCandidates.amountList.splice(i, 1, instance)
        }
      }
      for (const candidate of payload.selected) {
        const index = this.learningCandidates.amountList.findIndex(
          (obj) => obj.id === candidate.id
        )
        let instance = this.learningCandidates.amountList[index]
        instance.image_summary_journal = payload.journal
        this.learningCandidates.amountList.splice(index, 1, instance)
      }
    },
    createNewCompany() {
      const { formInvoice } = this
      const company = createCompanyParam(
        {
          ...formInvoice,
          company_id: 0,
          scanned_company_name: formInvoice.scannedCompanyName,
          scanned_company_address: formInvoice.scannedCompanyAddress,
          scanned_company_phonenumber: formInvoice.scannedPhoneNumber,
          bank_branch_code: this.$store.getters.getBankBranchCode(
            formInvoice.bank_name,
            formInvoice.bank_branch_name
          ),
          bank_code: this.$store.getters.getBankCode(formInvoice.bank_name),
        },
        formInvoice.scannedPhoneNumber,
        formInvoice.scannedCompanyAddress
      )
      const companyPattern = createCompanyPatternParam({
        journals: formInvoice.image_summary_journal,
        company_id: 0,
        name: 'デフォルト',
      })
      return this.learningInvoice({
        invoiceId: formInvoice.invoice_id,
        company,
        companyPattern,
      })
    },
    async onUpdatedInvoice() {
      const createDiffDialog = (formInvoice, company, companyPattern) => {
        if (!this.checkPrivilege('company:update')) {
          return
        }
        if (this.updateDiffDialog(formInvoice, company, companyPattern)) {
          this.showUpdateDialog = true
          URL.revokeObjectURL(this.invoiceFile.cachedUrl)
          this.$store
            .dispatch('getPdfUrl', {
              localId: this.$route.params.id,
              version: this.invoiceFile.currentVersion,
            })
            .then((data) => {
              axios
                .get(data.url, { responseType: 'blob' })
                .then((srcObject) => {
                  const fileObject = new Blob([srcObject.data], {
                    type: 'application/pdf',
                  })
                  this.invoiceFile.cachedUrl = URL.createObjectURL(fileObject)
                })
            })
        }
      }
      const createNewCompany = async () => {
        await this.createNewCompany()
      }

      const formInvoice = JSON.parse(JSON.stringify(this.formInvoice))
      const shouldSearchCompany =
        formInvoice.name !== formInvoice.company.company_name ||
        formInvoice.company.readonly
      if (!shouldSearchCompany) {
        const company = formInvoice.company
        if (company && company.id) {
          const companyPattern = company.patterns.find(
            (v) => v.id === formInvoice.company_pattern_id
          )
          createDiffDialog(formInvoice, company, companyPattern)
        } else {
          await createNewCompany()
        }
      } else {
        // 請求書のキーが変わった場合:  or readonly
        const phoneNumber = formInvoice.company.readonly
          ? formInvoice.company.company_phonenumber
          : formInvoice.scannedPhoneNumber
        let comparedCompany = await companyService.get(this.authToken, {
          name: formInvoice.name,
          phoneNumber,
          readonly: false,
        })
        if (comparedCompany) {
          createDiffDialog(this.formInvoice, comparedCompany, null)
        } else {
          await createNewCompany()
        }
      }
      this.getInvoiceDetail(false)
    },
    updateDiffDialog(invoice, company, companyPattern) {
      const newCompany = !(company && company.id)
      const shouldCheckJournals = this.formType !== 3
      this.$set(
        company,
        'journals',
        companyPattern
          ? companyPattern.company_journals
          : [getCompanyJournalModel()]
      )
      const diffObjects = newCompany
        ? []
        : diffInvoiceCompany(company, invoice, shouldCheckJournals)
      const noDifference = !newCompany && diffObjects.length === 0
      if (noDifference) {
        return
      }
      const config = {
        newCompany,
        invoice,
        company,
        diffObjects,
        loading: false,
      }
      this.$set(this, 'updateDialogConfig', config)
      return true
    },
    async learningInvoice({
      invoiceId,
      company,
      companyPattern,
      valuesBeingViewed,
    }) {
      this.updateDialogConfig.loading = true
      const newCompany = !company.id
      await learningInvoice({
        token: this.authToken,
        company,
        companyPattern,
        invoiceId,
        newCompany,
        valuesBeingViewed,
      })
        .then(({ company, companyPattern, status }) => {
          this.updateDialogConfig.loading = false
          this.$set(this.formInvoice, 'company', company)
          this.formInvoice.company_pattern_id =
            companyPattern && companyPattern.id
          if (status === 'error') {
            Snackbar.error(
              '他のユーザがこの学習データを更新しました。最新の学習データで再度比較してください。'
            )
            this.updateDiffDialog(this.formInvoice, company, companyPattern)
            return
          }
          Snackbar.success(
            `学習データを${newCompany ? '作成' : '更新'}しました。`
          )
          this.showUpdateDialog = false
          if (newCompany) {
            this.companyList.push(getCompanyListContent(company))
          }
        })
        .catch((error) => {
          if (error === 'no_privilege') {
            Snackbar.error(this.$t('請求元|学習データ更新権限がありません。'))
          } else {
            Snackbar.error(this.$t('message.unexpectedError'))
          }
          this.updateDialogConfig.loading = false
        })
    },
    setAllocationJournal(payload) {
      const items = payload.items
      const type = payload.type
      if (type === 'invoiceJournal') {
        asyncSetJournals(items, this.formInvoice.image_summary_journal)
      } else {
        asyncSetJournals(items, this.formInvoice.payment_journals)
      }
    },
    setCompanyList() {
      companyListService
        .get(this.authToken, this.organizationId, getCompanyListContent)
        .then((formatedCompanyList) => {
          this.$set(this, 'companyList', formatedCompanyList)
        })
    },
  },
}
</script>
