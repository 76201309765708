<template>
  <v-card
    :style="`
      width: 100%;
      height: calc(${height});
    `"
  >
    <div v-if="loading" class="app-flex justify-center align-center h-100">
      <single-invoice-loader />
    </div>

    <div v-show="displayPdf" :class="$style.invoiceImagePdfView">
      <pdf-view
        id="invoicepdf"
        :uri="pdfUrl"
        height="100%"
        width="100%"
        @download-file="$emit('download-file')"
      />
    </div>

    <div v-show="!displayPdf">
      <v-toolbar
        v-show="$route.params.id"
        flat
        class="border-bottom-1"
        height="50"
      >
        <v-toolbar-title class="font-sm">
          <span v-tooltip="fileInfo" class="ellipsis fs-12">{{
            fileInfo
          }}</span>
        </v-toolbar-title>
        <v-spacer />

        <v-btn
          v-tooltip="{ content: '拡大', placement: 'left' }"
          x-small
          fab
          depressed
          @click="zoomInImage"
        >
          <v-icon x-small> feather-plus </v-icon>
        </v-btn>
        <v-btn
          v-tooltip="{ content: '縮小', placement: 'left' }"
          x-small
          fab
          depressed
          class="ml-2"
          @click="zoomOutImage"
        >
          <v-icon x-small> feather-minus </v-icon>
        </v-btn>
      </v-toolbar>
      <vue-perfect-scrollbar
        class="pa-3 pl-5"
        :style="`
          overflow:scroll !important;
          height: calc(${height} - 50px);
        `"
      >
        <div class="inv-img">
          <template v-for="(uri, index) in imageUris" v-show="imageUris.length">
            <img
              :key="index"
              :src="uri"
              class="mb-3"
              :style="`height: calc(${height} - 50px);`"
            />
          </template>
        </div>
      </vue-perfect-scrollbar>
    </div>
  </v-card>
</template>

<style module>
.invoiceImagePdfView {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
</style>

<script>
import SingleInvoiceLoader from './SingleInvoiceLoader'
import PdfView from 'Components/Pdf/PdfView'

export default {
  components: {
    SingleInvoiceLoader,
    PdfView,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    displayPdf: {
      type: Boolean,
      required: true,
    },
    pdfUrl: {
      type: String,
      required: true,
    },
    imageUris: {
      type: Array,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    filePage: {
      validator: (prop) => typeof prop === 'number' || prop === null,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    fileInfo: function () {
      try {
        if (!this.imageDataURIs) {
          return ''
        }
        if (this.imageDataURIs.length > 1) {
          return this.fileName
        } else if (this.filePage) {
          return this.fileName + ' / ' + this.filePage + 'ページ目'
        } else {
          return this.fileName
        }
      } catch (e) {
        console.log(e)
        return ''
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    zoomInImage() {
      try {
        var zoomLevel = document.querySelector('.inv-img').style.zoom
        if (zoomLevel) {
          zoomLevel = parseFloat(zoomLevel) + 0.1
        } else {
          zoomLevel = 1.1
        }
        document.querySelector('.inv-img').style.zoom = zoomLevel
      } catch (e) {
        console.log(e)
      }
    },
    zoomOutImage() {
      try {
        var zoomLevel = document.querySelector('.inv-img').style.zoom
        if (zoomLevel) {
          zoomLevel = parseFloat(zoomLevel) - 0.1
        } else {
          zoomLevel = 0.9
        }
        document.querySelector('.inv-img').style.zoom = zoomLevel
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
