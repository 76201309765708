import { numberToCurrency } from 'Helpers/journal/index'
import {
  wrapJournalsForInvoiceForm,
  wrapJournalsForInvoiceCompany,
} from 'Helpers/journal/wrap'

function getDiffObj(value, text, from, to) {
  if (from !== to) {
    return { value, text, from, to }
  } else {
    return null
  }
}

function getDiffJournals(value, text, journalsfrom, journalsTo) {
  let diffs = []
  const journalsLenFrom = journalsfrom.length
  const journalsLenTo = journalsTo.length
  const journalLenMax = Math.min(journalsLenFrom, journalsLenTo)

  // set texts of journal fields
  const journalFieldTexts = {
    debit_amount: '借方金額',
    debit_tax_amount: '借方税額',
    debit_account_title: '借方勘定科目',
    debit_sub_account: '借方補助科目',
    debit_tax: '借方税区分',
    debit_department: '借方部門',
    debit_project: '借方プロジェクト',
    debit_segment: '借方セグメント',
    debit_vendor: '借方取引先',
    debit_item: '借方品目',
    debit_tag: '借方タグ',
    debit_walletable: '借方口座',
    credit_amount: '貸方金額',
    credit_tax_amount: '貸方税額',
    credit_account_title: '貸方勘定科目',
    credit_sub_account: '貸方補助科目',
    credit_tax: '貸方税区分',
    credit_department: '貸方部門',
    credit_project: '貸方プロジェクト',
    credit_segment: '貸方セグメント',
    credit_vendor: '貸方取引先',
    credit_item: '貸方品目',
    credit_tag: '貸方タグ',
    credit_walletable: '貸方口座',
    description: '摘要',
    free_text_1: '備考1',
    free_text_2: '備考2',
  }

  // compare length of journals
  if (journalsLenFrom !== journalsLenTo) {
    diffs.push({
      value: `${value}:count`,
      text: `${text}/明細数`,
      from: journalsfrom.length,
      to: journalsTo.length,
    })
  }

  // compare fields of journals
  let field, valueFrom, valueTo
  for (let i = 0; i < journalLenMax; i++) {
    if (journalsfrom[i].length !== journalsTo[i].length) {
      continue
    }
    for (let j = 0; j < journalsfrom[i].length; j++) {
      field = journalsfrom[i][j].field
      valueFrom = journalsfrom[i][j].value
      valueTo = journalsTo[i][j].value
      if (valueFrom === valueTo) {
        continue
      }
      diffs.push({
        value: `${value}:${i}:${field}`,
        text: `${text}/明細${i + 1}/${journalFieldTexts[field]}`,
        from: valueFrom,
        to: valueTo,
      })
    }
  }
  if (diffs.length > 0) {
    return diffs
  } else {
    return null
  }
}

function getDiffObjs(objFrom, objTo, fields) {
  let changedValues = []
  let key, text, valueFrom, valueTo, changedObj

  for (let field of fields) {
    key = field.value
    text = field.text
    valueFrom = objFrom[key]
    valueTo = objTo[key]

    // convert
    if (typeof field.convert === 'function') {
      valueFrom = field.convert(valueFrom)
      valueTo = field.convert(valueTo)
    }
    // compare
    changedObj = field.diff(key, text, valueFrom, valueTo)
    if (!changedObj) {
      continue
    }

    // push
    if (Array.isArray(changedObj)) {
      changedValues.push(...changedObj)
    } else {
      changedValues.push(changedObj)
    }
  }
  return changedValues
}

export function diffInvoiceForm(objFrom, objTo) {
  const fields = [
    {
      value: 'name',
      text: '請求元',
      diff: getDiffObj,
    },
    {
      value: 'date',
      text: '伝票日付',
      diff: getDiffObj,
    },
    {
      value: 'gensenzei',
      text: '源泉',
      diff: getDiffObj,
    },
    {
      value: 'gensenCode',
      text: '源泉コード',
      diff: getDiffObj,
    },
    {
      value: 'gensenTotalTargets',
      text: '源泉対象人数',
      diff: getDiffObj,
    },
    {
      value: 'amount',
      text: '請求金額',
      convert: numberToCurrency,
      diff: getDiffObj,
    },
    {
      value: 'tax_amount',
      text: '税額',
      convert: numberToCurrency,
      diff: getDiffObj,
    },
    {
      value: 'pay_amount',
      text: '支払金額',
      convert: numberToCurrency,
      diff: getDiffObj,
    },
    {
      value: 'gensen_amount',
      text: '源泉金額',
      convert: numberToCurrency,
      diff: getDiffObj,
    },
    {
      value: 'reward_amount',
      text: '報酬額',
      convert: numberToCurrency,
      diff: getDiffObj,
    },
    {
      value: 'pay_date',
      text: '支払日付',
      diff: getDiffObj,
    },
    {
      value: 'pay_type',
      text: '支払方法',
      diff: getDiffObj,
    },
    {
      value: 'learning_pay_site',
      text: '支払サイト学習',
      diff: getDiffObj,
    },
    {
      value: 'bank_name',
      text: '支払/金融機関',
      diff: getDiffObj,
    },
    {
      value: 'user_bank',
      text: '支払/振込元口座',
      convert: (user_bank) => (user_bank && user_bank.id ? user_bank.id : null),
      diff: getDiffObj,
    },
    {
      value: 'paid_by_customer',
      text: '支払/手数料負担',
      diff: getDiffObj,
    },
    {
      value: 'bank_branch_name',
      text: '支払/支店',
      diff: getDiffObj,
    },
    {
      value: 'bank_account_type',
      text: '支払/口座種別',
      diff: getDiffObj,
    },
    {
      value: 'bank_account_number',
      text: '支払/口座番号',
      diff: getDiffObj,
    },
    {
      value: 'bank_account_name',
      text: '支払/口座名義',
      diff: getDiffObj,
    },
    {
      value: 'image_summary_journal',
      text: '仕訳',
      convert: wrapJournalsForInvoiceForm,
      diff: getDiffJournals,
    },
    {
      value: 'payment_journals',
      text: '支払仕訳',
      convert: wrapJournalsForInvoiceForm,
      diff: getDiffJournals,
    },
    {
      value: 'payment_note1',
      text: '支払メモ1',
      diff: getDiffObj,
    },
    {
      value: 'payment_note2',
      text: '支払メモ2',
      diff: getDiffObj,
    },
    {
      value: 'payment_note3',
      text: '支払メモ3',
      diff: getDiffObj,
    },
  ]
  const diffObjs = getDiffObjs(objFrom, objTo, fields)
  return diffObjs
}

export function diffInvoiceCompany(company, invoice, checkJournals = true) {
  const fields = [
    {
      value: 'company_name',
      text: '請求元',
      diff: getDiffObj,
    },
    {
      value: 'invoice_issuer_number',
      text: 'インボイス番号',
      diff: getDiffObj,
    },
    {
      value: 'gensenzei',
      text: '源泉対象',
      diff: getDiffObj,
    },
    {
      value: 'gensen_code',
      text: '源泉コード',
      diff: getDiffObj,
    },
    {
      value: 'gensen_total_targets',
      text: '源泉対象人数',
      diff: getDiffObj,
    },
    {
      value: 'pay_type',
      text: '支払方法',
      diff: getDiffObj,
    },
    {
      value: 'user_bank',
      text: '支払/振込元口座',
      convert: (user_bank) => (user_bank && user_bank.id ? user_bank.id : null),
      diff: getDiffObj,
    },
    {
      value: 'paid_by_customer',
      text: '支払/手数料負担',
      diff: getDiffObj,
    },
    {
      value: 'bank_name',
      text: '支払/金融機関',
      diff: getDiffObj,
    },
    {
      value: 'bank_branch_name',
      text: '支払/支店',
      diff: getDiffObj,
    },
    {
      value: 'bank_account_type',
      text: '支払/口座種別',
      diff: getDiffObj,
    },
    {
      value: 'bank_account_number',
      text: '支払/口座番号',
      diff: getDiffObj,
    },
    {
      value: 'bank_account_name',
      text: '支払/口座名義',
      diff: getDiffObj,
    },
  ]
  if (checkJournals) {
    fields.push({
      value: 'journals',
      text: '請求仕訳',
      convert: wrapJournalsForInvoiceCompany,
      diff: getDiffJournals,
    })
  }
  if (invoice.learning_pay_site) {
    const paySiteFields = [
      {
        value: 'pay_month',
        text: '支払サイト/月',
        diff: getDiffObj,
      },
      {
        value: 'pay_day',
        text: '支払サイト/日',
        diff: getDiffObj,
      },
    ]
    fields.push(...paySiteFields)
  }
  const fileterLearnFields = (diffs) => {
    const expr = new RegExp(/free_text_[1-2]/)
    return diffs.filter((diff) => !diff.value.match(expr))
  }

  invoice.journals = invoice.image_summary_journal
  invoice.company_name = invoice.name
  invoice.gensen_code = invoice.gensenCode
  invoice.gensen_total_targets = invoice.gensenTotalTargets
  invoice.invoice_issuer_number = invoice.invoiceIssuerNumber
  for (let journal of company.journals) {
    journal.debit_sub_account = journal.debit_subject
    journal.credit_sub_account = journal.credit_subject
  }
  const diffObjs = getDiffObjs(company, invoice, fields)

  return fileterLearnFields(diffObjs)
}
