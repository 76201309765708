<template>
  <div>
    <confirm-dialog ref="confirm" size="medium" />
    <no-privilege-dialog ref="noPrivilegeDialog" />
    <v-menu
      v-model="menu"
      bottom
      transition="slide-y-transition"
      nudge-bottom="40"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="!isDeleted"
          v-bind="attrs"
          small
          rounded
          outlined
          :color="menuColor"
          class="ml-2"
          :disabled="disabled"
          v-on="on"
          v-text="'定期払い'"
        />
      </template>

      <v-card class="sweeep-popover" width="420">
        <v-card-title>
          定期払い登録
          <v-spacer />

          <v-menu
            offset-y
            top
            left
            nudge-top="10"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn small depressed color="grey lighten-2">
                  説明
                  <v-icon x-small class="pl-2"> feather-info </v-icon>
                </v-btn>
              </div>
            </template>
            <v-card class="sweeep-popover" width="420">
              <v-card-title>説明</v-card-title>
              <v-card-text>
                <base-alert>
                  「定期払い」を使うと賃料など請求書を受領しない場合にも簡単に経理処理ができます。
                  <br /><br />仕訳の最終承認または支払済ステータスへの変更時に次の期間の請求書データが自動登録されます。
                  契約書などを証憑としてアップロードしていただくことをおすすめします。
                </base-alert>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <div class="fs-10">対象期間</div>
            <v-row no-gutters>
              <date-pick-input v-model="form.startAt" :editable="false" />
              <span class="mx-2">〜</span>
              <date-pick-input
                v-model="form.endAt"
                clearable
                :editable="editable"
              />
            </v-row>
            <v-row no-gutters class="pa-4">
              <v-col
                v-for="monthcol in [1, 4, 7, 10]"
                :key="`monthcol${monthcol}`"
              >
                <v-checkbox
                  v-for="month in [monthcol, monthcol + 1, monthcol + 2]"
                  :key="`month${month}`"
                  v-model="form.issueMonths[month - 1]"
                  :label="`${month}月`"
                  class="mt-0"
                  hide-details
                  :disabled="!editable"
                />
              </v-col>
            </v-row>
            <div class="fs-10 py-3">伝票日付 / 支払日</div>
            <v-row no-gutters>
              <v-col cols="3">
                <v-autocomplete
                  v-model="form.issueDay"
                  :items="dayList"
                  label="計上日"
                  filled
                  outlined
                  dense
                  class="more-dense"
                  append-icon=""
                  placeholder=" "
                  item-text="name"
                  item-value="value"
                  hide-details
                  :disabled="!editable"
                />
              </v-col>
              <v-col cols="1" />
              <v-col cols="5">
                <v-autocomplete
                  v-model="form.paymentTermMonth"
                  :items="paymentTermMonthList"
                  label="支払サイト/月"
                  filled
                  outlined
                  dense
                  class="more-dense"
                  append-icon=""
                  placeholder=" "
                  item-text="name"
                  item-value="value"
                  hide-details
                  :disabled="!editable"
                />
              </v-col>
              <v-col cols="3" class="pl-0">
                <v-autocomplete
                  v-model="form.paymentTermDay"
                  :items="dayList"
                  label="日"
                  filled
                  outlined
                  dense
                  class="more-dense pl-2"
                  append-icon=""
                  placeholder=" "
                  item-text="name"
                  item-value="value"
                  hide-details
                  :disabled="!editable"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn small depressed @click="onClickCancel"> キャンセル </v-btn>
          <v-btn
            v-if="!form.id"
            small
            depressed
            color="primary"
            @click="onClickCreate"
          >
            登録
          </v-btn>
          <v-btn
            v-else
            small
            depressed
            color="primary"
            :disabled="!editable"
            @click="onClickUpdate"
          >
            更新
          </v-btn>
        </v-card-actions>

        <v-card-text
          class="border-top-1"
          style="overflow: scroll; height: calc(100vh - 600px)"
        >
          <v-card
            v-for="(invoice, i) in scheduledInvoices"
            :key="`invoice-${i}`"
            class="pa-2 my-1"
            outlined
          >
            <v-row no-gutters>
              <div style="width: 10%" class="px-1">
                {{ i + 1 }}
              </div>
              <div style="width: 24%" class="pl-1">
                <v-icon x-small v-text="'feather-book'" />
                {{ invoice.issueDate }}
              </div>
              <div style="width: 24%" class="pr-1">
                <v-icon x-small v-text="'feather-dollar-sign'" />
                {{ invoice.payDate }}
              </div>
              <div style="width: 14%" class="px-1">
                <s-chip
                  :color="invoice.created ? 'blue-500' : ''"
                  class="px-1"
                  :class="
                    invoice.created
                      ? ''
                      : 'tw-bg-gray-300 tw-border-none tw-font-normal'
                  "
                >
                  {{ invoice.created ? '登録済' : '未登録' }}
                </s-chip>
              </div>
              <div style="width: 14%" class="px-1">
                <s-chip
                  :color="invoice.approved ? 'blue-500' : ''"
                  class="px-1"
                  :class="
                    invoice.approved
                      ? ''
                      : 'tw-bg-gray-300 tw-border-none tw-font-normal'
                  "
                >
                  {{ invoice.approved ? '承認済' : '未承認' }}
                </s-chip>
              </div>
              <div style="width: 14%" class="px-1">
                <s-chip
                  :color="invoice.paid ? 'blue-500' : ''"
                  class="px-1"
                  :class="
                    invoice.paid
                      ? ''
                      : 'tw-bg-gray-300 tw-border-none tw-font-normal'
                  "
                >
                  {{ invoice.paid ? '支払済' : '未支払' }}
                </s-chip>
              </div>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  scheduledInvoicesGetter,
  issueMonthsConverter,
} from '@/views/invoice/_helpers/recurringPayment'
import { dateManager } from '@/helpers/date'
import DatePickInput from '@/components/Input/DatePickInput.vue'

export default {
  components: {
    DatePickInput,
  },
  props: {
    isDeleted: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isAvoidBankHoliday: {
      type: Boolean,
      default: false,
    },
    recurringSchedule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        id: '',
        startAt: '',
        endAt: '',
        issueDay: '',
        issueMonths: [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ],
        paymentTermMonth: '',
        paymentTermDay: '',
        invoices: [],
        invoicesByIssueYearMonth: {},
      },
      menu: false,
      paymentTermMonthList: [],
      dayList: [],
      scheduledInvoices: [],
    }
  },
  computed: {
    ...mapGetters(['invoicePrivilege']),
    editable() {
      return !this.disabled && !this.recurringSchedule.completed
    },
    menuColor() {
      if (this.recurringSchedule.id && this.recurringSchedule.completed) {
        return 'green'
      } else if (this.recurringSchedule.id) {
        return 'blue'
      } else {
        return 'grey'
      }
    },
  },
  watch: {
    recurringSchedule: {
      handler(value) {
        this.setForm(value)
      },
      deep: true,
      immediate: true,
    },
    form: {
      handler(values) {
        try {
          this.scheduledInvoices = scheduledInvoicesGetter.get({
            ...values,
            isAvoidBankHoliday: this.isAvoidBankHoliday
          })
        } catch (e) {
          console.log(e)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.setPaySiteItems()
  },
  methods: {
    validate() {
      try {
        let errors = []
        const invoices = this.form.invoices
        const startAt = this.form.startAt
        const endAt = this.form.endAt ? new Date(this.form.endAt) : null
        let latestIssueDate = null
        if (invoices.length > 0) {
          latestIssueDate = invoices[invoices.length - 1].issue_date
          latestIssueDate = new Date(latestIssueDate)
        }
        if (!startAt) {
          errors.push('開始日が入力されていません')
        }
        if (endAt && latestIssueDate && endAt < latestIssueDate) {
          errors.push(
            '終了日は登録済みの請求書の最終伝票日付よりも後の日付を設定してください。'
          )
        }
        return errors
      } catch (e) {
        console.log(e)
        return []
      }
    },
    onClickCreate() {
      if (!this.invoicePrivilege.update) {
        this.$refs.noPrivilegeDialog.open()
        return
      }
      const errors = this.validate()
      if (errors.length > 0) {
        const message = errors.join('\n')
        this.$refs.confirm.open(message)
        return
      }
      this.$emit('click-create', this._getEmitForm())
    },
    onClickUpdate() {
      if (!this.invoicePrivilege.update) {
        this.$refs.noPrivilegeDialog.open()
        return
      }
      const errors = this.validate()
      if (errors.length > 0) {
        const message = errors.join('\n')
        this.$refs.confirm.open(message)
        return
      }
      this.$emit('click-update', this._getEmitForm())
    },
    onClickCancel() {
      this.menu = false
    },
    _getEmitForm() {
      let formCp = Object.assign({}, this.form)
      formCp.issueMonths = issueMonthsConverter.fromBoolsToNums(
        formCp.issueMonths
      )
      return formCp
    },
    setForm(value) {
      const hasChange = (from, to) => {
        return from !== to
      }
      const formatInvoicesByIssueYearMonth = (invoices) => {
        let invoicesByIssuYearMonth = {}
        let issueYearMonth = ''
        invoices.forEach((obj) => {
          issueYearMonth = dateManager.format(new Date(obj.issue_date), 'Y-m')
          invoicesByIssuYearMonth[issueYearMonth] = {
            issueDate: obj.issue_date,
            payDate: obj.pay_date,
            paid: obj.paid ? true : false,
            approved: obj.approved ? true : false,
          }
        })
        return invoicesByIssuYearMonth
      }
      const fields = [
        'id',
        'startAt',
        'endAt',
        'issueDay',
        'paymentTermMonth',
        'paymentTermDay',
      ]
      fields.forEach((field) => {
        if (hasChange(this.form[field], value[field])) {
          this.$set(this.form, field, value[field])
        }
      })

      this.form.invoices = value.invoices
      this.form.invoicesByIssueYearMonth = formatInvoicesByIssueYearMonth(
        value.invoices
      )

      const issueMonths = issueMonthsConverter.fromNumsToBools(
        value.issueMonths
      )
      for (let i = 0; i < 12; i++) {
        if (this.form.issueMonths[i] !== issueMonths[i]) {
          this.form.issueMonths.splice(i, 1, issueMonths[i])
        }
      }
    },
    setPaySiteItems() {
      try {
        for (let i = 0; i <= 12; i++) {
          if (i == 0) {
            this.paymentTermMonthList.push({ name: '当月', value: i })
          } else {
            this.paymentTermMonthList.push({ name: i + 'ヶ月後の', value: i })
          }
        }
        for (let i = 31; i >= 1; i--) {
          if (i == 31) {
            this.dayList.push({ name: '月末', value: i })
          } else {
            this.dayList.push({ name: i + '日', value: i })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
