<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="$t('message.confirm')" />
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary " @click.native="submit">
          {{ $t('message.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '赤ライン箇所が正しく入力されていません。',
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    submit() {
      this.close()
    },
  },
}
</script>
