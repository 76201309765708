var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"border-right":"thin #ccc solid"},attrs:{"tile":"","flat":""}},[_c('v-dialog',{attrs:{"max-width":"350"},model:{value:(_vm.dialogRouteInvoice),callback:function ($$v) {_vm.dialogRouteInvoice=$$v},expression:"dialogRouteInvoice"}},[_c('v-card',{staticClass:"sweeep-dialog"},[_c('v-card-title',{domProps:{"textContent":_vm._s('確認')}}),_c('v-card-text',[_vm._v("\n        現在、編集中の情報が破棄されますがよろしいでしょうか？\n      ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{nativeOn:{"click":function($event){_vm.dialogRouteInvoice = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('message.no'))+"\n        ")]),_c('v-btn',{attrs:{"color":"primary"},nativeOn:{"click":function($event){_vm.dialogRouteInvoice = false
            _vm.routeInvoiceOnDialog()}}},[_vm._v("\n          "+_vm._s(_vm.$t('message.yes'))+"\n        ")])],1)],1)],1),_c('v-toolbar',{attrs:{"flat":"","color":"grey lighten-4","height":"50"}},[_c('v-toolbar-title',{staticClass:"font-sm"},[_c('v-btn',{attrs:{"small":"","outlined":"","rounded":"","color":"blue"},domProps:{"textContent":_vm._s(_vm.showFullScreen ? '全画面表示終了' : '全画面表示')},on:{"click":function($event){return _vm.$emit('toggle-screen')}}})],1)],1),(_vm.data.length == 0 && _vm.tableLoader == false)?_c('p',{staticClass:"pa-4"},[_vm._v("\n    条件と一致する請求書がありません。\n  ")]):_vm._e(),_c('div',[(_vm.tableLoader == true)?[_c('div',{staticClass:"text-center",staticStyle:{"margin-top":"60%"}},[_c('v-progress-circular',{staticClass:"text-center",attrs:{"indeterminate":"","color":"primary"}})],1)]:_vm._e(),_c('virtual-list',{ref:"virtual-list",staticClass:"invoice-sidebar-scroll",staticStyle:{"overflow-y":"auto"},style:(_vm.getScrollHeight()),attrs:{"id":"invoiceListScrollbar","data-key":'image_local_id',"data-sources":_vm.data,"data-component":_vm.itemComponent,"extra-props":{
        approveSettings: _vm.approveSettings,
        payConfirmSettings: _vm.payConfirmSettings,
        approveLevel: _vm.approveLevel,
        paramsId: _vm.paramsId,
        onItemClick: _vm.routeInvoiceOnClick,
      },"start":_vm.listIndex,"estimate-size":90}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }