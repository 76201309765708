<!-- ＝＝＝＝＝＝＝＝＝開発者向けのコメントです＝＝＝＝＝＝＝＝＝＝＝＝＝
  *invoice 以外で使うように変更された場合はディレクトリを変更ください
-->
<template>
  <v-menu
    v-model="menu"
    nudge-right="35"
    transition="slide-x-transition"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-icon
          v-tooltip="$t('message.invoice_amount_candidates_hint')"
          x-small
          class="ml-2"
          v-text="'feather-dollar-sign'"
        />
      </div>
    </template>
    <v-card class="sweeep-popover">
      <v-card-title>
        金額置換え
        <v-spacer />
        <v-menu
          offset-y
          top
          left
          nudge-top="10"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn small depressed color="grey lighten-2">
                説明
                <v-icon x-small class="pl-2"> feather-info </v-icon>
              </v-btn>
            </div>
          </template>

          <v-card class="sweeep-popover" width="280">
            <v-card-title>説明</v-card-title>
            <v-card-text>
              <base-alert>
                請求書に書かれている数字の読取結果を表示しています。
                選択して金額欄を置き換えることができ複数選択による合算も可能です。<br />
                二重利用を防ぐため一度使用した金額は他の明細で使用できなくなります。
                ただし、金額欄に直接手入力した場合、二重利用の防止機能が働きません。
              </base-alert>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-card-title>
      <div class="white">
        <v-toolbar flat height="50">
          <v-btn-toggle
            v-model="setting.inputMode"
            dense
            class="d-inline"
            mandatory
            color="blue"
          >
            <v-btn x-small :value="'exclude'" v-text="'本体入力'" />
            <v-btn x-small :value="'include'" v-text="'税込入力'" />
          </v-btn-toggle>
          <v-spacer />
          <v-select
            v-model="setting.lowestDigitNumber"
            :items="[1, 2, 3, 4, 5]"
            dense
            outlined
            filled
            class="more-dense mr-1 ml-3"
            style="max-width: 65px"
            hide-details
            @change="filterByDigitNumber"
          />
          <span class="fs-10">桁以上</span>
        </v-toolbar>
        <v-toolbar flat height="30">
          <v-btn-toggle
            v-model="setting.sortMode"
            dense
            mandatory
            color="blue"
            @change="sortAmount"
          >
            <v-btn
              v-tooltip="'請求書内の表示順'"
              x-small
              :value="'position'"
              v-text="'表示位置'"
            />
            <v-btn
              v-tooltip="'金額の降順'"
              x-small
              :value="'descending'"
              v-text="'↓'"
            />
            <v-btn
              v-tooltip="'金額の昇順'"
              x-small
              :value="'ascending'"
              v-text="'↑'"
            />
          </v-btn-toggle>
          <v-spacer />
          <v-btn-toggle
            v-if="allPages > 1"
            active-class="active-btn-opacity"
            class="ml-2"
          >
            <v-btn
              x-small
              :value="'exclude'"
              @click="previousPage"
              v-text="'<'"
            />
            <v-btn x-small :value="'include'" @click="nextPage" v-text="'>'" />
          </v-btn-toggle>
        </v-toolbar>
        <v-row no-gutters>
          <v-col
            v-for="(candidates, i) in computedCandidates"
            :key="`${journalIndex}-${currentPage}-${i}`"
            class="border-right-1"
          >
            <v-list dense class="fs-11">
              <v-list-item
                v-for="candidate in candidates"
                :key="`${journalIndex}-${candidate.id}`"
                v-tooltip="
                  judgeUsedAmountInOtherJournal(candidate)
                    ? translateJournalType(candidate)
                    : ''
                "
                link
                @click="onClickAmount(candidate)"
              >
                <v-checkbox
                  v-model="selectedAmount"
                  dense
                  hide-details
                  class="mt-0 mb-1"
                  :value="candidate"
                  :disabled="judgeUsedAmountInOtherJournal(candidate)"
                  @click="onClickAmount(candidate)"
                />
                <div class="text-right" style="width: 70px">
                  {{ candidate.amountText }}
                </div>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>

        <v-toolbar height="50" class="pl-1">
          {{ currentPage }}/{{ allPages }}
          <v-spacer />
          <span class="mr-3">
            {{ totalAmount }}
          </span>
          <v-btn
            small
            color="primary"
            @click="onClickInputAmount()"
            v-text="'入力'"
          />
        </v-toolbar>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { numberToCurrency } from 'Helpers/journal'

export default {
  props: {
    journalIndex: {
      type: Number,
      required: true,
    },
    journalType: {
      type: String,
      default: '',
    },
    learningCandidatesAmount: {
      type: Array,
      default: () => [],
    },
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
      selectedAmount: [],
      sortCandidates: [],
      currentPage: 1,
      itemsPerColumn: 10,
      pageColumn: 2,
    }
  },
  computed: {
    itemsPerPage: function () {
      return this.itemsPerColumn * this.pageColumn
    },
    allPages: function () {
      return Math.ceil(this.sortCandidates.length / this.itemsPerPage)
    },
    computedCandidates: function () {
      const candidates = []
      for (let i = 0; i < this.pageColumn; i++) {
        let startIndex =
          (this.currentPage - 1) * this.itemsPerPage + i * this.itemsPerColumn
        let endIndex = startIndex + this.itemsPerColumn
        candidates.push(this.sortCandidates.slice(startIndex, endIndex))
      }
      return candidates
    },
    totalAmount: function () {
      let totalAmount = 0
      for (const obj of this.selectedAmount) {
        totalAmount += obj.amount
      }
      return numberToCurrency(totalAmount)
    },
  },
  watch: {
    menu: function (menu) {
      if (menu) {
        this.sortCandidates = this.learningCandidatesAmount
        this.filterByDigitNumber()
        this.setSelectedAmount()
      }
    },
  },
  methods: {
    previousPage() {
      if (this.currentPage != 1) {
        this.currentPage -= 1
      }
    },
    nextPage() {
      if (this.currentPage != this.allPages) {
        this.currentPage += 1
      }
    },
    setSelectedAmount() {
      this.selectedAmount = []
      for (const obj of this.learningCandidatesAmount) {
        if (
          obj.journalIndex === this.journalIndex &&
          obj.journalType === this.journalType
        ) {
          this.selectedAmount.push(obj)
        }
      }
    },
    onClickInputAmount() {
      this.$emit('click:item', {
        tax: this.setting.inputMode,
        amount: this.totalAmount,
        selected: this.selectedAmount,
      })
      this.menu = false
    },
    judgeUsedAmountInOtherJournal(candidate) {
      return (
        candidate.journalIndex >= 0 &&
        (candidate.journalIndex != this.journalIndex ||
          candidate.journalType != this.journalType)
      )
    },
    translateJournalType(candidate) {
      let translateJournalType = ''
      if (candidate.journalType === 'invoiceJournal') {
        translateJournalType = '仕訳'
      } else {
        translateJournalType = '支払仕訳'
      }
      return `${translateJournalType}明細${candidate.journalIndex + 1}で使用`
    },
    onClickAmount(candidate) {
      if (this.judgeUsedAmountInOtherJournal(candidate)) {
        return
      }
      if (this.selectedAmount.includes(candidate)) {
        const index = this.selectedAmount.findIndex((obj) => obj === candidate)
        this.selectedAmount.splice(index, 1)
      } else {
        this.selectedAmount.push(candidate)
      }
    },
    filterByDigitNumber() {
      this.sortCandidates = []
      for (const candidate of this.learningCandidatesAmount) {
        const lowestAmount = 10 ** (this.setting.lowestDigitNumber - 1)
        if (candidate.amount >= lowestAmount) {
          this.sortCandidates.push(candidate)
        }
      }
      this.sortAmount()
    },
    sortAmount() {
      switch (this.setting.sortMode) {
        case 'position':
          this.sortCandidates.sort((a, b) => {
            return (
              a.page - b.page ||
              a.yCoordinate - b.yCoordinate ||
              a.xCoordinate - b.xCoordinate
            )
          })
          break
        case 'descending':
          this.sortCandidates.sort((a, b) => b.amount - a.amount)
          break
        case 'ascending':
          this.sortCandidates.sort((a, b) => a.amount - b.amount)
          break
      }
    },
  },
}
</script>
