<template>
  <v-card tile outlined class="pa-0" :style="`height: calc(${height});`">
    <vue-perfect-scrollbar
      class="sweeep-invoice-area-sidebar"
      style="height: calc(100% - 50px)"
    >
      <v-list>
        <v-list-item link class="pa-2 text-center">
          <v-menu
            offset-x
            left
            nudge-left="20"
            max-width="400"
            transition="slide-x-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" class="text-center w-100" v-on="on">
                <div class="my-3">
                  <s-icon
                    color="gray-500"
                    class="tw-mr-1"
                    icon="mdi mdi-school-outline"
                  />
                  <!-- 学習 -->
                  <span
                    class="d-inline-block"
                    :class="[
                      $style.invoiceAreaFooterCircle,
                      learned ? 'green' : 'grey',
                    ]"
                  />
                </div>
                <div class="my-3">
                  <s-icon
                    color="gray-500"
                    class="tw-mr-1"
                    icon="mdi mdi-magnify-scan"
                  />
                  <!-- 金額読み取り精度 -->
                  <span
                    class="d-inline-block"
                    :class="[
                      $style.invoiceAreaFooterCircle,
                      iconAmountAccuracy,
                    ]"
                  />
                </div>
                <div class="my-3">
                  <s-icon
                    color="gray-500"
                    class="tw-mr-1"
                    icon="mdi mdi-bank-outline"
                  />
                  <!-- 銀行口座 -->
                  <span
                    class="d-inline-block"
                    :class="[
                      $style.invoiceAreaFooterCircle,
                      invoiceAreaBankIconColor,
                    ]"
                  />
                </div>
              </div>
            </template>

            <v-card class="sweeep-popover" width="400">
              <v-card-title v-text="'アイコンの説明'" />

              <v-card-text>
                <div>
                  <s-icon
                    color="gray-500"
                    class="tw-mr-1"
                    icon="mdi mdi-school-outline"
                  />
                  <span>学習について</span>
                </div>
                <div class="px-4 py-2">
                  <span
                    class="d-inline-block green"
                    :class="$style.invoiceAreaFooterCircle"
                  />
                  <span> 学習済み </span>
                  <span
                    class="d-inline-block grey ml-3"
                    :class="$style.invoiceAreaFooterCircle"
                  />
                  <span> 未学習 </span>
                </div>

                <div class="mt-2">
                  <s-icon
                    color="gray-500"
                    class="tw-mr-1"
                    icon="mdi mdi-magnify-scan"
                  />
                  <span>金額の読取り精度について</span>
                </div>
                <div class="px-4 py-2">
                  <span
                    class="d-inline-block green"
                    :class="$style.invoiceAreaFooterCircle"
                  />
                  <span> 高精度 </span>
                  <span
                    class="d-inline-block yellow darken-1 ml-3"
                    :class="$style.invoiceAreaFooterCircle"
                  />
                  <span> 通常 </span>
                  <span
                    class="d-inline-block red ml-3"
                    :class="$style.invoiceAreaFooterCircle"
                  />
                  <span> 要注意 </span>
                </div>

                <div class="mt-2">
                  <s-icon
                    color="gray-500"
                    class="tw-mr-1"
                    icon="mdi mdi-bank-outline"
                  />
                  <span>振込先口座について</span>
                </div>
                <div class="px-4 py-2">
                  <div>
                    <span
                      class="d-inline-block green"
                      :class="$style.invoiceAreaFooterCircle"
                    />
                    <span> 学習済みの口座 </span>
                  </div>
                  <div>
                    <span
                      class="d-inline-block yellow"
                      :class="$style.invoiceAreaFooterCircle"
                    />
                    <span> 読取不可のため学習済口座を適用 </span>
                  </div>
                  <div>
                    <span
                      class="d-inline-block red"
                      :class="$style.invoiceAreaFooterCircle"
                    />
                    <span> 学習済みの口座と異なります </span>
                  </div>
                  <div>
                    <span
                      class="d-inline-block grey"
                      :class="$style.invoiceAreaFooterCircle"
                    />
                    <span> 未学習 </span>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-list-item>
        <v-list-item v-show="hasMessageFromInbox" class="px-2">
          <v-list-item-action>
            <inbox-note
              :inbox-purpose="inboxPurpose"
              :inbox-comment="inboxComment"
              offset-x
              left
              :nudge-left="20"
            >
              <template #display="slotProps">
                <v-btn
                  x-small
                  depressed
                  :class="{ 'blue lighten-3': inboxPurpose || inboxComment }"
                  v-bind="slotProps.attrs"
                  v-on="slotProps.on"
                >
                  <span> 回収<br />メモ </span>
                </v-btn>
              </template>
            </inbox-note>
          </v-list-item-action>
        </v-list-item>
        <v-list-item class="px-2">
          <v-list-item-action>
            <invoice-note
              number="1"
              :value="note1"
              :editable="editableNote"
              offset-x
              left
              :nudge-left="20"
              @click:update="
                $emit('click:updateNote', { number: 1, text: $event })
              "
            >
              <template #display="slotProps">
                <v-btn
                  v-tooltip="{ content: note1, placement: 'left' }"
                  x-small
                  depressed
                  :class="note1 ? 'blue lighten-3' : ''"
                  v-bind="slotProps.attrs"
                  v-on="slotProps.on"
                >
                  <span> 管理<br />メモ{{ slotProps.number }} </span>
                </v-btn>
              </template>
            </invoice-note>
          </v-list-item-action>
        </v-list-item>
        <v-list-item class="px-2">
          <v-list-item-action>
            <invoice-note
              number="2"
              :value="note2"
              :editable="editableNote"
              offset-x
              left
              :nudge-left="20"
              @click:update="
                $emit('click:updateNote', { number: 2, text: $event })
              "
            >
              <template #display="slotProps">
                <v-btn
                  v-tooltip="{ content: note2, placement: 'left' }"
                  x-small
                  depressed
                  :class="note2 ? 'blue lighten-3' : ''"
                  v-bind="slotProps.attrs"
                  v-on="slotProps.on"
                >
                  <span> 管理<br />メモ{{ slotProps.number }} </span>
                </v-btn>
              </template>
            </invoice-note>
          </v-list-item-action>
        </v-list-item>
        <v-list-item class="px-2">
          <v-list-item-action>
            <invoice-note
              number="3"
              :value="note3"
              :editable="editableNote"
              offset-x
              left
              :nudge-left="20"
              @click:update="
                $emit('click:updateNote', { number: 3, text: $event })
              "
            >
              <template #display="slotProps">
                <v-btn
                  v-tooltip="{ content: note3, placement: 'left' }"
                  x-small
                  depressed
                  :class="note3 ? 'blue lighten-3' : ''"
                  v-bind="slotProps.attrs"
                  v-on="slotProps.on"
                >
                  <span> 管理<br />メモ{{ slotProps.number }} </span>
                </v-btn>
              </template>
            </invoice-note>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
        <v-list-item class="px-2">
          <v-list-item-action>
            <v-menu
              offset-x
              left
              transition="slide-x-transition"
              nudge-left="20"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  depressed
                  block
                  v-bind="attrs"
                  v-on="on"
                  v-text="'v' + currentVersion"
                />
              </template>
              <v-card class="sweeep-popover">
                <v-card-title> Version </v-card-title>
                <v-list dense>
                  <v-list-item link :disabled="!canReplaceInvoice">
                    <i class="mr-3 feather-refresh-ccw fs-10" />
                    <span class="font-sm" @click="$emit('import-image')"
                      >ファイルを差し替え</span
                    >
                  </v-list-item>
                  <v-list-item link :disabled="currentVersion >= maxVersion">
                    <i class="mr-3 feather-arrow-up fs-10" />
                    <span class="font-sm" @click="$emit('version-up')"
                      >次のバージョン</span
                    >
                  </v-list-item>
                  <v-list-item link :disabled="currentVersion <= 1">
                    <i class="mr-3 feather-arrow-down fs-10" />
                    <span class="font-sm" @click="$emit('version-down')"
                      >前のバージョン</span
                    >
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
        <v-list-item class="px-2">
          <v-list-item-action>
            <v-btn
              v-tooltip="{
                content: '新しいウィンドウで表示',
                placement: 'left',
              }"
              small
              depressed
              block
              @click="openPdfWindow()"
            >
              PDF
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </vue-perfect-scrollbar>
    <div class="pa-1">
      <v-btn
        small
        depressed
        color="grey lighten-2"
        @click="$emit('open-history')"
        v-text="'履歴'"
      />
    </div>
  </v-card>
</template>

<style module>
.invoiceAreaFooterCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
</style>

<script>
import InboxNote from './InboxNote'
import InvoiceNote from './InvoiceNote'

export default {
  components: {
    InboxNote,
    InvoiceNote,
  },
  props: {
    learned: {
      type: Boolean,
      required: true,
    },
    height: {
      type: String,
      default: '100%',
    },
    note1: {
      type: String,
      required: true,
    },
    note2: {
      type: String,
      required: true,
    },
    note3: {
      type: String,
      required: true,
    },
    scoreMaxAmount: {
      validator: (prop) => typeof prop === 'number' || prop === null,
      required: true,
    },
    currentVersion: {
      type: Number,
      required: true,
    },
    maxVersion: {
      type: Number,
      required: true,
    },
    matchCompanyBank: {
      type: Boolean,
      required: true,
    },
    canReplaceInvoice: {
      type: Boolean,
      default: false,
    },
    invoiceAreaBankIconColor: {
      type: String,
      required: true,
    },
    localId: {
      type: Number,
      default: null,
    },
    inboxPurpose: {
      type: String,
      default: '',
    },
    inboxComment: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconAmountAccuracy() {
      const accuracy = Number(this.scoreMaxAmount)
      if (accuracy >= 70) {
        return 'green'
      } else if (accuracy >= 50) {
        return 'yellow darken-1'
      } else {
        return 'red'
      }
    },
    editableNote: function () {
      return this.$store.getters.invoicePrivilege.update_note ? true : false
    },
  },
  methods: {
    openPdfWindow() {
      const url = `/pdf/${this.localId}/${this.currentVersion}`
      window.open(url, '', 'width=500, height=700')
    },
    hasMessageFromInbox: function () {
      return Boolean(this.inboxPurpose || this.inboxComment)
    },
  },
}
</script>
