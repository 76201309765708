<template>
  <div
    class="sweeep-journal-tab"
    style="width: 22px; margin-top: 8px; padding: 3px 0px"
    :style="{ left: positionLeft }"
  >
    <v-menu
      offset-x
      z-index="9999"
      nudge-right="20"
      transition="slide-x-transition"
      :open-on-hover="showMenu"
      close-delay="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="showMenu ? attrs : ''" v-on="showMenu ? on : ''">
          <div class="fs-10 my-2">{{ index }}<br />／<br />{{ count }}</div>
          <div v-show="showMenu" class="pb-2">
            <s-icon icon="feather-plus" />
            <s-icon icon="feather-trash" />
          </div>
        </div>
      </template>

      <v-list dense class="fs-11">
        <v-list-item @click="$emit('add:next')">
          <s-icon class="tw-mr-3" icon="feather-plus" />
          次の行に明細追加
        </v-list-item>
        <v-list-item @click="onClick('add:last')">
          <s-icon class="tw-mr-3" icon="feather-plus" />
          最終行に明細追加
        </v-list-item>
        <v-list-item @click="onClick('copy:next')">
          <s-icon class="tw-mr-3" icon="feather-copy" />
          明細をコピーして次の行へ
        </v-list-item>
        <v-list-item @click="onClick('copy:last')">
          <s-icon class="tw-mr-3" icon="feather-copy" />
          明細をコピーして最終行へ
        </v-list-item>
        <v-list-item v-if="showAllocation" @click="onClick('allocation')">
          <s-icon class="tw-mr-3" icon="feather-share-2" />
          この明細を部門別に按分
        </v-list-item>
        <v-list-item @click="onClick('clear:this')">
          <s-icon class="tw-mr-3" icon="feather-x" />
          この明細をクリア
        </v-list-item>
        <v-list-item @click="onClick('clear:all')">
          <s-icon class="tw-mr-3" icon="feather-x" />
          全明細をクリア
        </v-list-item>
        <v-list-item
          v-if="index > 1 || count >= 2"
          @click="onClick('delete:this')"
        >
          <s-icon class="tw-mr-3" icon="feather-trash-2" />
          この明細を削除
        </v-list-item>
        <v-list-item
          v-if="index > 1 || count >= 2"
          @click="onClick('delete:all')"
        >
          <s-icon class="tw-mr-3" icon="feather-trash-2" />
          全明細を削除
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
      default: null,
    },
    count: {
      type: Number,
      required: true,
      default: null,
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    positionLeft: {
      type: String,
      default: '446px',
    },
    showAllocation: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick(event) {
      try {
        this.$emit(event)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
