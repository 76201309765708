import OrganizationLearningSettings from '@/clients/organization/excludeLearningFields'

const companyCandidatesService = {
  sortPhoneNumbers(phoneNumberList, ownPhoneNumbers, invoiceValue) {
    const copyedPhoneNumberList = JSON.parse(JSON.stringify(phoneNumberList))
    if (invoiceValue) {
      copyedPhoneNumberList.push(invoiceValue)
    }
    const ownPhoneNumbersSet = new Set(ownPhoneNumbers)
    const numbersWithoutOwn = copyedPhoneNumberList.filter((phoneNumber) => {
      return !ownPhoneNumbersSet.has(phoneNumber)
    })
    return Array.from(new Set(numbersWithoutOwn))
  },
  async registerLearningSettings(token, data) {
    return await OrganizationLearningSettings.post(token, data)
  },
}

export default companyCandidatesService
