
export const journalLockMode = Object.freeze({
  DEFAULT: 0,
  STRICT: 1
})


export const isJournalLocked = (invoice, invoicePrivilege, settingJournalLockMode) => {
  if (settingJournalLockMode === journalLockMode.DEFAULT) {
    return Boolean(invoice.approved)
  } else {
    if (invoicePrivilege.approve5) {
      return Boolean(invoice.approved)
    } else {
      return Boolean(
        invoice.approved1 ||
        invoice.approved2 ||
        invoice.approved3 ||
        invoice.approved4 ||
        invoice.approved
      )
    }
  }
}