<template>
  <v-btn-toggle
    v-model="data"
    v-tooltip="
      isInvoiceJournal
        ? '<span class=v-tooltip_shortcutkey>Ctrl</span> <span class=v-tooltip_shortcutkey>Shift</span> <span class=v-tooltip_shortcutkey>←→</span>'
        : ''
    "
    mandatory
    color="blue"
  >
    <v-btn
      v-if="isInvoiceJournal"
      v-shortkey="{
        left: ['ctrl', 'shift', 'arrowleft'],
        right: ['ctrl', 'shift', 'arrowright'],
      }"
      small
      :value="1"
      @shortkey="displayMode == 2 ? moveTabs($event) : ''"
    >
      仕訳
      <span v-show="lockedInvoiceJournals">
        <s-icon
          tooltip="最終承認されているため編集できません"
          class="tw-ml-1"
          icon="feather-lock"
        />
      </span>
      <edited-badge
        ref="invoiceJournalEditedBadge"
        :check-items="[{ value: 'image_summary_journal', include: true }]"
      />
    </v-btn>

    <v-btn v-if="isPaymentJournal" small :value="2">
      支払仕訳
      <span v-show="lockedPaymentJournals">
        <s-icon
          tooltip="支払連携されているため編集できません"
          class="tw-ml-1"
          icon="feather-lock"
        />
      </span>
      <edited-badge
        ref="paymentJournalEditedBadge"
        :check-items="[{ value: 'payment_journals', include: true }]"
      />
    </v-btn>

    <v-btn small :value="3">
      支払
      <span v-show="lockedPayment">
        <s-icon
          tooltip="支払連携されているため編集できません"
          class="tw-ml-1"
          icon="feather-lock"
        />
      </span>
      <edited-badge
        ref="paymentBankEditedBadge"
        :check-items="[
          { value: 'user_bank' },
          { value: 'paid_by_customer' },
          { value: 'bank_name' },
          { value: 'bank_branch_name' },
          { value: 'bank_account_name' },
          { value: 'bank_account_number' },
          { value: 'bank_account_type' },
          { value: 'payment_note1' },
          { value: 'payment_note2' },
          { value: 'payment_note3' },
        ]"
      />
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import EditedBadge from './EditedBadge.vue'

export default {
  components: {
    EditedBadge,
  },
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    formType: {
      type: Number,
      required: true,
    },
    editableInvoiceJournals: {
      type: Boolean,
      required: true,
    },
    editablePaymentJournals: {
      type: Boolean,
      required: true,
    },
    editablePayment: {
      type: Boolean,
      required: true,
    },
    displayMode: {
      type: Number,
      required: true,
    },
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('select', val)
      },
    },
    isInvoiceJournal: function () {
      return [1, 2].includes(this.formType)
    },
    isPaymentJournal: function () {
      return this.formType === 1
    },
    lockedInvoiceJournals() {
      return !this.editableInvoiceJournals
    },
    lockedPaymentJournals() {
      return !this.editablePaymentJournals
    },
    lockedPayment() {
      return !this.editablePayment
    },
  },
  methods: {
    getEditedStatus() {
      let result = {}

      if (this.isInvoiceJournal) {
        result.invoiceJournal = this.$refs.invoiceJournalEditedBadge.getStatus()
      }
      if (this.isPaymentJournal) {
        result.paymentJournal = this.$refs.paymentJournalEditedBadge.getStatus()
      }
      result.paymentBank = this.$refs.paymentBankEditedBadge.getStatus()
      return result
    },
    moveTabs(event) {
      switch (event.srcKey) {
        case 'left':
          if (this.data === 1) {
            this.data = 3
          } else if (this.data === 3 && !this.isPaymentJournal) {
            this.data = 1
          } else {
            this.data -= 1
          }
          break
        case 'right':
          if (this.data === 1 && !this.isPaymentJournal) {
            this.data = 3
          } else if (this.data === 3) {
            this.data = 1
          } else {
            this.data += 1
          }
          break
      }
    },
  },
}
</script>
