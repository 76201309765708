import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class CurrencyCodeClient extends APIClient {
  async get(token) {
    const url = URLs.CURRENCY_CODE
    const payload = {
      url,
      method: 'GET',
      headers: { Authorization: token },
    }
    const response = await this.sendRequest(payload)
    return response.data.currency_codes
  }
}
export default new CurrencyCodeClient()
