<template>
  <base-dialog
    ref="dialog"
    :title="$t('message.confirm')"
    :message="$t('message.confirm_delete')"
    :cancel-text="$t('message.no')"
    :submit-text="$t('message.delete')"
    max-width="350"
    @submit="submit()"
  />
</template>

<script>
export default {
  methods: {
    open() {
      this.$refs.dialog.open()
    },
    close() {
      this.$refs.dialog.close()
    },
    submit() {
      this.$emit('submit')
      this.close()
    },
  },
}
</script>
