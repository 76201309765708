<template>
  <span v-tooltip="tooltipText">
    <s-chip :color="chipColor">
      {{ statusText }}
    </s-chip>
  </span>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    approveLevel: {
      type: Number,
      default: 5,
    },
    approveSettings: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    statusText: function () {
      if (this.item.export_count > 0) {
        return '出力済'
      }
      if (this.item.approve_user5.id) {
        return this.approveSettings.approve5.name
      }
      if (this.item.approve_user4.id) {
        return this.approveSettings.approve4.name
      }
      if (this.item.approve_user3.id) {
        return this.approveSettings.approve3.name
      }
      if (this.item.approve_user2.id) {
        return this.approveSettings.approve2.name
      }
      if (this.item.approve_user1.id) {
        return this.approveSettings.approve1.name
      }
      if (this.item.update_user.id) {
        return '更新済'
      }
      return '未更新'
    },
    chipColor: function () {
      if (this.item.export_count > 0) {
        return 'blue-500'
      }
      if (this.item.approve_user5.id) {
        return 'yellow-500'
      }
      if (this.item.approve_user4.id) {
        return 'yellow-500'
      }
      if (this.item.approve_user3.id) {
        return 'yellow-500'
      }
      if (this.item.approve_user2.id) {
        return 'yellow-500'
      }
      if (this.item.approve_user1.id) {
        return 'yellow-500'
      }
      if (this.item.update_user.id) {
        return 'green-500'
      }
      return 'gray-500'
    },
    tooltipText: function () {
      let text = ''
      text += `アップロード ${this.item.date_upload} ${this.getFullName(
        this.item.upload_user
      )}<br>`
      text += `更新 ${this.item.date_update} ${this.getFullName(
        this.item.update_user
      )}<br>`

      if (this.approveLevel > 4) {
        text += `承認${this.approveLevel - 4} ${
          this.item.date_approve1
        } ${this.getFullName(this.item.approve_user1)}<br>`
      }
      if (this.approveLevel > 3) {
        text += `承認${this.approveLevel - 3} ${
          this.item.date_approve2
        } ${this.getFullName(this.item.approve_user2)}<br>`
      }
      if (this.approveLevel > 2) {
        text += `承認${this.approveLevel - 2} ${
          this.item.date_approve3
        } ${this.getFullName(this.item.approve_user3)}<br>`
      }
      if (this.approveLevel > 1) {
        text += `承認${this.approveLevel - 1} ${
          this.item.date_approve4
        } ${this.getFullName(this.item.approve_user4)}<br>`
      }
      text += `承認${this.approveLevel} ${
        this.item.date_approve5
      } ${this.getFullName(this.item.approve_user5)}<br>`
      text += `仕訳エクスポート ${this.item.export_count}回 ${
        this.item.date_export
      } ${this.getFullName(this.item.export_user)}`
      return text
    },
  },
  methods: {
    getFullName(item) {
      try {
        if (item.full_name) {
          return item.full_name
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
  },
}
</script>
