import { abstractLogics } from './_logics'
import { i18n } from '@/plugins/i18n'

export const companyRules = {
  logics: {
    ...abstractLogics,
  },
  getRules() {
    const { logics } = this
    return {
      name: [
        (v) => !!v || i18n.t('message.form_input_required'),
        (v) =>
          (v && v.replace(/\s/g, '').length >= 1) ||
          i18n.t('message.form_input_required'),
        (v) => logics.getLimitRule('', 255)(v),
      ],
      phoneNumber: [(v) => logics.getLimitRule('', 20)(v)],
      address: [
        (v) => logics.getLimitRule('', 200)(v),
        (v) => logics.getMinLimitAllowBlankRule('', 5)(v)
      ],
      gensenTotalTargets: [
        (v) => !v || Number(v) >= 0 || '0人以上を選択してください。',
      ],
    }
  },
}

export const companyPatternRules = {
  logics: {
    ...abstractLogics,
    getTrimmed(v) {
      return !!(v && v.replace(/\s/g, '').length >= 1)
    },
  },
  getRules() {
    const { logics } = this
    return {
      name: [
        (v) => !!v || i18n.t('message.form_input_required'),
        (v) => logics.getTrimmed(v) || i18n.t('message.form_input_required'),
        (v) => logics.getLimitRule('', 255)(v),
      ],
      keyword: [(v) => v.length !== 1 || '2字以上で入力してください。'],
    }
  },
}
