<template>
  <v-toolbar height="50" flat class="sweeep-invoice-area-header">
    <div class="mr-2" style="white-space: nowrap">
      <!-- <v-menu
        offset-y
        nudge-bottom="25"
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            style="display: inline-block;"
            v-bind="attrs"
            v-on="on"
          >
            <v-chip
              v-tooltip="timeStampState.discription"
              small
              outlined
              link
              :color="timeStampState.color"
              v-text="'電帳'"
            />
          </div>
        </template>

        <v-card class="sweeep-popover" width="450">
          <v-card-title>
            電子帳簿について
          </v-card-title>
          <v-card-text>
            <div class="mb-2">
              税務署への申請承認が完了していてタイムスタンプが付与されているデータは電子帳簿保存法（スキャナ保存）の適用が可能です。（実際の適用可否はお客様にてご確認ください）
            </div>
            <div class="fw-bold mb-2">
              表示の説明
            </div>
            <div class="mt-1">
              <v-chip
                small
                outlined
                color="blue"
                class="mr-2"
                v-text="'電帳'"
              />
              タイムスタンプが付与されています。
            </div>
            <div class="mt-1">
              <v-chip
                small
                outlined
                color="grey lighten-1"
                class="mr-2"
                v-text="'電帳'"
              />
              タイムスタンプが付与されていません。
            </div>
            <div class="mt-1">
              <v-chip
                small
                outlined
                color="red"
                class="mr-2"
                v-text="'電帳'"
              />
              タイムスタンプが付与されていますが有効期限が切れています。
            </div>
          </v-card-text>
        </v-card>
      </v-menu> -->
      <v-chip
        v-if="invoiceForm.idFreee"
        small
        outlined
        color="blue"
        class="ml-2"
        style="display: inline-block"
        @click="$emit('click:freeeDeal')"
        v-text="'freee'"
      />
    </div>

    <invoice-avatars
      :user-uploaded="invoiceForm.userUploaded"
      :user-viewed="invoiceForm.userViewed"
      :user-updated="invoiceForm.userUpdated"
      :date-uploaded="invoiceForm.dateUploaded"
      :date-viewed="invoiceForm.dateViewed"
      :date-updated="invoiceForm.dateUpdated"
      @click:cancelUserViewed="$emit('click:cancelUserViewed')"
    />

    <v-toolbar-items>
      <v-divider vertical class="my-0 mx-3" />
    </v-toolbar-items>

    <invoice-approve-btns
      :value="invoiceForm"
      :disabled="disabled"
      :freee-separate-approval="freeeSeparateApproval"
      :approved="invoiceForm.approved === 1"
      :is-freee-coordinated="invoiceForm.idFreee !== 0"
      @click:approve="$emit('click:approveInvoice', $event)"
      @click:cancelApprove="$emit('click:cancelApproveInvoice', $event)"
    />

    <v-btn
      v-tooltip="'メール送信'"
      small
      depressed
      class="mx-2"
      @click="onClickSendEmail"
    >
      <v-icon x-small> メール </v-icon>
    </v-btn>

    <v-menu offset-y nudge-bottom="25" transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <div class="tw-mt-4" v-bind="attrs" v-on="on">
          <s-icon icon="feather-info" />
        </div>
      </template>

      <v-card class="sweeep-popover" width="450">
        <v-card-title> 仕訳承認について </v-card-title>
        <v-card-text>
          仕訳に対する承認フローを管理できます。<br />
          承認フローは最大5段階まで設けることが可能できます。
          また各段階で承認できるユーザ権限をコントロールすることができます。（管理者設定メニュー内で指定できます。権限については利用プランによる制限あり。）
        </v-card-text>
      </v-card>
    </v-menu>

    <v-toolbar-items>
      <v-divider vertical class="my-0 mx-3" />
    </v-toolbar-items>

    <payment-approve-btns
      :value="invoiceForm"
      :disabled="disabled"
      @click:approve="$emit('click:approvePayment', $event)"
      @click:cancelApprove="$emit('click:cancelApprovePayment', $event)"
    />

    <v-menu offset-y nudge-bottom="25" transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <div class="tw-mt-4 tw-ml-2" v-bind="attrs" v-on="on">
          <s-icon icon="feather-info" />
        </div>
      </template>

      <v-card class="sweeep-popover" width="450">
        <v-card-title> 支払承認について </v-card-title>
        <v-card-text>
          支払に対する承認フローを管理できます。<br />
          承認フローは最大3段階まで設けることが可能できます。
          支払連携を行うと支払メニューにデータが連携され、振込データ（総合振込用）の出力が可能になります。
        </v-card-text>
      </v-card>
    </v-menu>
  </v-toolbar>
</template>

<script>
import InvoiceAvatars from './InvoiceAvatars'
import InvoiceApproveBtns from 'Views/invoice/components/ApproveBtns/InvoiceApproveBtns.vue'
import PaymentApproveBtns from 'Views/invoice/components/ApproveBtns/PaymentApproveBtns.vue'

export default {
  components: {
    InvoiceAvatars,
    InvoiceApproveBtns,
    PaymentApproveBtns,
  },
  props: {
    invoiceForm: {
      type: Object,
      required: true,
    },
    invoiceFile: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    freeeSeparateApproval: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    timeStampState() {
      let color, discription
      if (this.invoiceFile.isStamped && !this.invoiceFile.isExpired) {
        color = 'blue'
        discription = 'タイムスタンプあり'
      } else if (this.invoiceFile.isStamped && this.invoiceFile.isExpired) {
        color = 'red'
        discription = 'タイムスタンプあり(有効期限切れ)'
      } else {
        color = 'grey lighten-1'
        discription = 'タイムスタンプなし'
      }
      return { color, discription }
    },
  },
  methods: {
    onClickSendEmail() {
      this.$emit('click:sendEmail')
    },
    getToolTips(label, user, date) {
      const fullName = this.getFullName(user)
      if (fullName) {
        return `${label}ユーザ: ${fullName}<br>${label}日時: ${date}`
      } else {
        return ''
      }
    },
    getAvaterName(obj) {
      try {
        const fullName = this.getFullName(obj)
        if (fullName) {
          return fullName.slice(0, 1)
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
    getFullName(obj) {
      try {
        return obj.full_name
      } catch (e) {
        return ''
      }
    },
  },
}
</script>
