
// Common
const isInvalidDate = (date: Date) => Number.isNaN(date.getTime());

const getTaxDeductionRate = (issuedOn: Date): number => {
  if (isInvalidDate(issuedOn)) {
    return 1
  }
  const deductionConfigs = [
    { rate: 1, lastDate: new Date('2023-09-30') },
    { rate: 0.8, lastDate: new Date('2026-09-30') },
    { rate: 0.5, lastDate: new Date('2029-09-30') },
  ]
  let deductionRate = 0
  for (let cfg of deductionConfigs) {
    if (issuedOn <= cfg.lastDate) {
      deductionRate = cfg.rate
      break
    }
  }
  return deductionRate
}

export enum EntrySide {
  DEBIT = "debit",
  CREDIT = "credit",
}

export enum AmountInputMode {
  TAX_EXCLUDED = 1,
  TAX_INCLUDED = 2,
}

// Model
export type TJournal = {
  amount: number
  amountWithoutTax: number
  amountWithoutTaxAfterAdjustment: number
  taxAmount: number
  taxAmountAfterAdjustment: number
  isKeikasochi: boolean
  amountInputMode: AmountInputMode
  getTaxRate: () => number
}

export class Journal implements TJournal {
  amount: number
  amountWithoutTax: number
  taxAmount: number
  amountWithoutTaxAfterAdjustment: number
  taxAmountAfterAdjustment: number
  isKeikasochi: boolean
  amountInputMode: AmountInputMode
  getTaxRate: () => number

  constructor(values: TJournal) {
    Object.assign(this, values)
  }

  computeAmountAfterAdjustment() {
    this.amount = this.amountWithoutTaxAfterAdjustment + this.taxAmountAfterAdjustment
  }

  // 経過措置適用後の税額をセット
  setTaxAmountAfterAdjustment(taxAmount: number) {
    this.taxAmountAfterAdjustment = taxAmount
    this.amountWithoutTaxAfterAdjustment = this.amount - taxAmount
  }

  // 控除率で税額と税抜額を調整
  adjustTaxAmount(deductionRate: number) {
    if (this.isKeikasochi) {
      this.taxAmountAfterAdjustment = Math.trunc(this.taxAmount * deductionRate)
      this.amountWithoutTaxAfterAdjustment = this.amount - this.taxAmountAfterAdjustment
    }
  }

  computeTaxAmount() {
    let taxAmount = 0
    const taxRate = this.getTaxRate()
    if (this.amountInputMode === AmountInputMode.TAX_INCLUDED) {
      taxAmount = this.amount * taxRate / (100 + taxRate)
      this.taxAmount = Math.trunc(taxAmount)
      this.amountWithoutTax = this.amount - this.taxAmount
    } else {
      taxAmount = this.amountWithoutTax * taxRate / 100
      this.taxAmount = Math.trunc(taxAmount)
      this.amount = this.amountWithoutTax + this.taxAmount
    }
  }

  clearTaxAmount() {
    this.taxAmount = 0
    this.taxAmountAfterAdjustment = 0
    this.amountWithoutTaxAfterAdjustment = 0
    if (this.amountInputMode === AmountInputMode.TAX_INCLUDED) {
      this.amountWithoutTax = this.amount
    } else {
      this.amount = this.amountWithoutTax
    }
  }
}

export type TTax = {
  id: number
}

export type TMasterList = {
  tax: Array<TTax>
}

// Application Service
class JournalService {

  getTaxDeductionRate(issueDate?: Date): number {
    if (!issueDate) {
      return 0
    }
    return getTaxDeductionRate(issueDate)
  }

  getTaxDeductionRateAsPercentage(issueDate?: Date): number {
    if (!issueDate) {
      return 0
    }
    return getTaxDeductionRate(issueDate) * 100
  }

  // 非適格請求書の税額調整対象かどうか
  isTaxAdjustment(isInvoiceIssuer: number, issueDate?: Date) {
    if (isInvoiceIssuer || !issueDate) {
      return false
    }
    const deductionRate = getTaxDeductionRate(issueDate)
    return deductionRate < 1
  }

  // 経過措置控除期間
  isKeikasochi(isInvoiceIssuer: boolean, issueDate?: Date) {
    if (isInvoiceIssuer || !issueDate) {
      return false
    }
    const deductionRate = getTaxDeductionRate(issueDate)
    return deductionRate > 0 && deductionRate < 1
  }

  // 経過措置期間でひとつも税額調整されていない
  isNoTaxAmountAdjustedWhenKeikasochi(
    journals: Array<Journal>,
    isInvoiceIssuer: boolean,
    issueDate: Date
  ): boolean {
    if (!issueDate) {
      return false
    }
    const isKeikasochi = this.isKeikasochi(isInvoiceIssuer, issueDate)
    if (!isKeikasochi) {
      return false
    }
    const isAnyTaxAmountAdjusted = journals.some(j => j.isKeikasochi)
    return !isAnyTaxAmountAdjusted
  }

  computeTaxAmount(journal: Journal, isInvoiceIssuer: boolean, issueDate: Date): Journal {
    const deductionRate = getTaxDeductionRate(issueDate)
    if (!isInvoiceIssuer && deductionRate === 0) {
      // 非適格 & 経過措置 期間後
      journal.clearTaxAmount()
      return journal
    }
    journal.computeTaxAmount()

    // 非適格 & 経過措置中
    if (this.isKeikasochi(isInvoiceIssuer, issueDate)) {
      journal.adjustTaxAmount(deductionRate)
    }
    return journal
  }

  adjustTaxAmount(journal: Journal, isInvoiceIssuer: boolean, issueDate?: Date): Journal {
    if (!issueDate || isInvoiceIssuer) {
      return journal
    }
    // 経過措置適用後の税額と税抜額を計算
    if (this.isKeikasochi(isInvoiceIssuer, issueDate)) {
      journal.adjustTaxAmount(getTaxDeductionRate(issueDate))
    }
    return journal
  }

  turnOnKeikasochi(journal: Journal): Journal {
    if (journal.getTaxRate() > 0) {
      journal.isKeikasochi = true
    }
    return journal
  }

  turnOffKeikasochi(journal: Journal): Journal {
    journal.isKeikasochi = false
    return journal
  }

  setTaxAmountAfterAdjustment(
    journal: Journal,
    taxAmountAfterAdjustment: number,
  ): Journal {
    journal.setTaxAmountAfterAdjustment(taxAmountAfterAdjustment)
    return journal
  }
}


export const journalService = new JournalService()