<template>
  <div
    class="pointer"
    :class="{
      'blue lighten-5':
        !source.date_viewed && source.image_local_id != paramsId,
      [$style.invoiceListItemSelectedColor]: source.image_local_id == paramsId,
    }"
    @click="onItemClick(source.image_local_id, index)"
  >
    <div class="px-2 pb-1 pt-1">
      <div>
        <span class="fs-10" v-text="'# ' + source.image_local_id" />
        <span
          v-if="source.reading_date"
          v-tooltip="'読取日付<br>' + source.reading_date"
          class="ml-2"
          :class="[
            source.image_local_id == paramsId
              ? 'fs-10 light--text'
              : 'fs-10 grey--text',
          ]"
          v-text="source.reading_date.slice(5, 10)"
        />
        <span
          v-if="source.issue_date"
          v-tooltip="'伝票日付<br>' + source.issue_date"
          class="ml-2"
          :class="[
            source.image_local_id == paramsId
              ? 'fs-10 light--text'
              : 'fs-10 grey--text',
          ]"
          v-text="source.issue_date.slice(5, 10)"
        />
        <span
          v-else
          class="ml-2"
          :class="[
            source.image_local_id == paramsId
              ? 'fs-10 light--text'
              : 'fs-10 grey--text',
          ]"
          v-text="'読取NG'"
        />
        <span
          v-if="source.pay_date"
          v-tooltip="'支払日付<br>' + source.pay_date"
          class="ml-2"
          :class="[
            source.image_local_id == paramsId
              ? 'fs-10 light--text'
              : 'fs-10 grey--text',
          ]"
          v-text="source.pay_date.slice(5, 10)"
        />
        <span
          v-if="!source.past_transaction"
          v-tooltip="'新しい請求元<br>(読取時点)'"
          class="right"
        >
          <s-chip color="blue-500"> 新 </s-chip>
        </span>
      </div>

      <div
        v-if="source.company_name.length > 0"
        class="fs-11 fw-bold ellipsis w-100 py-1"
        v-text="source.company_name"
      />

      <span v-else class="fs-10 fw-bold" v-text="'--社名読取NG--'" />
      <div>
        <div
          v-tooltip="'仕訳ステータス'"
          class="mr-1"
          style="display: inline-block"
        >
          <span
            v-if="source.date_update"
            v-tooltip="
              '更新済<br>' +
              source.update_user.full_name +
              '<br>' +
              source.date_update
            "
          >
            <s-chip
              class="px-1"
              :color="
                source.image_local_id == paramsId ? 'gray-200' : 'gray-500'
              "
            >
              更
            </s-chip>
          </span>
          <div v-else class="mr-6" />
        </div>

        <!-- 仕訳承認ステータス -->
        <div
          v-tooltip="'仕訳ステータス'"
          class="mr-1"
          style="display: inline-block"
        >
          <s-chip-dark
            v-if="source.approve_user5 && source.approve_user5.id"
            class="px-1"
            color="green-500"
          >
            {{ approveSettings.approve5.name2 }}
          </s-chip-dark>
          <s-chip-dark
            v-else-if="
              approveLevel > 1 &&
              source.approve_user4 &&
              source.approve_user4.id
            "
            class="px-1"
            color="blue-500"
          >
            {{ approveSettings.approve4.name2 }}
          </s-chip-dark>
          <s-chip-dark
            v-else-if="
              approveLevel > 2 &&
              source.approve_user3 &&
              source.approve_user3.id
            "
            class="px-1"
            color="blue-500"
          >
            {{ approveSettings.approve3.name2 }}
          </s-chip-dark>
          <s-chip-dark
            v-else-if="
              approveLevel > 3 &&
              source.approve_user2 &&
              source.approve_user2.id
            "
            class="px-1"
            color="blue-500"
          >
            {{ approveSettings.approve2.name2 }}
          </s-chip-dark>
          <s-chip-dark
            v-else-if="
              approveLevel > 4 &&
              source.approve_user1 &&
              source.approve_user1.id
            "
            class="px-1"
            color="blue-500"
          >
            {{ approveSettings.approve1.name2 }}
          </s-chip-dark>
          <div v-else class="mr-6" />
        </div>
        <!-- /仕訳承認ステータス -->

        <!-- 支払済みステータス -->
        <div v-tooltip="'支払ステータス'" style="display: inline-block">
          <s-chip-dark
            v-if="source.paid_user && source.paid_user.id"
            class="px-1"
            color="green-500"
          >
            {{ $t('message.invoice_list_payment_paid_short') }}
          </s-chip-dark>
          <s-chip-dark
            v-else-if="source.pay_approve_user && source.pay_approve_user.id"
            class="px-1"
            color="blue-500"
          >
            {{ $t('message.invoice_list_payment_approved_short') }}
          </s-chip-dark>
          <s-chip-dark
            v-else-if="
              payConfirmSettings.pay_confirm3.display
                && source.pay_confirm_user3
                && source.pay_confirm_user3.id
            "
            class="px-1"
            color="blue-500"
          >
            {{ payConfirmSettings.pay_confirm3.name2 }}
          </s-chip-dark>
          <s-chip-dark
            v-else-if="
              payConfirmSettings.pay_confirm2.display
                && source.pay_confirm_user2
                && source.pay_confirm_user2.id
            "
            class="px-1"
            color="blue-500"
          >
            {{ payConfirmSettings.pay_confirm2.name2 }}
          </s-chip-dark>
          <s-chip-dark
            v-else-if="
              payConfirmSettings.pay_confirm1.display
                && source.pay_confirm_user
                && source.pay_confirm_user.id
            "
            class="px-1"
            color="blue-500"
          >
            {{ payConfirmSettings.pay_confirm1.name2 }}
          </s-chip-dark>
        </div>
        <!-- /支払済みステータス -->
        <span class="fs-10 right pt-1">
          ￥ {{ source.debit_amount_total | currency }}
        </span>
      </div>
      <s-chip
        v-if="source.is_deleted"
        class="py-1"
        :color="source.image_local_id == paramsId ? 'gray-200' : ''"
      >
        <v-icon x-small class="mr-1" v-text="'feather-trash-2'" />
        削除済み
      </s-chip>
    </div>
    <v-divider :key="index + '-divider'" style="margin: 0" />
  </div>
</template>

<style module>
.invoiceListItemSelectedColor {
  background-color: #37474e;
  color: white;
}
</style>

<script>
// sourceの上にpropsを定義しないと動かないので注意ください
// caution! must difine prop names above source property
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    approveSettings: {
      type: Object,
      required: true,
    },
    payConfirmSettings: {
      type: Object,
      required: true
    },
    approveLevel: {
      type: Number,
      required: true,
    },
    paramsId: {
      default: undefined,
      validator: (prop) => typeof prop === 'string' || prop === undefined,
    },
    onItemClick: {
      type: Function,
      required: true,
    },
    source: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
