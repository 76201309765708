<template>
  <div style="white-space: nowrap">
    <no-privilege-dialog ref="noPrivilegeDialog" />
    <fab-button
      v-if="approveLevel > 4"
      class="mr-2"
      :icon="approve_user1.icon"
      :text="approve_user1.text"
      :tooltip="approve_user1.tooltip"
      :color="approve_user1.color"
      :dark="approve_user1.isDark && approve_user1.editable"
      :disabled="!approve_user1.editable"
      @click="submit(1)"
    />

    <fab-button
      v-if="approveLevel > 3"
      class="mr-2"
      :icon="approve_user2.icon"
      :text="approve_user2.text"
      :tooltip="approve_user2.tooltip"
      :color="approve_user2.color"
      :dark="approve_user2.isDark && approve_user2.editable"
      :disabled="!approve_user2.editable"
      @click="submit(2)"
    />

    <fab-button
      v-if="approveLevel > 2"
      class="mr-2"
      :icon="approve_user3.icon"
      :text="approve_user3.text"
      :tooltip="approve_user3.tooltip"
      :color="approve_user3.color"
      :dark="approve_user3.isDark && approve_user3.editable"
      :disabled="!approve_user3.editable"
      @click="submit(3)"
    />

    <fab-button
      v-if="approveLevel > 1"
      class="mr-2"
      :icon="approve_user4.icon"
      :text="approve_user4.text"
      :tooltip="approve_user4.tooltip"
      :color="approve_user4.color"
      :dark="approve_user4.isDark && approve_user4.editable"
      :disabled="!approve_user4.editable"
      @click="submit(4)"
    />

    <fab-button
      class="mr-2"
      :icon="approve_user5.icon"
      :text="approve_user5.text"
      :tooltip="approve_user5.tooltip"
      :color="approve_user5.color"
      :dark="approve_user5.isDark && approve_user5.editable"
      :disabled="!approve_user5.editable"
      @click="submit(5)"
    />

    <fab-button
      v-if="freeeSeparateApproval"
      image="/freee-logo.png"
      :tooltip="freeeTooltip"
      :disabled="!approved"
      :color="isFreeeCoordinated ? '#82b1ff' : 'grey lighten-2'"
      @click="submit('freee')"
    />
  </div>
</template>

<script>
import FabButton from 'Components/Button/FabButton'
import { mapGetters } from 'vuex'

export default {
  components: {
    FabButton,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    freeeSeparateApproval: {
      type: Boolean,
      default: false,
    },
    approved: {
      type: Boolean,
      default: false,
    },
    isFreeeCoordinated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['editMode', 'approveLevel', 'invoicePrivilege']),
    approve_user1() {
      const user = this.value.approve_user1
      const date = this.value.date_approve1
      return this.getUserAttrs(user, date, 1)
    },
    approve_user2() {
      const user = this.value.approve_user2
      const date = this.value.date_approve2
      return this.getUserAttrs(user, date, 2)
    },
    approve_user3() {
      const user = this.value.approve_user3
      const date = this.value.date_approve3
      return this.getUserAttrs(user, date, 3)
    },
    approve_user4() {
      const user = this.value.approve_user4
      const date = this.value.date_approve4
      return this.getUserAttrs(user, date, 4)
    },
    approve_user5() {
      const user = this.value.approve_user5
      const date = this.value.date_approve5
      return this.getUserAttrs(user, date, 5, 'green', 'feather-lock')
    },
    freeeTooltip() {
      if (this.approved) {
        if (this.isFreeeCoordinated) {
          return 'freeeへ同期。<br>仕訳は上書き、画像はファイルボックスに新規登録されます。<br>（古い画像は削除されます。）'
        } else {
          return 'freeeへ同期。'
        }
      } else {
        return 'freeeへ同期。<br>最終承認前は使用出来ません。'
      }
    },
  },
  methods: {
    submit(level) {
      try {
        let user
        const obj = this.value
        switch (level) {
          case 1:
            user = obj.approve_user1
            break
          case 2:
            user = obj.approve_user2
            break
          case 3:
            user = obj.approve_user3
            break
          case 4:
            user = obj.approve_user4
            break
          case 5:
            user = obj.approve_user5
            break
        }
        const approved = this.checkApproved(user)

        if (!approved) {
          this.$emit('click:approve', level)
        } else {
          this.$emit('click:cancelApprove', level)
        }
      } catch (e) {
        console.log(e)
      }
    },
    checkApproved(approveUser) {
      try {
        if (!approveUser) {
          return false
        }
        if (typeof approveUser !== 'object') {
          return false
        }
        if (typeof approveUser.full_name !== 'string') {
          return false
        }
        if (approveUser.full_name.length === 0) {
          return false
        }
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    getApprovePhase(level) {
      try {
        return level + this.approveLevel - 5
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    getIcon(approveUser, defaultIcon, approved) {
      try {
        if (approved) {
          return ''
        } else {
          return defaultIcon
        }
      } catch (e) {
        console.log(e)
        return defaultIcon
      }
    },
    getText(approveUser, phase = '', approved) {
      try {
        if (approved) {
          return approveUser.full_name.slice(0, 2)
        } else {
          return String(phase)
        }
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    getColor(approveUser, defaultColor, approvedColor, approved) {
      try {
        if (approved) {
          return approvedColor
        } else {
          return defaultColor
        }
      } catch (e) {
        console.log(e)
        return defaultColor
      }
    },
    getToolTip(approveUser, approvedDate, approved, editable, level) {
      try {
        if (!approved && level == '5') {
          return this.value.approved
            ? ''
            : '仕訳を最終承認。<br>編集できないようロックがかかります。'
        } else if (!approved) {
          return this.value.approved ? '' : '仕訳承認'
        }

        const reg = new RegExp('/', 'g')
        approvedDate = approvedDate.replace(reg, '-')
        return this.formatApprovedToolTip(
          approvedDate,
          approveUser.full_name,
          editable
        )
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    checkEditable(level) {
      if (this.disabled) {
        return false
      }
      return !this.value.approved || level == 5
    },
    formatApprovedToolTip(date, userName, editable) {
      let text = `承認済み <br>${date} ${userName}`
      if (editable) {
        text = text + '<br>クリックして承認取消'
      }
      return text
    },
    getUserAttrs(
      user,
      date,
      level,
      optColor = 'blue',
      optIcon = 'feather-check'
    ) {
      const unApprovedColor = 'grey lighten-2'
      const approved = this.checkApproved(user)
      const editable = this.checkEditable(level)
      const icon = this.getIcon(user, optIcon, approved)
      const phase = this.getApprovePhase(level)
      const text = this.getText(user, phase, approved)
      const tooltip = this.getToolTip(user, date, approved, editable, level)
      const color = this.getColor(user, unApprovedColor, optColor, approved)
      const isDark = color != unApprovedColor
      return {
        approved,
        editable,
        icon,
        text,
        tooltip,
        color,
        isDark,
      }
    },
  },
}
</script>
