<template>
  <div class="py-2 px-3">
    <v-row>
      <span v-tooltip="item.bank_data.bank_name" class="ellipsis">
        {{ item.bank_data.bank_code }} {{ item.bank_data.bank_name }}
      </span>
    </v-row>
    <v-row>
      <span
        v-tooltip="item.bank_data.branch_name"
        class="ellipsis"
        style="margin-left: 6px"
      >
        {{ item.bank_data.branch_code }} {{ item.bank_data.branch_name }}
      </span>
    </v-row>
    <v-row align>
      <s-chip
        v-if="item.bank_data.bank_account_type"
        :color="
          item.bank_data.bank_account_type == '普通' ? 'blue-500' : 'green-500'
        "
        class="px-1"
      >
        {{ item.bank_data.bank_account_type }}
      </s-chip>
      <span class="ml-1">{{ item.bank_data.bank_account_number }}</span>
    </v-row>
    <v-row>
      <span v-tooltip="item.bank_data.bank_account_name" class="ellipsis">
        {{ item.bank_data.bank_account_name }}
      </span>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  methods: {},
}
</script>
