<template>
  <base-dialog
    ref="dialog"
    :title="$t('message.confirm')"
    :message="$t('message.confirm_change_invoice_department')"
    :cancel-text="$t('message.modal_cancel_btn')"
    :submit-text="$t('message.change')"
    max-width="300"
    @submit="submit()"
  >
    <template #card-content>
      <v-autocomplete
        v-model="department"
        :items="$store.getters.departmentList"
        dense
        filled
        outlined
        hide-details
        placeholder="部門"
        class="pb-5 more-dense"
        item-text="name_code"
        return-object
        :rules="rules"
      />
      <base-alert>
        所管部門に応じた操作制限が可能です。<br />（管理者設定メニューで権限指定できます）
      </base-alert>
    </template>
  </base-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      department: {},
      rules: [(v) => !!v || this.$t('message.form_input_required')],
    }
  },
  computed: {
    ...mapGetters(['masterList']),
  },
  methods: {
    open(department) {
      this.department = department
      this.$refs.dialog.open()
    },
    close() {
      this.$refs.dialog.close()
    },
    submit() {
      this.$emit('submit', this.department)
      this.close()
    },
  },
}
</script>
