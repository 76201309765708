import { abstractLogics } from './_logics'
import { i18n } from '@/plugins/i18n'

export const bankRules = {
  logics: {
    ...abstractLogics,
    accountNumber(accountNumber) {
      return (
        accountNumber.replace(/^[0-9]*/, '').length == 0 &&
        accountNumber.length <= 7
      )
    },
    accountNameCharacters(accountName) {
      return (
        accountName.replace(/^[ァ-ンｦ-ﾟA-Z0-9ヴ\(\)\.\-\/ー（） 　]+$/, '')
          .length == 0
      )
    },
  },
  getRules() {
    const logics = this.logics
    const requiredRule = logics.getRequiredRule()
    return {
      bankName: [
        (bankObj) => !!bankObj || i18n.t('message.form_input_required'),
        (bankObj) => requiredRule(bankObj.name || ''),
      ],
      branchName: [
        (branchObj) => !!branchObj || i18n.t('message.form_input_required'),
        (branchObj) => requiredRule(branchObj.name || ''),
      ],
      accountName: [
        (accountName) => requiredRule(accountName),
        (accountName) => logics.getLimitRule('この項目', 48)(accountName),
        (accountName) =>
          logics.accountNameCharacters(accountName) ||
          'カタカナ・半角数字・半角大文字英字・半角記号 ()./- で入力してください。',
      ],
      accountNumber: [
        (accountNumber) =>
          logics.accountNumber(accountNumber) ||
          '7桁以下の数字を入力してください。',
      ],
    }
  },
}
