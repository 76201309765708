<template>
  <div class="w-100 pa-0" style="height: calc(100vh - 160px)">
    <v-navigation-drawer
      v-model="drawerPdfView"
      width="600"
      app
      right
      hide-overlay
      disable-resize-watcher
    >
      <v-fab-transition>
        <v-btn
          small
          color="grey lighten-3"
          absolute
          fab
          style="top: 70px; left: 20px"
          @click="drawerPdfView = false"
        >
          <s-icon icon="feather-x" />
        </v-btn>
      </v-fab-transition>
      <pdf-view
        :uri="invoiceUrl"
        width="100%"
        height="100vh"
        @download-file="downloadFile"
      />
    </v-navigation-drawer>

    <v-data-table
      v-show="!loading"
      :id="tableId"
      :headers="computedHeaders"
      :items="computedItems"
      item-key="id"
      fixed-header
      hide-default-footer
      :items-per-page="999"
      class="sweeep-invoice-table mb-3"
      :height="height"
    >
      <!-- selected -->
      <template v-slot:header.selected="{}">
        <select-all-header-cell
          v-if="selectMultiple"
          :items="items"
          compute-value-with-items
          @click:item="onClickSelectAll($event.value, $event.message)"
        />
      </template>

      <!-- image_local_id -->
      <template v-slot:header.image_local_id="{ header }">
        <template v-if="sortable">
          <div style="min-width: 50px" @click="sortFunc('image_local_id')">
            <span v-tooltip="'クリックで並び替え'" class="pointer">
              {{ header.text }}
            </span>
            <header-sort-arrow :item="header" :sort-params="sortParams" />
          </div>
        </template>
        <template v-else>
          {{ header.text }}
        </template>
      </template>

      <!-- date -->
      <template v-slot:header.date="{ header }">
        <template v-if="sortable">
          <sortable-header-cell
            style="max-width: 80px; min-width: 80px"
            :text="header.text"
            :items="sortItems[header.value]"
            :mode="sortParams.mode"
            :show-header-icon="sortedColumn === header.value"
            :tooltip="sortHeaderTooltip"
            @click:item="sortFunc($event.value, $event.mode)"
          />
        </template>
        <template v-else>
          {{ header.text }}
        </template>
      </template>

      <!-- department -->
      <template v-slot:header.department="{ header }">
        <template v-if="sortable">
          <sortable-header-cell
            style="max-width: 200px; min-width: 80px"
            :text="header.text"
            :items="sortItems[header.value]"
            :mode="sortParams.mode"
            :show-header-icon="sortedColumn === header.value"
            :tooltip="sortHeaderTooltip"
            @click:item="sortFunc($event.value, $event.mode)"
          />
        </template>
        <template v-else>
          {{ header.text }}
        </template>
      </template>

      <!-- company_name -->
      <template v-slot:header.company_name="{ header }">
        <div
          v-if="sortable"
          style="min-width: 100px; max-width: 200px"
          @click="sortFunc('company_name')"
        >
          <span v-tooltip="'クリックで並び替え'" class="pointer">
            {{ header.text }}
          </span>
          <header-sort-arrow :item="header" :sort-params="sortParams" />
        </div>
        <div v-else>
          {{ header.text }}
        </div>
      </template>

      <!-- is_invoice_issuer -->
      <template v-slot:header.is_invoice_issuer="{ header }">
        <div
          v-if="sortable"
          style="min-width: 70px; max-width: 70px"
          @click="sortFunc('is_invoice_issuer')"
        >
          <span v-tooltip="'クリックで並び替え'" class="pointer">
            {{ header.text }}
          </span>
          <header-sort-arrow :item="header" :sort-params="sortParams" />
        </div>
        <div v-else>
          {{ header.text }}
        </div>
      </template>

      <!-- journals -->

      <template v-slot:header.journals="{ header }">
        <template v-if="sortable">
          <sortable-header-cell
            style="min-width: 150px"
            :text="header.text"
            :items="sortItems.master"
            :mode="sortParams.mode"
            :show-header-icon="sortedColumn === header.value"
            :tooltip="sortHeaderTooltip"
            @click:item="
              sortByMaster(journalSortObject.value, $event.value, $event.mode)
            "
            @input="onToggleStatusMenu(header.value, $event)"
          >
            <template #select>
              <v-autocomplete
                v-model="journalSortObject"
                item-text="text"
                item-value="value"
                label="並び替えキーを選択"
                class="pa-4"
                style="width: 220px"
                :items="journalSortItems"
                filled
                outlined
                dense
                hide-details
                return-object
              />
            </template>
          </sortable-header-cell>
        </template>
      </template>

      <!-- text fields (description) -->
      <template v-slot:header.description="{ header }">
        <template v-if="sortable">
          <sortable-header-cell
            style="min-width: 100px; max-width: 200px"
            :text="header.text"
            :items="sortItems[header.value]"
            :show-header-icon="sortedColumn === header.value"
            :tooltip="sortHeaderTooltip"
            :mode="sortParams.mode"
            @click:item="sortFunc($event.value, $event.mode)"
          />
        </template>
        <template v-else>
          {{ header.text }}
        </template>
      </template>

      <!-- debit_amount_total -->
      <template v-slot:header.debit_amount_total="{ header }">
        <template v-if="sortable">
          <sortable-header-cell
            style="min-width: 112px; max-width: 112px"
            :text="header.text"
            :items="sortItems[header.value]"
            :mode="sortParams.mode"
            :show-header-icon="sortedColumn === header.value"
            :tooltip="sortHeaderTooltip"
            @click:item="sortFunc($event.value, $event.mode)"
          />
        </template>
        <template v-else>
          {{ header.text }}
        </template>
      </template>

      <!-- pay_type -->
      <template v-slot:header.pay_type="{ header }">
        <template v-if="sortable">
          <div
            style="min-width: 50px; max-width: 50px"
            @click="sortFunc('pay_type')"
          >
            <span v-tooltip="'クリックで並び替え'" class="pointer">
              {{ header.text }}
            </span>
            <header-sort-arrow :item="header" :sort-params="sortParams" />
          </div>
        </template>
        <template v-else>
          <span> {{ header.text }} </span>
        </template>
      </template>

      <!-- payment_bank -->
      <template v-slot:header.payment_bank="{ header }">
        <div style="max-width: 100px; min-width: 80px">
          <sortable-header-cell
            style="min-width: 112px; max-width: 112px"
            :text="header.text"
            :items="sortItems[header.value]"
            :mode="sortParams.mode"
            :show-header-icon="sortedColumn === header.value"
            :tooltip="sortHeaderTooltip"
            @click:item="sortFunc($event.value, $event.mode)"
          />
        </div>
      </template>

      <!-- invoice_status -->
      <template v-slot:header.invoice_status="{ header }">
        <template v-if="sortable">
          <sortable-header-cell
            ref="invoiceStatusHeaderCell"
            style="min-width: 75px; max-width: 75px"
            :text="header.text"
            :items="sortItems.invoice_status"
            :mode="sortParams.mode"
            :show-header-icon="sortedColumn === header.value"
            :tooltip="sortHeaderTooltip"
            @click:item="sortFunc($event.value, $event.mode)"
          >
            <template #add>
              <v-list-group :value="false" style="width: 220px">
                <template v-slot:activator>
                  <v-list-item-title class="fs-11">
                    詳細検索
                  </v-list-item-title>
                </template>
                <v-select
                  v-model="invoiceStatusObject"
                  item-text="text"
                  item-value="value"
                  label="並び替えキーを選択"
                  class="pa-4"
                  style="width: 220px"
                  :items="invoiceStatusItems"
                  filled
                  outlined
                  dense
                  hide-details
                  return-object
                />
                <v-list dense class="fs-11">
                  <div v-for="(item, i) in sortItems.statusDetail" :key="i">
                    <v-list-item
                      v-if="item.display"
                      link
                      @click="
                        sortByStatus(
                          invoiceStatusObject.value,
                          item.value,
                          item.mode
                        )
                        $refs.invoiceStatusHeaderCell.showMenu = false
                      "
                    >
                      <i :class="'mr-3 ' + item.icon" />
                      {{ item.text }}
                    </v-list-item>
                  </div>
                </v-list>
              </v-list-group>
            </template>
          </sortable-header-cell>
        </template>
        <div v-else style="min-width: 75px; max-width: 75px">
          <span> {{ header.text }} </span>
        </div>
      </template>

      <!-- payment_status -->
      <template v-slot:header.payment_status="{ header }">
        <template v-if="sortable">
          <sortable-header-cell
            ref="paymentStatusHeaderCell"
            style="min-width: 78px; max-width: 78px"
            :text="header.text"
            :items="sortItems.payment_status"
            :mode="sortParams.mode"
            :show-header-icon="sortedColumn === header.value"
            :tooltip="sortHeaderTooltip"
            @click:item="sortFunc($event.value, $event.mode)"
          >
            <template #add>
              <v-list-group :value="false" style="width: 220px">
                <template v-slot:activator>
                  <v-list-item-title class="fs-11">
                    詳細検索
                  </v-list-item-title>
                </template>
                <v-select
                  v-model="paymentStatusObject"
                  item-text="text"
                  item-value="value"
                  label="並び替えキーを選択"
                  class="pa-4"
                  style="width: 220px"
                  :items="paymentStatusItems"
                  filled
                  outlined
                  dense
                  hide-details
                  return-object
                />
                <v-list dense class="fs-11">
                  <div v-for="(item, i) in sortItems.statusDetail" :key="i">
                    <v-list-item
                      v-if="item.display"
                      link
                      @click="
                        sortByStatus(
                          paymentStatusObject.value,
                          item.value,
                          item.mode
                        )
                        $refs.paymentStatusHeaderCell.showMenu = false
                      "
                    >
                      <i :class="'mr-3 ' + item.icon" />
                      {{ item.text }}
                    </v-list-item>
                  </div>
                </v-list>
              </v-list-group>
            </template>
          </sortable-header-cell>
        </template>
        <div v-else style="min-width: 78px; max-width: 78px">
          <span> {{ header.text }} </span>
        </div>
      </template>

      <!-- upload_type -->
      <template v-slot:header.upload_type_id="{ header }">
        <template v-if="sortable">
          <div
            style="min-width: 50px; max-width: 50px"
            @click="sortFunc('upload_type_id')"
          >
            <span v-tooltip="'クリックで並び替え'" class="pointer">
              {{ header.text }}
            </span>
            <header-sort-arrow :item="header" :sort-params="sortParams" />
          </div>
        </template>
        <template v-else>
          <span> {{ header.text }} </span>
        </template>
      </template>

      <!--

        table content

      -->
      <template v-slot:item="{ item }">
        <tr :class="{ 'blue lighten-5': !item.date_viewed }">
          <!-- selected -->
          <td v-if="!isHistory">
            <v-checkbox
              v-model="item.selected"
              hide-details
              @click.stop="onClickSelectOne(item)"
            />
          </td>

          <!-- detail -->
          <td align="center">
            <v-btn
              v-tooltip="itemTips"
              x-small
              depressed
              color="primary"
              class="m-auto"
              :disabled="disabled"
              @click="
                drawerPdfView = false
                $emit('click:item', item)
              "
              v-text="isHistory ? 'コピー' : '詳細'"
            />
          </td>

          <!-- image_local_id -->
          <td class="text-right" style="width: 50px">
            <div class="mb-1">
              <span
                v-tooltip="{ content: item.image__upload_source }"
                class="has-link"
                v-bind="{ disabled: $store.getters.loadedCount < 9 }"
                @click="getInvoiceTooltip(item.image_local_id)"
              >
                {{ item.image_local_id }}
              </span>
            </div>
            <div v-if="true">
              <s-icon
                v-if="item.duplicated_local_ids.length > 0"
                :tooltip="
                  '同じファイルがアップロードされました #' +
                  item.duplicated_local_ids.join(',')
                "
                icon="feather-alert-triangle"
                color="red-500"
                class="ml-1"
              />
              <s-icon
                v-if="item.is_stamped"
                :tooltip="
                  item.is_expired
                    ? 'タイムスタンプの期限が切れています'
                    : 'タイムスタンプ有り'
                "
                :color="item.is_expired ? 'red-500' : 'blue-500'"
                icon="ti-stamp"
                class="ml-1"
              />
              <s-icon
                v-if="item.schedule && item.schedule.id"
                :tooltip="'定期払い登録済み'"
                :color="
                  item.schedule && item.schedule.completed
                    ? 'green-500'
                    : 'blue-500'
                "
                icon="feather-repeat"
                class="ml-1"
              />
              <s-icon
                v-if="item.is_deleted"
                :tooltip="'削除済み'"
                color="red-500"
                icon="feather-trash-2"
                class="ml-1"
              />
            </div>
          </td>

          <!-- date -->
          <td>
            <div class="py-1" style="min-width: 65px">
              <div v-tooltip="'読取日付 ' + item.reading_date" class="mx-1">
                <s-chip-dark class="mr-2" color="blue-500"> 読 </s-chip-dark>
                {{ item.reading_date.slice(-5) }}
              </div>
              <div v-tooltip="'伝票日付 ' + item.issue_date" class="mx-1">
                <s-chip-dark class="mr-2" color="green-600"> 伝 </s-chip-dark>
                {{ item.issue_date.slice(-5) }}
              </div>
              <div
                v-if="item.pay_date"
                v-tooltip="'支払日付 ' + item.pay_date"
                class="mx-1"
              >
                <s-chip-dark class="mr-2" color="green-400"> 支 </s-chip-dark>
                {{ item.pay_date.slice(-5) }}
              </div>
            </div>
          </td>

          <!-- department -->
          <td>
            <div class="mt-2">
              {{ item.department.name }}
            </div>
          </td>

          <!-- company_name -->
          <td style="min-width: 100px; max-width: 200px">
            {{ item.company_name }}
            <s-chip v-if="!item.past_transaction" color="blue-500"> 新 </s-chip>
          </td>

          <!-- is_invoice_issuer -->
          <td style="min-width: 70px; max-width: 120px" class="text-center">
            <div v-if="item.invoice_issuer_number">
              {{ `T${item.invoice_issuer_number}` }}
            </div>
            <s-chip v-if="item.is_invoice_issuer" color="blue-500"> 適格請求 </s-chip>
          </td>

          <!-- description -->
          <td>
            <journal-cell
              :items="item.journal"
              :show-all="item.showAll"
              :definition="definition"
              @click:show-all="changeShowAll(item)"
            />
          </td>

          <!-- description -->
          <td style="min-width: 100px; max-width: 200px" class="py-1">
            <div
              v-if="
                displaySetting.description &&
                !item.showAll &&
                computeDescription(item.journal[0].description, item.issue_date)
              "
              v-tooltip.right="
                computeDescription(item.journal[0].description, item.issue_date)
              "
              :class="$style.sweeepInvoiceTableTextCell"
            >
              <s-chip-dark class="mr-1" color="blue-500">
                {{ definition.description.slice(0, 3) }}
              </s-chip-dark>
              <text-parser
                class="d-inline-flex"
                :class="$style.sweeepInvoiceTableParser"
                :text="
                  computeDescription(
                    item.journal[0].description,
                    item.issue_date
                  )
                "
              />
            </div>
            <div v-if="item.showAll && checkDescriptionExist(item)">
              <template v-for="(journal, i) in item.journal">
                <div :key="i">
                  <div
                    v-if="
                      displaySetting.description &&
                      (journal.debit_account_title.name ||
                        journal.credit_account_title.name)
                    "
                    v-tooltip.right="
                      computeDescription(journal.description, item.issue_date)
                    "
                    :class="$style.sweeepInvoiceTableTextCell"
                  >
                    <s-chip-dark class="mr-1" color="blue-500">
                      {{ definition.description.slice(0, 3) }}
                    </s-chip-dark>
                    <text-parser
                      class="d-inline-flex"
                      :class="$style.sweeepInvoiceTableParser"
                      :text="
                        computeDescription(journal.description, item.issue_date)
                      "
                    />
                  </div>
                </div>
              </template>
            </div>
            <div
              v-if="displaySetting.free_text_1 && item.journal[0].free_text_1"
              v-tooltip.right="item.journal[0].free_text_1"
              :class="$style.sweeepInvoiceTableTextCell"
            >
              <s-chip-dark class="mr-1" color="green-600">
                {{ definition.free_text_1.slice(0, 3) }}
              </s-chip-dark>
              <text-parser
                class="d-inline-flex"
                :class="$style.sweeepInvoiceTableParser"
                :text="item.journal[0].free_text_1"
              />
            </div>
            <div
              v-if="displaySetting.free_text_2 && item.journal[0].free_text_2"
              v-tooltip.right="item.journal[0].free_text_2"
              :class="$style.sweeepInvoiceTableTextCell"
            >
              <s-chip-dark class="mr-1" color="green-400">
                {{ definition.free_text_2.slice(0, 3) }}
              </s-chip-dark>
              <text-parser
                class="d-inline-flex"
                :class="$style.sweeepInvoiceTableParser"
                :text="item.journal[0].free_text_2"
              />
            </div>
            <div
              v-if="item.note_1"
              v-tooltip.right="item.note_1"
              :class="$style.sweeepInvoiceTableTextCell"
            >
              <invoice-note
                number="1"
                :value="item.note_1"
                :editable="editableNote"
                offset-y
                bottom
                :nudge-bottom="10"
                @click:update="
                  $emit('click:updateNote', {
                    item: item,
                    number: 1,
                    text: $event,
                  })
                "
              >
                <template #display="slotProps">
                  <span v-bind="slotProps.attrs" v-on="slotProps.on">
                    <s-chip-dark class="mr-1" color="green-300">
                      管理メモ{{ slotProps.number }}
                    </s-chip-dark>
                  </span>
                </template>
              </invoice-note>
              <text-parser
                class="d-inline-flex"
                :class="$style.sweeepInvoiceTableParser"
                :text="item.note_1"
              />
            </div>
            <div
              v-if="item.note_2"
              v-tooltip.right="item.note_2"
              :class="$style.sweeepInvoiceTableTextCell"
            >
              <invoice-note
                number="2"
                :value="item.note_2"
                :editable="editableNote"
                offset-y
                bottom
                :nudge-bottom="10"
                @click:update="
                  $emit('click:updateNote', {
                    item: item,
                    number: 2,
                    text: $event,
                  })
                "
              >
                <template #display="slotProps">
                  <span v-bind="slotProps.attrs" v-on="slotProps.on">
                    <s-chip-dark class="mr-1" color="green-300">
                      管理メモ{{ slotProps.number }}
                    </s-chip-dark>
                  </span>
                </template>
              </invoice-note>
              <text-parser
                class="d-inline-flex"
                :class="$style.sweeepInvoiceTableParser"
                :text="item.note_2"
              />
            </div>
            <div
              v-if="item.note_3"
              v-tooltip.right="item.note_3"
              :class="$style.sweeepInvoiceTableTextCell"
            >
              <invoice-note
                number="3"
                :value="item.note_3"
                :editable="editableNote"
                offset-y
                bottom
                :nudge-bottom="10"
                @click:update="
                  $emit('click:updateNote', {
                    item: item,
                    number: 3,
                    text: $event,
                  })
                "
              >
                <template #display="slotProps">
                  <span v-bind="slotProps.attrs" v-on="slotProps.on">
                    <s-chip-dark class="mr-1" color="green-300">
                      管理メモ{{ slotProps.number }}
                    </s-chip-dark>
                  </span>
                </template>
              </invoice-note>
              <text-parser
                class="d-inline-flex"
                :class="$style.sweeepInvoiceTableParser"
                :text="item.note_3"
              />
            </div>
          </td>

          <!-- debit_amount_total -->
          <td style="min-width: 90px; max-width: 90px">
            <div class="text-right">
              <div class="left grey--text">合計</div>
              {{ item.debit_amount_total | currency }}
            </div>
            <div class="text-right">
              <div class="left grey--text">税額</div>
              {{ item.debit_tax_amount_total | currency }}
            </div>
            <div class="text-right">
              <div class="left grey--text">源泉</div>
              {{ item.gensen_amount | currency }}
            </div>
            <div v-if="item.currency_code != 'JPY'" class="text-right">
              <div class="left grey--text">
                {{ item.currency_code }}
              </div>
              {{ floatToCurrency(item.foreign_currency_amount) }}
            </div>
          </td>

          <!-- pay_type -->
          <td class="text-center">
            <s-chip :color="convertPaymentType(item.pay_type).twColor">
              {{ convertPaymentType(item.pay_type).content }}
            </s-chip>
            <div v-if="item.gensenzei" class="mt-2">
              <s-chip-dark color="blue-500"> 源泉 </s-chip-dark>
            </div>
          </td>

          <!-- payment_bank -->
          <td style="max-width: 100px; min-width: 80px">
            <payment-bank-cell :item="item" />
          </td>

          <!-- invoice_status -->
          <td style="min-width: 70px; max-width: 70px">
            <div class="pa-2 pb-3 text-center">
              <invoice-status-cell
                :item="item"
                :approve-level="approveLevel"
                :approve-settings="approveSettings"
              />
            </div>
          </td>

          <!-- payment_status -->
          <td style="min-width: 70px; max-width: 70px">
            <div class="pa-2 pb-3 text-center">
              <payment-status-cell
                :item="item"
                :pay-confirm-settings="payConfirmSettings"
              />
            </div>
          </td>

          <!-- upload_type -->
          <td class="text-center" style="min-width: 70px; max-width: 70px">
            <s-chip
              v-if="!!item.upload_type"
              v-tooltip="item.upload_tooltip"
              :color="getUploadTypeColor(item.upload_type_id)"
              class="px-2"
            >
              {{ item.upload_type }}
            </s-chip>
          </td>
        </tr>
      </template>

      <v-alert slot="no-results" :value="true" color="info" icon="warning">
        {{ $t('message.noResult') }}
      </v-alert>
    </v-data-table>
    <v-toolbar flat height="60" style="border-top: 1px solid #ccc">
      <v-col cols="8" class="pa-0">
        <v-card flat tile class="d-flex align-content-center flex-wrap">
          <v-card v-if="selectItemsPerPage" flat class="align-self-center">
            <div class="fs-10 mb-1">表示件数</div>
            <span>
              <v-autocomplete
                v-model="itemsPerPageModel"
                :items="itemsPerPageOptions"
                dense
                filled
                hide-details
                item-text="text"
                item-value="value"
                class="more-dense"
                style="width: 100px"
                :clearable="false"
                @change="$emit('change:items-per-page', itemsPerPageModel)"
              />
            </span>
          </v-card>

          <v-card flat class="align-self-center ml-4">
            <div class="fs-10 mb-1">表示 / 検索結果</div>
            <v-chip small label class="px-4">
              {{ tableDataRange }} / {{ items.length }}
            </v-chip>
          </v-card>

          <template v-if="selectable && selectMultiple">
            <v-card flat class="align-self-center ml-4">
              <div class="fs-10 mb-1">選択中</div>
              <v-chip small label class="px-4">
                {{ selected.length }}
              </v-chip>
            </v-card>

            <v-card flat class="align-self-center ml-4">
              <div class="fs-10 mb-1">承認済</div>
              <v-chip small label class="px-4">
                {{ selectedItems.countApprove5 }}
              </v-chip>
            </v-card>

            <v-card flat class="align-self-center ml-4">
              <div class="fs-10 mb-1">支払済</div>
              <v-chip small label class="px-4">
                {{ selectedItems.paid_user }}
              </v-chip>
            </v-card>

            <v-card flat class="align-self-center ml-4">
              <div class="fs-10 mb-1">請求額</div>
              <v-chip small label class="px-4">
                {{ selectedItems.invoice | currency }}
              </v-chip>
            </v-card>

            <v-card flat class="align-self-center ml-4">
              <div class="fs-10 mb-1">源泉税</div>
              <v-chip small label class="px-4">
                {{ selectedItems.gensen | currency }}
              </v-chip>
            </v-card>

            <v-card flat class="align-self-center ml-4">
              <div class="fs-10 mb-1">支払額</div>
              <v-chip small label class="px-4">
                {{ selectedItems.payment | currency }}
              </v-chip>
            </v-card>
          </template>
        </v-card>
      </v-col>

      <v-col cols="4" class="text-right">
        <v-pagination
          v-model="page"
          circle
          :length="lastPage"
          style="width: auto"
          @input="onPagenation"
        />
      </v-col>
    </v-toolbar>
  </div>
</template>

<style module>
.sweeepInvoiceTableParser {
  display: inline-block;
  white-space: nowrap;
  width: 100%;
}
.sweeepInvoiceTableTextCell {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
</style>

<script>
import Snackbar from 'Helpers/snackbar/index'
import JournalCell from 'Components/Table/JournalCell'
import DepartmentCell from 'Components/Table/DepartmentCell'
import PrivilegeCell from 'Components/Table/PrivilegeCell'
import InvoiceStatusCell from 'Components/Table/InvoiceStatusCell'
import PaymentStatusCell from 'Components/Table/PaymentStatusCell'
import PaymentBankCell from 'Components/Table/PaymentBankCell'
import HeaderSortArrow from 'Components/Table/HeaderSortArrow'
import SortableHeaderCell from 'Components/Table/SortableHeaderCell'
import SelectAllHeaderCell from 'Components/Table/SelectAllHeaderCell'
import PdfView from 'Components/Pdf/PdfView'
import TextParser from 'Components/Journal/TextParser'
import InvoiceNote from 'Views/invoice/components/InvoiceArea/components/InvoiceNote'

import { convertPaymentType } from 'Helpers/payment/index'
import { computeDescription } from 'Helpers/invoice/index'
import { journalFields, floatToCurrency } from 'Helpers/journal'
import { scrollTableToTop } from 'Helpers/table/scroll'

export default {
  components: {
    JournalCell,
    DepartmentCell,
    PrivilegeCell,
    HeaderSortArrow,
    PdfView,
    InvoiceStatusCell,
    PaymentStatusCell,
    PaymentBankCell,
    SortableHeaderCell,
    SelectAllHeaderCell,
    TextParser,
    InvoiceNote,
  },
  model: {
    prop: 'selected',
    event: 'select',
  },
  props: {
    tableId: {
      type: String,
      default: 'sweeep-invoice-table',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    itemTips: {
      type: String,
      default: '',
    },
    isHistory: {
      type: Boolean,
      default: false,
    },
    displaySetting: {
      type: Object,
      required: true,
    },
    definition: {
      type: Object,
      required: true,
    },
    sortFunc: {
      type: Function,
      default: function () {},
    },
    sortParams: {
      type: Object,
      default: () => ({}),
    },
    height: {
      type: String,
      default: 'calc(100vh - 240px)',
    },
    selectMultiple: {
      type: Boolean,
      default: true,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    approveLevel: {
      type: Number,
      default: 5,
    },
    approveSettings: {
      type: Object,
      default: () => {},
    },
    payConfirmSettings: {
      type: Object,
      required: true
    },
    selectItemsPerPage: {
      type: Boolean,
      default: true,
    },
    itemsPerPage: {
      type: Number,
      default: 20,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => {
        return [
          { value: 20, text: '20件' },
          { value: 50, text: '50件' },
          { value: 100, text: '100件' },
        ]
      },
    },
  },
  data() {
    let t = this
    return {
      sortedColumn: '',
      sortHeaderTooltip: '',
      itemsPerPageModel: 20,
      sortItems: {
        date: [
          {
            text: '読取日付で昇順',
            icon: 'feather-arrow-up',
            value: 'reading_date',
            mode: 'asc',
            display: true,
          },
          {
            text: '読取日付で降順',
            icon: 'feather-arrow-down',
            value: 'reading_date',
            mode: 'desc',
            display: true,
          },
          {
            text: '伝票日付で昇順',
            icon: 'feather-arrow-up',
            value: 'issue_date',
            mode: 'asc',
            display: true,
          },
          {
            text: '伝票日付で降順',
            icon: 'feather-arrow-down',
            value: 'issue_date',
            mode: 'desc',
            display: true,
          },
          {
            text: '支払日付で昇順',
            icon: 'feather-arrow-up',
            value: 'pay_date',
            mode: 'asc',
            display: true,
          },
          {
            text: '支払日付で降順',
            icon: 'feather-arrow-down',
            value: 'pay_date',
            mode: 'desc',
            display: true,
          },
        ],
        department: [
          {
            text: '所管部門名で昇順',
            icon: 'feather-arrow-up',
            value: 'department.name',
            mode: 'asc',
            display: true,
          },
          {
            text: '所管部門名で降順',
            icon: 'feather-arrow-down',
            value: 'department.name',
            mode: 'desc',
            display: true,
          },
          {
            text: '所管部門コードで昇順',
            icon: 'feather-arrow-up',
            value: 'department.code',
            mode: 'asc',
            display: true,
          },
          {
            text: '所管部門コードで降順',
            icon: 'feather-arrow-down',
            value: 'department.code',
            mode: 'desc',
            display: true,
          },
        ],
        description: [
          {
            text: this.definition.description + 'で昇順',
            icon: 'feather-arrow-up',
            value: 'journal.description',
            mode: 'asc',
            display: this.displaySetting.description,
          },
          {
            text: this.definition.description + 'で降順',
            icon: 'feather-arrow-down',
            value: 'journal.description',
            mode: 'desc',
            display: this.displaySetting.description,
          },
          {
            text: this.definition.free_text_1 + 'で昇順',
            icon: 'feather-arrow-up',
            value: 'journal.free_text_1',
            mode: 'asc',
            display: this.displaySetting.free_text_1,
          },
          {
            text: this.definition.free_text_1 + 'で降順',
            icon: 'feather-arrow-down',
            value: 'journal.free_text_1',
            mode: 'desc',
            display: this.displaySetting.free_text_1,
          },
          {
            text: this.definition.free_text_2 + 'で昇順',
            icon: 'feather-arrow-up',
            value: 'journal.free_text_2',
            mode: 'asc',
            display: this.displaySetting.free_text_2,
          },
          {
            text: this.definition.free_text_2 + 'で降順',
            icon: 'feather-arrow-down',
            value: 'journal.free_text_2',
            mode: 'desc',
            display: this.displaySetting.free_text_2,
          },
          {
            text: '管理メモ1で昇順',
            icon: 'feather-arrow-up',
            value: 'note_1',
            mode: 'asc',
            display: true,
          },
          {
            text: '管理メモ1で降順',
            icon: 'feather-arrow-down',
            value: 'note_1',
            mode: 'desc',
            display: true,
          },
          {
            text: '管理メモ2で昇順',
            icon: 'feather-arrow-up',
            value: 'note_2',
            mode: 'asc',
            display: true,
          },
          {
            text: '管理メモ2で降順',
            icon: 'feather-arrow-down',
            value: 'note_2',
            mode: 'desc',
            display: true,
          },
          {
            text: '管理メモ3で昇順',
            icon: 'feather-arrow-up',
            value: 'note_3',
            mode: 'asc',
            display: true,
          },
          {
            text: '管理メモ3で降順',
            icon: 'feather-arrow-down',
            value: 'note_3',
            mode: 'desc',
            display: true,
          },
        ],
        debit_amount_total: [
          {
            text: '合計で昇順',
            icon: 'feather-arrow-up',
            value: 'debit_amount_total',
            mode: 'asc',
            display: true,
          },
          {
            text: '合計で降順',
            icon: 'feather-arrow-down',
            value: 'debit_amount_total',
            mode: 'desc',
            display: true,
          },
          {
            text: '税額で昇順',
            icon: 'feather-arrow-up',
            value: 'debit_tax_amount_total',
            mode: 'asc',
            display: true,
          },
          {
            text: '税額で降順',
            icon: 'feather-arrow-down',
            value: 'debit_tax_amount_total',
            mode: 'desc',
            display: true,
          },
          {
            text: '源泉で昇順',
            icon: 'feather-arrow-up',
            value: 'gensen_amount',
            mode: 'asc',
            display: true,
          },
          {
            text: '源泉で降順',
            icon: 'feather-arrow-down',
            value: 'gensen_amount',
            mode: 'desc',
            display: true,
          },
        ],
        invoice_status: [
          {
            text: '仕訳状況で昇順',
            icon: 'feather-arrow-up',
            value: 'current_invoice_status.id',
            mode: 'asc',
            display: true,
          },
          {
            text: '仕訳状況で降順',
            icon: 'feather-arrow-down',
            value: 'current_invoice_status.id',
            mode: 'desc',
            display: true,
          },
        ],
        payment_status: [
          {
            text: '支払状況で昇順',
            icon: 'feather-arrow-up',
            value: 'current_payment_status.id',
            mode: 'asc',
            display: true,
          },
          {
            text: '支払状況で降順',
            icon: 'feather-arrow-down',
            value: 'current_payment_status.id',
            mode: 'desc',
            display: true,
          },
        ],
        statusDetail: [
          {
            text: 'ユーザ名で昇順',
            icon: 'feather-arrow-up',
            value: 'full_name',
            mode: 'asc',
            display: true,
          },
          {
            text: 'ユーザ名で降順',
            icon: 'feather-arrow-down',
            value: 'full_name',
            mode: 'desc',
            display: true,
          },
          {
            text: 'ユーザコードで昇順',
            icon: 'feather-arrow-up',
            value: 'code',
            mode: 'asc',
            display: true,
          },
          {
            text: 'ユーザコード降順',
            icon: 'feather-arrow-down',
            value: 'code',
            mode: 'desc',
            display: true,
          },
          {
            text: '実行日時で昇順',
            icon: 'feather-arrow-up',
            value: 'date',
            mode: 'asc',
            display: true,
          },
          {
            text: '実行日時で降順',
            icon: 'feather-arrow-down',
            value: 'date',
            mode: 'desc',
            display: true,
          },
        ],
        master: [
          {
            text: '名称で昇順',
            icon: 'feather-arrow-up',
            value: 'name',
            mode: 'asc',
            display: true,
          },
          {
            text: '名称で降順',
            icon: 'feather-arrow-down',
            value: 'name',
            mode: 'desc',
            display: true,
          },
          {
            text: 'コードで昇順',
            icon: 'feather-arrow-up',
            value: 'code',
            mode: 'asc',
            display: true,
          },
          {
            text: 'コードで降順',
            icon: 'feather-arrow-down',
            value: 'code',
            mode: 'desc',
            display: true,
          },
        ],
        payment_bank: [
          {
            text: '口座名義人名で昇順',
            icon: 'feather-arrow-up',
            value: 'bank_data.bank_account_name',
            mode: 'asc',
            display: true,
          },
          {
            text: '口座名義人名で降順',
            icon: 'feather-arrow-down',
            value: 'bank_data.bank_account_name',
            mode: 'desc',
            display: true,
          },
          {
            text: '法人格略称を除く口座名義人名で昇順',
            icon: 'feather-arrow-up',
            value: 'bank_data.bank_account_name.1',
            mode: 'asc',
            display: true,
          },
          {
            text: '法人格略称を除く口座名義人名で降順',
            icon: 'feather-arrow-down',
            value: 'bank_data.bank_account_name.1',
            mode: 'desc',
            display: true,
          },
        ],
      },
      invoiceStatusItems: [],
      invoiceStatusObject: {
        text: 'アップロード',
        value: 'upload',
      },
      invoiceStatusCache: {},
      paymentStatusItems: [],
      paymentStatusObject: {
        text: '口座更新',
        value: 'update_bank',
      },
      paymentStatusCache: {},
      journalSortObject: {},
      journalSortItems: [],
      journalSortCache: {},
      page: 1,
      invoiceUrl: '',
      drawerPdfView: false,
      pdfViewLocalId: 0,
      selectedItems: {
        paid_user: 0,
        pay_approve_user: 0,
        pay_confirm_user: 0,
        invoice: 0,
        cousumer_tax: 0,
        gensen: 0,
        payment: 0,
        countApprove5: 0,
        countApprove4: 0,
        countApprove3: 0,
        countApprove2: 0,
        countApprove1: 0,
      },
      headers: [
        {
          text: '',
          value: 'selected',
          key: 'selected',
          sortable: false,
          display: true,
        },
        {
          text: '',
          value: 'detail',
          key: 'detail',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.invoice_table_invoice_local_id'),
          value: 'image_local_id',
          key: 'image_local_id',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.invoice_table_date'),
          value: 'date',
          key: 'date',
          sortable: false,
          display: true,
        },
        {
          text: '所管部門',
          value: 'department',
          key: 'department',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.invoice_table_business_partner'),
          value: 'company_name',
          key: 'company_name',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.invoice_table_is_invoice_issuer'),
          value: 'is_invoice_issuer',
          key: 'is_invoice_issuer',
          sortable: false,
          display: true,
        },
        {
          text: '仕訳',
          value: 'journals',
          key: 'debit_account_title_name',
          sortable: false,
          display: true,
        },
        {
          text: 'テキスト項目',
          value: 'description',
          sortable: false,
          key: 'description',
          display: true,
        },
        {
          text: '金額',
          value: 'debit_amount_total',
          key: 'debit_amount_total',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.invoice_table_payment_type'),
          value: 'pay_type',
          key: 'pay_type',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.invoice_table_payment_bank'),
          value: 'payment_bank',
          key: 'payment_bank',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.invoice_table_invoice_status'),
          value: 'invoice_status',
          key: 'invoice_status',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.invoice_table_payment_status'),
          value: 'payment_status',
          key: 'payment_status',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.invoice_table_upload_type'),
          value: 'upload_type_id',
          key: 'upload_type',
          sortable: false,
          display: true,
        },
      ],
    }
  },
  computed: {
    startIndex: function () {
      return (this.page - 1) * this.itemsPerPageModel
    },
    endIndex: function () {
      const start = this.startIndex
      const itemsPerPage = this.itemsPerPageModel
      return this.items.length > start + itemsPerPage
        ? start + itemsPerPage
        : this.items.length
    },
    computedHeaders: function () {
      return this.headers.filter((h) => h.display)
    },
    computedItems: function () {
      return this.items.slice(this.startIndex, this.endIndex)
    },
    computedSelected: function () {
      return this.items.filter((v) => v.selected)
    },
    tableDataRange: function () {
      return this.startIndex + 1 + '-' + this.endIndex
    },
    lastPage: function () {
      return Math.ceil(this.items.length / this.itemsPerPageModel)
    },
    editableNote: function () {
      return this.$store.getters.invoicePrivilege.update_note
    },
  },
  watch: {
    computedSelected: function (values) {
      this.sumAmount()
      this.$emit('select', values)
    },
    sortParams: {
      handler: function (params) {
        try {
          this.switchSortedHeader(params)
        } catch (e) {
          console.log(e)
        }
      },
      deep: true,
    },
    itemsPerPage: {
      handler: function (value) {
        this.itemsPerPageModel = value
      },
      immediate: true,
    },
  },
  created() {
    this.headers[0].display = this.selectable
    this.setInvoiceApproveSortItems()
    this.setPaymentStatusItems()
    this.setJournalSortItems()
  },
  methods: {
    goToPage(p) {
      this.page = p
    },
    computeDescription(description, date) {
      return computeDescription(description, date)
    },
    convertPaymentType(type) {
      return convertPaymentType(type)
    },
    floatToCurrency(amount) {
      return floatToCurrency(amount)
    },
    onClickSelectOne(item) {
      try {
        if (!item.selected) {
          item.selected = true
        } else {
          item.selected = false
        }

        if (this.selectMultiple) {
          return
        }

        for (let v of this.items) {
          if (v.id !== item.id && v.selected) {
            v.selected = false
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    onClickSelectAll(selectType, message) {
      if (!this.selectMultiple) {
        return
      }
      //selectType  1:全選択 2:現在ページ選択 3:全解除 4:現在ページ解除
      switch (selectType) {
        case 1: {
          for (let item of this.items) {
            this.$set(item, 'selected', true)
          }
          break
        }
        case 2: {
          for (let item of this.computedItems) {
            this.$set(item, 'selected', true)
          }
          break
        }
        case 3: {
          for (let item of this.items) {
            this.$set(item, 'selected', false)
          }
          break
        }
        case 4: {
          for (let item of this.computedItems) {
            this.$set(item, 'selected', false)
          }
          break
        }
      }
      Snackbar.success(message)
    },
    getUploadTypeColor(type) {
      switch (type) {
        case 1:
          return 'blue-500'
        case 2:
          return 'red-500'
        case 3:
          return 'green-500'
        case 4:
          return 'green-500'
        case 5:
          return 'green-500'
        case 6:
          return 'yellow-800'
        case 7:
          return 'yellow-500'
        default:
          return ''
      }
    },
    getInvoiceTooltip(local_id) {
      try {
        const payload = {
          localId: local_id,
        }
        this.$store.dispatch('getPdfUrl', payload).then((response) => {
          if (response.status === 'success') {
            this.invoiceUrl = response.url
            this.pdfViewLocalId = local_id
            this.drawerPdfView = true
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    downloadFile() {
      try {
        const localId = this.pdfViewLocalId
        if (localId) {
          const payload = {
            localId: localId,
          }
          this.$store.dispatch('downloadInvoicePdfFile', payload)
        }
      } catch (e) {
        console.log(e)
      }
    },
    sumAmount() {
      try {
        let total_invoice_amount = 0
        let total_consumer_tax = 0
        let total_gensen_amount = 0
        let total_payment_amount = 0
        let count_paid_user = 0
        let count_pay_approve_user = 0
        let count_pay_confirm_user = 0
        let count_approve5 = 0
        let count_approve4 = 0
        let count_approve3 = 0
        let count_approve2 = 0
        let count_approve1 = 0
        for (let item of this.selected) {
          total_invoice_amount += parseFloat(item.debit_amount_total)
          total_gensen_amount += parseFloat(item.gensen_amount)
          total_payment_amount += parseFloat(item.pay_amount)
          if (!item.gensenzei) {
            total_consumer_tax += parseFloat(item.debit_tax_amount_total)
          }
          if (item.paid_user.id) {
            count_paid_user += 1
          } else if (item.pay_approve_user.id) {
            count_pay_approve_user += 1
          } else if (item.pay_confirm_user.id) {
            count_pay_confirm_user += 1
          }
          if (item.approve_user5.id) {
            count_approve5 += 1
          } else if (item.approve_user4.id) {
            count_approve4 += 1
          } else if (item.approve_user3.id) {
            count_approve3 += 1
          } else if (item.approve_user2.id) {
            count_approve2 += 1
          } else if (item.approve_user1.id) {
            count_approve1 += 1
          }
        }
        this.selectedItems.invoice = total_invoice_amount
        this.selectedItems.gensen = total_gensen_amount
        this.selectedItems.consumer_tax = total_consumer_tax
        this.selectedItems.payment = total_payment_amount
        this.selectedItems.paid_user = count_paid_user
        this.selectedItems.pay_approve_user = count_pay_approve_user
        this.selectedItems.pay_confirm_user = count_pay_confirm_user
        this.selectedItems.countApprove5 = count_approve5
        this.selectedItems.countApprove4 = count_approve4
        this.selectedItems.countApprove3 = count_approve3
        this.selectedItems.countApprove2 = count_approve2
        this.selectedItems.countApprove1 = count_approve1
      } catch (e) {
        console.log(e)
      }
    },
    sortByStatus(status, property, mode) {
      if (property === 'date') {
        const key = `date_${status}`
        this.sortFunc(key, mode)
      } else {
        const rank = status.match('[0-9]') ? status.match('[0-9]')[0] : null
        let key
        if (rank) {
          key = `${status.slice(0, -1)}_user${rank}.${property}`
        } else {
          key =
            status === 'update_bank'
              ? `user_update_bank.${property}`
              : `${status}_user.${property}`
        }
        this.sortFunc(key, mode)
      }
    },
    switchSortedHeader(params) {
      // change comlicate header cell tooltip and array
      const value = params.value
      const mode = params.mode
      const simpleSort = [
        'image_local_id',
        'company_name',
        'id',
        'pay_type',
        'upload_type_id',
      ]
      if (simpleSort.includes(value)) {
        this.sortedColumn = ''
        return
      }

      let instance, sortType
      const suffix = mode === 'asc' ? 'で昇順' : 'で降順'

      const singleActionColumns = [
        'date',
        'department',
        'debit_amount_total',
        'description',
        'payment_bank',
        'invoice_status',
        'payment_status',
      ]
      instance = this.checkSingleSortColumns(value, mode, singleActionColumns)
      if (instance) {
        const valueIndex = {
          debit_amount_total: '合計金額',
          gensen_amount: '源泉税額',
        }
        this.sortHeaderTooltip = valueIndex[instance.value]
          ? valueIndex[instance.value] + suffix
          : instance.text
        return
      }

      instance = this.checkMultiActionColumns(value)

      const actionType = instance.text
      if (value.match(/journal\.(credit|debit)/)) {
        sortType = value.match(/(name)$/) ? 'マスタ名' : 'マスタコード'
      } else if (value.match(/^date/)) {
        sortType = '日付'
      } else if (value.match('full_name')) {
        sortType = 'ユーザ名'
      } else {
        sortType = 'ユーザコード'
      }
      this.sortHeaderTooltip = actionType + sortType + suffix
    },
    checkSingleSortColumns(value, mode, columns) {
      let instance
      columns.forEach((key) => {
        let sortInstance = this.sortItems[key].find((item) => {
          return item.value === value && item.mode === mode
        })
        if (sortInstance) {
          this.sortedColumn = key
          instance = sortInstance
          return
        }
      })
      return instance
    },
    checkMultiActionColumns(sortValue) {
      const journalRegExp = new RegExp(/journal\.(credit|debit)/)
      if (sortValue.match(/pay|update_bank|paid/)) {
        this.sortedColumn = 'payment_status'
        return this.paymentStatusObject
      } else if (sortValue.match(journalRegExp)) {
        this.sortedColumn = 'journals'
        return this.journalSortObject
      } else {
        this.sortedColumn = 'invoice_status'
        return this.invoiceStatusObject
      }
    },
    onToggleStatusMenu(headerType, open) {
      // payment_status || journals || invoice_status
      if (open) {
        switch (headerType) {
          case 'invoice_status':
            this.invoiceStatusCache = { ...this.invoiceStatusObject }
            break
          case 'payment_status':
            this.paymentStatusCache = { ...this.paymentStatusObject }
            break
          case 'journals':
            this.journalSortCache = { ...this.journalSortObject }
            break
        }
      } else {
        switch (headerType) {
          case 'invoice_status':
            this.invoiceStatusObject = this.invoiceStatusCache
            this.invoiceStatusCache = {}
            break
          case 'payment_status':
            this.paymentStatusObject = this.paymentStatusCache
            this.paymentStatusCache = {}
            break
          case 'journals':
            this.journalSortObject = this.journalSortCache
            this.journalSortCache = {}
            break
        }
      }
    },
    setInvoiceApproveSortItems() {
      let statusItems = [
        { text: 'アップロード', value: 'upload' },
        { text: '更新', value: 'update' },
      ]
      const approveItems = Object.keys(this.approveSettings).map((key) => {
        const setting = this.approveSettings[key]
        if (setting.display) {
          return { text: setting.name, value: key }
        }
      })
      statusItems.push(...approveItems.filter((item) => item))
      statusItems.push({ text: 'エクスポート', value: 'export' })
      this.invoiceStatusItems.push(...statusItems)
    },
    setPaymentStatusItems(){
      let statusItems = [
        { text: '口座更新', value: 'update_bank' },
      ]
      const confirmItems = Object.keys(this.payConfirmSettings).map((key) => {
        const setting = this.payConfirmSettings[key]
        if (setting.display) {
          return { text: setting.name, value: key }
        }
      })
      statusItems.push(...confirmItems.filter((item) => item))
      statusItems.push({ text: '連携', value: 'pay_approve' })
      statusItems.push({ text: '支払', value: 'paid' })
      statusItems.push({ text: 'エクスポート', value: 'pay_export' })
      this.paymentStatusItems.push(...statusItems)
    },
    setJournalSortItems() {
      const initJournalSortObject = (items) => {
        this.journalSortObject = { ...items[0] }
      }
      const displaySetting = this.displaySetting
      const definition = this.definition
      const items = journalFields
        .map((masterType) => {
          if (displaySetting[masterType]) {
            const keyInvoiceMaster =
              masterType === 'tax' ? 'tax_class' : masterType
            return [
              {
                text: `借方${definition[masterType]}`,
                value: `debit_${keyInvoiceMaster}`,
              },
              {
                text: `貸方${definition[masterType]}`,
                value: `credit_${keyInvoiceMaster}`,
              },
            ]
          }
        })
        .flat()
        .filter((item) => !!item)
      this.journalSortItems.push(...items)
      initJournalSortObject(items)
    },
    sortByMaster(masterType, property, mode) {
      const key = `journal.${masterType}.${property}`
      this.sortFunc(key, mode)
    },
    onPagenation() {
      scrollTableToTop(this.tableId)
    },
    changeWholeShowAll(flag) {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].showAll = flag
      }
    },
    changeShowAll(item) {
      item.showAll = !item.showAll
    },
    checkDescriptionExist(item) {
      for (let i = 0; i < item.journal.length; i++) {
        if (item.journal[i].description) {
          return true
        }
      }
      return false
    },
  },
}
</script>
