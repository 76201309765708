<template>
  <v-autocomplete
    v-model="item"
    :items="itemsToShow"
    item-text="name"
    :item-value="displayNameKey"
    append-icon=""
    dense
    hide-details
    clearable
    return-object
    :tabindex="tabindex"
    @focus="onFocus"
    @change="onChange($event)"
  >
    <template slot="item" slot-scope="data">
      {{ getDisplayName(data) }}
    </template>
  </v-autocomplete>
</template>

<script>
import { isMapObject } from 'Helpers/helpers'

export default {
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    value: {
      validator: (prop) => isMapObject(prop),
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    displayNameKey: {
      type: String,
      default: 'name_code',
    },
    fieldName: {
      type: String,
      default: '',
    },
    tabindex: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      focus: false,
      recentUseList: [],
      itemsIncludeRecentUse: [],
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        if (val) {
          this.$emit('select', val)
        } else {
          this.$emit('select', {})
        }
      },
    },
    itemsToShow() {
      // to reduce redering speed 13 % and reduce destorying speed about 30%
      if (this.focus && this.itemsIncludeRecentUse.length > 0) {
        return this.itemsIncludeRecentUse
      } else if (this.focus) {
        return this.items
      } else if (this.value && this.value.id) {
        return [this.value]
      } else {
        return []
      }
    },
  },
  methods: {
    onFocus() {
      try {
        this.focus = true
        this.getRecentUseList(this.fieldName)
        this.createList()
      } catch (e) {
        console.log(e)
      }
    },
    getRecentUseList(key) {
      try {
        let recentUse = JSON.parse(localStorage.getItem('recentUse'))
        if (recentUse === null || !(key in recentUse)) {
          this.recentUseList = []
        } else {
          this.recentUseList = recentUse[key]
        }
      } catch (e) {
        console.log(e)
      }
    },
    createList() {
      try {
        let tempList = []
        this.recentUseList.forEach((id) => {
          let obj = this.items.find((x) => x.id === id)
          if (obj) {
            const copyObj = JSON.parse(JSON.stringify(obj))
            copyObj[this.displayNameKey] =
              '[最新] ' + copyObj[this.displayNameKey]
            tempList.push(copyObj)
          }
        })
        tempList = tempList.concat(this.items)
        this.itemsIncludeRecentUse = tempList
      } catch (e) {
        console.log(e)
      }
    },
    onChange(event) {
      try {
        if (event) {
          this.setLocalStorageRecentUseList(this.fieldName, event.id)
        }
        this.$emit('change', event || {})
      } catch (e) {
        console.log(e)
      }
    },
    setLocalStorageRecentUseList(key, value) {
      try {
        let recentUse = JSON.parse(localStorage.getItem('recentUse'))
        if (recentUse === null) {
          recentUse = {}
          recentUse[key] = [value]
        } else if (!(key in recentUse)) {
          recentUse[key] = [value]
        } else {
          //既にキーがある場合、同じvalueがあるかチェック
          let arr = recentUse[key]
          const duplicateIndex = arr.indexOf(value)
          if (duplicateIndex !== -1) {
            // 同じvalueがある場合は削除（先頭に移動するため）
            arr.splice(duplicateIndex, 1)
          } else {
            if (arr.length >= 5) {
              //5以上あれば最後の値を削除
              arr.splice(4, 1)
            }
          }
          arr.unshift(value)
        }
        localStorage.setItem('recentUse', JSON.stringify(recentUse))
      } catch (e) {
        console.log(e)
      }
    },
    getDisplayName(data) {
      return data.item[this.displayNameKey]
        ? data.item[this.displayNameKey]
        : ''
    },
  },
}
</script>
