import { numberToCurrency } from 'Helpers/journal/index'

function wrapJournalsAsArray(journals, fields) {
  // 仕訳のオブジェクトをラップして配列で返す
  // [{ field: `フィールド名`, value: `名称 コード`　or `金額`` or `テキスト` }
  // 出力例:
  //[
  //  [
  //    { field: 'debit_account_title', value: '未払金 001'},
  //    { field: 'debit_amount', value: '1,000'},
  //    { field: 'description', value: '3月未払'},
  //  ]
  //]
  const amountFields = [
    'debit_amount',
    'debit_tax_amount',
    'credit_amount',
    'credit_tax_amount',
  ]
  const textFields = ['description', 'free_text_1', 'free_text_2']

  return journals.map((journal) => {
    return fields.map((field) => {
      let value = journal[field]
      if (value === undefined || value === null) {
        return { field, value: '' }
      }
      if (value && amountFields.includes(field)) {
        value = numberToCurrency(value)
      } else if (value && textFields.includes(field)) {
        value = value
      } else if (value.name) {
        value = value.name + ' ' + value.code
      } else {
        value = ''
      }
      return { field, value }
    })
  })
}

export function wrapJournalsForInvoiceForm(journals) {
  const fields = [
    'debit_amount',
    'debit_tax_amount',
    'debit_account_title',
    'debit_sub_account',
    'debit_tax',
    'debit_department',
    'debit_project',
    'debit_segment',
    'debit_vendor',
    'debit_item',
    'debit_tag',
    'debit_walletable',
    'credit_amount',
    'credit_tax_amount',
    'credit_account_title',
    'credit_sub_account',
    'credit_tax',
    'credit_department',
    'credit_project',
    'credit_segment',
    'credit_vendor',
    'credit_item',
    'credit_tag',
    'credit_walletable',
    'description',
    'free_text_1',
    'free_text_2',
  ]
  return wrapJournalsAsArray(journals, fields)
}

export function wrapJournalsForInvoiceCompany(journals) {
  const fields = [
    'debit_account_title',
    'debit_sub_account',
    'debit_tax',
    'debit_department',
    'debit_project',
    'debit_segment',
    'debit_vendor',
    'debit_item',
    'debit_tag',
    'debit_walletable',
    'credit_account_title',
    'credit_sub_account',
    'credit_tax',
    'credit_department',
    'credit_project',
    'credit_segment',
    'credit_vendor',
    'credit_item',
    'credit_tag',
    'credit_walletable',
    'description',
    'free_text_1',
    'free_text_2',
  ]
  return wrapJournalsAsArray(journals, fields)
}
