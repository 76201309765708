<template>
  <div style="white-space: nowrap">
    <no-privilege-dialog ref="noPrivilegeDialog" />
    <fab-button
      v-if="payConfirmLevel > 2"
      class="mr-2"
      :icon="pay_confirm_user1.icon"
      :text="pay_confirm_user1.text"
      :tooltip="pay_confirm_user1.tooltip"
      :color="pay_confirm_user1.color"
      :dark="pay_confirm_user1.isDark && pay_confirm_user1.editable"
      :disabled="!pay_confirm_user1.editable"
      @click="submit(1)"
    />
    <fab-button
      v-if="payConfirmLevel > 1"
      class="mr-2"
      :icon="pay_confirm_user2.icon"
      :text="pay_confirm_user2.text"
      :tooltip="pay_confirm_user2.tooltip"
      :color="pay_confirm_user2.color"
      :dark="pay_confirm_user2.isDark && pay_confirm_user2.editable"
      :disabled="!pay_confirm_user2.editable"
      @click="submit(2)"
    />
    <fab-button
      v-if="payConfirmLevel > 0"
      class="mr-2"
      :icon="pay_confirm_user3.icon"
      :text="pay_confirm_user3.text"
      :tooltip="pay_confirm_user3.tooltip"
      :color="pay_confirm_user3.color"
      :dark="pay_confirm_user3.isDark && pay_confirm_user3.editable"
      :disabled="!pay_confirm_user3.editable"
      @click="submit(3)"
    />
    <fab-button
      class="mr-2"
      :icon="pay_approve_user.icon"
      :text="pay_approve_user.text"
      :tooltip="pay_approve_user.tooltip"
      :color="pay_approve_user.color"
      :dark="pay_approve_user.isDark && pay_approve_user.editable"
      :disabled="!pay_approve_user.editable"
      @click="submit(4)"
    />
    <fab-button
      :icon="paid_user.icon"
      :text="paid_user.text"
      :tooltip="paid_user.tooltip"
      :color="paid_user.color"
      :dark="paid_user.isDark && paid_user.editable"
      :disabled="!paid_user.editable"
      @click="submit(5)"
    />
  </div>
</template>

<script>
import { formatDate } from 'Helpers/date'
import FabButton from 'Components/Button/FabButton'
import { mapGetters } from 'vuex'

export default {
  components: {
    FabButton,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['editMode', 'payConfirmLevel']),
    pay_confirm_user1() {
      const user = this.value.pay_confirm_user
      const date = this.value.date_pay_confirm
      return this.getUserAttrs(user, date, 1, 'blue')
    },
    pay_confirm_user2() {
      const user = this.value.pay_confirm_user2
      const date = this.value.date_pay_confirm2
      return this.getUserAttrs(user, date, 2, 'blue')
    },
    pay_confirm_user3() {
      const user = this.value.pay_confirm_user3
      const date = this.value.date_pay_confirm3
      return this.getUserAttrs(user, date, 3, 'blue')
    },
    pay_approve_user() {
      const user = this.value.pay_approve_user
      const date = this.value.date_pay_approve
      return this.getUserAttrs(user, date, 4, 'blue', '')
    },
    paid_user() {
      const user = this.value.paid_user
      const date = this.value.date_paid
      return this.getUserAttrs(user, date, 5, 'green', '')
    },
  },
  methods: {
    submit(level) {
      try {
        const obj = this.value
        let user
        switch (level) {
          case 1:
            user = obj.pay_confirm_user
            break
          case 2:
            user = obj.pay_confirm_user2
            break
          case 3:
            user = obj.pay_confirm_user3
            break
          case 4:
            user = obj.pay_approve_user
            break
          case 5:
            user = obj.paid_user
            break
        }
        const approved = this.checkApproved(user)

        if (!approved) {
          this.$emit('click:approve', level)
        } else {
          this.$emit('click:cancelApprove', level)
        }
      } catch (e) {
        console.log(e)
      }
    },
    checkApproved(approveUser) {
      try {
        if (!approveUser) {
          return false
        }
        if (typeof approveUser !== 'object') {
          return false
        }
        if (typeof approveUser.full_name !== 'string') {
          return false
        }
        if (approveUser.full_name.length === 0) {
          return false
        }
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    getIcon(approveUser, defaultIcon, approved) {
      try {
        if (approved) {
          return ''
        } else {
          return defaultIcon
        }
      } catch (e) {
        console.log(e)
        return defaultIcon
      }
    },
    getText(approveUser, phase, approved) {
      try {
        if (approved) {
          return approveUser.full_name.slice(0, 2)
        } else {
          if (phase == 5) {
            return '支払'
          } else if (phase == 4) {
            return '連携'
          } else {
            return String(phase)
          }
        }
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    getColor(approveUser, defaultColor, approvedColor, approved) {
      try {
        if (approved) {
          return approvedColor
        } else {
          return defaultColor
        }
      } catch (e) {
        console.log(e)
        return defaultColor
      }
    },
    formatConfirmedToolTip(date, userName, editable) {
      let text = `承認済み <br>${date} ${userName}`
      if (editable) {
        text = text + '<br>クリックして承認取消'
      }
      return text
    },
    formatApprovedToolTip(date, userName, editable) {
      let text = `連携済み <br>${date} ${userName}`
      if (editable) {
        text = text + '<br>クリックして連携取消'
      }
      return text
    },
    formatPaidToolTip(date, userName, editable) {
      let text = `支払済み <br>${date} ${userName}`
      if (editable) {
        text = text + '<br>クリックして支払取消'
      }
      return text
    },
    getToolTip(level, approveUser, approvedDate, approved, editable) {
      try {
        if (!approved) {
          if (level < 4) {
            return '支払確認。<br>支払確認ユーザを記録します。'
          } else if (level === 4) {
            return '支払メニューへ連携。<br>連携するとロックがかかります。'
          } else {
            return '支払済に変更。<br>支払済みにするとロックがかかります。'
          }
        }
        const reg = new RegExp('/', 'g')

        if (typeof approvedDate === 'string') {
          approvedDate = approvedDate.replace(reg, '-')
        } else {
          approvedDate = formatDate(approvedDate)
        }

        if (level < 4) {
          return this.formatConfirmedToolTip(
            approvedDate,
            approveUser.full_name,
            editable
          )
        } else if (level === 4) {
          return this.formatApprovedToolTip(
            approvedDate,
            approveUser.full_name,
            editable
          )
        } else {
          return this.formatPaidToolTip(
            approvedDate,
            approveUser.full_name,
            editable
          )
        }
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    checkEditable(level) {
      if (this.disabled) {
        return false
      }
      if (level < 4) {
        return !this.checkApproved(this.value.pay_approve_user)
      } else if (level == 4) {
        return !this.checkApproved(this.value.paid_user)
      } else {
        return true
      }
    },
    getConfirmPhase(level) {
      try {
        return level > 3 ? level : level + this.payConfirmLevel - 3
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    getUserAttrs(
      user,
      date,
      level,
      optColor = 'blue',
      optIcon = 'feather-check'
    ) {
      const unApprovedColor = 'grey lighten-2'
      const approved = this.checkApproved(user)
      const editable = this.checkEditable(level)
      const icon = this.getIcon(user, optIcon, approved)
      const phase = this.getConfirmPhase(level)
      const text = this.getText(user, phase, approved)
      const tooltip = this.getToolTip(level, user, date, approved, editable)
      const color = this.getColor(user, unApprovedColor, optColor, approved)
      const isDark = color != unApprovedColor
      return {
        approved,
        editable,
        icon,
        text,
        tooltip,
        color,
        isDark,
      }
    },
  },
}
</script>
