import Big from 'big.js';
import { currencyToNumber, numberToCurrency } from 'Helpers/journal'
import CurrencyCodeClient from '@/clients/organization/currencyCode'

export const CURRENCY_RATE_MAX_DECIMAL = 4

const localStorageClient = {
  saveRate(rateString) {
    localStorage.setItem('foreignCurrencyRate', rateString)
    return rateString
  },
  getRate() {
    return localStorage.getItem('foreignCurrencyRate') || ''
  },
  saveCurrency(currency) {
    const stringCurrency = JSON.stringify(currency)
    return localStorage.setItem('foreignCurrency', stringCurrency)
  },
  getCurrency() {
    return JSON.parse(localStorage.getItem('foreignCurrency'))
  },
}

const JPY = {
  currency_code: 'JPY',
  currency_code_id: 392,
}

export const saveCurrency = (currency) => {
  return localStorageClient.saveCurrency(currency || JPY)
}

export const getLatestCurrency = () => {
  return localStorageClient.getCurrency()
}

export const saveRateString = (rate) => {
  return localStorageClient.saveRate(rate)
}

export const getLatestRateString = () => {
  return localStorageClient.getRate()
}

export const caluculateYenFromForeignAmount = (
  journal, // image_summary_journal
  entrySide, // debit or credit
  currencyRateString // string
) => {
  const currencyAmountString = journal[`${entrySide}_foreign_currency_amount`]
  const foreignCurrencyAmount = currencyToNumber(currencyAmountString).toFixed(2)
  const foreignCurrencyRate = currencyToNumber(currencyRateString).toFixed(CURRENCY_RATE_MAX_DECIMAL)
  const result = new Big(foreignCurrencyAmount).times(foreignCurrencyRate)
  const amount = Math.floor(result.toFixed(6)) // 切り捨て
  const yenAmount = numberToCurrency(amount)
  return yenAmount
}

export const getCurrencyCodes = async (token) => {
  const currencyCodes = await CurrencyCodeClient.get(token)
  const currencyToPriority = {
    USD: 1,
    EUR: 2,
    GBP: 3,
    CNY: 4,
    getPriority(code) {
      return this[code] || 1000
    },
  }

  const currencyCodesByPriority = currencyCodes.sort((after, before) => {
    return (
      currencyToPriority.getPriority(after.currency_code) -
      currencyToPriority.getPriority(before.currency_code)
    )
  })
  return currencyCodesByPriority
}
