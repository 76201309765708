<template>
  <base-dialog
    ref="dialog"
    :title="$t('message.confirm')"
    message="この伝票をコピーしますか？"
    :cancel-text="$t('message.no')"
    :submit-text="'複製'"
    @submit="submit()"
  />
</template>

<script>
export default {
  methods: {
    open() {
      this.$refs.dialog.open()
    },
    close() {
      this.$refs.dialog.close()
    },
    submit() {
      this.$emit('submit')
      this.close()
    },
  },
}
</script>
