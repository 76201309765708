<template>
  <v-card tile flat style="border-right: thin #ccc solid">
    <v-dialog v-model="dialogRouteInvoice" max-width="350">
      <v-card class="sweeep-dialog">
        <v-card-title v-text="'確認'" />
        <v-card-text>
          現在、編集中の情報が破棄されますがよろしいでしょうか？
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click.native="dialogRouteInvoice = false">
            {{ $t('message.no') }}
          </v-btn>
          <v-btn
            color="primary"
            @click.native="
              dialogRouteInvoice = false
              routeInvoiceOnDialog()
            "
          >
            {{ $t('message.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-toolbar flat color="grey lighten-4" height="50">
      <v-toolbar-title class="font-sm">
        <v-btn
          small
          outlined
          rounded
          color="blue"
          @click="$emit('toggle-screen')"
          v-text="showFullScreen ? '全画面表示終了' : '全画面表示'"
        />
      </v-toolbar-title>
    </v-toolbar>
    <p v-if="data.length == 0 && tableLoader == false" class="pa-4">
      条件と一致する請求書がありません。
    </p>
    <div>
      <template v-if="tableLoader == true">
        <div class="text-center" style="margin-top: 60%">
          <v-progress-circular
            indeterminate
            color="primary"
            class="text-center"
          />
        </div>
      </template>
      <virtual-list
        id="invoiceListScrollbar"
        ref="virtual-list"
        class="invoice-sidebar-scroll"
        style="overflow-y: auto"
        :data-key="'image_local_id'"
        :data-sources="data"
        :data-component="itemComponent"
        :extra-props="{
          approveSettings,
          payConfirmSettings,
          approveLevel,
          paramsId,
          onItemClick: routeInvoiceOnClick,
        }"
        :start="listIndex"
        :estimate-size="90"
        :style="getScrollHeight()"
      />
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import InvoiceListItem from './InvoiceListItem'
import VirtualList from 'vue-virtual-scroll-list'

export default {
  components: {
    VirtualList,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    showFullScreen: {
      type: Boolean,
      default: false,
    },
    payConfirmSettings: {
      type: Object,
      required: true
    },
    tableLoader: null,
    selected_invoice_array: null,
    tax_list: null,
    displayMode: null,
    approveSettings: Object,
    addTableData: Function,
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 160,
      },
      paramsId: '',
      dialogRouteInvoice: false,
      invoice_id: '',
      data_index: '',
      sortingTypeList: [
        {
          id: 0,
          name: '読取日付（昇順）',
          sort_key: 'date_upload',
          ascending: true,
        },
        {
          id: 1,
          name: '読取日付（降順）',
          sort_key: 'date_upload',
          ascending: false,
        },
        {
          id: 2,
          name: '伝票日付（昇順）',
          sort_key: 'issue_date',
          ascending: true,
        },
        {
          id: 3,
          name: '伝票日付（降順）',
          sort_key: 'issue_date',
          ascending: false,
        },
        {
          id: 4,
          name: '支払日付（昇順）',
          sort_key: 'pay_date',
          ascending: true,
        },
        {
          id: 5,
          name: '支払日付（降順）',
          sort_key: 'pay_date',
          ascending: false,
        },
      ],
      sortingType: {},
      itemComponent: InvoiceListItem,
      listIndex: 0,
    }
  },
  computed: {
    ...mapGetters([
      'loadingInvoiceEditingUser',
      'loadingInvoiceDetail',
      'editedInvoiceForm',
      'users',
      'selectedUser',
      'approveLevel',
    ]),
    sortedInvoiceList() {
      try {
        const sort_key = this.sortingType.sort_key
        const ascending = this.sortingType.ascending
        const data = this.data

        return data.sort((a, b) => {
          try {
            if (a[sort_key] && b[sort_key]) {
              var date_a = Date.parse(a[sort_key])
              var date_b = Date.parse(b[sort_key])
              if (date_a == date_b) {
                return a.pk - b.pk
              }
              if (ascending) {
                return date_b < date_a ? 1 : date_a < date_b ? -1 : 0
              } else {
                return date_b < date_a ? -1 : date_a < date_b ? 1 : 0
              }
            } else if (a[sort_key]) {
              return 1
            } else if (b[sort_key]) {
              return -1
            } else {
              return a.pk - b.pk
            }
          } catch (e) {
            return 0
          }
        })
      } catch (e) {
        console.log(e)
        return 0
      }
    },
  },
  watch: {
    '$route.params.id': function (id) {
      this.paramsId = id
    },
    displayMode: function (to, from) {
      try {
        if (from === 2) {
          void 0
        }
        const elm = this.$refs['virtual-list']
        Vue.nextTick(() => {
          const listIndex = this.data.findIndex((obj) => {
            return obj.image_local_id === Number(this.paramsId)
          })
          elm.scrollToIndex(listIndex)
        })
        /*var tile = 'tile-' + id
        var currentTile = this.$refs[tile][0].$el
        if (!this.isFullyVisible(currentTile)) {
          $("#invoiceListScrollbar").animate({
            scrollTop: currentTile.offsetTop
          }, 500)
        }
        */
      } catch (error) {
        console.log(error)
      }
    },
    data: function (newVal) {
      if (newVal.length && this.displayMode == 2 && this.$route.params.id) {
        this.changeListOffset(this.$route.params.id)
      } else {
        void 0
      }
    },
  },
  //updated(){//this.setScrollEventOnList()},
  mounted() {
    try {
      this.paramsId = this.$route.params.id
      //this.sortInvoiceList(this.sortingTypeList[0])
      //window.addEventListener('resize', this.setScrollEventOnList)
    } catch (e) {
      console.log(e)
    }
  },
  beforeDestroy: function () {
    //window.removeEventListener('resize', this.setScrollEventOnList)
  },
  methods: {
    isFullyVisible(el) {
      var rect = el.getBoundingClientRect()
      return rect.top >= 150 && rect.bottom <= window.innerHeight
    },
    routeInvoiceOnClick(id, index) {
      try {
        if (this.loadingInvoiceEditingUser.update) {
          return
        }
        this.invoice_id = id
        this.data_index = index
        if (parseInt(id) === parseInt(this.$route.params.id)) {
          return
        }
        this.$emit('update:selected_invoice_array', index)
        this.$emit('select:invoice', this.invoice_id)
      } catch (e) {
        console.log(e)
      }
    },
    routeInvoiceOnDialog() {
      try {
        this.$emit('update:selected_invoice_array', this.data_index)
        this.$emit('select:invoice', this.invoice_id)
      } catch (e) {
        console.log(e)
      }
    },
    setScrollEventOnList() {
      try {
        // スクロールさせる要素を取得
        var elm = document.getElementById('invoiceListScrollbar')

        // 要素の表示領域を取得
        var height = elm.offsetHeight

        var t = this

        // スクロールイベントを定義
        elm.onscroll = function () {
          // 要素のスクロール分を含めた高さを取得
          var scrollHeight = elm.scrollHeight

          // 要素のスクロール位置を取得
          var scrollTop = elm.scrollTop

          // 現在の表示位置の高さ
          var scrollPosition = height + scrollTop

          // どれだけ近づいたかを判断する値
          // 0に近いほど、スクロールの最終が近い
          var proximity = 0

          if ((scrollHeight - scrollPosition) / scrollHeight <= proximity) {
            t.addTableData()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    getScrollHeight() {
      const globalHeaderHeight = '100px'
      const invoiceListHeaderHeight = '48px'

      let height = `100vh - ${invoiceListHeaderHeight}`

      if (!this.showFullScreen) {
        height = `${height} - ${globalHeaderHeight}`
      }
      return `height: calc(${height})`
    },
    changeListOffset(id) {
      try {
        const elm = this.$refs['virtual-list']
        const listIndex = this.data.findIndex((obj) => {
          return obj.image_local_id === Number(id)
        })
        Vue.nextTick(() => {
          elm.scrollToIndex(listIndex)
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
