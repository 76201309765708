<template>
  <div class="payment-bank-form">
    <v-row v-if="userBankList.length > 1" no-gutters class="pb-1">
      <v-autocomplete
        ref="userBank"
        v-model="value.user_bank"
        :items="userBankList"
        dense
        filled
        :outlined="editableBank"
        hide-details
        placeholder="振込元銀行"
        tabindex="1"
        class="more-dense"
        :class="{ 'white-back': editableBank }"
        item-text="fulltext"
        item-value="id"
        return-object
        :clearable="editableBank"
        :readonly="!editable"
        @click="toCustomMode"
        @click:clear="setEmptyObject('user_bank')"
      />
    </v-row>
    <v-row no-gutters class="py-1">
      <v-col cols="5">
        <v-checkbox
          v-if="settingPaidByCustomer"
          v-model="value.paid_by_customer"
          label="振込手数料先方負担"
          dense
          hide-details
          class="mt-0"
          :readonly="!editable"
        />
      </v-col>
      <v-col cols="7" />
    </v-row>
    <v-row no-gutters class="py-1">
      <v-autocomplete
        ref="bankName"
        v-model="value.bank_name"
        :items="bankList"
        dense
        filled
        :outlined="editableBank"
        hide-details
        item-text="name_code"
        item-value="name"
        placeholder="金融機関"
        tabindex="1"
        class="more-dense"
        :class="{ 'white-back': editableBank }"
        :clearable="editableBank"
        :readonly="!editable"
        @click="toCustomMode"
        @click:clear="setBlankBankName"
      />
    </v-row>
    <v-row no-gutters class="py-1">
      <v-autocomplete
        ref="bankBranchName"
        v-model="value.bank_branch_name"
        :items="branchList"
        dense
        filled
        :outlined="editableBank"
        hide-details
        placeholder="支店"
        tabindex="1"
        class="more-dense"
        :class="{ 'white-back': editableBank }"
        item-text="name_code"
        item-value="name"
        :clearable="editableBank"
        :readonly="!editable"
        @click="toCustomMode"
        @click:clear="setBlankBankBranchName"
      />
    </v-row>
    <v-row no-gutters class="py-1">
      <v-btn-toggle
        :value="value.bank_account_type"
        dense
        color="blue"
        :readonly="!editableBank"
        @click.native="onClickAccountType"
        @change="value.bank_account_type = $event || ''"
      >
        <v-btn
          small
          outlined
          value="普通"
          tabindex="1"
          :class="{
            'disable-events': !editable && value.bank_account_type === '普通',
          }"
          :disabled="!editable && value.bank_account_type !== '普通'"
          v-text="'普通'"
        />
        <v-btn
          small
          outlined
          value="当座"
          tabindex="1"
          :class="{
            'disable-events': !editable && value.bank_account_type === '当座',
          }"
          :disabled="!editable && value.bank_account_type !== '当座'"
          v-text="'当座'"
        />
        <v-btn
          small
          outlined
          value="貯蓄"
          tabindex="1"
          :class="{
            'disable-events': !editable && value.bank_account_type === '貯蓄',
          }"
          :disabled="!editable && value.bank_account_type !== '貯蓄'"
          v-text="'貯蓄'"
        />
        <v-btn
          small
          outlined
          value="その他"
          tabindex="1"
          :class="{
            'disable-events': !editable && value.bank_account_type === 'その他',
          }"
          :disabled="!editable && value.bank_account_type !== 'その他'"
          v-text="'その他'"
        />
      </v-btn-toggle>
    </v-row>
    <v-row no-gutters class="py-1">
      <v-text-field
        v-model="value.bank_account_number"
        dense
        filled
        :outlined="editableBank"
        hide-details="auto"
        maxlength="7"
        placeholder="口座番号"
        tabindex="1"
        class="more-dense"
        :class="{ 'white-back': editableBank }"
        :rules="[rules.accountNumber, rules.accountType]"
        :readonly="!editable"
        @click="toCustomMode"
      />
    </v-row>
    <v-row no-gutters class="py-1">
      <v-col cols="9">
        <v-text-field
          v-model="value.bank_account_name"
          dense
          filled
          :outlined="editableBank"
          hide-details="auto"
          placeholder="名義人名"
          tabindex="1"
          class="more-dense"
          :class="{ 'white-back': editableBank }"
          :rules="[rules.accountNameLength, rules.accountNameCharacters]"
          :readonly="!editable"
          @click="toCustomMode"
        />
      </v-col>
      <v-col cols="3">
        <v-btn
          small
          depressed
          tabindex="1"
          color="grey lighten-2"
          class="ml-2"
          :disabled="!editableBank"
          @click="setAccountNameFromCompanyName"
          v-text="'請求元カナ変換'"
        />
      </v-col>
    </v-row>

    <v-row no-gutters class="py-2">
      <v-radio-group
        v-model="selected"
        mandatory
        class="mt-0 w-100"
        :disabled="!editable"
      >
        <template v-for="(item, index) in value.bank_list">
          <v-row :key="index" no-gutters class="mb-3">
            <v-col cols="2" @click="selectBank(index)">
              <v-radio
                :value="index"
                :label="item.type == 'learned' ? '学習済' : '読取'"
              />
            </v-col>
            <v-col cols="2" class="pl-1">
              <v-chip
                v-tooltip="item.bank_name"
                x-small
                label
                color="grey lighten-4"
                class="w-100 px-1 py-3"
                v-text="item.bank_name"
              />
            </v-col>
            <v-col cols="2" class="pl-1">
              <v-chip
                v-tooltip="item.bank_branch_name"
                x-small
                label
                color="grey lighten-4"
                class="w-100 px-1 py-3"
                v-text="item.bank_branch_name"
              />
            </v-col>

            <v-col cols="2" class="pl-1">
              <v-chip
                x-small
                label
                color="grey lighten-4"
                class="w-100 px-1 py-3"
                v-text="item.bank_account_type + ' ' + item.bank_account_number"
              />
            </v-col>

            <v-col cols="4" class="pl-1">
              <v-chip
                v-tooltip="item.bank_account_name"
                x-small
                label
                color="grey lighten-4"
                class="w-100 px-1 py-3"
                v-text="item.bank_account_name"
              />
            </v-col>
          </v-row>
        </template>

        <v-radio :value="-1" label="カスタム（編集）" />
      </v-radio-group>
    </v-row>
    <v-row no-gutters class="py-2 sweeep-filter">
      <v-col cols="12">
        <v-text-field
          v-model="value.payment_note1"
          :label="definition.payment_note1"
          :readonly="!editable"
          :filled="!editable"
          outlined
          dense
          tabindex="1"
          class="more-dense"
          hide-details
        />
      </v-col>
      <v-col cols="12" class="mt-3">
        <v-text-field
          v-model="value.payment_note2"
          :label="definition.payment_note2"
          :readonly="!editable"
          :filled="!editable"
          outlined
          dense
          tabindex="1"
          class="more-dense"
          hide-details
        />
      </v-col>
      <v-col cols="12" class="mt-3">
        <v-textarea
          v-model="value.payment_note3"
          :label="definition.payment_note3"
          :readonly="!editable"
          :filled="!editable"
          outlined
          tabindex="1"
          class="fs-11 more-dense"
          hide-details
        />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.payment-bank-form .error--text {
  background-color: rgb(240, 220, 200) !important;
}
</style>

<script>
import Axios from 'axios'
import { mapGetters } from 'vuex'
import AmountInput from '@/components/Input/AmountInput'
import { correctMasterListByName } from '@/helpers/payment/index'

export default {
  components: {
    AmountInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    userBankList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: 0,
      rules: {
        accountType: (v) =>
          !(v && !this.value.bank_account_type) ||
          '口座種別が選択されていません。',
        accountNumber: (v) =>
          (v.replace(/^[0-9]*/, '').length == 0 && v.length <= 7) ||
          this.$t('message.bank_account_number_form_message'),
        accountNameLength: (v) =>
          v.length <= 48 ||
          this.$t('message.bank_account_name_form_message_length'),
        accountNameCharacters: (v) =>
          v.replace(/^[ァ-ンｦ-ﾟA-Z0-9ヴ\(\)\.\-\/ー（） 　]+$/, '').length ==
            0 ||
          this.$t('message.bank_account_name_form_message_name_characters'),
      },
    }
  },
  computed: {
    ...mapGetters(['settingJournal', 'definition', 'settingPaidByCustomer']),
    editableBank: function () {
      return this.editable && this.selected === -1
    },
    matchedIndex: function () {
      const value = this.value
      if (value.bank_list.length > 0 && value.bank_list[0].bank_name) {
        return value.bank_list.findIndex((item) => {
          return (
            item.bank_name === value.bank_name &&
            item.bank_branch_name === value.bank_branch_name &&
            item.bank_account_type === value.bank_account_type &&
            item.bank_account_name === value.bank_account_name &&
            item.bank_account_number === value.bank_account_number
          )
        })
      } else {
        return -1
      }
    },
    bankList() {
      const bankName = this.value.bank_name ? this.value.bank_name: ''
      const bankCode = this.value.bank_code ? this.value.bank_code: ''
      let bankList =  this.$store.getters.bankList
      bankList = correctMasterListByName(bankList, bankName, bankCode)
      return bankList
    },
    branchList() {
      const bankName = this.value.bank_name ? this.value.bank_name: ''
      let branchList = this.$store.getters.bankBranchList[bankName]
      branchList = branchList ? branchList: []
      const branchName = this.value.bank_branch_name ? this.value.bank_branch_name: ''
      const branchCode = this.value.bank_branch_code ? this.value.bank_branch_code: ''
      branchList = correctMasterListByName(branchList, branchName, branchCode)
      return branchList
    },
  },
  watch: {
    'value.changed': function () {
      this.selected = this.matchedIndex
    },
  },
  methods: {
    onClickAccountType() {
      this.$emit('validate')
      this.toCustomMode()
    },
    toCustomMode() {
      if (!this.editable) {
        return
      }
      this.selected = -1
    },
    selectBank(index) {
      if (!this.editable) {
        return
      }
      const item = this.value.bank_list[index]
      this.value.bank_name = item.bank_name
      this.value.bank_branch_name = item.bank_branch_name
      this.value.bank_account_type = item.bank_account_type
      this.value.bank_account_number = item.bank_account_number
      this.value.bank_account_name = item.bank_account_name
      this.selected = index
    },
    setAccountNameFromCompanyName() {
      Axios.get(this.$store.getters.apiInvoiceConvertName, {
        headers: { Authorization: this.$store.getters.getAuthToken },
        params: { company_name: this.value.name },
      }).then((response) => {
        this.value.bank_account_name = response.data.company_name
      })
    },
    setBlankBankName() {
      this.$nextTick(() => {
        this.value.bank_name = ''
        this.value.bank_branch_name = ''
      })
    },
    setBlankBankBranchName() {
      this.$nextTick(() => {
        this.value.bank_branch_name = ''
      })
    },
    setEmptyObject(key) {
      this.$nextTick(() => {
        this.value[key] = {}
      })
    },
    focusBankName() {
      this.$refs.bankName.focus()
    },
    blurBank() {
      //ショートカットキーを使用した編集モード終了時にフォーカスを外す (マシンスペックに依存する)
      this.$refs.bankName.blur()
      this.$refs.bankBranchName.blur()
      document.activeElement.blur()
    },
  },
}
</script>
