import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class OrganizationLearningSettings extends APIClient {
  async post(token, data) {
    const url = URLs.LEARNING_SETTINGS
    const payload = {
      url,
      data,
      method: 'POST',
      headers: { Authorization: token },
    }
    try {
      const response = await this.sendRequest(payload)
      return response
    } catch (error) {
      throw new Error(this.getErrorMessage(error.response.data.message))
    }
  }
  getErrorMessage(message) {
    const errorEngToJa = {
      'No privilege to update': '権限がありません。',
    }
    return errorEngToJa[message]
      ? errorEngToJa[message]
      : 'エラーが発生しました。サポートに連絡をお願いします。'
  }
}
export default new OrganizationLearningSettings()
