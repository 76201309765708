import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class InvoiceEmailAPI extends APIClient {

  async post(token, params) {
    const payload = {
      url: URLs.INVOICE_EMAIL,
      method: 'POST',
      args: {
        local_id: params.localId
      },
      data: {
        to_user_organization_ids: params.toUserOrganizationIds,
        subject: params.subject,
        comment: params.comment,
      },
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(response.data.error)
      }
      return response.data
    } catch (err) {
      throw this.createResponseRrror(err)
    }
  }
}
export default new InvoiceEmailAPI()
