<template>
  <v-menu
    transition="slide-x-transition"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :left="left"
    :bottom="bottom"
    :nudge-left="nudgeLeft"
    :nudge-bottom="nudgeBottom"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="display" :attrs="attrs" :on="on" />
    </template>
    <v-card class="sweeep-popover" width="350">
      <v-card-title> 回収メモ </v-card-title>
      <v-card-text>
        <div class="fs-10">利用目的</div>
        <v-text-field
          v-model="inboxPurpose"
          dense
          filled
          hide-details
          outlined
          readonly
          class="more-dense my-1"
        />
        <div class="fs-10 mt-3">コメント</div>
        <div
          class="pa-2 my-1 fs-12 fw-bold"
          style="
            border: #a5a7b2 1px solid;
            border-radius: 5px;
            min-height: 100px;
          "
          v-html="inboxCommentWithHyperLink"
        />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { escapeText, surroundUrlByTag } from 'Helpers/helpers'

export default {
  props: {
    offsetX: {
      type: Boolean,
      default: false,
    },
    offsetY: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    nudgeLeft: {
      type: Number,
      default: 0,
    },
    nudgeBottom: {
      type: Number,
      default: 0,
    },
    inboxPurpose: {
      type: String,
      default: '',
    },
    inboxComment: {
      type: String,
      default: '',
    },
  },
  computed: {
    inboxCommentWithHyperLink() {
      let text = escapeText(this.inboxComment)
      text = surroundUrlByTag(text)
      return text
    },
  },
}
</script>
