<template>
  <div
    v-tooltip="editedToolTip"
    style="
      position: absolute !important;
      top: -8px !important;
      right: -7px !important;
      padding-top: 10px;
      padding-right: 5px;
    "
  >
    <v-badge
      :value="editedToolTip"
      dot
      color="red"
      class="pa-0"
      style="height: 50px"
    />
  </div>
</template>

<script>
export default {
  props: {
    checkItems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    editedToolTip: function () {
      const editedItems = this.$store.getters.editedInvoiceFormFields

      let definedInProps

      let messages = []
      editedItems.forEach((item) => {
        definedInProps = this.checkItems.some((prop) => {
          if (prop.value === item.value) {
            return true
          }
          if (prop.include === true && item.value.includes(prop.value)) {
            return true
          }
        })
        if (!definedInProps) {
          return
        }

        if (messages.length === 0) {
          messages.push('------編集済------')
        }
        let fromValue = item.from
        let toValue = item.to
        if (fromValue === undefined || fromValue === null || fromValue === '') {
          fromValue = '入力なし'
        }
        if (toValue === undefined || toValue === null || toValue === '') {
          toValue = '入力なし'
        }
        messages.push(`${item.text}:    ${fromValue} -> ${toValue}`)
      })
      return messages.join('<br>')
    },
  },
  methods: {
    getStatus() {
      return this.editedToolTip ? true : false
    },
  },
}
</script>
