import LearningInvoiceClient from '@/clients/company/learningInvoice'
export const learningInvoice = async ({
  token,
  company,
  companyPattern,
  invoiceId,
  newCompany,
  valuesBeingViewed,
}) => {
  const data = {
    company,
    companyPattern,
    invoiceId,
    valuesBeingViewed,
  }
  if (newCompany) {
    return LearningInvoiceClient.post(token, data)
  } else {
    return LearningInvoiceClient.put(token, data)
  }
}
