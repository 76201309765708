import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'
import { getUserBankSimpleText } from '@/helpers/account/index'

class CompanyListClient extends APIClient {
  async get({ token, orgId }) {
    const companyListURL = URLs.COMPANY_LIST
    const payload = {
      url: companyListURL,
      method: 'GET',
      headers: { Authorization: token },
      params: { organization_id: orgId },
    }
    try {
      const response = await this.sendRequest(payload)
      if ('error' in response.data) {
        throw new Error(response.data.error)
      }
      let company_list = response.data.company_list
      company_list.forEach((item) => {
        if (item.user_bank) {
          item.user_bank.simpletext = getUserBankSimpleText(item.user_bank)
        }
      })
      return company_list
    } catch (err) {
      throw this.createResponseError(err)
    }
  }
}
export default new CompanyListClient()
