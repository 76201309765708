<template>
  <v-card tile outlined class="h-100 border-top-0">
    <div v-if="loading" class="app-flex justify-center align-center h-vh-100">
      <single-invoice-loader />
    </div>

    <input
      v-show="false"
      id="import-image-file"
      type="file"
      @change="$emit('import-image', $event)"
    />

    <base-dialog
      ref="confirmImportImageDialog"
      :title="$t('message.confirm')"
      :message="confirmImportImageDialogText"
      :cancel-text="$t('message.no')"
      :submit-text="$t('message.yes')"
      @submit="
        importImageBtn()
        $refs.confirmImportImageDialog.close()
      "
    />

    <div class="h-100">
      <invoice-area-header
        :invoice-form="invoiceForm"
        :invoice-file="invoiceFile"
        :disabled="invoiceForm.isDeleted"
        :freee-separate-approval="freeeSeparateApproval"
        @click:sendEmail="$emit('click:sendEmail')"
        @click:freeeDeal="$emit('click:freeeDeal')"
        @click:cancelUserViewed="$emit('click:cancelUserViewed')"
        @click:approveInvoice="$emit('click:approveInvoice', $event)"
        @click:approvePayment="$emit('click:approvePayment', $event)"
        @click:cancelApproveInvoice="
          $emit('click:cancelApproveInvoice', $event)
        "
        @click:cancelApprovePayment="
          $emit('click:cancelApprovePayment', $event)
        "
      />
      <div v-if="actionLogs.length > 0" style="position: relative" class="pa-0">
        <action-logs :items="actionLogs" :height="heightOfActionLogs" />
        <v-btn
          v-if="actionLogs.length > 1"
          style="position: absolute; right: 10px; top: 7px; z-index: 9"
          x-small
          text
          fab
          class="ml-2"
          @click="expandedActionLogs = !expandedActionLogs"
        >
          <s-icon
            :icon="
              expandedActionLogs ? 'feather-chevron-up' : 'feather-chevron-down'
            "
          />
        </v-btn>
      </div>
      <v-row no-gutters>
        <v-col style="width: calc(100% - 60px)">
          <invoice-image
            :height="heightOfImageArea"
            :loading="loading"
            :display-pdf="invoiceFile.displayPdf"
            :pdf-url="invoiceFile.pdfUrl"
            :image-uris="invoiceFile.imageUris"
            :file-name="invoiceFile.fileName"
            :file-page="invoiceFile.filePage"
            @download-file="$emit('download-file')"
          />
        </v-col>
        <v-col style="max-width: 60px">
          <invoice-area-sidebar
            :height="heightOfImageArea"
            :learned="invoiceForm.learned"
            :note1="invoiceForm.note_1"
            :note2="invoiceForm.note_2"
            :note3="invoiceForm.note_3"
            :inbox-purpose="invoiceForm.inboxPurpose"
            :inbox-comment="invoiceForm.inboxComment"
            :score-max-amount="invoiceForm.scoreMaxAmount"
            :current-version="invoiceFile.currentVersion"
            :max-version="invoiceFile.maxVersion"
            :match-company-bank="matchCompanyBank"
            :can-replace-invoice="!invoiceForm.isDeleted"
            :invoice-area-bank-icon-color="invoiceAreaBankIconColor"
            :local-id="invoiceForm.local_id"
            @click:updateNote="$emit('click:updateNote', $event)"
            @open-history="$emit('open-history')"
            @import-image="confirmImportImage"
            @version-up="$emit('version-up')"
            @version-down="$emit('version-down')"
          />
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import ActionLogs from 'Views/invoice/components/ActionLogs/ActionLogs'
import InvoiceImage from './components/InvoiceImage'
import InvoiceAreaHeader from './components/InvoiceAreaHeader'
import InvoiceAreaSidebar from './components/InvoiceAreaSidebar'
import SingleInvoiceLoader from './components/SingleInvoiceLoader'

export default {
  components: {
    ActionLogs,
    SingleInvoiceLoader,
    InvoiceImage,
    InvoiceAreaHeader,
    InvoiceAreaSidebar,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    invoiceForm: {
      type: Object,
      required: true,
    },
    invoiceFile: {
      type: Object,
      required: true,
    },
    actionLogs: {
      type: Array,
      required: true,
    },
    matchCompanyBank: {
      type: Boolean,
      required: true,
    },
    invoiceAreaBankIconColor: {
      type: String,
      required: true,
    },
    freeeSeparateApproval: {
      type: Boolean,
      required: true,
    },
    showFullScreen: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      drawerHistory: false,
      drawerHistoryImage: false,
      drawerHistoryExpand: false,
      dialogConfirmCopiedImage: false,
      expandedActionLogs: false,
      confirmImportImageDialogText: '',
    }
  },
  computed: {
    heightOfActionLogs() {
      if (this.actionLogs.length == 0) {
        return '0px'
      } else if (!this.expandedActionLogs) {
        return '44px'
      } else {
        return '44px + 62px'
      }
    },
    heightOfImageArea() {
      const globalHeaderHeight = '100px'
      const invoiceAreaHeaderHeight = '52px'
      const actionLogsHeight = '44px'
      const expandedActionLogsHeight = '62px'

      let height = `100vh - ${invoiceAreaHeaderHeight}`

      if (!this.showFullScreen) {
        height = `${height} - ${globalHeaderHeight}`
      }
      if (this.actionLogs.length != 0) {
        height = `${height} - ${actionLogsHeight}`
        if (this.expandedActionLogs) {
          height = `${height} - ${expandedActionLogsHeight}`
        }
      }
      return height
    },
  },
  methods: {
    toBlob(base64, contentType) {
      var bin = atob(base64)
      var buffer = new Uint8Array(bin.length)
      for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i)
      }
      // Blobを作成
      try {
        var blob = new Blob([buffer.buffer], {
          type: contentType,
        })
      } catch (e) {
        return false
      }
      return blob
    },
    confirmImportImage() {
      let message = ''
      if (this.invoiceForm.copied) {
        message += this.$t('message.invoice_confirm_import_multiple_reference')
      }
      if (this.invoiceForm.approved) {
        message += this.$t(
          'message.invoice_confirm_import_image_to_approved_invoice'
        )
      }
      if (message !== '') {
        this.confirmImportImageDialogText = message
        this.$refs.confirmImportImageDialog.open()
      } else {
        this.importImageBtn()
      }
    },
    importImageBtn() {
      $('#import-image-file').click()
    },
  },
}
</script>
