<template>
  <div>
    <journal-form-header no-padding />
    <div v-for="(journal, index) in journals" :key="`markedJournals-${index}`">
      <v-row no-gutters>
        <template v-for="(entrySide, h) in ['debit', 'credit']">
          <v-col :key="`journal_${index}_${h}`" cols="6">
            <v-card
              tile
              outlined
              class="pb-3"
              style="background-color: #fcfcfc"
            >
              <div class="px-2 pt-2">
                <journal-iterators
                  :ref="`${entrySide}_journal`"
                  :journal="journal"
                  :editable="false"
                  :entry-side="entrySide"
                  :disables-auto-complete="true"
                />
              </div>
            </v-card>
          </v-col>
        </template>
        <v-col cols="12">
          <v-card tile outlined class="pa-2" style="background-color: #fcfcfc">
            <journal-text-field
              v-if="displaySetting.description"
              ref="description"
              v-model="journal.description"
              description-field
              :chip="sliceText(definition.description, 0, 4)"
              :editable="false"
            />
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JournalIterators from 'Components/Journal/JournalIterators.vue'
import JournalFormHeader from 'Components/Journal/JournalFormHeader.vue'
import JournalTextField from 'Components/Journal/JournalTextField.vue'
import { sliceText } from 'Helpers/journal'

export default {
  components: {
    JournalIterators,
    JournalFormHeader,
    JournalTextField,
  },
  props: {
    journals: {
      type: Array,
      default: () => [],
    },
    markNames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      markedElements: [],
    }
  },
  computed: {
    ...mapGetters(['displaySetting', 'definition']),
  },
  watch: {
    markNames: {
      handler: function (arr) {
        if (!arr) {
          return
        }
        this.initalizeMark().then(() => {
          this.markElements(arr)
        })
      },
      immediate: true,
    },
  },
  methods: {
    sliceText(text, i, h) {
      return sliceText(text, i, h)
    },
    initalizeMark() {
      return new Promise((resolve) => {
        this.markedElements.forEach((elem) => {
          elem.style.background = ''
        })
        this.markedElements = []
        resolve(true)
      })
    },
    markElements(markNames) {
      try {
        let t = this
        let refs = this.$refs
        let vals, index, name, child, entrySide, elm

        markNames.forEach((str) => {
          vals = str.split(':')
          if (vals.length !== 2) {
            return
          }
          index = Number(vals[0])
          name = vals[1]

          if (['free_text_1', 'free_text_2'].includes(name)) {
            return
          }

          if (name === 'description') {
            child = refs.description[index].$refs.textParser
            elm = child.$el
          } else {
            entrySide = name.match('debit|credit')[0]
            elm = refs[`${entrySide}_journal`][index].$refs[name][0]
          }
          if (elm) {
            elm.style.background = '#bce2e8'
            t.markedElements.push(elm)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
