<template>
  <v-dialog v-model="dialog" width="300">
    <v-card class="freeUploadWaitingModal sweeep-dialog">
      <div class="waiting-container-free-upload pt-9" align="center">
        <v-progress-circular indeterminate color="primary" />
        <br />
        <br />
        <br />
        <label style="margin-top: 15px"> freeeと接続しています... </label>
        <br />
        <br />
        <br />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    submit() {
      this.close()
    },
  },
}
</script>
