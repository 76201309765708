<template>
  <div>
    <allocation-menu
      ref="allocationMenu"
      @submit="$emit('submit:allocation', $event)"
    />
    <journal-form-header />

    <template v-for="(journal, i) in journals">
      <div :key="i" class="mb-5">
        <v-row no-gutters class="pr-3">
          <template v-for="(dc, j) in ['debit', 'credit']">
            <v-col :key="j" cols="6">
              <v-card
                tile
                outlined
                class="pb-5"
                style="background-color: #fcfcfc"
              >
                <journal-tab
                  v-if="j === 0"
                  :index="i + 1"
                  :count="journalLengthRendering || journals.length"
                  :show-menu="editable"
                  :show-allocation="!isForeignCurrency"
                  @add:next="addRecord(i)"
                  @add:last="addRecord(i, true)"
                  @copy:next="copyRecord(i)"
                  @copy:last="copyRecord(i, true)"
                  @clear:this="clearRecord(i)"
                  @clear:all="clearRecord(i, true)"
                  @delete:this="deleteRecord(i)"
                  @delete:all="deleteRecord(i, true)"
                  @allocation="onClickAllocation(journal)"
                />

                <div class="px-2 pt-2">
                  <journal-iterators
                    :ref="`${dc}_iterator`"
                    :journal="journal"
                    :editable="editable"
                    :entry-side="dc"
                    :get-validation-rule="getValidationRule"
                    :get-form-error="getFormError"
                    :get-form-disable="getFormDisable"
                    :display-name-key="'display_name'"
                    :show-auto-complete-tooltip="true"
                    :tabindex="i + 1"
                    @change="onChangeJournal($event, journal)"
                  />
                </div>

                <v-row no-gutters class="px-2 pt-4">
                  <v-btn-toggle
                    :value="journal[dc + '_amount_input_mode']"
                    mandatory
                    dense
                    color="blue"
                    @change="toggleInputMode(journal, dc, $event)"
                  >
                    <v-btn
                      x-small
                      :value="2"
                      :disabled="
                        !editable && journal[dc + '_amount_input_mode'] == 1
                      "
                      :tabindex="i + 1"
                      v-text="'内税'"
                    />
                    <v-btn
                      x-small
                      :value="1"
                      :disabled="
                        !editable && journal[dc + '_amount_input_mode'] == 2
                      "
                      :tabindex="i + 1"
                      v-text="'外税'"
                    />
                  </v-btn-toggle>
                  <v-spacer />

                  <v-chip
                    v-if="!editable && journal[dc + '_is_keikasochi']"
                    x-small
                    label
                  >
                    経過措置
                  </v-chip>

                  <v-btn v-show="editable" x-small icon>
                    <v-icon
                      v-tooltip="'貸借逆から金額コピー'"
                      x-small
                      @click="transferAmount(dc, journal)"
                      v-text="'feather-copy'"
                    />
                  </v-btn>
                  <learning-amount-menu
                    v-if="editable && !isForeignCurrency"
                    :journal-index="i"
                    :journal-type="journalType"
                    :learning-candidates-amount="learningCandidatesAmount"
                    :setting="learningAmountMenuSetting"
                    @click:item="onClickSetAmount({ ...$event, dc, journal })"
                  />
                </v-row>
                <v-row
                  v-show="isForeignCurrency && editable"
                  class="mx-3 mt-4 mb-3"
                  no-gutters
                >
                  <v-col cols="3">
                    <div
                      class="fs-10 pr-3"
                      :class="editable ? 'mt-3 mr-3' : 'mt-2'"
                    >
                      通貨
                    </div>
                  </v-col>
                  <v-col cols="9">
                    <v-autocomplete
                      ref="input"
                      :value="currencyCode"
                      :items="currencyCodes"
                      dense
                      filled
                      outlined
                      hide-details
                      class="
                        py-1
                        more-dense
                        white-back
                        select-absolute
                        ml-3
                        mr-3
                      "
                      item-text="currency_code"
                      item-value="currency_number"
                      return-object
                      @change="$emit('change:currency-number', $event)"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <journal-amount-field
                  v-if="isForeignCurrency && editable && currencyCode"
                  :value="currencyRate"
                  :editable="editable"
                  :tabindex="i + 1"
                  :label="currencyCodeLabel"
                  emit-focus-event
                  emit-blur-event
                  @select="onInputRate(journal, $event)"
                />
                <journal-amount-field
                  v-if="isForeignCurrency && editable && currencyCode"
                  :value="journal[`${dc}_foreign_currency_amount`]"
                  label="外貨金額"
                  :editable="editable"
                  :tabindex="i + 1"
                  emit-focus-event
                  emit-blur-event
                  @focus="
                    setCurrencyToNumber(journal, [
                      `${dc}_foreign_currency_amount`,
                    ])
                  "
                  @blur="
                    setFloatToCurrency(journal, [
                      `${dc}_foreign_currency_amount`,
                    ])
                  "
                  @select="onInputForeignCurrencyAmount(journal, dc, $event)"
                />
                <v-row
                  v-show="isForeignCurrency && !editable"
                  no-gutters
                  class="px-3"
                >
                  <v-col cols="3">
                    <div class="fs-10 mt-3">
                      {{ currencyCode && currencyCode.currency_code }}
                    </div>
                  </v-col>
                  <v-col cols="9">
                    <div class="right fs-10 mt-3">
                      {{
                        numberToCurrencyUSD(
                          journal[`${dc}_foreign_currency_amount`]
                        )
                      }}
                    </div>
                  </v-col>
                </v-row>
                <journal-amount-field
                  v-model="journal[dc + '_amount']"
                  label="税込額"
                  class="mt-2"
                  :class="{ 'fw-bold': !editable }"
                  :editable="editable"
                  :disabled="
                    (editable && journal[dc + '_amount_input_mode'] === 1)
                  "
                  :tabindex="i + 1"
                  @input="onInputAmount(journal, dc)"
                />
                <journal-amount-field
                  v-model="journal[dc + '_amount_without_tax']"
                  label="本体"
                  :editable="editable"
                  :disabled="
                    (editable && journal[dc + '_amount_input_mode'] === 2)
                  "
                  :tabindex="i + 1"
                  :value-after-adjustment="journal[dc + '_is_keikasochi'] ? journal[dc + '_amount_without_tax_after_adjustment']: ''"
                  @input="onInputAmountWithoutTax(journal, dc)"
                />
                <journal-amount-field
                  v-model="journal[dc + '_tax_amount']"
                  label="税額"
                  :editable="editable"
                  :disabled="isForeignCurrency"
                  :tabindex="i + 1"
                  :value-after-adjustment="journal[dc + '_is_keikasochi'] ? journal[dc + '_tax_amount_after_adjustment']: ''"
                  @input="onInputTaxAmount(journal, dc)"
                />

                <template v-if="editable && isKeikasochi">

                  <!--経過措置期間のみチェックボックス表示 -->
                  <v-row no-gutters class="px-2 pt-4">
                    <!-- 経過措置チェックボックス -->
                    <v-simple-checkbox
                      :value="journal[dc + '_is_keikasochi']"
                      @input="onInputIsKeikasochi(journal, dc, $event)"
                    />
                    <span class="fs-10 pt-1 pl-1">経過措置</span>

                    <v-spacer />
                    <v-chip
                      v-show="journal[dc + '_is_keikasochi']"
                      class="px-1"
                      x-small
                      label
                    >
                       {{ `控除率${taxDeductionRate}%` }}
                    </v-chip>
                  </v-row>

                  <journal-amount-field
                    v-show="journal[dc + '_is_keikasochi']"
                    v-model="journal[dc + '_amount_without_tax_after_adjustment']"
                    label="適用後本体"
                    :editable="editable"
                    disabled
                    :tabindex="i + 1"
                  />
                  <journal-amount-field
                    v-show="journal[dc + '_is_keikasochi']"
                    v-model="journal[dc + '_tax_amount_after_adjustment']"
                    label="適用後税額"
                    :editable="editable"
                    :disabled="isForeignCurrency"
                    :tabindex="i + 1"
                    @input="onInputTaxAmountAfterAdjustment(journal, dc)"
                  />
                </template>
              </v-card>
            </v-col>
          </template>
        </v-row>

        <v-card
          v-if="showJournalTextArea"
          tile
          outlined
          class="pt-3 px-3 mr-3"
          :class="editable ? 'pt-5 pb-0' : 'pb-3'"
          style="background-color: #fcfcfc"
        >
          <v-row v-if="displaySetting.description" no-gutters>
            <journal-text-field
              description-field
              :value="
                editable
                  ? journal.description
                  : _computeDescription(journal.description)
              "
              :chip="sliceText(definition.description, 0, 4)"
              :tooltip="definition.description"
              :editable="editable"
              :mode="displaySetting.description"
              :counter="displaySetting.maxlen_description"
              :check-byte="displaySetting.wordOrByte == 2"
              :tabindex="i + 1"
              @change="journal.description = $event"
            />
          </v-row>
          <v-row v-if="displaySetting.free_text_1" no-gutters>
            <journal-text-field
              v-model="journal.free_text_1"
              :chip="sliceText(definition.free_text_1, 0, 4)"
              :tooltip="definition.free_text_1"
              :editable="editable"
              :mode="displaySetting.free_text_1"
              :counter="displaySetting.maxlen_free_text_1"
              :check-byte="displaySetting.wordOrByte == 2"
              :tabindex="i + 1"
            />
          </v-row>
          <v-row v-if="displaySetting.free_text_2" no-gutters>
            <journal-text-field
              v-model="journal.free_text_2"
              :chip="sliceText(definition.free_text_2, 0, 4)"
              :tooltip="definition.free_text_2"
              :editable="editable"
              :mode="displaySetting.free_text_2"
              :counter="displaySetting.maxlen_free_text_2"
              :check-byte="displaySetting.wordOrByte == 2"
              :tabindex="i + 1"
            />
          </v-row>
        </v-card>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import {
  getName,
  getNameCode,
  computeDescription,
  getInitialJournalForm,
} from 'Helpers/invoice'
import { getDecimal } from 'Helpers/helpers'
import Snackbar from 'Helpers/snackbar'
import {
  clearOne,
  sliceText,
  currencyToNumber,
  numberToCurrency,
  numberToCurrencyUSD,
  onChangeJournal,
  copyObject,
  floatToCurrency,
} from 'Helpers/journal'

import {
  journalStateFields,
  fromJournalStateToJournal,
  fromJournalToJournalState,
} from 'Helpers/journal/state'

import { toDate } from 'Helpers/date'

import { caluculateYenFromForeignAmount, CURRENCY_RATE_MAX_DECIMAL } from '@/services/invoice/foreignAmount'
import { journalService, EntrySide } from '@/services/invoice/journal'

import JournalTab from 'Components/Journal/JournalTab.vue'
import JournalTextField from 'Components/Journal/JournalTextField.vue'
import JournalAmountField from 'Components/Journal/JournalAmountField.vue'
import JournalFormHeader from 'Components/Journal/JournalFormHeader.vue'
import JournalIterators from 'Components/Journal/JournalIterators.vue'
import LearningAmountMenu from 'Views/invoice/components/InvoiceInfo/components/LearningAmountMenu.vue'
import AllocationMenu from 'Views/invoice/components/InvoiceInfo/components/AllocationMenu.vue'

export default {
  components: {
    JournalTab,
    JournalTextField,
    JournalAmountField,
    JournalFormHeader,
    JournalIterators,
    LearningAmountMenu,
    AllocationMenu,
  },
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    invoiceIdRendered: {
      type: Number,
      default: 0,
    },
    issueDate: {
      type: String,
      default: '',
    },
    journalLengthRendering: {
      type: Number,
      default: 0,
    },
    learningCandidatesAmount: {
      type: Array,
      default: () => [],
    },
    journalType: {
      type: String,
      default: '',
    },
    learningAmountMenuSetting: {
      type: Object,
      required: true,
    },
    isForeignCurrency: {
      type: Boolean,
      default: false,
    },
    isInvoiceIssuer: {
      type: Boolean,
      default: false,
    },
    currencyCode: {
      type: Object,
      default() {
        return {}
      },
    },
    settingForeignCurrency: {
      type: Boolean,
      default: false,
    },
    currencyCodes: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      journalTextKeys: ['description', 'free_text_1', 'free_text_2'],
      formFieldNames: journalStateFields,
    }
  },
  computed: {
    ...mapGetters([
      'displaySetting',
      'definition',
      'masterList',
      'showJournalTextArea',
      'journalRules',
    ]),
    journals: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('select', val)
      },
    },
    totalDebit: function () {
      try {
        let total = 0
        for (var x = 0; x < this.journals.length; x++) {
          var val = 0
          try {
            val = currencyToNumber(this.journals[x].debit_amount)
          } catch (err) {
            val = 0
          }
          total = total + val
        }
        return total
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    totalCredit: function () {
      try {
        let total = 0
        for (var x = 0; x < this.journals.length; x++) {
          var val = currencyToNumber(this.journals[x].credit_amount)
          if (!val) {
            val = 0
          }
          total = total + val
        }
        return total
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    totalDebitTax: function () {
      try {
        const isKeikasochi = this.isKeikasochi
        let total = 0
        for (var x = 0; x < this.journals.length; x++) {
          let amount = this.journals[x].debit_tax_amount
          if (isKeikasochi && this.journals[x].debit_is_keikasochi) {
            amount = this.journals[x].debit_tax_amount_after_adjustment
          }
          var val = currencyToNumber(amount)
          if (!val) {
            val = 0
          }
          total = total + val
        }
        return total
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    totalCreditTax: function () {
      try {
        const isKeikasochi = this.isKeikasochi
        let total = 0
        for (var x = 0; x < this.journals.length; x++) {
          let amount = this.journals[x].credit_tax_amount
          if (isKeikasochi && this.journals[x].credit_is_keikasochi) {
            amount = this.journals[x].credit_tax_amount_after_adjustment
          }
          var val = currencyToNumber(amount)
          if (!val) {
            val = 0
          }
          total = total + val
        }
        return total
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    currencyCodeLabel() {
      const currencyCode = this.currencyCode.currency_code
      if (currencyCode) {
        return `1${currencyCode}= `
      } else {
        return ''
      }
    },
    currencyRate() {
      return this.journals[0].foreign_currency_rate
    },
    taxDeductionRate() {
      return journalService.getTaxDeductionRateAsPercentage(toDate(this.issueDate))
    },
    isTaxAdjustment: function () {
      return !this.isInvoiceIssuer && this.taxDeductionRate < 100
    },
    isKeikasochi() {
      return this.isTaxAdjustment && this.taxDeductionRate > 0
    },
    isKeikasochiEnded() {
      return this.isTaxAdjustment && this.taxDeductionRate == 0
    },
  },
  watch: {
    totalDebit: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.$emit('change:debit-amount', newVal)
      }
    },
    totalDebitTax: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.$emit('change:debit-tax-amount', newVal)
      }
    },
    totalCredit: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.$emit('change:credit-amount', newVal)
      }
    },
    totalCreditTax: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.$emit('change:credit-tax-amount', newVal)
      }
    },
    invoiceIdRendered: function (newVal, oldVal) {
      if (oldVal === newVal || !this.editable) {
        return
      }
      this.bulkCompputeIsKeikasochi()
      if (!this.isInvoiceIssuer) {
        this.bulkComputeTaxAmount()
      }
    },

    editable: function (newVal, oldVal) {
      if (oldVal === newVal || !newVal) {
        return
      }
      this.bulkCompputeIsKeikasochi()
      if (!this.isInvoiceIssuer) {
        this.bulkComputeTaxAmount()
      }
    },

    // 伝票日付変更時に控除率が変わったとき
    taxDeductionRate: function (newVal, oldVal) {
      if ((oldVal === newVal) || !this.editable) {
        return
      }
      this.bulkCompputeIsKeikasochi()
      if (!this.isInvoiceIssuer) {
        this.bulkComputeTaxAmount()
      }
    },

    // 適格請求区分が変わったとき
    isInvoiceIssuer: function (newVal, oldVal) {
      if ((oldVal === newVal) || !this.editable) {
        return
      }
      this.bulkCompputeIsKeikasochi()
      this.bulkComputeTaxAmount()
    },
  },
  methods: {
    bulkCompputeIsKeikasochi() {
      if (this.isInvoiceIssuer) {
        this.bulkTurnOffKeikasochi()
        return
      }
      if (this.isKeikasochi) {
        this.bulkTurnOnKeikasochi()
      } else {
        this.bulkTurnOffKeikasochi()
      }
    },
    setJournalState(journalState, values) {
      for ( const k in values ) {
        let before = journalState[k]
        let after = values[k]
        if (k.includes("amount")) {
          if (before === "-") {
            // マイナスのみ入力の場合は変更しない
            continue
          }
          before = numberToCurrency(before)
          after = numberToCurrency(after)
        }
        if (before === after) {
          continue
        }
        journalState[k] = values[k]
      }
    },
    sliceText(text, i1, i2) {
      return sliceText(text, i1, i2)
    },
    getJournalField(dc, name) {
      try {
        return this.formFieldNames[dc][name]
      } catch (e) {
        console.log(e)
      }
    },
    addRecord(index, last = false) {
      try {
        let dataToAdd = getInitialJournalForm()
        if (last) {
          this.journals.push(dataToAdd)
        } else {
          this.journals.splice(index + 1, 0, dataToAdd)
        }
        Snackbar.success(this.$t('message.addedJournal'))
      } catch (e) {
        console.log(e)
      }
    },
    copyRecord(index, last = false) {
      try {
        let journal = this.journals[index]
        let dataToAdd = {
          debit_account_title: copyObject(journal.debit_account_title),
          debit_sub_account: copyObject(journal.debit_sub_account),
          debit_department: copyObject(journal.debit_department),
          debit_vendor: copyObject(journal.debit_vendor),
          debit_segment: copyObject(journal.debit_segment),
          debit_project: copyObject(journal.debit_project),
          debit_walletable: copyObject(journal.debit_walletable),
          debit_item: copyObject(journal.debit_item),
          debit_tag: copyObject(journal.debit_tag),
          debit_tax: copyObject(journal.debit_tax),

          credit_account_title: copyObject(journal.credit_account_title),
          credit_sub_account: copyObject(journal.credit_sub_account),
          credit_department: copyObject(journal.credit_department),
          credit_vendor: copyObject(journal.credit_vendor),
          credit_segment: copyObject(journal.credit_segment),
          credit_project: copyObject(journal.credit_project),
          credit_walletable: copyObject(journal.credit_walletable),
          credit_item: copyObject(journal.credit_item),
          credit_tag: copyObject(journal.credit_tag),
          credit_tax: copyObject(journal.credit_tax),

          debit_amount: '',
          debit_amount_without_tax: '',
          debit_tax_amount: '',
          debit_amount_input_mode: journal.debit_amount_input_mode,
          debit_foreign_currency_amount: '0',

          credit_amount: '',
          credit_amount_without_tax: '',
          credit_tax_amount: '',
          credit_amount_input_mode: journal.credit_amount_input_mode,
          credit_foreign_currency_amount: '0',
          foreign_currency_rate: 0,

          description: journal.description,
          free_text_1: journal.free_text_1,
          free_text_2: journal.free_text_2,
        }
        if (last) {
          this.journals.push(dataToAdd)
        } else {
          this.journals.splice(index + 1, 0, dataToAdd)
        }
        Snackbar.success(this.$t('message.copyedJournal'))
      } catch (e) {
        console.log(e)
      }
    },
    deleteRecord(index, all = false) {
      try {
        if (all) {
          this.journals.splice(1, this.journals.length)
          clearOne(this.journals[0])
        } else {
          this.journals.splice(index, 1)
        }
        const messageKey = all ? 'deletedAllJournals' : 'deletedOneJournal'
        Snackbar.success(this.$t(`message.${messageKey}`))
      } catch (e) {
        console.log(e)
      }
    },
    clearRecord(index, all = false) {
      try {
        if (index === '' || index === null || index === undefined) {
          return false
        }
        if (all) {
          for (let id = 0; id < this.journals.length; id++) {
            clearOne(this.journals[id])
          }
        } else {
          clearOne(this.journals[index])
        }
        const messageKey = all ? 'clearedAllJournals' : 'clearedOneJournal'
        Snackbar.success(this.$t(`message.${messageKey}`))
      } catch (e) {
        console.log(e)
      }
    },
    getValidationSetting(item, dc, field) {
      // 0:禁止 1:任意 2:必須
      try {
        var account_title_name_code
        if (dc === 'debit') {
          account_title_name_code = getNameCode(item.debit_account_title)
        } else {
          account_title_name_code = getNameCode(item.credit_account_title)
        }
        var account_title_obj = this.masterList.account_title.find((v) => {
          return v.name_code == account_title_name_code
        })

        var validation_setting = null

        const fieldWraped = field === 'sub_account' ? 'subaccount' : field

        if (account_title_obj) {
          validation_setting = account_title_obj.display_settings[fieldWraped]
        }
        if (validation_setting in [0, 1, 2]) {
          return validation_setting
        }

        validation_setting = this.journalRules[field]['input_' + dc]

        if (field === 'sub_account' && account_title_name_code === '') {
          //勘定科目 0 => 補助科目も連動してdisable になるから設定必要ない
          //勘定科目 2 => 補助科目1の場合 勘定科目が設定されている時だけ、必須にする
          validation_setting = 1
        }

        return validation_setting
      } catch (e) {
        console.log(e)
      }
    },
    getAmount(item, dc) {
      let amount
      if (dc === 'debit') {
        amount = item.debit_amount
      } else {
        amount = item.credit_amount
      }
      return currencyToNumber(amount)
    },
    getFormError(item, dc, field) {
      try {
        if (this.journalType === 'paymentJournal') {
          return false
        }
        const amount = this.getAmount(item, dc)
        var form_field_name = this.getJournalField(dc, field)
        var validation_setting = this.getValidationSetting(item, dc, field)

        if (amount != 0 && validation_setting === 2) {
          const value = getName(item[form_field_name])
          return !value
        } else {
          return false
        }
      } catch (e) {
        console.log(e)
      }
    },
    getFormDisable(item, dc, field) {
      try {
        if (this.journalType === 'paymentJournal') {
          return false
        }
        var validation_setting = this.getValidationSetting(item, dc, field)

        if (validation_setting === 0) {
          return true
        } else {
          return false
        }
      } catch (e) {
        console.log(e)
      }
    },
    getName(obj) {
      return getName(obj)
    },
    getValidationRule(item, dc, field) {
      try {
        if (this.journalType === 'invoiceJournal') {
          const amount = this.getAmount(item, dc)
          let form_field_name = this.getJournalField(dc, field)
          let validation_setting = this.getValidationSetting(item, dc, field)

          if (amount !== 0 && validation_setting === 0) {
            this.$set(item, form_field_name, {})
          }

          var _getName = this.getName

          if (amount !== 0 && validation_setting === 2) {
            return [(v) => !!_getName(v) || '※この項目は必須です']
          } else {
            return []
          }
        } else {
          return []
        }
      } catch (e) {
        console.log(e)
      }
    },
    computeAmount(item, dc, field) {
      try {
        if (field === 'account_title') {
          this.onInputAccountTitle(item, dc)
        }

        if (field === 'amount') {
          this.onInputAmount(item, dc)
        }

        if (field === 'amount_without_tax') {
          this.onInputAmountWithoutTax(item, dc)
        }

        if (field === 'tax_amount') {
          this.onInputTaxAmount(item, dc)
        }

        if (field === 'tax') {
          this.onInputTaxClass(item, dc)
        }
      } catch (e) {
        console.log(e)
      }
    },
    onInputAccountTitle(item, dc) {
      // 税区分取得 => 税額計算
      try {
        const fieldAccountTitle = this.getJournalField(dc, 'account_title')
        const fieldSubAccount = this.getJournalField(dc, 'sub_account')
        const fieldTax = this.getJournalField(dc, 'tax')

        const accountTitle = item[fieldAccountTitle]
        const tax = this.getAccountTax(accountTitle)

        // clear sub account
        item[fieldSubAccount] = {}

        if (tax) {
          // input tax class
          item[fieldTax] = tax

          Vue.nextTick(() => {
            this.onInputTaxClass(item, dc)
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    onInputTaxClass(item, dc) {
      // 税区分入力
      // 内税 => 税込から税額計算 税込-税額で 税抜額計算
      // 外税 => 税抜から税額計算 税抜+税額で 税込額計算
      try {
        const fieldTax = this.getJournalField(dc, 'tax')
        const fieldAmount = this.getJournalField(dc, 'amount')
        const fieldAmountWithoutTax = this.getJournalField(
          dc,
          'amount_without_tax'
        )
        const fieldTaxAmount = this.getJournalField(dc, 'tax_amount')
        const fieldInputMode = this.getJournalField(dc, 'amount_input_mode')

        const tax = item[fieldTax]
        const taxRate = parseFloat(this.getTaxRate(tax))
        const inputMode = parseInt(item[fieldInputMode])

        let amount = parseFloat(currencyToNumber(item[fieldAmount]))
        let amountWithoutTax = parseFloat(
          currencyToNumber(item[fieldAmountWithoutTax])
        )
        let taxAmount = parseFloat(currencyToNumber(item[fieldTaxAmount]))

        switch (inputMode) {
          case 1:
            taxAmount = parseInt((taxRate * amountWithoutTax) / 100)
            amount = amountWithoutTax + taxAmount
            item[fieldAmount] = numberToCurrency(amount)
            item[fieldTaxAmount] = numberToCurrency(taxAmount)
            break
          case 2:
            taxAmount = parseInt((taxRate * amount) / (100 + taxRate))
            amountWithoutTax = amount - taxAmount
            item[fieldAmountWithoutTax] = numberToCurrency(amountWithoutTax)
            item[fieldTaxAmount] = numberToCurrency(taxAmount)
            break
        }
        this.$nextTick(() => {
          this.computeTaxAmount(item, dc)
        })
      } catch (e) {
        console.log(e)
      }
    },
    onInputAmount(item, dc) {
      // 税込入力 => 税込から税額計算 => 税抜額計算
      try {
        const fieldAmount = this.getJournalField(dc, 'amount')
        const fieldTaxAmount = this.getJournalField(dc, 'tax_amount')
        const fieldAmountWithoutTax = this.getJournalField(
          dc,
          'amount_without_tax'
        )
        const fieldTax = this.getJournalField(dc, 'tax')

        const amount = parseFloat(currencyToNumber(item[fieldAmount]))
        const taxRate = parseFloat(this.getTaxRate(item[fieldTax]))

        let amountWithoutTax = parseFloat(
          currencyToNumber(item[fieldAmountWithoutTax])
        )
        let taxAmount = parseFloat(currencyToNumber(item[fieldTaxAmount]))

        taxAmount = parseInt((taxRate * amount) / (100 + taxRate))
        amountWithoutTax = amount - taxAmount

        item[fieldAmountWithoutTax] = numberToCurrency(amountWithoutTax)
        item[fieldTaxAmount] = numberToCurrency(taxAmount)

        this.$nextTick(() => {
          this.computeTaxAmount(item, dc)
        })
      } catch (e) {
        console.log(e)
      }
    },
    onInputAmountWithoutTax(item, dc) {
      // 税抜入力 => 税抜から税額計算 => 税込額計算
      try {
        const fieldAmount = this.getJournalField(dc, 'amount')
        const fieldTaxAmount = this.getJournalField(dc, 'tax_amount')
        const fieldAmountWithoutTax = this.getJournalField(
          dc,
          'amount_without_tax'
        )
        const fieldTax = this.getJournalField(dc, 'tax')

        const amountWithoutTax = parseFloat(
          currencyToNumber(item[fieldAmountWithoutTax])
        )
        const taxRate = parseFloat(this.getTaxRate(item[fieldTax]))

        let amount = parseFloat(currencyToNumber(item[fieldAmount]))
        let taxAmount = parseFloat(currencyToNumber(item[fieldTaxAmount]))

        taxAmount = parseInt((taxRate * amountWithoutTax) / 100)
        amount = amountWithoutTax + taxAmount

        item[fieldAmount] = numberToCurrency(amount)
        item[fieldTaxAmount] = numberToCurrency(taxAmount)

        this.$nextTick(() => {
          this.computeTaxAmount(item, dc)
        })
      } catch (e) {
        console.log(e)
      }
    },
    onInputTaxAmount(item, dc) {
      // 税額入力
      // 内税 => 税込-税額で税抜額計算
      // 外税 => 税抜+税額で税込額計算
      try {
        const fieldAmount = this.getJournalField(dc, 'amount')
        const fieldAmountWithoutTax = this.getJournalField(
          dc,
          'amount_without_tax'
        )
        const fieldTaxAmount = this.getJournalField(dc, 'tax_amount')
        const fieldInputMode = this.getJournalField(dc, 'amount_input_mode')

        const taxAmount = parseFloat(currencyToNumber(item[fieldTaxAmount]))
        const inputMode = parseInt(item[fieldInputMode])

        let amount = parseFloat(currencyToNumber(item[fieldAmount]))
        let amountWithoutTax = parseFloat(
          currencyToNumber(item[fieldAmountWithoutTax])
        )

        // 税額入力 内税 => 税込-税額で税抜額計算 外税 => 税抜+税額で税込額計算
        switch (inputMode) {
          case 1:
            amount = amountWithoutTax + taxAmount
            item[fieldAmount] = numberToCurrency(amount)
            break
          case 2:
            amountWithoutTax = amount - taxAmount
            item[fieldAmountWithoutTax] = numberToCurrency(amountWithoutTax)
            break
        }
        this.$nextTick(() => {
          this.adjustTaxAmount(item, dc)
        })
      } catch (e) {
        console.log(e)
      }
    },
    onInputRate(journal, rateString) {
      if (!isNaN(rateString) && getDecimal(rateString) <= CURRENCY_RATE_MAX_DECIMAL) {
        for (const imageSummaryJournal of this.journals) {
          imageSummaryJournal.foreign_currency_rate = rateString
          this.caluculateForeignCurrencyAmount(imageSummaryJournal, 'debit')
          this.caluculateForeignCurrencyAmount(imageSummaryJournal, 'credit')
        }
        this.$emit('change:rate', rateString)
      } else {
        const prevValue = journal.foreign_currency_rate
        journal.foreign_currency_rate = 0
        this.$nextTick(() => {
          journal.foreign_currency_rate = prevValue
        })
      }
    },
    onInputForeignCurrencyAmount(journal, entrySide, foreignAmount) {
      const amountKey = `${entrySide}_foreign_currency_amount`
      const foreignAmountReplaced = foreignAmount.replaceAll(',', '')
      if (
        !isNaN(foreignAmountReplaced) &&
        getDecimal(foreignAmountReplaced) < 3
      ) {
        journal[amountKey] = foreignAmountReplaced
        this.caluculateForeignCurrencyAmount(journal, entrySide)
      } else {
        const prevValue = journal[amountKey]
        journal[amountKey] = 0
        this.$nextTick(() => {
          journal[amountKey] = prevValue
        })
      }
    },
    onInputIsKeikasochi(journalState, dc, value) {
      if (value) {
        this.turnOnKeikasochi(journalState, dc)
      } else {
        this.turnOffKeikasochi(journalState, dc)
      }
      this.computeTaxAmount(journalState, dc)
    },
    onInputTaxAmountAfterAdjustment(journalState, dc) {
      // 経過措置適用後の税額を入力時
      // 前提: 経過措置On
      try {
        // モデル生成
        let journal = fromJournalStateToJournal(journalState, dc, this.masterList)

        // サービス呼び出し
        journal = journalService.setTaxAmountAfterAdjustment(
          journal,
          journal.taxAmountAfterAdjustment
        )
        // 状態更新
        this.setJournalState(journalState, fromJournalToJournalState(journal, dc))
      } catch (e) {
        console.log(e)
      }
    },
    bulkTurnOnKeikasochi() {
      for (let journalState of this.value) {
        this.turnOnKeikasochi(journalState, EntrySide.DEBIT)
        this.turnOnKeikasochi(journalState, EntrySide.CREDIT)
      }
    },
    turnOnKeikasochi(journalState, dc) {
      try {
        // モデル生成
        let journal = fromJournalStateToJournal(journalState, dc, this.masterList)

        // サービス呼び出し
        journal = journalService.turnOnKeikasochi(journal, toDate(this.issueDate))

        // 状態更新
        this.setJournalState(journalState, fromJournalToJournalState(journal, dc))
      } catch (e) {
        console.error(e)
      }
    },
    bulkTurnOffKeikasochi() {
      for (let journalState of this.value) {
        this.turnOffKeikasochi(journalState, EntrySide.DEBIT)
        this.turnOffKeikasochi(journalState, EntrySide.CREDIT)
      }
    },
    turnOffKeikasochi(journalState, dc, value) {
      try {
        // モデル生成
        let journal = fromJournalStateToJournal(journalState, dc, this.masterList)

        // サービス呼び出し
        journal = journalService.turnOffKeikasochi(journal)

        // 状態更新
        this.setJournalState(journalState, fromJournalToJournalState(journal, dc))
      } catch (e) {
        console.error(e)
      }
    },
    adjustTaxAmount(journalState, dc) {
      try {
        // モデル生成
        let journal = fromJournalStateToJournal(journalState, dc, this.masterList)

        // サービス呼び出し
        journal = journalService.adjustTaxAmount(journal, this.isInvoiceIssuer, toDate(this.issueDate))

        // 状態更新
        this.setJournalState(journalState, fromJournalToJournalState(journal, dc))
      } catch (e) {
        console.log(e)
      }
    },
    bulkComputeTaxAmount() {
      for (let journalState of this.value) {
        this.computeTaxAmount(journalState, EntrySide.DEBIT)
        this.computeTaxAmount(journalState, EntrySide.CREDIT)
      }
    },
    computeTaxAmount(journalState, dc) {
      try {
        // モデル生成
        let journal = fromJournalStateToJournal(journalState, dc, this.masterList)

        // サービス呼び出し
        journal = journalService.computeTaxAmount(journal, this.isInvoiceIssuer, toDate(this.issueDate))

        // 状態更新
        this.setJournalState(journalState, fromJournalToJournalState(journal, dc))
      } catch (e) {
        console.log(e)
      }
    },
    caluculateForeignCurrencyAmount(journal, entrySide) {
      try {
        const externalTaxNum = 1
        const yenAmount = caluculateYenFromForeignAmount(
          journal,
          entrySide,
          this.currencyRate
        )
        if (journal[entrySide + '_amount_input_mode'] === externalTaxNum) {
          this.$set(journal, `${entrySide}_amount`, yenAmount)
          this.onInputAmount(journal, entrySide)
        } else {
          journal[`${entrySide}_amount_without_tax`] = yenAmount
          this.onInputAmountWithoutTax(journal, entrySide)
        }
      } catch (e) {
        console.log(e)
      }
    },
    getAccountTax(obj) {
      try {
        if (obj && obj.id) {
          const taxId = this.masterList.account_title.find(
            (item) => item.id === obj.id
          ).tax_classification_id
          const tax = this.masterList.tax.find((item) => item.id === taxId)
          return tax
        } else if (obj && obj.name) {
          const taxId = this.masterList.account_title.find(
            (item) => item.name === obj.name
          ).tax_classification_id
          const tax = this.masterList.tax.find((item) => item.id === taxId)
          return tax
        } else {
          return null
        }
      } catch (e) {
        console.log(e)
        return null
      }
    },
    getTaxRate(obj) {
      try {
        return this.masterList.tax.find((item) => item.id === obj.id).rate
      } catch (e) {
        return 0
      }
    },
    transferAmount(dc, item) {
      // 外貨対応でない場合は 0.00 を交換
      if (dc === 'debit') {
        item.debit_amount = item.credit_amount
        item.debit_foreign_currency_amount = item.credit_foreign_currency_amount
      }
      if (dc === 'credit') {
        item.credit_amount = item.debit_amount
        item.credit_foreign_currency_amount = item.debit_foreign_currency_amount
      }
      this.onInputAmount(item, dc)
    },
    onChangeJournal(event, journal) {
      try {
        onChangeJournal(event, journal)
        this.computeAmount(event.journal, event.entrySide, event.field)
      } catch (error) {
        console.log(error)
      }
    },
    _computeDescription(description) {
      if (this.editable) {
        return description
      } else {
        return computeDescription(description, this.issueDate)
      }
    },
    onClickSetAmount(payload) {
      this.setAmount(payload)
      this.$emit('change:selected-amount', payload)
    },
    setAmount(payload) {
      const entrySide = payload.dc
      if (payload.tax === 'include') {
        payload.journal[`${entrySide}_amount`] = numberToCurrency(
          payload.amount
        )
        this.onInputAmount(payload.journal, entrySide)
      } else {
        payload.journal[`${entrySide}_amount_without_tax`] = numberToCurrency(
          payload.amount
        )
        this.onInputAmountWithoutTax(payload.journal, entrySide)
      }
    },
    focusJournalSelect() {
      const ref = this.$refs.debit_iterator[0]
      if (ref) {
        ref.focusAutoComplete()
      }
    },
    onClickAllocation(journal) {
      this.$refs.allocationMenu.open(journal, this.journalType)
    },
    numberToCurrency: (amount) => numberToCurrency(amount),
    numberToCurrencyUSD: (amount) => numberToCurrencyUSD(amount),
    setFloatToCurrency(journal, key) {
      journal[key] = floatToCurrency(journal[key])
    },
    setCurrencyToNumber(journal, key) {
      if (Number(journal[key]) === 0) {
        journal[key] = ''
      } else {
        journal[key] = currencyToNumber(journal[key])
      }
    },
    toggleInputMode(journal, entrySide, inputMode) {
      journal[entrySide + '_amount_input_mode'] = inputMode
      if (this.isForeignCurrency) {
        this.caluculateForeignCurrencyAmount(journal, entrySide)
      }
    },
  },
}
</script>
