import { bankRules } from './bank'
import { emailRules } from './profile'
import { companyRules, companyPatternRules } from './company'

class Validator {
  constructor(ruleObjects) {
    for (const [key, rule] of Object.entries(ruleObjects)) {
      if (typeof rule.getRules === 'function') {
        rule.rules = rule.getRules()
      }
      this[key] = rule
    }
    Object.freeze(this)
  }
}

export default new Validator({
  email: emailRules,
  bank: bankRules,
  company: companyRules,
  companyPattern: companyPatternRules,
})
