<template>
  <v-container
    v-show="display"
    fluid
    px-0
    style="
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: white;
      z-index: 9;
      border-top: thin #ccc solid;
    "
    :style="`height: ${historyHeight}px;`"
  >
    <base-dialog
      ref="confirmCopy"
      title="過去仕訳のコピー"
      message="仕訳明細を置き換えますか？"
      :cancel-text="$t('message.no')"
      :submit-text="$t('message.yes')"
      :loading="loadingConfirm"
      @submit="updateInvoice"
    />

    <base-dialog
      ref="confirmDeleteRecord"
      title="確認"
      message="明細が削除されますが、よろしいですか?"
      cancel-text="いいえ"
      submit-text="はい"
      @submit="updateDeleteInvoice"
    />

    <v-row class="px-3">
      <v-toolbar flat height="40">
        <v-text-field
          v-model="search"
          placeholder="請求元"
          v-bind="searchTextAttrs"
        />

        <v-text-field
          v-tooltip="$t('message.filterBarTooltip')"
          :value="searchDetail"
          placeholder="簡単フィルタ"
          v-bind="searchTextAttrs"
          @input="onInputDetail($event)"
        />

        <v-btn small @click="getHistory()"> 検索 </v-btn>

        <v-spacer />

        <v-btn
          small
          depressed
          class="mr-2"
          color="grey lighten-3"
          @click="drawerHistoryExpand = !drawerHistoryExpand"
        >
          <s-icon
            :icon="
              drawerHistoryExpand
                ? 'feather-chevron-down'
                : 'feather-chevron-up'
            "
          />
        </v-btn>

        <v-btn
          small
          depressed
          color="grey lighten-3"
          @click="$emit('close-history')"
          v-text="'閉じる'"
        />
      </v-toolbar>
    </v-row>

    <v-row no-gutters class="py-3">
      <sweeep-invoice-table
        ref="sweeepInvoiceTable"
        table-id="sweeep-invoice-history"
        :items="tableDataFiltered"
        :items-per-page="10"
        :display-setting="$store.getters.displaySetting"
        :definition="$store.getters.definition"
        :height="`${historyHeight - 142}px`"
        :select-multiple="false"
        :selectable="false"
        :select-items-per-page="false"
        :sort-func="sortTableData"
        :sort-params="sortParams"
        :approve-level="$store.getters.approveLevel"
        :approve-settings="approveSettings"
        :pay-confirm-settings="payConfirmSettings"
        item-tips="この仕訳をコピー"
        :is-history="true"
        :disabled="!$store.getters.editMode"
        @click:item="onClickItem"
      />
    </v-row>

    <v-navigation-drawer
      v-model="drawerHistoryImage"
      app
      temporary
      hide-overlay
      right
      width="700"
      style="z-index: 10"
    />
  </v-container>
</template>

<script>
import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import SweeepInvoiceTable from 'Components/Table/SweeepInvoiceTable'
import { sortTableData, filterTableByText } from 'Helpers/table'
import { getInitialJournalForm } from 'Helpers/invoice/index'
import { mapGetters } from 'vuex'

import { currencyToNumber, numberToCurrency } from 'Helpers/journal'

const searchTextAttrs = Object.freeze({
  'hide-details': true,
  'append-icon': 'search',
  clearable: true,
  class: 'more-dense mr-2',
  style: 'max-width:400px;',
  dense: true,
  filled: true,
})

export default {
  components: {
    SweeepInvoiceTable,
  },
  props: {
    display: {
      type: Boolean,
      required: true,
    },
    formInvoice: {
      type: Object,
      required: true,
    },
    approveSettings: {
      type: Object,
      required: true,
    },
    payConfirmSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      searchDetail: '',
      tableData: [],
      tableDataFiltered: [],
      newJournals: [],
      selected: {},
      loadingTable: false,
      loadingConfirm: false,
      drawerHistoryExpand: false,
      drawerHistoryImage: false,
      wordQueueCount: 0,
      sortParams: {
        value: null,
        mode: null,
      },
      searchTextAttrs,
    }
  },
  computed: {
    ...mapGetters(['masterList']),
    historyHeight: function () {
      return this.drawerHistoryExpand ? 600 : 300
    },
  },
  watch: {
    display: function (value) {
      if (value) {
        this.search = this.formInvoice.name
        this.getHistory()
      }
    },
    'formInvoice.invoice_id': function (v) {
      if (this.display && v) {
        this.search = this.formInvoice.name
        this.getHistory()
      }
    },
  },
  mounted() {
    if (this.$route.params.id && this.display) {
      this.getHistory()
    }
  },
  methods: {
    getHistory() {
      try {
        this.loadingTable = true
        Axios.get(this.$store.getters.apiInvoiceListFunction, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: {
            mode: 'history',
            organization_id: this.$store.getters.getOrganizationID,
            company_name: this.search,
            exclude_id: this.formInvoice.invoice_id,
          },
        }).then((response) => {
          this.tableData = response.data.data.reverse()
          this.setFilteredData()
          this.initTablePage()
          this.loadingTable = false
        })
      } catch (e) {
        console.log(e)
      }
    },
    openDialog(name) {
      this.$refs[name].open()
    },
    closeDialog(name) {
      this.$refs[name].close()
    },
    onClickItem(item) {
      this.selected = item
      this.openDialog('confirmCopy')
    },
    updateInvoice() {
      try {
        this.loadingConfirm = true

        Axios.get(this.$store.getters.apiInvoiceSummaryJournalUrl, {
          params: {
            new_id: this.selected.id,
          },
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        }).then((response) => {
          if ('error' in response.data) {
            Snackbar.error(response.data.error)
            this.loadingConfirm = false
          } else {
            var instance = this.$props.formInvoice.image_summary_journal
            var newJournals = response.data.data
            this.newJournals = newJournals

            if (newJournals.length >= instance.length) {
              var numRoop = newJournals.length

              for (var x = 0; x < numRoop; x++) {
                if (x < instance.length) {
                  this.updateRecord(instance[x], newJournals[x])
                } else {
                  this.addRecord(instance)
                  this.updateRecord(instance[x], newJournals[x])
                }
              }
              Snackbar.success('仕訳をコピーしました。')
            } else {
              this.updateDeleteInvoice(true)
            }
            this.closeDialog('confirmCopy')
          }
          this.loadingConfirm = false
          this.$emit('history-copied')
        })
      } catch (e) {
        console.log(e)
      }
    },
    updateDeleteInvoice(dialog = false) {
      try {
        if (dialog) {
          this.openDialog('confirmDeleteRecord')
          return false
        }

        var instance = this.$props.formInvoice.image_summary_journal
        var numRoop = instance.length

        for (var i = 0; i < numRoop; i++) {
          if (i < this.newJournals.length) {
            this.updateRecord(instance[i], this.newJournals[i])
          } else {
            this.deleteRecord(instance, instance.length - 1)
          }
        }
        this.closeDialog('confirmDeleteRecord')
        Snackbar.success('仕訳をコピーしました。')
        this.closeDialog('confirmCopy')
      } catch (e) {
        console.log(e)
      }
    },
    updateRecord(record, new_record) {
      try {
        record.debit_account_title = new_record.debit_account_title
        record.debit_sub_account = new_record.debit_sub_account
        record.debit_department = new_record.debit_department
        record.debit_tax = new_record.debit_tax
        record.debit_project = new_record.debit_project
        record.debit_segment = new_record.debit_segment
        record.debit_vendor = new_record.debit_vendor
        record.debit_item = new_record.debit_item
        record.debit_tag = new_record.debit_tag
        record.debit_walletable = new_record.debit_walletable

        record.credit_account_title = new_record.credit_account_title
        record.credit_sub_account = new_record.credit_sub_account
        record.credit_department = new_record.credit_department
        record.credit_tax = new_record.credit_tax
        record.credit_project = new_record.credit_project
        record.credit_segment = new_record.credit_segment
        record.credit_vendor = new_record.credit_vendor
        record.credit_item = new_record.credit_item
        record.credit_tag = new_record.credit_tag
        record.credit_walletable = new_record.credit_walletable

        record.description = new_record.description
        record.free_text_1 = new_record.free_text_1
        record.free_text_2 = new_record.free_text_2

        this.calculateTax(record, 'debit')
        this.calculateTax(record, 'credit')
      } catch (e) {
        console.log(e)
      }
    },
    addRecord(instance) {
      try {
        let dataToAdd = getInitialJournalForm()
        instance.push(dataToAdd)
      } catch (e) {
        console.log(e)
      }
    },
    deleteRecord(instance, index) {
      try {
        instance.splice(index, 1)
      } catch (e) {
        console.log(e)
      }
    },
    sortTableData(headerValue, mode = null) {
      try {
        sortTableData(headerValue, mode, this.sortParams, this.tableData)
      } catch (error) {
        console.log(error)
      }
    },
    getTaxRate(obj) {
      try {
        return this.masterList.tax.find((item) => item.id === obj.id).rate
      } catch (e) {
        return 0
      }
    },
    calculateTax(journal, dc) {
      let fieldTax
      let fieldAmount
      let fieldAmountWithoutTax
      let fieldTaxAmount
      let fieldInputMode

      if (dc == 'debit') {
        fieldTax = 'debit_tax'
        fieldAmount = 'debit_amount'
        fieldAmountWithoutTax = 'debit_amount_without_tax'
        fieldTaxAmount = 'debit_tax_amount'
        fieldInputMode = 'debit_amount_input_mode'
      } else {
        fieldTax = 'credit_tax'
        fieldAmount = 'credit_amount'
        fieldAmountWithoutTax = 'credit_amount_without_tax'
        fieldTaxAmount = 'credit_tax_amount'
        fieldInputMode = 'credit_amount_input_mode'
      }

      const tax = journal[fieldTax]
      const taxRate = parseFloat(this.getTaxRate(tax))
      const inputMode = parseInt(journal[fieldInputMode])

      let amount = parseFloat(currencyToNumber(journal[fieldAmount]))
      let amountWithoutTax = parseFloat(
        currencyToNumber(journal[fieldAmountWithoutTax])
      )
      let taxAmount = parseFloat(currencyToNumber(journal[fieldTaxAmount]))

      switch (inputMode) {
        case 1:
          taxAmount = parseInt((taxRate * amountWithoutTax) / 100)
          amount = amountWithoutTax + taxAmount
          journal[fieldAmount] = numberToCurrency(amount)
          journal[fieldTaxAmount] = numberToCurrency(taxAmount)
          break
        case 2:
          taxAmount = parseInt((taxRate * amount) / (100 + taxRate))
          amountWithoutTax = amount - taxAmount
          journal[fieldAmountWithoutTax] = numberToCurrency(amountWithoutTax)
          journal[fieldTaxAmount] = numberToCurrency(taxAmount)
      }
    },
    setFilteredData() {
      const items = filterTableByText(this.searchDetail, this.tableData)
      this.tableDataFiltered = items
    },
    onInputDetail(value) {
      this.searchDetail = value
      this.wordQueueCount++
      setTimeout(() => {
        this.wordQueueCount--
        if (this.wordQueueCount === 0) {
          this.setFilteredData()
          this.initTablePage()
        }
      }, 100)
    },
    initTablePage() {
      this.$refs.sweeepInvoiceTable.goToPage(1)
    },
  },
}
</script>
