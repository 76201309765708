import { i18n } from '@/plugins/i18n'

export const abstractLogics = {
  isEmpty(v) {
    return !!(v && v.length)
  },
  isStringEmpty(v) {
    typeof !!(v === 'string' && v.replace(/\s/g, '').length >= 1)
  },
  isBlank(v) {
    return !(v && v.length)
  },
  isUnderCharLimit(v, count) {
    return !!(typeof v === 'string' && v.length <= count)
  },
  isOverCharLimit(v, count) {
    return !!(typeof v === 'string' && v.length >= count)
  },
  getLimitRule(name, count) {
    return (v) =>
      this.isUnderCharLimit(v, count) ||
      `${name || 'この項目'}は${count}以内で入力してください。`
  },
  getMinLimitAllowBlankRule(name, count) {
    return (v) =>
      this.isBlank(v) ||
      this.isOverCharLimit(v, count) ||
      `${name || 'この項目'}は空もしくは${count}以上で入力してください。`
  },
  getRequiredRule(name) {
    return (value) =>
      this.isEmpty(value) ||
      (name ? `${name}は必須です` : i18n.t('message.form_input_required'))
  },
}
