<template>
  <div style="white-space: nowrap">
    <v-avatar
      v-tooltip="getToolTips('読取', userUploaded, dateUploaded)"
      size="20"
      class="mr-1 grey fs-10 white--text"
      v-text="getAvaterName(userUploaded)"
    />
    <v-avatar
      v-tooltip="getToolTips('閲覧', userViewed, dateViewed)"
      size="20"
      class="mr-1 grey fs-10 white--text"
      :style="canCancelViewed ? 'cursor:pointer;' : ''"
      @click="onClickUserViewed"
      v-text="getAvaterName(userViewed)"
    />
    <v-avatar
      v-tooltip="getToolTips('更新', userUpdated, dateUpdated)"
      size="20"
      class="mr-1 grey fs-10 white--text"
      v-text="getAvaterName(userUpdated)"
    />
  </div>
</template>

<script>
export default {
  props: {
    userUploaded: {
      type: Object,
      required: true,
    },
    userViewed: {
      type: Object,
      required: true,
    },
    userUpdated: {
      type: Object,
      required: true,
    },
    dateUploaded: {
      type: String,
      required: true,
    },
    dateViewed: {
      type: String,
      required: true,
    },
    dateUpdated: {
      type: String,
      required: true,
    },
  },
  computed: {
    canCancelViewed() {
      const viewdUserOrgId = this.getId(this.userViewed)
      const loginUserOrgId = this.$store.getters.getUserOrganizationID
      return viewdUserOrgId && viewdUserOrgId === loginUserOrgId
    },
  },
  methods: {
    onClickUserViewed() {
      if (this.canCancelViewed) {
        this.$emit('click:cancelUserViewed')
      }
    },
    getToolTips(label, user, date) {
      const fullName = this.getFullName(user)
      if (fullName) {
        return `${label}ユーザ: ${fullName}<br>${label}日時: ${date}`
      } else {
        return ''
      }
    },
    getAvaterName(obj) {
      try {
        const fullName = this.getFullName(obj)
        if (fullName) {
          return fullName.slice(0, 1)
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
    getFullName(obj) {
      try {
        return obj.full_name
      } catch (e) {
        return ''
      }
    },
    getId(obj) {
      try {
        return obj.id
      } catch (e) {
        return null
      }
    },
  },
}
</script>
