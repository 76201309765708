<template>
  <div>
    <v-menu
      transition="slide-x-transition"
      :close-on-content-click="false"
      :disabled="disabled"
      @input="setDefaultCompany($event)"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-tooltip="'学習データの仕訳パターンから仕訳を呼び出します。'"
          v-bind="attrs"
          v-on="on"
        >
          <v-btn :disabled="disabled" small color="grey lighten-3" depressed>
            <span class="px-1">仕訳呼出</span>
          </v-btn>
        </div>
      </template>
      <v-card class="sweeep-dialog" width="480">
        <v-card-title>仕訳呼出</v-card-title>
        <v-card-text>
          <h5 class="mb-5">
            請求元と仕訳パターンを指定して学習データを呼び出すことができます。
          </h5>
          <v-autocomplete
            v-model="company"
            :items="companyList"
            label="請求元"
            item-text="name"
            item-value="id"
            return-object
            outlined
            dense
            hide-details
            class="mb-3"
            @change="getCompanyPatterns(company.id)"
          />
          <v-autocomplete
            v-model="companyPattern"
            :items="company.patterns"
            label="仕訳パターン"
            item-text="name"
            item-value="id"
            return-object
            outlined
            dense
            @change="setCompanyPattern(companyPattern)"
          />
          <!-- <v-radio-group :value="companyPattern.id" dense>
            <v-list-item
              v-for="pattern in company.patterns"
              :key="`company-pattern-${pattern.id}`"
            >
              <v-list-item-content>
                <v-radio
                  :value="pattern.id"
                  active-class="fw-bold"
                  :label="getPatternLabel(pattern)"
                  @change="setCompanyPattern(pattern)"
                />
              </v-list-item-content>
            </v-list-item>
          </v-radio-group> -->
          <div class="company-candidates-journal-area">
            <journal-form-header
              v-show="companyPattern.company_journals"
              no-padding
            />
            <div
              v-for="(journal, index) in companyPattern.company_journals"
              :key="`markedJournals-${index}`"
            >
              <v-row no-gutters>
                <template v-for="(entrySide, h) in ['debit', 'credit']">
                  <v-col :key="`journal_${index}_${h}`" cols="6">
                    <v-card
                      tile
                      outlined
                      class="pb-3"
                      style="background-color: #fcfcfc"
                    >
                      <div class="px-2 pt-2">
                        <journal-iterators
                          :ref="`${entrySide}_journal`"
                          :journal="journal"
                          :editable="false"
                          :entry-side="entrySide"
                        />
                      </div>
                    </v-card>
                  </v-col>
                </template>
                <v-col cols="12">
                  <v-card
                    tile
                    outlined
                    class="pa-2"
                    style="background-color: #fcfcfc"
                  >
                    <journal-text-field
                      ref="description"
                      v-model="journal.description"
                      description-field
                      :chip="
                        sliceText($store.getters.definition.description, 0, 4)
                      "
                      :editable="false"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            :disabled="!companyPattern.id"
            color="primary"
            @click="submit()"
            v-text="'セット'"
          />
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<style scoped>
.company-candidates-journal-area {
  height: 300px;
  overflow-y: scroll;
}
</style>

<script>
import { getCompanies } from '@/services/company/company'
import { getPatternLabel, getJounralName } from 'Helpers/company'
import { sliceText } from 'Helpers/journal'

import JournalIterators from 'Components/Journal/JournalIterators.vue'
import JournalFormHeader from 'Components/Journal/JournalFormHeader.vue'
import JournalTextField from 'Components/Journal/JournalTextField.vue'

export default {
  components: {
    JournalIterators,
    JournalFormHeader,
    JournalTextField,
  },
  props: {
    defaultCompany: {
      type: Object,
      default: () => {
        return {}
      },
    },
    defaultCompanyPatternId: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    companyList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      company: {},
      companyPattern: {},
    }
  },
  methods: {
    setDefaultCompany(opened) {
      if (!opened) {
        return
      }
      const company = this.defaultCompany
      const firstCompany = this.companyList[0]
      if (company && company.id) {
        this.$set(this, 'company', company)
        const defaultCompanyPattern = this.company.patterns.find(
          (pattern) => pattern.id === this.defaultCompanyPatternId
        )
        this.setCompanyPattern(defaultCompanyPattern)
      } else if (firstCompany.patterns && firstCompany.patterns[0]) {
        this.$set(this, 'company', firstCompany)
        this.setCompanyPattern(firstCompany.patterns[0])
      } else {
        this.getCompanyPatterns(firstCompany.id)
      }
    },
    async getCompanyPatterns(companyId) {
      try {
        const token = this.$store.getters.getAuthToken
        const params = { id: companyId }
        const companies = await getCompanies(token, params)
        const index = this.companyList.findIndex(
          (company) => company.id === companyId
        )
        this.$set(this, 'company', companies[0])
        this.$set(this.companyList, index, {
          id: companies[0].id,
          name: companies[0].company_name,
          address: companies[0].company_address,
          phoneNumber: companies[0].company_phonenumber,
          patterns: companies[0].patterns,
        })
        // movefunction
        this.setCompanyPattern(this.company.patterns[0])
      } catch (error) {
        console.warn(error)
      }
    },
    setCompanyPattern(pattern) {
      this.$set(this, 'companyPattern', pattern)
    },
    async submit() {
      const yes = await this.$confirm({
        message: '請求書仕訳を変更します。よろしいですか？',
      })
      if (yes) {
        this.$emit('submit', this.companyPattern.company_journals)
      }
    },
    getPatternLabel: (pattern) => getPatternLabel(pattern),
    getJounralName: (journal, attr) => getJounralName(journal, attr),
    sliceText: (text, i1, i2) => sliceText(text, i1, i2),
  },
}
</script>
