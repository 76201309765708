<template>
  <base-dialog
    ref="dialog"
    :title="$t('message.confirm')"
    :message="messages"
    :cancel-text="$t('message.modal_cancel_btn')"
    :submit-text="$t('message.change')"
    max-width="350"
    @submit="submit()"
  />
</template>

<script>
export default {
  data() {
    return {
      messages: '',
      warnNames: [],
      department: {},
    }
  },
  methods: {
    checkWarn(form) {
      this.warnNames = this.checkChangeDepartmentWarn(form)
    },
    hasWarn() {
      return this.warnNames.length > 0
    },
    open(department) {
      this.department = department

      const messageConfig = {
        approved: '最終承認済み',
        payApproved: '支払連携済み',
        paid: '支払済み',
      }
      let messages = 'この請求書は'
      this.warnNames.forEach((field, index) => {
        messages += messageConfig[field]
        if (index < this.warnNames.length - 1) {
          messages += '・'
        }
      })
      messages += 'です。\n'
      messages += '部門を変更してよろしいですか?'
      this.messages = messages
      this.$refs.dialog.open()
    },
    close() {
      this.$refs.dialog.close()
    },
    submit() {
      this.$emit('submit', this.department)
      this.close()
    },
    checkChangeDepartmentWarn(form) {
      try {
        const checkId = function (obj) {
          if (typeof obj !== 'object' || !obj) {
            return false
          }
          if (obj.id) {
            return true
          } else {
            return false
          }
        }
        const warnFields = [
          { value: 'approve_user5', name: 'approved' },
          { value: 'pay_approve_user', name: 'payApproved' },
          { value: 'paid_user', name: 'paid' },
        ]
        const warnNames = []
        warnFields.forEach((obj) => {
          if (checkId(form[obj.value])) {
            warnNames.push(obj.name)
          }
        })
        return warnNames
      } catch (err) {
        console.log(err)
        return []
      }
    },
  },
}
</script>
