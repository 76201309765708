export const createInvoiceJournal = (invoiceJournal, companyJournal) => {
  const journalFields = [
    'debit_account_title',
    'debit_department',
    'debit_vendor',
    'debit_project',
    'debit_segment',
    'debit_item',
    'debit_tag',
    'debit_walletable',
    'debit_tax',
    'credit_account_title',
    'credit_department',
    'credit_vendor',
    'credit_project',
    'credit_segment',
    'credit_item',
    'credit_tag',
    'credit_walletable',
    'credit_tax',
  ]
  for (const key of journalFields) {
    invoiceJournal[key] = companyJournal[key] || {}
  }
  invoiceJournal.debit_sub_account = companyJournal.debit_subject || {}
  invoiceJournal.credit_sub_account = companyJournal.credit_subject || {}
  invoiceJournal.description = companyJournal.description
  return invoiceJournal
}
