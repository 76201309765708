const companyJournal = {
  debit_account_title: {},
  debit_subject: {},
  debit_department: {},
  debit_vendor: {},
  debit_project: {},
  debit_segment: {},
  debit_item: {},
  debit_tag: {},
  debit_walletable: {},
  debit_tax: {},
  credit_account_title: {},
  credit_subject: {},
  credit_department: {},
  credit_vendor: {},
  credit_project: {},
  credit_segment: {},
  credit_item: {},
  credit_tag: {},
  credit_walletable: {},
  credit_tax: {},
  description: '',
}

const companyPattern = {
  id: 0,
  name: '',
  // department: {id: 0, code: ""},
  userOrganization: { id: 0, fullName: '', code: '' },
  keyword: '',
  companyId: 0,
  journals: [],
}

export const getCompanyJournalModel = () => {
  return JSON.parse(JSON.stringify(companyJournal))
}
export function getCompanyPatternModel() {
  const model = JSON.parse(JSON.stringify(companyPattern))
  model.journals.push(JSON.parse(JSON.stringify(companyJournal)))
  return model
}
