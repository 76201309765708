<template>
  <div>
    <v-menu
      v-model="showMenu"
      offset-x
      left
      z-index="99"
      transition="slide-x-transition"
      nudge-left="20"
      close-delay="300"
      :close-on-content-click="false"
      @input="$emit('input', $event)"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-tooltip="'並び替えを指定'" v-bind="attrs" v-on="on">
          {{ text }}
        </span>
      </template>

      <v-card class="sweeep-popover">
        <v-card-title> 並び替え </v-card-title>
        <slot name="select" />
        <!-- error item.value -->
        <v-list dense class="fs-11">
          <div v-for="(item, i) in items" :key="i">
            <v-list-item v-if="item.display" link @click="onSort(item)">
              <i :class="'mr-3 ' + item.icon" />
              {{ item.text }}
            </v-list-item>
          </div>
        </v-list>
        <slot name="add" />
      </v-card>
    </v-menu>
    <span v-show="showHeaderIcon" v-tooltip="{ content: tooltip }" class="ml-2">
      <span v-show="mode === 'asc'">
        <s-icon icon="ti-arrow-up" />
      </span>
      <span v-show="mode === 'desc'">
        <s-icon icon="ti-arrow-down" />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    mode: {
      validator: (v) => ['asc', 'desc', null].includes(v),
      required: true,
    },
    showHeaderIcon: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showMenu: false,
    }
  },
  methods: {
    onSort(item) {
      const value = item.value
      const mode = item.mode
      this.$emit('click:item', { value, mode })
      this.showMenu = false
    },
  },
}
</script>
