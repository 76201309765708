<template>
  <v-dialog v-model="dialog" max-width="650">
    <v-card class="sweeep-dialog freee-error-dialog">
      <v-card-title v-text="'確認'" />
      <v-card-text>{{ title }}</v-card-text>

      <v-card-text>
        <ul>
          <li v-for="(error, index) in errors" :key="index">
            {{ error }}
          </li>
        </ul>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn depressed @click.native="close">
          {{ $t('message.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      title: '',
    }
  },
  methods: {
    open(type) {
      const titles = {
        error: 'freeeへの登録に失敗しました',
        warning: '注意',
      }
      this.title = titles[type]
      this.dialog = true
    },
    close() {
      this.title = ''
      this.dialog = false
      this.$emit('close')
    },
  },
}
</script>
