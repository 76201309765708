<template>
  <v-dialog v-model="dialog" scrollable max-width="500px">
    <v-card class="sweeep-dialog">
      <v-card-title>承認依頼</v-card-title>
      <v-card-text>
        チェックしたユーザに承認依頼メールを送信することができます。<br />
        ただし、次の段階の承認権限を持たないユーザには送信できません。<br /><br />
        例）承認1をクリック → 承認2の権限保有ユーザのみ送信可能
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="approveUserOrganizations"
          item-key="id"
          fixed-header
          class="mt-3"
          :footer-props="footerProps"
          style="width: 100%"
        >
          <template #item="{ item, isSelected }">
            <tr :class="!hasPrivilege(item) ? 'grey lighten-1' : ''">
              <td style="width: 30px">
                <v-simple-checkbox
                  v-if="hasPrivilege(item)"
                  :value="isSelected"
                  @input="toggleOne(item)"
                />
              </td>
              <td>
                <span v-text="item.name" />
              </td>
            </tr>
          </template>

          <template #no-results>
            <v-alert :value="true" color="yellow" icon="warning" class="mt-3">
              {{ $t('message.noResult') }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="close"> キャンセル </v-btn>
        <v-btn color="primary" :loading="loading" @click="submit"> 実行 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: '', diplay: true, value: 'selected', sortable: false },
        { text: '名前', diplay: true, value: 'name' },
      ],
      selected: [],
      currentApproveLevel: 1,
      resource: null,
    }
  },
  computed: {
    footerProps() {
      return {
        'items-per-page-text': '表示件数',
        'items-per-page-options': [20, 50, 100],
      }
    },
    approveUserOrganizations() {
      return this.users.filter(
        (user) => user.id != this.$store.getters.getUserOrganizationID
      )
    },
  },
  methods: {
    open(resource, level) {
      this.selected = []
      this.resource = resource
      this.currentApproveLevel = level
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$emit('cancel')
    },
    submit() {
      this.$emit('submit', {
        selectedUsers: this.selected,
        resource: this.resource,
        level: this.currentApproveLevel,
      })
    },
    toggleOne(item) {
      try {
        if (this.selected.includes(item)) {
          this.selected = this.selected.filter((v) => v.id != item.id)
        } else {
          this.selected.push(item)
        }
      } catch (e) {
        console.log(e)
      }
    },
    hasPrivilege(user) {
      if (this.resource == 'invoice') {
        if (this.currentApproveLevel == 1) {
          return user.privileges.invoice.approve2
        } else if (this.currentApproveLevel == 2) {
          return user.privileges.invoice.approve3
        } else if (this.currentApproveLevel == 3) {
          return user.privileges.invoice.approve4
        } else if (this.currentApproveLevel == 4) {
          return user.privileges.invoice.approve5
        }
      } else if (this.resource == 'payment') {
        if (this.currentApproveLevel == 1) {
          return user.privileges.payment.approve2
        } else if (this.currentApproveLevel == 2) {
          return user.privileges.payment.approve3
        }
      }
      return false
    },
  },
}
</script>
