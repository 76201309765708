<template>
  <div class="py-3">
    <v-row>
      <v-col v-if="showUserBank" ref="user_bank" cols="12">
        <v-text-field
          v-model="userBank"
          label="振込元銀行"
          placeholder=" "
          dense
          hide-details
          readonly
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="settingPaidByCustomer" ref="paid_by_customer" cols="6">
        <v-checkbox
          v-model="paidByCustomer"
          label="振込手数料先方負担"
          dense
          hide-details
          class="mt-0"
          readonly
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col ref="bank_name" cols="6">
        <v-text-field
          v-model="bankName"
          label="金融機関"
          placeholder=" "
          dense
          hide-details
          readonly
        />
      </v-col>

      <v-col ref="bank_branch_name" cols="6">
        <v-text-field
          v-model="bankBranchName"
          label="支店"
          placeholder=" "
          dense
          hide-details
          readonly
        />
      </v-col>
    </v-row>
    <v-row row wrap>
      <v-col ref="bank_account_type" cols="6">
        <v-text-field
          v-model="bankAccountType"
          label="口座種別"
          placeholder=" "
          dense
          hide-details
          readonly
        />
      </v-col>

      <v-col ref="bank_account_number" cols="6">
        <v-text-field
          v-model="bankAccountNumber"
          label="口座番号"
          placeholder=" "
          dense
          hide-details
          readonly
        />
      </v-col>
    </v-row>

    <v-row row wrap>
      <!-- 口座名義 -->
      <v-col ref="bank_account_name" cols="12">
        <v-text-field
          v-model="bankAccountName"
          label="口座名義"
          placeholder=" "
          dense
          hide-details
          readonly
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    userBank: {
      type: String,
      default: '',
    },
    paidByCustomer: {
      type: Boolean,
      default: false,
    },
    bankName: {
      type: String,
      default: '',
    },
    bankBranchName: {
      type: String,
      default: '',
    },
    bankAccountType: {
      type: String,
      default: '',
    },
    bankAccountNumber: {
      type: String,
      default: '',
    },
    bankAccountName: {
      type: String,
      default: '',
    },
    markNames: {
      type: Array,
      default: () => [[]],
    },
    showUserBank: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      markedElements: [],
    }
  },
  computed: {
    ...mapGetters(['settingPaidByCustomer']),
  },
  watch: {
    markNames: {
      handler: function () {
        this.initalizeMark().then(() => {
          this.markChanged()
        })
      },
      immediate: true,
    },
  },
  methods: {
    markChanged() {
      // 各要素の背景色を変更
      try {
        let refs = this.$refs
        let t = this

        t.markNames.forEach((name) => {
          if (refs[name] !== undefined) {
            refs[name].style.backgroundColor = '#bce2e8'
            t.markedElements.push(refs[name])
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    initalizeMark() {
      let t = this
      return new Promise((resolve) => {
        // 各要素の背景色をリセット
        t.markedElements.forEach((elem) => {
          elem.style.backgroundColor = ''
        })
        t.markedElements = []
        resolve(true)
      })
    },
  },
}
</script>
