import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class LearningInvoiceClient extends APIClient {
  async post(token, { company, companyPattern, invoiceId }) {
    const payload = {
      data: {
        company,
        company_pattern: companyPattern,
        image_summary_id: invoiceId,
      },
      url: URLs.LEARNING_INVOICE,
      method: 'POST',
      headers: { Authorization: token },
    }
    const response = await this.sendRequest(payload)
    return {
      company: response.data.company,
      companyPattern: response.data.company_pattern,
    }
  }
  async put(token, { company, companyPattern, invoiceId, valuesBeingViewed }) {
    const payload = {
      data: {
        company,
        company_pattern: companyPattern,
        image_summary_id: invoiceId,
        values_being_viewed: valuesBeingViewed,
      },
      url: URLs.LEARNING_INVOICE + `/${company.id}`,
      method: 'PUT',
      headers: { Authorization: token },
    }
    const response = await this.sendRequest(payload).catch((e) => e.response)
    if (
      response.status !== 200 &&
      response.data.error !== 'Company changed since last viewed.'
    ) {
      throw response.data && response.data.error
    }

    if (response.status === 400) {
      return {
        status: 'error',
        company: response.data.detail.company,
        companyPattern: response.data.detail.company_pattern,
      }
    }
    return {
      status: 'success',
      company: response.data.company,
      companyPattern: response.data.company_pattern,
    }
  }
}

export default new LearningInvoiceClient()
