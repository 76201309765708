<template>
  <v-card tile class="h-100">
    <no-privilege-dialog ref="noPrivilegeDialog" />

    <delete-dialog ref="delete" @submit="onSubmitDelete" />
    <cannot-delete-dialog ref="cannotDelete" />
    <base-dialog
      ref="confirmRestore"
      :title="$t('message.confirm')"
      :message="'この請求書を復元しますか？'"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="'復元'"
      @submit="onSubmitRestore"
    />

    <copy-invoice-dialog ref="copy" @submit="onSubmitCopy" />
    <discard-edit-dialog
      ref="offEditMode"
      @submit="onSubmitOffEditMode(true)"
    />
    <force-update-dialog
      ref="forceUpdateDialog"
      :editing-users="editingUsers"
      @cancel="stopLoadUpdate"
      @submit="handleUpdate"
    />
    <invalid-form-dialog ref="invalidForm" />

    <v-form ref="invoiceSummaryForm">
      <invoice-top-btns
        :is-deleted="formInvoice.isDeleted"
        :department="formInvoice.department"
        :editable="editable"
        :display-mode="displayMode"
        :viewable-inbox="viewableInbox"
        :loading-view-inbox="loadingViewInbox"
        :recurring-schedule="recurringSchedule"
        :is-avoid-bank-holiday="settingAvoidBankHolidays"
        @click:toprev="onClickToPrev"
        @click:tonext="onClickToNext"
        @click:copy="onClickCopyInvoice"
        @click:edit="onClickEdit"
        @click:view-inbox="onClickViewInbox"
        @click:department="$emit('click:department')"
        @click-create-recurring-schedule="
          $emit('click-create-recurring-schedule', $event)
        "
        @click-update-recurring-schedule="
          $emit('click-update-recurring-schedule', $event)
        "
      />
      <invoice-summary-form
        ref="summary"
        :value="formInvoice"
        :hide-info="hideInfo"
        :form-type="formType"
        :editable-company-name="editMode && editablePayment"
        :editable-invoice-issuer-number="editMode"
        :editable-issue-date="editMode && editableIssueDate"
        :editable-gensenzei="editMode && editableGensenzei"
        :editable-invoice-amount="editMode && editableInvoiceAmount"
        :editable-pay-date="editMode && editablePayment"
        :editable-pay-site="editMode && editablePayment"
        :editable-pay-type="editMode && editablePayment"
        :learning-candidates="learningCandidates"
        :editable-is-foreign-currency="editMode && editablePayment"
        :is-invoice-issuer="formInvoice.isInvoiceIssuer"
        :is-foreign-currency="formInvoice.isForeignCurrency"
        :setting-foreign-currency="settingForeignCurrency"
        @toggle="toggleForeignCurrency"
        @change:pay-type="changePayType($event)"
        @change:is-invoice-issuer="changeIsInvoiceIssuer($event)"
      />
    </v-form>

    <v-container px-4 pt-5>
      <v-row no-gutters>
        <invoice-toggle-btn
          ref="invoiceToggleBtn"
          v-model="toggleTarget"
          :form-type="formType"
          :editable-invoice-journals="editableInvoiceJournals"
          :editable-payment-journals="editablePaymentJournals"
          :editable-payment="editablePayment"
          :display-mode="displayMode"
        />
        <v-spacer />
        <company-candidates
          :disabled="
            !(toggleTarget === 1 && editMode && editableInvoiceJournals)
          "
          :default-company="formInvoice.company"
          :default-company-pattern-id="formInvoice.company_pattern_id"
          :company-list="companyList"
          class="mr-3"
          @submit="setInvoiceJournalFromCompany($event)"
        />
        <v-btn
          v-shortkey="['ctrl', 'shift', 'i']"
          v-tooltip="
            '入力エリア<br>拡大／縮小<br>\
                      <span class=v-tooltip_shortcutkey>Ctrl</span> <span class=v-tooltip_shortcutkey>Shift</span> <span class=v-tooltip_shortcutkey>I</span>'
          "
          small
          color="grey lighten-3"
          depressed
          @click="hideInfo = !hideInfo"
          @shortkey="displayMode == 2 ? (hideInfo = !hideInfo) : ''"
        >
          入力エリア
          <s-icon
            class="tw-ml-1"
            :icon="hideInfo ? 'feather-chevron-down' : 'feather-chevron-up'"
          />
        </v-btn>
      </v-row>
    </v-container>

    <v-container
      px-4
      :class="{
        'sweeep-invoice-info-detail': !showFullScreen,
        'sweeep-invoice-info-detail-full': showFullScreen,
        'consentrate-mode': hideInfo,
        'gensen-mode': formInvoice.gensenzei,
      }"
    >
      <v-form
        v-if="formType !== 3"
        v-show="toggleTarget === 1"
        ref="invoiceJournalForm"
      >
        <journal-form
          ref="invoiceJournal"
          v-model="formInvoice.image_summary_journal"
          :editable="editMode && editableInvoiceJournals"
          :invoice-id-rendered="invoiceIdRendered"
          :issue-date="formInvoice.date"
          :journal-length-rendering="invoiceJournalLengthRendering"
          :learning-candidates-amount="displayCandidatesAmount"
          :learning-amount-menu-setting="learningAmountMenuSetting"
          :is-foreign-currency="formInvoice.isForeignCurrency"
          :setting-foreign-currency="settingForeignCurrency"
          :currency-code="formInvoice.currency_code"
          journal-type="invoiceJournal"
          :currency-codes="currencyCodes"
          :is-invoice-issuer="formInvoice.isInvoiceIssuer"
          @change:debit-amount="onChangeInvoiceDebitTotal"
          @change:debit-tax-amount="onChangeInvoiceDebitTaxTotal"
          @change:credit-amount="onChangeInvoiceCreditTotal"
          @change:credit-tax-amount="onChangeInvoiceCreditTaxTotal"
          @change:selected-amount="$emit('change:selected-amount', $event)"
          @change:currency-number="onChangeCurrencyNumber"
          @change:rate="saveRateString($event)"
          @submit:allocation="$emit('submit:allocation', $event)"
        />
      </v-form>

      <v-form
        v-if="formType == 1"
        v-show="toggleTarget === 2"
        ref="paymentJournalForm"
      >
        <journal-form
          ref="paymentJournal"
          v-model="formInvoice.payment_journals"
          :editable="editMode && editablePaymentJournals"
          :issue-date="formInvoice.pay_date"
          :learning-amount-menu-setting="learningAmountMenuSetting"
          :journal-length-rendering="paymentJournalLengthRendering"
          :learning-candidates-amount="displayCandidatesAmount"
          journal-type="paymentJournal"
          :form-type="formType"
          @change:selected-amount="$emit('change:selected-amount', $event)"
          @submit:allocation="$emit('submit:allocation', $event)"
        />
      </v-form>

      <v-form v-show="toggleTarget === 3" ref="paymentBankForm">
        <payment-bank-form
          ref="paymentBank"
          :value="formInvoice"
          :editable="editMode && editablePayment"
          :user-bank-list="userBankList"
          @validate="validatePaymentBankForm"
        />
      </v-form>
    </v-container>

    <invoice-footer-bar
      ref="invoiceFooter"
      :note="formInvoice.note_1"
      :total-debit="totalDebit"
      :total-debit-tax="totalDebitTax"
      :total-credit="totalCredit"
      :total-credit-tax="totalCreditTax"
      :display-total="formType !== 3"
      :is-deleted="formInvoice.isDeleted"
      :updatable="editMode && editable"
      :display-mode="displayMode"
      @click:update="onClickUpdate"
      @delete="onClickDelete"
      @restore="onClickRestore"
    />
  </v-card>
</template>

<script>
import Axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'
import { isMapObject } from 'Helpers/helpers'
import { mapGetters } from 'vuex'
import {
  numberToCurrency,
  floatToCurrency,
  currencyToNumber,
} from 'Helpers/journal'
import InvoiceToggleBtn from './components/InvoiceToggleBtn.vue'
import JournalForm from 'Components/Journal/JournalForm.vue'
import InvoiceSummaryForm from './components/InvoiceSummaryForm.vue'
import InvoiceTopBtns from './components/InvoiceTopBtns.vue'
import InvoiceFooterBar from './components/InvoiceFooterBar.vue'
import PaymentBankForm from './components/PaymentBankForm.vue'
import CompanyCandidates from './components/CompanyMenu/CompanyCandidates.vue'

// Dialogs
import CopyInvoiceDialog from 'Views/invoice/components/Dialog/CopyInvoiceDialog'
import DeleteDialog from 'Views/invoice/components/Dialog/DeleteDialog'
import CannotDeleteDialog from 'Views/invoice/components/Dialog/CannotDeleteDialog.vue'
import InvalidFormDialog from 'Views/invoice/components/Dialog/InvalidFormDialog.vue'
import DiscardEditDialog from 'Views/invoice/components/Dialog/DiscardEditDialog'
import ForceUpdateDialog from 'Views/invoice/components/Dialog/ForceUpdateDialog'

import { toDate } from 'Helpers/date'
import { getEntrySideJournalFields } from 'Helpers/journal'
import { fromJournalStatesToJournals } from 'Helpers/journal/state'
import { createInvoiceJournal } from '@/services/company/createInvoiceFromCompany'
import {
  getLatestRateString,
  saveRateString,
  getLatestCurrency,
  saveCurrency,
  getCurrencyCodes,
} from '@/services/invoice/foreignAmount'
import {
  journalLockMode,
  isJournalLocked,
} from '@/services/invoice/journalLock'
import { journalService, EntrySide } from '@/services/invoice/journal'

const targetNumRefName = Object.freeze({
  1: 'invoiceJournal',
  2: 'paymentJournal',
  3: 'paymentBank',
})

const formTypeObject = Object.freeze({
  journal: 1,
  paymentJournal: 2,
  payment: 3,
})

export default {
  components: {
    InvoiceToggleBtn,
    InvoiceSummaryForm,
    JournalForm,
    PaymentBankForm,
    InvoiceTopBtns,
    InvoiceFooterBar,
    CopyInvoiceDialog,
    DeleteDialog,
    DiscardEditDialog,
    ForceUpdateDialog,
    CannotDeleteDialog,
    InvalidFormDialog,
    CompanyCandidates,
  },
  props: {
    formInvoice: {
      type: Object,
      required: true,
    },
    recurringSchedule: {
      type: Object,
      required: true,
    },
    invoiceList: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    filterResultCount: {
      type: Object,
      required: true,
    },
    invoiceIdRendered: {
      type: Number,
      default: 0,
    },
    invoiceJournalLengthRendering: {
      type: Number,
      required: true,
    },
    paymentJournalLengthRendering: {
      type: Number,
      required: true,
    },
    learningCandidates: {
      validator: (prop) => isMapObject(prop),
      default() {
        return {}
      },
    },
    displayMode: {
      type: Number,
      required: true,
    },
    userBankList: {
      type: Array,
      required: true,
    },
    learningAmountMenuSetting: {
      type: Object,
      required: true,
    },
    formType: {
      type: Number,
      required: true,
    },
    companyList: {
      type: Array,
      required: true,
    },
    showFullScreen: {
      type: Boolean,
      default: false,
    },
    settingForeignCurrency: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let t = this
    return {
      learningWayCode: 0,
      toggleTarget: 1,
      hideInfo: false,
      loading_payment: false,
      loading_journalItems: false,
      loadingFreeBtn: false,
      loadingViewInbox: false,
      fromTable: false,
      new_company: false,
      spotFlag: false,
      loaded: false,
      onLoadAccount: false,
      toLearn: true,
      nextInstance: true,
      prevInstance: true,
      gensenzei: false,
      menu: false,
      menu2: false,
      modal: false,
      approved: null,

      increaseMonth: 0,
      nameRules: [
        (v) => !!v || t.$t('message.form_input_required'),
        (v) =>
          (v && v.replace(/\s/g, '').length >= 1) ||
          t.$t('message.form_input_required'),
        (v) => v.length < 40 || t.$t('message.invalid_input'),
      ],
      noteRules: [
        (v) => v.length < 255 || '255' + t.$t('message.character_max_length'),
      ],
      amountRules: [(v) => v.length <= 20 || t.$t('message.invalid_input')],
      changedValues: [],

      dragSelectMonth: '',
      isDragOverSelectMonth: false,

      totalDebit: 0,
      totalCredit: 0,
      totalDebitTax: 0,
      totalCreditTax: 0,
      rewardAmount: 0,
      gensenAmount: 0,
      companyPatterns: [],
      formTypeObject,
      currencyCodes: [],
    }
  },
  computed: {
    ...mapGetters([
      'editMode',
      'editingUsers',
      'editedInvoiceFormFields',
      'displaySetting',
      'definition',
      'invoicePrivilege',
      'ownCompanyNames',
      'contactNumberList',
      'approveLevel',
      'settingJournalLockMode',
      'settingAvoidBankHolidays',
    ]),
    isDeleted: function () {
      return this.formInvoice.isDeleted
    },
    editable: function () {
      return (
        !this.isDeleted &&
        (this.editableInvoiceJournals || this.editablePayment)
      )
    },
    editableNote: function () {
      return this.invoicePrivilege.update_note ? true : false
    },
    editableInvoiceJournals: function () {
      return (
        this.invoicePrivilege.update &&
        !isJournalLocked(this.formInvoice, this.invoicePrivilege, this.settingJournalLockMode) &&
        [1, 2].includes(this.formType)
      )
    },
    editablePaymentJournals: function () {
      return this.editablePayment && this.formType === 1
    },
    editablePayment: function () {
      return (
        this.invoicePrivilege.update &&
        !this.formInvoice.pay_approved &&
        !this.formInvoice.paid
      )
    },
    editableIssueDate: function () {
      if (this.formType === 3) {
        return this.editablePayment
      } else {
        return this.editableInvoiceJournals
      }
    },
    editableGensenzei: function () {
      if (this.formType === 3) {
        return this.editablePayment
      } else {
        return this.editablePayment && this.editableInvoiceJournals
      }
    },
    editableInvoiceAmount: function () {
      return this.formType === 3 && this.editablePayment
    },
    payAmount: function () {
      return this.totalDebit - this.gensenAmount
    },
    invoiceJournalsChanged: function () {
      return this.$store.getters.editedInvoiceFormFields.some((obj) => {
        return obj.value.includes('image_summary_journal')
      })
    },
    paymentJournalsChanged: function () {
      return this.$store.getters.editedInvoiceFormFields.some((obj) => {
        return obj.value.includes('payment_journals')
      })
    },
    bankChanged: function () {
      return this.$store.getters.editedInvoiceFormFields.some((obj) => {
        return [
          'bank_name',
          'bank_branch_name',
          'bank_account_type',
          'bank_account_number',
          'bank_account_name',
        ].includes(obj.value)
      })
    },
    viewableInbox: function () {
      return Boolean(
        this.checkPrivilege('inbox:read') && this.formInvoice.inboxId
      )
    },
    displayCandidatesAmount: function () {
      try {
        if (this.learningCandidates.amountList.length === 0) {
          return []
        }
        const displayCandidates = []
        const invoiceJournals = this.formInvoice.image_summary_journal
        const paymentJournals = this.formInvoice.payment_journals
        const journals = invoiceJournals.concat(paymentJournals)
        for (const obj of this.learningCandidates.amountList) {
          const id = obj.id
          const amount = Number(obj.object)
          const xCoordinate = Number(obj.x1_coordinate)
          const yCoordinate = Number(obj.y1_coordinate)
          const imageSummaryJournal =
            journals.find(
              (journal) => journal.id === obj.image_summary_journal_id
            ) || obj.image_summary_journal
          const journalType = invoiceJournals.includes(imageSummaryJournal)
            ? 'invoiceJournal'
            : 'paymentJournal'
          const journalIndex =
            journalType === 'invoiceJournal'
              ? invoiceJournals.findIndex(
                  (journal) => journal === imageSummaryJournal
                )
              : paymentJournals.findIndex(
                  (journal) => journal === imageSummaryJournal
                )
          const newAttr = {
            id,
            amount,
            amountText: numberToCurrency(amount),
            page: obj.page,
            xCoordinate,
            yCoordinate,
            imageSummaryJournal,
            journalIndex,
            journalType,
          }
          displayCandidates.push(newAttr)
        }
        return displayCandidates
      } catch (e) {
        return []
      }
    },
    invoiceCompanyPattern() {
      const invoiceCompanyPatternId = this.formInvoice.company_pattern_id
      if (invoiceCompanyPatternId) {
        return this.formInvoice.companyPatterns.find(
          (v) => v.id === invoiceCompanyPatternId
        )
      } else {
        return {}
      }
    },
  },
  watch: {
    formType: {
      handler: function (v) {
        switch (v) {
          case 1:
            this.toggleTarget = 1
            break
          case 2:
            this.toggleTarget = 1
            break
          case 3:
            this.toggleTarget = 3
            break
        }
      },
      immediate: true,
    },
    toggleTarget: function (toggleTarget) {
      const targetRefName = targetNumRefName[toggleTarget]
      if (this.editMode) {
        this.$nextTick(() => {
          this.focusTargetElement(targetRefName)
        })
      }
    },
    editMode: function (isEditMode) {
      const targetRefName =
        this.hideInfo || !isEditMode
          ? targetNumRefName[this.toggleTarget]
          : 'summary'
      if (isEditMode) {
        this.$nextTick(() => {
          this.focusTargetElement(targetRefName)
        })
      } else {
        this.$nextTick(() => {
          this.blurTargetElement(targetRefName)
        })
      }
    },
  },
  created() {
    getCurrencyCodes(this.$store.getters.getAuthToken).then(
      (currencyCodesByPriority) => {
        this.$set(this, 'currencyCodes', currencyCodesByPriority)
      }
    )
  },
  methods: {
    focusTargetElement(refName) {
      const ref = this.$refs[refName]
      if (!ref) {
        return
      }
      switch (refName) {
        case 'invoiceJournal':
          ref.focusJournalSelect()
          break
        case 'paymentJournal':
          ref.focusJournalSelect()
          break
        case 'paymentBank':
          ref.focusBankName()
          break
        case 'summary':
          ref.focusOnCompanyName()
          break
      }
    },
    blurTargetElement(refName) {
      const ref = this.$refs[refName]
      if (!ref) {
        return
      }
      switch (refName) {
        case 'paymentBank':
          ref.blurBank()
          break
        case 'summary':
          ref.blurOnCompanyName()
          break
      }
    },
    stopLoadUpdate() {
      this.$refs.invoiceFooter.stopLoad()
    },
    onClickUpdate() {
      if (this.$store.getters.loadingInvoiceDetail) {
        this.stopLoadUpdate()
        return
      }
      if (!this.editable) {
        this.stopLoadUpdate()
        return
      }
      if (!this.validate()) {
        this.$refs.invalidForm.open()
        this.stopLoadUpdate()
        return
      }
      this.$store
        .dispatch('getEditingUsers', this.$route.params.id)
        .then((exists) => {
          if (exists) {
            this.$refs.forceUpdateDialog.open()
          } else {
            this.handleUpdate()
          }
        })
    },
    onClickCopyInvoice() {
      this.openDialog('copy')
    },
    onClickDelete() {
      if (!this.invoicePrivilege.delete) {
        this.$refs.noPrivilegeDialog.open()
        return
      }
      if (this.hasSomeApproved()) {
        this.openDialog('cannotDelete')
        return
      }
      this.openDialog('delete')
    },
    onClickRestore() {
      this.openDialog('confirmRestore')
    },
    onSubmitDelete() {
      this.deleteInvoice()
      this.closeDialog('delete')
      this.$store.dispatch('offEditMode')
    },
    onSubmitRestore() {
      this.restoreInvoice()
      this.closeDialog('confirmRestore')
    },
    onSubmitCopy() {
      this.copyInvoice()
      this.closeDialog('copy')
    },
    onClickEdit() {
      try {
        if (!this.editMode) {
          this.$emit('click:edit')
        } else {
          this.onSubmitOffEditMode()
        }
      } catch (e) {
        console.log(e)
      }
    },
    onSubmitOffEditMode(force = false) {
      if (!this.editMode) {
        return
      }
      if (force) {
        this.$emit('get:invoice', false)
        this.$store.commit('setEditedInvoiceFormFields', [])
        this.$store.dispatch('offEditMode')
      } else if (this.$store.getters.editedInvoiceForm) {
        this.openDialog('offEditMode')
      } else {
        this.$emit('get:invoice', false)
        this.$store.commit('setEditedInvoiceFormFields', [])
        this.$store.dispatch('offEditMode')
      }
    },
    onClickToPrev() {
      this.changeInvoice('prev')
    },
    onClickToNext() {
      this.changeInvoice('next')
    },
    onChangeInvoiceDebitTotal(amount) {
      this.totalDebit = amount
      this.formInvoice.amount = amount
    },
    onChangeInvoiceDebitTaxTotal(amount) {
      this.totalDebitTax = amount
      this.formInvoice.tax_amount = amount
    },
    onChangeInvoiceCreditTotal(amount) {
      this.totalCredit = amount
    },
    onChangeInvoiceCreditTaxTotal(amount) {
      this.totalCreditTax = amount
    },
    onChangeCurrencyNumber(currency) {
      saveCurrency(currency)
      this.$set(this.formInvoice, 'currency_code', currency)
    },
    onInvalidPaymentApprove() {
      this.$refs.invalidForm.open()
    },
    openDialog(ref) {
      this.$refs[ref].open()
    },
    closeDialog(ref) {
      this.$refs[ref].close()
    },
    onClickViewInbox() {
      this.loadingViewInbox = true
      this.$router.push('/inbox/' + this.formInvoice.inboxId)
    },
    validate() {
      let validate = this.$refs.invoiceSummaryForm.validate()
      let editedStatus = this.$refs.invoiceToggleBtn.getEditedStatus()

      //編集された項目があるFormのみValidate
      if (editedStatus.invoiceJournal && this.editableInvoiceJournals) {
        validate = validate && this.$refs.invoiceJournalForm.validate()
      }
      if (editedStatus.paymentJournal && this.editablePayment) {
        validate = validate && this.$refs.paymentJournalForm.validate()
      }
      if (editedStatus.paymentBank && this.editablePayment) {
        validate = validate && this.$refs.paymentBankForm.validate()
      }

      return validate
    },
    validatePaymentBankForm() {
      return this.$refs.paymentBankForm.validate()
    },
    resetValidation() {
      this.$refs.invoiceSummaryForm.resetValidation()
      switch (this.formType) {
        case 1:
          // 仕訳 + 支払 + 支払仕訳
          this.$refs.paymentBankForm.resetValidation()
          this.$refs.paymentJournalForm.resetValidation()
          this.$refs.invoiceJournalForm.resetValidation()
          break
        case 2:
          // 仕訳 + 支払
          this.$refs.paymentBankForm.resetValidation()
          this.$refs.invoiceJournalForm.resetValidation()
          break
        case 3:
          // 支払
          this.$refs.paymentBankForm.resetValidation()
          break
      }
    },
    async handleUpdate() {
      try {
        if (this.totalDebit !== this.totalCredit) {
          Snackbar.error('貸借で金額が一致しません')
          this.stopLoadUpdate()
          return
        }
        if (
          this.formInvoice.isForeignCurrency &&
          !this.formInvoice.currency_code
        ) {
          Snackbar.error('通貨を選択してください')
          this.stopLoadUpdate()
          return
        }
        if (
          this.formInvoice.isForeignCurrency &&
          !this.formInvoice.image_summary_journal[0].foreign_currency_rate
        ) {
          Snackbar.error('レートを入力してください')
          this.stopLoadUpdate()
          return
        }
        if (
          this.formInvoice.isForeignCurrency &&
          this.formInvoice.image_summary_journal[0].foreign_currency_rate <= 0.0
        ) {
          Snackbar.error('レートは0.00より大きい必要があります')
          this.stopLoadUpdate()
          return
        }
        this.submitUpdate()
      } catch (e) {
        console.log(e)
      }
    },
    checkApproved(approveUser) {
      try {
        if (!approveUser) {
          return false
        }
        if (typeof approveUser !== 'object') {
          return false
        }
        if (typeof approveUser.full_name !== 'string') {
          return false
        }
        if (approveUser.full_name.length === 0) {
          return false
        }
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    hasSomeApproved() {
      const userKeys = [
        'pay_confirm_user',
        'pay_confirm_user2',
        'pay_confirm_user3',
        'pay_approve_user',
        'paid_user',
      ]
      let level = 5
      for (let i = 0; i < this.approveLevel; i++) {
        userKeys.push(`approve_user${level}`)
        level--
      }

      const t = this
      return userKeys.some((key) => {
        return t.checkApproved(t.formInvoice[key])
      })
    },
    submitUpdate() {
      const t = this
      const checkAutoCreatePaymentJournals = function () {
        // 以下の場合のみ支払仕訳を自動生成
        // 未最終承認 & 未支払連携 & (ユーザが請求仕訳を変更 or ユーザが源泉税額を変更) & ユーザが支払仕訳を変更していない
        const gensenChanged = t.$store.getters.editedInvoiceFormFields.some(
          (obj) => {
            return obj.value.includes('gensen_amount')
          }
        )
        let autoCreatePaymentJournals = false
        if (
          t.editableInvoiceJournals &&
          t.editablePayment &&
          (gensenChanged || t.invoiceJournalsChanged) &&
          !t.paymentJournalsChanged
        ) {
          autoCreatePaymentJournals = true
        }
        return autoCreatePaymentJournals
      }
      try {
        const trimAmount = (journal, key) => {
          if (!journal[key]) {
            journal[key] = '0'
          } else if (typeof journal[key] === 'number') {
            journal[key] = String(journal[key])
          } else {
            journal[key] = journal[key].replaceAll(',', '').trim() || '0'
          }
        }
        if (this.formInvoice.image_summary_journal) {
          for (
            let x = 0;
            x < this.formInvoice.image_summary_journal.length;
            x++
          ) {
            var record = this.formInvoice.image_summary_journal[x]
            for (var key in record) {
              record[key] = record[key] == null ? '' : record[key]
            }
            record.credit_amount = record.credit_amount.replace(/,/g, '').trim()
            record.credit_tax_amount = record.credit_tax_amount
              .replace(/,/g, '')
              .trim()
            record.debit_amount = record.debit_amount.replace(/,/g, '').trim()
            record.debit_tax_amount = record.debit_tax_amount
              .replace(/,/g, '')
              .trim()
            record.foreign_currency_rate = record.foreign_currency_rate || 0
            trimAmount(record, 'debit_foreign_currency_amount')
            trimAmount(record, 'credit_foreign_currency_amount')
          }
        }

        if (this.formType === 3) {
          this.formInvoice.tax_amount = 0
        }

        if (!this.formInvoice.gensenzei) {
          this.formInvoice.gensenCode = ''
          this.formInvoice.gensenTotalTargets = 0
        }

        const getCurrencyCodeNum = () => {
          const JPY = 392
          return (
            (this.formInvoice.currency_code &&
              this.formInvoice.currency_code.currency_number) ||
            JPY
          )
        }
        const data = {
          id: this.formInvoice.invoice_id,
          window_token: this.$store.getters.windowToken,

          company_name: this.formInvoice.name,
          is_invoice_issuer: this.formInvoice.isInvoiceIssuer,
          invoice_issuer_number: this.formInvoice.invoiceIssuerNumber,
          issue_date: this.formInvoice.date,
          invoice_amount: this.formInvoice.amount,
          tax_amount: this.formInvoice.tax_amount,
          reward_amount: this.formInvoice.reward_amount,
          gensen_amount: this.formInvoice.gensen_amount,
          gensenzei: this.formInvoice.gensenzei,
          gensen_code: this.formInvoice.gensenCode,
          gensen_total_targets: parseInt(this.formInvoice.gensenTotalTargets),
          invoice_journals: this.formInvoice.image_summary_journal.map(j => {
            // 経過措置の場合, APIとUIで持ち方が異なるため, ここで変換
            const journalPayload = Object.assign({}, j)
            if (j.debit_is_keikasochi) {
              journalPayload.debit_amount_without_tax_before_adjustment = j.debit_amount_without_tax
              journalPayload.debit_tax_amount_before_adjustment = j.debit_tax_amount
              journalPayload.debit_amount_without_tax = j.debit_amount_without_tax_after_adjustment
              journalPayload.debit_tax_amount = j.debit_tax_amount_after_adjustment
            }
            if (j.credit_is_keikasochi) {
              journalPayload.credit_amount_without_tax_before_adjustment = j.credit_amount_without_tax
              journalPayload.credit_tax_amount_before_adjustment = j.credit_tax_amount
              journalPayload.credit_amount_without_tax = j.credit_amount_without_tax_after_adjustment
              journalPayload.credit_tax_amount = j.credit_tax_amount_after_adjustment
            }
            return journalPayload
          }),
          learning_pay_site: this.formInvoice.learning_pay_site,
          currency_code_id: getCurrencyCodeNum(),
          pay_type: this.formInvoice.pay_type,
          pay_date: this.formInvoice.pay_date,
          pay_month: this.formInvoice.pay_month,
          pay_day: this.formInvoice.pay_day,
          user_bank: this.formInvoice.user_bank,
          paid_by_customer: this.formInvoice.paid_by_customer,
          bank_name: this.formInvoice.bank_name
            ? this.formInvoice.bank_name
            : '',
          bank_branch_name: this.formInvoice.bank_branch_name
            ? this.formInvoice.bank_branch_name
            : '',
          bank_account_name: this.formInvoice.bank_account_name,
          bank_account_number: this.formInvoice.bank_account_number,
          bank_account_type: this.formInvoice.bank_account_type || '',
          payment_journals: this.formInvoice.payment_journals,
          payment_note1: this.formInvoice.payment_note1,
          payment_note2: this.formInvoice.payment_note2,
          payment_note3: this.formInvoice.payment_note3,
          auto_create_payment_journals: checkAutoCreatePaymentJournals(),
          image_contents: this.displayCandidatesAmount,
        }
        this.$store.dispatch('editInvoice', { data }).then((response) => {
          this.stopLoadUpdate()
          if (response.data.status == 'success') {
            const updatedInfo = response.data.updated_info
            var msgs = []
            if (updatedInfo.updated_invoice) {
              msgs.push('請求書情報が更新されました。')
            }
            if (updatedInfo.updated_invoice_journals) {
              msgs.push('仕訳が更新されました。')
            }
            if (updatedInfo.updated_payment) {
              msgs.push('支払情報が更新されました。')
            }
            if (updatedInfo.updated_payment_journals) {
              if (updatedInfo.auto_updated_payment_journals) {
                msgs.push('支払仕訳が自動更新されました。')
              } else {
                msgs.push('支払仕訳が更新されました。')
              }
            }
            Snackbar.success(msgs.join('\n'))

            this.$emit('updated:invoice')

            this.updateTable(this.formInvoice.invoice_id, data, response)
            this.$store.commit('setEditedInvoiceFormFields', [])
            this.$store.dispatch('onEditMode')

            const isNoTaxAmountAdjustedWhenKeikasochi = journalService.isNoTaxAmountAdjustedWhenKeikasochi(
              fromJournalStatesToJournals(this.formInvoice.image_summary_journal, EntrySide.DEBIT),
              this.formInvoice.isInvoiceIssuer,
              toDate(this.formInvoice.date),
              { tax: [] },
            )
            if (isNoTaxAmountAdjustedWhenKeikasochi) {
              // 直後に出すと成功トースターが表示されないため
              setTimeout(() => {
                Snackbar.error("非適格請求書に対して経過措置対象の仕訳明細がありません")
              }, 1000)
            }
          } else {
            const messages = {
              cannot_update_amount:
                '最終承認済み, または支払連携済みの場合, 金額を変更できません',
              invalid_amount: '貸借で金額が一致しません',
              invalid_bank: '金融機関が不適切です',
              invalid_branch: '支店が不適切です'
            }
            const error = response.data.error
            const message = error ? messages[error] : ''
            if (message) {
              Snackbar.error(message)
            } else {
              Snackbar.error(
                'エラーが発生しました。サポートにお知らせください。' + error
              )
            }
          }
        })
      } catch (e) {
        this.stopLoadUpdate()
        console.warn(e)
      }
    },
    updateTable(invoice_id, form, response) {
      try {
        var index = this.tableData.findIndex((x) => x.id == invoice_id)
        var item = this.tableData[index]
        var first_record = form.invoice_journals[0]
        const user_org_id = this.$store.getters.getUserOrganizationID
        const updatedInfo = response.data.updated_info

        item.company_name = form.company_name
        item.is_invoice_issuer = form.is_invoice_issuer
        item.invoice_issuer_number = form.invoice_issuer_number
        item.issue_date = form.issue_date
        item.pay_date = form.pay_date
        item.pay_type = form.pay_type
        item.debit_amount_total = form.invoice_amount
        item.debit_tax_amount_total = form.tax_amount
        item.gensenzei = form.gensenzei ? 1 : 0
        item.gensen_amount = form.gensen_amount
        item.description = first_record.description
        item.free_text_1 = first_record.free_text_1
        item.free_text_2 = first_record.free_text_2
        item.journal_row_count = form.invoice_journals.length
        item.update_user = {
          id: user_org_id,
          full_name: response.data.user_name,
        }
        item.user_update_bank = updatedInfo.updated_payment
          ? item.update_user
          : {}
        item.date_update = response.data.date
        item.date_update_bank = updatedInfo.updated_payment
          ? item.date_update
          : ''
        item.pay_amount = form.pay_amount
        item.payment_note1 = form.payment_note1
        item.payment_note2 = form.payment_note2
        item.payment_note3 = form.payment_note3
        const currencyCodeObj = this.currencyCodes.find(
          (code) => code.currency_number === form.currency_code_id
        )
        item.currency_code =
          (currencyCodeObj && currencyCodeObj.currency_code) || ''
        item.foreign_currency_amount = floatToCurrency(
          form.invoice_journals.reduce((total, journal) => {
            return total + Number(journal.debit_foreign_currency_amount)
          }, 0)
        )

        item.bank_data = {
          bank_code: this.$store.getters.getBankCode(form.bank_name),
          bank_name: form.bank_name,
          branch_code: this.$store.getters.getBankBranchCode(
            form.bank_name,
            form.bank_branch_name
          ),
          branch_name: form.bank_branch_name,
          bank_account_type: form.bank_account_type,
          bank_account_name: form.bank_account_name,
          bank_account_number: form.bank_account_number,
        }

        var journals = []

        for (let i = 0; i < form.invoice_journals.length; i++) {
          var record = form.invoice_journals[i]
          let journal = {
            description: record.description,
            free_text_1: record.free_text_1,
            free_text_2: record.free_text_2,

            debit_amount: currencyToNumber(record.debit_amount),
            debit_account_title: record.debit_account_title,
            debit_sub_account: record.debit_sub_account,
            debit_department: record.debit_department,
            debit_vendor: record.debit_vendor,
            debit_project: record.debit_project,
            debit_segment: record.debit_segment,
            debit_walletable: record.debit_walletable,
            debit_item: record.debit_item,
            debit_tag: record.debit_tag,
            debit_tax_class: record.debit_tax,

            credit_amount: currencyToNumber(record.credit_amount),
            credit_account_title: record.credit_account_title,
            credit_sub_account: record.credit_sub_account,
            credit_department: record.credit_department,
            credit_vendor: record.credit_vendor,
            credit_project: record.credit_project,
            credit_segment: record.credit_segment,
            credit_walletable: record.credit_walletable,
            credit_item: record.credit_item,
            credit_tag: record.credit_tag,
            credit_tax_class: record.credit_tax,
          }

          if (i == 0) {
            //Update invoicetable journals
            item = Object.assign(item, journal)
          }
          journals.push(journal)
        }
        item.journal = journals

        //バッジのカウント−1
        if (!item.updated) {
          this.changeFilterResultCount('not_updated', -1)
        }
      } catch (e) {
        console.log(e)
      }
    },
    changeFilterResultCount(key, val) {
      if (this.filterResultCount[key]) {
        this.filterResultCount[key] += val
      }
    },
    deleteInvoice() {
      try {
        const data = {
          image_summary_id: this.formInvoice.invoice_id,
        }
        this.$store.dispatch('deleteInvoice', { data }).then((response) => {
          if (response.data.status == 'success') {
            if (response.data.exists_copy_invoices) {
              Snackbar.success(
                this.$t('message.invoice_delete_multiple_reference')
              )
            } else {
              Snackbar.success(this.$t('message.invoice_delete_success'))
            }
            this.$emit('deleted', this.formInvoice.invoice_id)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    restoreInvoice() {
      try {
        const data = {
          image_summary_id: this.formInvoice.invoice_id,
        }
        this.$store.dispatch('restoreInvoice', { data }).then((response) => {
          if (response.data.status == 'success') {
            Snackbar.success(this.$t('message.invoice_restore_success'))
            this.$emit('restored', this.formInvoice.invoice_id)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    changeInvoice(type) {
      try {
        if (this.loadingEditUser) {
          return false
        }
        var changedLocalId = this.getChangedLocalId(
          this.formInvoice.local_id,
          type
        )
        if (!changedLocalId) {
          return false
        }
        this.$emit('select:invoice', changedLocalId, true)
      } catch (e) {
        console.log(e)
      }
    },
    getChangedLocalId(localId, type) {
      var index = this.invoiceList.findIndex((x) => x.image_local_id == localId)
      if (type === 'next') {
        index += 1
      } else if (type === 'prev') {
        index -= 1
      } else {
        return null
      }
      if (index < 0 || index > this.invoiceList.length - 1) {
        return null
      }
      localId = this.invoiceList[index].image_local_id
      return localId
    },
    checkNextPrevInstance() {
      const localId = this.$route.params.id
      var nextId = this.getChangedLocalId(localId, 'next')
      var prevId = this.getChangedLocalId(localId, 'prev')
      if (nextId) {
        this.nextInstance = true
      } else {
        this.nextInstance = false
      }
      if (prevId) {
        this.prevInstance = true
      } else {
        this.prevInstance = false
      }
    },
    copyInvoice() {
      try {
        let url = this.$store.getters.apiInvoiceCopyFunction
        let data = {
          org_id: this.$store.getters.getOrganizationID,
          image_summary_id: this.formInvoice.invoice_id,
        }

        Axios.post(url, data, {
          headers: { Authorization: this.$store.getters.getAuthToken },
        }).then((response) => {
          if (response.data.status == 'success') {
            this.$emit('get:table', false)
            this.$emit('get:invoice', false)
            Snackbar.success('成功しました')
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    setInvoiceJournalFromCompany(companyJournals) {
      const journalFields = getEntrySideJournalFields()
      journalFields.push('description')
      const invoiceJournals = this.formInvoice.image_summary_journal
      const diff = invoiceJournals.length - companyJournals.length
      if (diff > 0) {
        invoiceJournals.splice(invoiceJournals.length - diff, diff)
      }
      for (const [index, journal] of Object.entries(companyJournals)) {
        if (invoiceJournals[index] === undefined) {
          const newJournal = JSON.parse(JSON.stringify(invoiceJournals[0]))
          newJournal.credit_amount = '0'
          newJournal.credit_amount_without_tax = '0'
          newJournal.credit_amount_input_mode = 2
          newJournal.debit_amount = '0'
          newJournal.debit_amount_without_tax = '0'
          newJournal.debit_amount_input_mode = 2
          invoiceJournals.push(newJournal)
        }
        const updateJournal = createInvoiceJournal(
          invoiceJournals[index],
          journal
        )
        this.$set(this.formInvoice.image_summary_journal, index, updateJournal)
        this.$refs.invoiceJournal.onInputTaxClass(updateJournal, 'debit')
        this.$refs.invoiceJournal.onInputTaxClass(updateJournal, 'credit')
      }
    },
    toggleForeignCurrency() {
      this.formInvoice.isForeignCurrency = !this.formInvoice.isForeignCurrency

      const JPY = {
        currency_code: 'JPY',
        currency_number: 392,
      }
      const USD = this.currencyCodes[0]
      const curreny = this.formInvoice.isForeignCurrency
        ? getLatestCurrency() || USD
        : JPY
      this.$set(this.formInvoice, 'currency_code', curreny)

      const foreignCurrencyRate = getLatestRateString()
      for (const journal of this.formInvoice.image_summary_journal) {
        if (this.formInvoice.isForeignCurrency) {
          journal.credit_amount = '0'
          journal.credit_amount_without_tax = '0'
          journal.credit_tax_amount = '0'
          journal.debit_amount = '0'
          journal.debit_amount_without_tax = '0'
          journal.debit_tax_amount = '0'
          journal.foreign_currency_rate = foreignCurrencyRate
        } else {
          this.$set(journal, 'debit_foreign_currency_amount', 0)
          this.$set(journal, 'credit_foreign_currency_amount', 0)
          this.$set(journal, 'foreign_currency_rate', 0.0)
        }
      }
    },
    saveRateString: (rate) => saveRateString(rate),
    changePayType(payType) {
      this.$set(this.formInvoice, 'pay_type', payType)
      const foreignTransferNum = 6
      if (
        payType === foreignTransferNum &&
        this.settingForeignCurrency &&
        !this.formInvoice.isForeignCurrency
      ) {
        this.toggleForeignCurrency()
      }
    },
    changeIsInvoiceIssuer(isInvoiceIssuer) {
      this.$set(this.formInvoice, 'isInvoiceIssuer', isInvoiceIssuer)
    },
  },
}
</script>
