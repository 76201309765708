<template>
  <v-container v-show="!hideInfo" px-4 pb-0 class="sweeep-invoice-info-base">
    <v-row no-gutters>
      <v-col cols="6">
        <v-text-field
          ref="focusOnCompanyName"
          v-model="value.name"
          v-tooltip="value.name.length > 19 ? value.name : ''"
          label="請求元"
          placeholder=" "
          :readonly="!editableCompanyName"
          :rules="nameRules"
          class="details-up"
          tabindex="1"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          ref="focusOninvoiceIssuerNumber"
          v-model="value.invoiceIssuerNumber"
          label="インボイス番号"
          prefix="T"
          placeholder=" "
          hide-details
          :readonly="!editableInvoiceIssuerNumber"
          :rules="invoiceIssuerNumberRules"
          class="details-up"
          tabindex="1"
        />
      </v-col>
      <v-col cols="2">
        <div>
          <a
            class="tw-text-xs"
            target="_blank"
            :href="`https://www.invoice-kohyo.nta.go.jp/regno-search/detail?selRegNo=${value.invoiceIssuerNumber}`">
            番号照会
          </a>
        </div>
        <div>
          <div class="d-flex pt-2">
            <v-simple-checkbox
              :value="isInvoiceIssuer"
              :disabled="!editableInvoiceIssuerNumber"
              @input="onChangeIsInvoiceIssuer"
            />
            <div class="fs-10 pt-1">適格請求</div>
          </div>
        </div>
      </v-col>
      <v-chip
        v-if="!value.company.id"
        x-small
        label
        outlined
        color="blue"
        style="position: absolute; left: 55px; margin-top: 4px"
        v-text="'新規の取引先です'"
      />
    </v-row>

    <v-row no-gutters>
      <template>
        <v-col cols="3">
          <date-pick-input
            :value="value.date"
            label="伝票日付"
            :flat="false"
            :editable="editableIssueDate"
            :rules="rules"
            hide-details
            @select="selectDate($event)"
          />
        </v-col>
        <v-col cols="3">
          <date-pick-input
            :value="value.pay_date"
            label="支払日付"
            :flat="false"
            :editable="editablePayDate"
            :rules="!settingJournal ? rules : []"
            hide-details
            @select="selectPayDate($event)"
          />
        </v-col>
        <div
          style="
            position: absolute;
            margin-top: 2px;
            left: 200px;
            display: inherit;
          "
        >
          <div
            :style="{ color: value.learning_pay_site ? '#4CAF50' : '#c9c9c9' }"
            @click="onClickLearningPaySiteMode"
          >
            <s-icon
              v-if="editablePaySite"
              tooltip="支払サイトを学習します"
              icon="zmdi zmdi-graduation-cap"
              color="current"
            />
          </div>

          <div class="ml-3">
            <v-menu
              v-if="editablePaySite"
              offset-x
              z-index="99"
              nudge-right="10"
              transition="slide-x-transition"
              open-on-hover
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <s-icon icon="feather-edit" />
                </div>
              </template>

              <v-list dense class="fs-11">
                <v-list-item
                  link
                  @click="onClickSetPayDate(0, 31)"
                  v-text="'当月末'"
                />
                <v-list-item
                  link
                  @click="onClickSetPayDate(1, 31)"
                  v-text="'翌月末'"
                />
                <v-list-item
                  link
                  @click="onClickSetPayDate(2, 31)"
                  v-text="'翌々月末'"
                />
                <v-divider />
                <v-list-item
                  link
                  @click="onClickSetPayDate(1, 5)"
                  v-text="'翌月 05日'"
                />
                <v-list-item
                  link
                  @click="onClickSetPayDate(1, 10)"
                  v-text="'翌月 10日'"
                />
                <v-list-item
                  link
                  @click="onClickSetPayDate(1, 15)"
                  v-text="'翌月 15日'"
                />
                <v-list-item
                  link
                  @click="onClickSetPayDate(1, 20)"
                  v-text="'翌月 20日'"
                />
                <v-list-item
                  link
                  @click="onClickSetPayDate(1, 25)"
                  v-text="'翌月 25日'"
                />
                <v-divider />
                <v-list-item
                  link
                  @click="onClickSetPayDate(2, 5)"
                  v-text="'翌々月 05日'"
                />
                <v-list-item
                  link
                  @click="onClickSetPayDate(2, 10)"
                  v-text="'翌々月 10日'"
                />
                <v-list-item
                  link
                  @click="onClickSetPayDate(2, 15)"
                  v-text="'翌々月 15日'"
                />
                <v-list-item
                  link
                  @click="onClickSetPayDate(2, 20)"
                  v-text="'翌々月 20日'"
                />
                <v-list-item
                  link
                  @click="onClickSetPayDate(2, 25)"
                  v-text="'翌々月 25日'"
                />
              </v-list>
            </v-menu>
          </div>
        </div>
        <v-col cols="2">
          <v-autocomplete
            v-show="value.learning_pay_site"
            v-model="value.pay_month"
            :items="payMonthList"
            label="支払サイト/月"
            append-icon=""
            placeholder=" "
            item-text="name"
            item-value="value"
            hide-details
            readonly
          />
        </v-col>
        <v-col cols="1" class="pl-0">
          <v-autocomplete
            v-show="value.learning_pay_site"
            v-model="value.pay_day"
            :items="payDayList"
            label="日"
            append-icon=""
            placeholder=" "
            item-text="name"
            item-value="value"
            hide-details
            readonly
          />
        </v-col>
        <v-col cols="3">
          <template>
            <v-autocomplete
              :value="value.pay_type"
              :items="payTypes"
              label="支払方法"
              append-icon=""
              placeholder=" "
              item-text="name"
              item-value="id"
              class="small-clearable"
              :readonly="!editablePayType"
              :rules="!settingJournal ? payTypeRules : []"
              hide-details
              tabindex="1"
              @change="$emit('change:pay-type', $event)"
            />
          </template>
        </v-col>
      </template>
      <template>
        <v-col cols="3">
          <amount-input
            v-model="value.amount"
            v-tooltip="formType !== 3 && $t('message.invoice_amount_edit_hint')"
            label="請求金額"
            class="mt-2"
            :rules="amountRules"
            :readonly="!editableInvoiceAmount"
            tabindex="1"
          />
        </v-col>
        <v-col cols="3">
          <amount-input
            v-model="value.pay_amount"
            v-tooltip="formType !== 3 && $t('message.invoice_amount_edit_hint')"
            label="支払金額"
            class="mt-2"
            :rules="amountRules"
            readonly
            tabindex="1"
          />
        </v-col>
        <v-col cols="3">
          <amount-input
            v-model="value.gensen_amount"
            label="源泉税額"
            class="mt-2"
            :rules="amountRules"
            :readonly="!editableInvoiceAmount"
            tabindex="1"
          />
          <amount-input
            v-show="value.gensenzei"
            v-model="value.reward_amount"
            v-tooltip="{ placement: 'left', content: '源泉対象の報酬額' }"
            dense
            :rules="amountRules"
            :readonly="!editablePayType || !value.gensenzei"
            tabindex="1"
          />
        </v-col>
        <v-col cols="3" class="px-5 pt-3">
          <v-menu
            v-model="menuGensen"
            offset-y
            top
            nudge-top="15"
            nudge-left="364"
            z-index="99"
            transition="slide-y-transition"
            :close-on-content-click="false"
            :open-on-click="value.gensenzei ? true : false"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  x-small
                  outlined
                  :class="value.gensenzei ? 'blue' : ''"
                  :dark="value.gensenzei"
                  @click="onClickGensenzei"
                  v-text="'源泉'"
                />
                <span v-show="value.gensenzei">
                  <s-icon class="px-1" icon="feather-edit" />
                </span>
              </div>
            </template>

            <v-card class="sweeep-popover" width="480">
              <v-card-title v-text="'源泉税'" />

              <v-card-text>
                <gensen-form :value="value" :editable="editableGensenzei" />
              </v-card-text>
            </v-card>
          </v-menu>
          <div v-if="settingForeignCurrency" class="d-flex pt-2">
            <v-simple-checkbox
              :value="isForeignCurrency"
              :disabled="!editableIsForeignCurrency"
              @input="toggle()"
            />
            <div class="fs-10 pt-1">外貨入力</div>
          </div>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<style>
.details-up .v-text-field__details {
  position: absolute;
  margin-top: 13px;
}
</style>

<script>
import Vue from 'vue'
import DatePickInput from 'Components/Input/DatePickInput'
import AmountInput from 'Components/Input/AmountInput'
import GensenForm from '../components/GensenForm.vue'
import { getPayDate } from 'Helpers/invoice'
import { currencyToNumber, calcGensenAmount } from 'Helpers/journal'
import { monthDiff } from 'Helpers/date'
import { mapGetters } from 'vuex'

export default {
  components: {
    DatePickInput,
    AmountInput,
    GensenForm,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: null,
    },
    formType: {
      type: Number,
      required: true,
    },
    hideInfo: {
      type: Boolean,
      default: false,
    },
    editableCompanyName: {
      type: Boolean,
      default: false,
    },
    editableInvoiceIssuerNumber: {
      type: Boolean,
      default: false,
    },
    editableIssueDate: {
      type: Boolean,
      default: false,
    },
    editablePayDate: {
      type: Boolean,
      default: false,
    },
    editablePaySite: {
      type: Boolean,
      default: false,
    },
    editablePayType: {
      type: Boolean,
      default: false,
    },
    editableGensenzei: {
      type: Boolean,
      default: false,
    },
    editableInvoiceAmount: {
      type: Boolean,
      default: false,
    },
    learningCandidates: {
      type: Object,
      default() {},
    },
    isInvoiceIssuer: {
      type: Boolean,
      default: false,
    },
    isForeignCurrency: {
      type: Boolean,
      default: false,
    },
    settingForeignCurrency: {
      type: Boolean,
      default: false,
    },
    editableIsForeignCurrency: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuJounalDate: false,
      payTypes: [
        { id: 1, name: '振込' },
        { id: 2, name: '引落' },
        { id: 3, name: 'クレジット' },
        { id: 4, name: '手形' },
        { id: 5, name: '現金' },
        { id: 6, name: '海外送金' },
        { id: 0, name: 'その他' },
      ],
      payMonthList: [],
      payDayList: [],
      menuGensen: false,
      rules: [(v) => !!v || this.$t('message.form_input_required')],
      payTypeRules: [(v) => !!v || v === 0 || this.$t('message.form_input_required')],
      nameRules: [
        (v) => !!v || this.$t('message.form_input_required'),
        (v) =>
          (v && v.replace(/\s/g, '').length >= 1) ||
          this.$t('message.form_input_required'),
        (v) => v.length < 40 || this.$t('message.invalid_input'),
      ],
      invoiceIssuerNumberRules: [
        (v) => !v || v.replace(/\d/g, '').length == 0 || this.$t('message.invalid_input'),
        (v) => !v || v.length == 13 || this.$t('message.invalid_input'),
        (v) => !v || v[0] !== '0' || this.$t('message.invalid_input'),
      ],
      amountRules: [(v) => v.length <= 20 || this.$t('message.invalid_input')],
    }
  },
  computed: {
    ...mapGetters(['settingJournal']),
  },
  watch: {
    'value.amount': function () {
      this.computePayAmount()
    },
    'value.tax_amount': function () {
      this.computePayAmount()
    },
    'value.gensen_amount': function () {
      this.computePayAmount('gensen_amount')
    },
    'value.reward_amount': function () {
      this.computePayAmount('reward_amount')
    },
  },
  mounted() {
    this.setPaySiteItems()
  },
  methods: {
    onClickGensenzei() {
      if (this.editableGensenzei) {
        this.value.gensenzei = !this.value.gensenzei
        Vue.nextTick(() => {
          this.computePayAmount()
        })
      }
    },
    onClickSetPayDate(pay_month, pay_day) {
      try {
        if (this.editablePayDate) {
          if (this.value.learning_pay_site) {
            this.value.pay_month = pay_month
            this.value.pay_day = pay_day
          }
          this.value.pay_date = getPayDate(this.value.date, pay_month, pay_day)
        }
      } catch (e) {
        console.log(e)
      }
    },
    onClickLearningPaySiteMode() {
      if (this.editablePaySite) {
        this.value.learning_pay_site = !this.value.learning_pay_site
        if (this.value.learning_pay_site) {
          this.computePaySite()
        } else {
          this.value.pay_day = null
          this.value.pay_month = null
        }
      }
    },
    setPaySiteItems() {
      try {
        for (let i = 0; i <= 12; i++) {
          if (i == 0) {
            this.payMonthList.push({ name: '当月', value: i })
          } else {
            this.payMonthList.push({ name: i + 'ヶ月後の', value: i })
          }
        }
        for (let i = 31; i >= 1; i--) {
          if (i == 31) {
            this.payDayList.push({ name: '月末', value: i })
          } else {
            this.payDayList.push({ name: i + '日', value: i })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    getPayTypeName(payType) {
      try {
        let payObj = this.payTypes.filter((v) => {
          if (payType === v.id) {
            return true
          }
        })
        return payObj[0].name
      } catch (e) {
        return ''
      }
    },
    selectDate(event) {
      this.value.date = event
      if (this.value.learning_pay_site) {
        const formInvoice = this.value
        const payDay = formInvoice.pay_day
        const payMonth = formInvoice.pay_month
        this.value.pay_date = getPayDate(this.value.date, payMonth, payDay)
      }
    },
    selectPayDate(event) {
      this.value.pay_date = event
      if (this.value.learning_pay_site) {
        this.computePaySite()
      }
    },
    computePaySite() {
      try {
        Vue.nextTick(() => {
          this.value.pay_month = ''
          this.value.pay_day = ''
          if (this.value.pay_date) {
            var issue_date = new Date(this.value.date)
            var pay_date = new Date(this.value.pay_date)
            var site_month = monthDiff(issue_date, pay_date)
            var site_day = pay_date.getDate()
            var end_of_month = new Date(
              pay_date.getFullYear(),
              pay_date.getMonth() + 1,
              0
            )
            var end_day = end_of_month.getDate()
            if (end_day == site_day) {
              site_day = 31
            }
            this.value.pay_month = site_month
            this.value.pay_day = site_day
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    computePayAmount(input_name = '') {
      try {
        if (this.$store.getters.loadingInvoiceDetail) {
          return
        }
        var invoiceAmount = this.value.amount
        var invoiceTaxAmount = this.value.tax_amount
        var rewardAmount, gensenAmount, payAmount

        if (!this.value.gensenzei) {
          this.value.reward_amount = null
          this.value.gensen_amount = null
          this.value.pay_amount = invoiceAmount
        } else {
          if (!input_name) {
            rewardAmount = invoiceAmount - invoiceTaxAmount
            gensenAmount = calcGensenAmount(rewardAmount)
            if (this.value.gensenTotalTargets < 1) {
              this.value.gensenTotalTargets = 1
            }
          } else if (input_name === 'reward_amount') {
            rewardAmount = currencyToNumber(this.value.reward_amount)
            gensenAmount = calcGensenAmount(rewardAmount)
          } else if (input_name === 'gensen_amount') {
            rewardAmount = currencyToNumber(this.value.reward_amount)
            gensenAmount = currencyToNumber(this.value.gensen_amount)
          }

          payAmount = invoiceAmount - gensenAmount

          this.value.reward_amount = rewardAmount
          this.value.gensen_amount = gensenAmount
          this.value.pay_amount = payAmount
        }
      } catch (e) {
        console.log(e)
      }
    },
    getDepartmentToolTip(obj) {
      try {
        return obj.name ? obj.name + ' ' + obj.code : ''
      } catch (e) {
        return ''
      }
    },
    focusOnCompanyName() {
      this.$refs.focusOnCompanyName.focus()
    },
    blurOnCompanyName() {
      this.$refs.focusOnCompanyName.blur()
    },
    toggle() {
      this.$emit('toggle')
    },
    onChangeIsInvoiceIssuer(v) {
      this.$emit('change:is-invoice-issuer', v)
    },
  },
}
</script>
