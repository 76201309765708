import CompanyListClient from '@/clients/company/companyList'
import CompaniesClient from '@/clients/company/companies'
import { AsYouType } from 'libphonenumber-js'

export const companyListService = {
  async get(token, orgId, cb = null) {
    const defaultCallbacK = (company) => {
      company.company_phonenumber = new AsYouType('JP').input(
        company.company_phonenumber
      )
      return company
    }
    const companies = await CompanyListClient.get({ token, orgId }).catch(
      () => []
    )
    return companies.map((company) =>
      cb ? cb(company) : defaultCallbacK(company)
    )
  },
  async getByCompanyView(token, orgId) {
    function callback(company) {
      company.company_phonenumber = new AsYouType('JP').input(
        company.company_phonenumber
      )
      company.selected = false
      return company
    }
    return await this.get(token, orgId, callback)
  },
  async delete({ token, ids }) {
    return await CompaniesClient.delete({ token, ids })
  },
}
