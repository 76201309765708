import { currencyToNumber, numberToCurrency } from 'Helpers/journal'
import { EntrySide, Journal, TTax, TJournal, TMasterList } from '@/services/invoice/journal'

export type TJournalState = {
  debit_account_title: string,
  debit_sub_account: string,
  debit_department: string,
  debit_vendor: string,
  debit_project: string,
  debit_segment: string,
  debit_item: string,
  debit_tag: string,
  debit_walletable: string,
  debit_tax: TTax,
  debit_amount: string,
  debit_amount_without_tax: string,
  debit_amount_without_tax_after_adjustment: string,
  debit_tax_amount: string,
  debit_tax_amount_after_adjustment: string,
  debit_amount_input_mode: number,
  debit_is_keikasochi: boolean,
  credit_account_title: string,
  credit_sub_account: string,
  credit_department: string,
  credit_vendor: string,
  credit_project: string,
  credit_segment: string,
  credit_item: string,
  credit_tag: string,
  credit_walletable: string,
  credit_tax: TTax,
  credit_amount: string,
  credit_amount_without_tax: string,
  credit_amount_without_tax_after_adjustment: string,
  credit_tax_amount: string,
  credit_tax_amount_after_adjustment: string,
  credit_amount_input_mode: number,
  credit_is_keikasochi: boolean,
  foreign_currency_amount: string,
  foreign_currency_rate: string,
}

export const amountStringToFloat = (amountStr) => {
  return parseFloat(currencyToNumber(amountStr))
}

export const journalStateFields = {
  debit: {
    account_title: 'debit_account_title',
    sub_account: 'debit_sub_account',
    department: 'debit_department',
    vendor: 'debit_vendor',
    project: 'debit_project',
    segment: 'debit_segment',
    item: 'debit_item',
    tag: 'debit_tag',
    walletable: 'debit_walletable',
    tax: 'debit_tax',
    amount: 'debit_amount',
    amount_without_tax: 'debit_amount_without_tax',
    amount_without_tax_after_adjustment: 'debit_amount_without_tax_after_adjustment',
    tax_amount: 'debit_tax_amount',
    tax_amount_after_adjustment: 'debit_tax_amount_after_adjustment',
    amount_input_mode: 'debit_amount_input_mode',
    is_keikasochi: 'debit_is_keikasochi',
  },
  credit: {
    account_title: 'credit_account_title',
    sub_account: 'credit_sub_account',
    department: 'credit_department',
    vendor: 'credit_vendor',
    project: 'credit_project',
    segment: 'credit_segment',
    item: 'credit_item',
    tag: 'credit_tag',
    walletable: 'credit_walletable',
    tax: 'credit_tax',
    tax_amount_after_adjustment: 'credit_tax_amount_after_adjustment',
    amount: 'credit_amount',
    amount_without_tax: 'credit_amount_without_tax',
    amount_without_tax_after_adjustment: 'credit_amount_without_tax_after_adjustment',
    tax_amount: 'credit_tax_amount',
    amount_input_mode: 'credit_amount_input_mode',
    is_keikasochi: 'credit_is_keikasochi',
  },
  foreign_currency_amount: 'foreign_currency_amount',
  foreign_currency_rate: 'foreign_currency_rate',
}

export const fromJournalStateToJournal = (
  journalState: TJournalState,
  dc: EntrySide,
  masterList: TMasterList,
): Journal => {
  const f = journalStateFields[dc]
  return new Journal({
    amount: amountStringToFloat(journalState[f.amount]),
    taxAmount: amountStringToFloat(journalState[f.tax_amount]),
    taxAmountAfterAdjustment: amountStringToFloat(journalState[f.tax_amount_after_adjustment]),
    amountWithoutTax: amountStringToFloat(journalState[f.amount_without_tax]),
    amountWithoutTaxAfterAdjustment: amountStringToFloat(journalState[f.amount_without_tax_after_adjustment]),
    isKeikasochi: journalState[f.is_keikasochi],
    amountInputMode: parseInt(journalState[f.amount_input_mode]),
    getTaxRate: (): number => {
      try {
        const taxId = parseInt(journalState[f.tax].id)
        const tax = masterList.tax.find((tax) => tax.id === taxId)
        // @ts-ignore
        // 数値以外の型の可能性を考慮して変換
        return parseFloat(tax? tax.rate: 0)
      } catch (e) {
        return 0
      }
    },
  })
}

export const fromJournalStatesToJournals = (
  journalStates: Array<TJournalState>,
  dc: EntrySide,
  masterList: TMasterList,
):  Array<Journal> => {
  return journalStates.map(js => fromJournalStateToJournal(js, dc, masterList))
}

export const fromJournalToJournalState = (journal: TJournal, dc: EntrySide): Partial<TJournalState> => {
  const f = journalStateFields[dc]
  const journalState = {}
  journalState[f.amount] = numberToCurrency(journal.amount)
  journalState[f.tax_amount] = numberToCurrency(journal.taxAmount)
  journalState[f.tax_amount_after_adjustment] = numberToCurrency(journal.taxAmountAfterAdjustment)
  journalState[f.amount_without_tax] = numberToCurrency(journal.amountWithoutTax)
  journalState[f.amount_without_tax_after_adjustment] = numberToCurrency(journal.amountWithoutTaxAfterAdjustment)
  journalState[f.is_keikasochi] = journal.isKeikasochi
  return journalState
}
