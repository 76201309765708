<template>
  <div>
    <base-dialog
      ref="dialogConfirm"
      title="確認"
      message="按分前金額と按分後金額が一致しません。このまま処理を続けますか？"
      :cancel-text="$t('message.modal_no_btn')"
      :submit-text="$t('message.modal_yes_btn')"
      @submit="
        submitJournals()
        $refs.dialogConfirm.close()
      "
    />
    <v-menu
      v-model="menu"
      min-width="90%"
      max-width="90%"
      transition="slide-y-transition"
      :position-x="positionX"
      :position-y="positionY"
      :close-on-content-click="false"
      :close-on-click="false"
    >
      <v-card class="sweeep-popover">
        <v-card-title> 部門按分 </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col md="3" lg="2">
              <vue-perfect-scrollbar>
                <v-list class="px-2" :height="'calc(100vh - 270px)'">
                  <v-checkbox
                    v-model="selectedAllDepartments"
                    dense
                    hide-details
                    :label="'すべての部門を選択'"
                    class="mt-0 mb-5"
                    @change="onClickAllDepartments"
                  />
                  <v-checkbox
                    v-for="department in departmentList"
                    :key="department.id"
                    v-model="selectedDepartments"
                    dense
                    hide-details
                    :label="department.name"
                    :value="department"
                    class="mt-0 mb-1"
                    @click.self="onClickDepartment(department)"
                    @click.shift="onShiftClickDepartment(department)"
                  />
                </v-list>
              </vue-perfect-scrollbar>
            </v-col>
            <v-col md="9" lg="10" class="pl-3">
              <v-data-table
                :headers="beforeHeaders"
                :items="beforeItems"
                disable-sort
                hide-default-footer
              >
                <template #item="{ item }">
                  <tr>
                    <td>
                      <s-chip-dark
                        v-if="item.dc === 'debit'"
                        color="blue-500"
                        class="px-1"
                      >
                        借方
                      </s-chip-dark>
                      <s-chip-dark
                        v-if="item.dc === 'credit'"
                        color="green-500"
                        class="px-1"
                      >
                        貸方
                      </s-chip-dark>
                    </td>
                    <td v-for="field in displayJournalFields" :key="field">
                      {{ item[field].name }}
                    </td>
                    <td>
                      {{ item.amount | currency }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-row no-gutters>
                <v-col>
                  <v-row no-gutters>
                    <v-checkbox
                      v-model="targets"
                      value="debit"
                      label="借方を按分"
                      dense
                      hide-details
                      class="mr-8 py-2"
                      @change="onClickTarget('debit')"
                    />
                    <v-checkbox
                      v-model="targets"
                      value="credit"
                      label="貸方を按分"
                      dense
                      hide-details
                      class="py-2"
                      @change="onClickTarget('credit')"
                    />
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-toolbar flat bottom>
                  <v-spacer />
                  <span v-show="targets.includes('debit')">
                    <s-chip class="px-1 mr-1"> 按分後借方合計 </s-chip>
                    <span class="fs-12">
                      {{ afterDebitTotalAmount | currency }}
                    </span>
                  </span>
                  <span v-show="debitDifferenceAmount !== 0">
                    <s-chip-dark
                      color="gray-300"
                      class="ml-2 px-2 tw-border-none tw-text-gray-800"
                    >
                      差 {{ debitDifferenceAmount | currency }}
                    </s-chip-dark>
                  </span>
                  <span v-show="targets.includes('credit')">
                    <s-chip class="px-1 mr-1 ml-3"> 按分後貸方合計 </s-chip>
                    <span class="fs-12">
                      {{ afterCreditTotalAmount | currency }}
                    </span>
                  </span>
                  <span v-show="creditDifferenceAmount !== 0">
                    <s-chip-dark
                      color="gray-300"
                      class="ml-2 px-2 tw-border-none tw-text-gray-800"
                    >
                      差 {{ creditDifferenceAmount | currency }}
                    </s-chip-dark>
                  </span>

                  <v-btn width="120px" class="ml-4" @click="allocateEqually()">
                    均等に按分
                  </v-btn>
                  <v-btn width="120px" class="ml-2" @click="allocateManually">
                    割合から計算
                  </v-btn>
                </v-toolbar>
              </v-row>
              <v-data-table
                :headers="afterHeaders"
                :items="afterItems"
                :items-per-page="999"
                disable-sort
                hide-default-footer
                fixed-header
                no-data-text=""
                :height="'calc(100vh - 500px)'"
              >
                <template #item="{ item }">
                  <tr>
                    <td>
                      <s-chip-dark
                        v-if="item.dc === 'debit'"
                        color="blue-500"
                        class="px-1"
                      >
                        借方
                      </s-chip-dark>
                      <s-chip-dark
                        v-if="item.dc === 'credit'"
                        color="green-500"
                        class="px-1"
                      >
                        貸方
                      </s-chip-dark>
                    </td>
                    <td
                      v-for="(field, index) in displayJournalFields"
                      :key="field"
                    >
                      <template v-if="field === 'department'">
                        {{ item.department.name }}
                      </template>
                      <allocation-journal-select
                        v-else
                        :value="item[field]"
                        :items="getMasterList(field, item)"
                        :display-name-key="getNamekey(field)"
                        :field-name="`${item.dc}_${field}`"
                        :tabindex="String(100 + index)"
                        @change="changeJournal($event, item, field)"
                      />
                    </td>
                    <td>
                      <amount-input
                        v-model="item.amount"
                        dense
                        reverse
                        tabindex="110"
                      />
                    </td>
                    <td>
                      <amount-input
                        v-model="item.proportion"
                        dense
                        reverse
                        tabindex="111"
                        :rules="[rules.proportion]"
                      />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click.native="close"> キャンセル </v-btn>
          <v-btn
            color="primary"
            :disabled="afterItems.length <= 1"
            @click="submit"
          >
            実行
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  currencyToNumber,
  numberToCurrency,
  journalFields,
} from 'Helpers/journal'
import AmountInput from 'Components/Input/AmountInput.vue'
import AllocationJournalSelect from 'Components/Journal/AllocationJournalSelect.vue'

export default {
  components: {
    AmountInput,
    AllocationJournalSelect,
  },
  data() {
    return {
      menu: false,
      journalFields,
      journal: {},
      journalType: '',
      selectedAllDepartments: false,
      selectedDepartments: [],
      targets: ['debit'],
      rules: {
        proportion: (v) => v.replace(/^[0-9.,]*/, '').length == 0,
      },
      beforeItems: [],
      afterItems: [],
    }
  },
  computed: {
    ...mapGetters(['displaySetting', 'definition', 'masterList']),
    positionX: function () {
      return window.innerWidth / 20
    },
    positionY: function () {
      return window.innerHeight / 20
    },
    departmentList: function () {
      return this.masterList.department.filter((item) => item.status == 1)
    },
    displayJournalFields: function () {
      const displayJournalFields = []
      for (const field of journalFields) {
        if (this.displaySetting[field]) {
          displayJournalFields.push(field)
        }
      }
      return displayJournalFields
    },
    beforeHeaders: function () {
      let headers = [
        {
          text: '貸借',
          value: 'dc',
          width: '50px',
        },
        {
          text: '税込金額',
          value: 'amount',
          width: '100px',
        },
      ]
      for (const field of this.displayJournalFields) {
        let addField = {}
        addField.text = this.definition[field]
        addField.value = field
        addField.width = '100px'
        headers.splice(-1, 0, addField)
      }
      return headers
    },
    afterHeaders: function () {
      const addHeader = {
        text: '割合',
        value: 'proportion',
        width: '100px',
      }
      return this.beforeHeaders.concat(addHeader)
    },
    beforeItemsDebit: function () {
      return this.beforeItems.length === 0 ? {} : this.beforeItems[0]
    },
    beforeItemsCredit: function () {
      return this.beforeItems.length === 0 ? {} : this.beforeItems[1]
    },
    afterItemsDebit: function () {
      return this.afterItems.filter((item) => item.dc === 'debit')
    },
    afterItemsCredit: function () {
      return this.afterItems.filter((item) => item.dc === 'credit')
    },
    beforeDebitTotalAmount: function () {
      return this.beforeItems.length === 0 ? 0 : this.beforeItemsDebit.amount
    },
    beforeCreditTotalAmount: function () {
      return this.beforeItems.length === 0 ? 0 : this.beforeItemsCredit.amount
    },
    afterDebitTotalAmount: function () {
      return this.afterItemsDebit.reduce((x, item) => x + item.amount, 0)
    },
    afterCreditTotalAmount: function () {
      return this.afterItemsCredit.reduce((x, item) => x + item.amount, 0)
    },
    debitDifferenceAmount: function () {
      if (this.targets.includes('debit')) {
        return this.afterDebitTotalAmount - this.beforeDebitTotalAmount
      } else {
        return 0
      }
    },
    creditDifferenceAmount: function () {
      if (this.targets.includes('credit')) {
        return this.afterCreditTotalAmount - this.beforeCreditTotalAmount
      } else {
        return 0
      }
    },
  },
  watch: {
    selectedDepartments: function (newArray, oldArray) {
      if (newArray.length > oldArray.length) {
        const addDepartment = newArray.filter(
          (department) => !oldArray.includes(department)
        )
        for (const department of addDepartment) {
          for (const target of this.targets) {
            let addObject =
              target === 'debit'
                ? { ...this.beforeItemsDebit }
                : { ...this.beforeItemsCredit }
            addObject.department = department
            addObject.proportion = 1
            this.afterItems.push(addObject)
          }
        }
      } else {
        const filterItems = this.afterItems.filter((item) =>
          newArray.includes(item.department)
        )
        this.afterItems = filterItems
      }
      this.allocateEqually()
      this.sortItems()

      if (this.selectedDepartments.length === this.departmentList.length) {
        this.selectedAllDepartments = true
      } else {
        this.selectedAllDepartments = false
      }
    },
  },
  methods: {
    open(journal, journalType) {
      this.selectedDepartments = []
      this.targets = ['debit']
      this.journal = journal
      this.journalType = journalType
      this.setBeforeItems()
      this.menu = true
    },
    close() {
      this.menu = false
    },
    getNamekey(field) {
      if (field === 'sub_account' || field === 'project') {
        return 'name_code'
      } else if (field === 'tax') {
        return 'rate_name_code'
      } else {
        return 'display_name'
      }
    },
    getMasterList(field, item) {
      if (field === 'sub_account') {
        return this.getSubjectList(item.account_title)
      } else {
        return this.masterList[field].filter((item) => item.status == 1)
      }
    },
    getSubjectList(obj) {
      try {
        let subjects = this.masterList.account_title.find(
          (item) => item.id == obj.id
        ).subjects
        return subjects.filter((item) => item.status == 1)
      } catch (e) {
        return []
      }
    },
    onClickAllDepartments() {
      if (this.selectedAllDepartments) {
        this.selectedDepartments = [...this.departmentList]
      } else {
        this.selectedDepartments = []
      }
    },
    onClickDepartment(department) {
      if (this.selectedDepartments.includes(department)) {
        const index = this.selectedDepartments.findIndex(
          (obj) => obj.id === department.id
        )
        this.selectedDepartments.splice(index, 1)
      } else {
        const newArray = this.selectedDepartments.concat(department)
        this.selectedDepartments = newArray
      }
    },
    onShiftClickDepartment(department) {
      const currentIndex = this.departmentList.findIndex(
        (obj) => obj.id === department.id
      )
      for (let i = currentIndex - 1; i >= 0; i--) {
        if (this.selectedDepartments.includes(this.departmentList[i])) {
          break
        } else {
          const newArray = this.selectedDepartments.concat(
            this.departmentList[i]
          )
          this.selectedDepartments = newArray
        }
      }
    },
    onClickTarget(target) {
      if (this.targets.includes(target)) {
        for (const department of this.selectedDepartments) {
          const addObject =
            target === 'debit'
              ? { ...this.beforeItemsDebit }
              : { ...this.beforeItemsCredit }
          addObject.department = department
          addObject.proportion = 1
          this.afterItems.push(addObject)
        }
      } else {
        const newArray = this.afterItems.filter((item) => item.dc !== target)
        this.afterItems = newArray
      }
      this.allocateEqually([target])
      this.sortItems()
    },
    sortItems() {
      this.afterItems.sort(function (a, b) {
        if (a.dc !== b.dc) {
          return a.dc < b.dc ? 1 : -1
        } else {
          return a.department.sort_num - b.department.sort_num
        }
      })
    },
    allocateEqually(targets = ['debit', 'credit']) {
      const debitAmount = Math.floor(
        this.beforeDebitTotalAmount / this.selectedDepartments.length
      )
      const creditAmount = Math.floor(
        this.beforeCreditTotalAmount / this.selectedDepartments.length
      )
      for (const item of this.afterItems) {
        if (item.dc === 'debit' && targets.includes('debit')) {
          this.$set(item, 'amount', debitAmount)
          this.$set(item, 'proportion', 1)
        } else if (item.dc === 'credit' && targets.includes('credit')) {
          this.$set(item, 'amount', creditAmount)
          this.$set(item, 'proportion', 1)
        }
      }
      this.adjustAmount(targets)
    },
    allocateManually() {
      const debitTotalProportion = this.afterItemsDebit.reduce(
        (x, item) => x + item.proportion,
        0
      )
      const creditTotalProportion = this.afterItemsCredit.reduce(
        (x, item) => x + item.proportion,
        0
      )

      for (const item of this.afterItems) {
        if (item.dc === 'debit') {
          item.amount = Math.floor(
            (this.beforeDebitTotalAmount * item.proportion) /
              debitTotalProportion
          )
        } else {
          item.amount = Math.floor(
            (this.beforeCreditTotalAmount * item.proportion) /
              creditTotalProportion
          )
        }
      }
      this.adjustAmount()
    },
    adjustAmount(targets = ['debit', 'credit']) {
      const differenceDebit = this.afterItemsDebit.length
        ? this.debitDifferenceAmount
        : 0
      const differenceCredit = this.afterItemsCredit.length
        ? this.creditDifferenceAmount
        : 0

      if (differenceDebit !== 0 && targets.includes('debit')) {
        for (const item of this.afterItems) {
          if (item.dc === 'debit') {
            item.amount = item.amount - differenceDebit
            break
          }
        }
      }
      if (differenceCredit !== 0 && targets.includes('credit')) {
        for (const item of this.afterItems) {
          if (item.dc === 'credit') {
            item.amount = item.amount - differenceCredit
            break
          }
        }
      }
    },
    submit() {
      if (
        this.debitDifferenceAmount !== 0 ||
        this.creditDifferenceAmount !== 0
      ) {
        this.$refs.dialogConfirm.open()
      } else {
        this.submitJournals()
      }
    },
    submitJournals() {
      const submitItems = []
      const debitItems = this.targets.includes('debit')
        ? this.afterItemsDebit
        : [this.beforeItemsDebit]
      const creditItems = this.targets.includes('credit')
        ? this.afterItemsCredit
        : [this.beforeItemsCredit]
      const maxLength =
        debitItems.length - creditItems.length >= 0
          ? debitItems.length
          : creditItems.length

      for (let i = 0; i < maxLength; i++) {
        const addObj = {}
        for (const field of this.displayJournalFields) {
          debitItems[i]
            ? (addObj[`debit_${field}`] = debitItems[i][field])
            : (addObj[`debit_${field}`] = {})
          creditItems[i]
            ? (addObj[`credit_${field}`] = creditItems[i][field])
            : (addObj[`credit_${field}`] = {})
        }
        const caluculateTaxDebit = this.caluculateTax(debitItems[i])
        const caluculateTaxCredit = this.caluculateTax(creditItems[i])
        addObj.debit_amount = debitItems[i]
          ? numberToCurrency(debitItems[i].amount)
          : numberToCurrency(0)
        addObj.credit_amount = creditItems[i]
          ? numberToCurrency(creditItems[i].amount)
          : numberToCurrency(0)
        addObj.debit_amount_without_tax = debitItems[i]
          ? numberToCurrency(caluculateTaxDebit.amountWithoutTax)
          : numberToCurrency(0)
        addObj.credit_amount_without_tax = creditItems[i]
          ? numberToCurrency(caluculateTaxCredit.amountWithoutTax)
          : numberToCurrency(0)
        addObj.debit_tax_amount = debitItems[i]
          ? numberToCurrency(caluculateTaxDebit.taxAmount)
          : numberToCurrency(0)
        addObj.credit_tax_amount = creditItems[i]
          ? numberToCurrency(caluculateTaxCredit.taxAmount)
          : numberToCurrency(0)
        addObj.credit_foreign_currency_amount = '0'
        addObj.debit_foreign_currency_amount = '0'
        addObj.foreign_currency_rate = 0
        addObj.description = this.journal.description
        addObj.free_text_1 = this.journal.free_text_1
        addObj.free_text_2 = this.journal.free_text_2
        submitItems.push(addObj)
      }
      this.$emit('submit', { items: submitItems, type: this.journalType })
      this.close()
    },
    caluculateTax(item) {
      try {
        const amount = parseFloat(item.amount)
        const taxRate = parseFloat(this.getTaxRate(item.tax))
        const taxAmount = parseInt((taxRate * amount) / (100 + taxRate))
        const amountWithoutTax = amount - taxAmount
        return { amountWithoutTax: amountWithoutTax, taxAmount: taxAmount }
      } catch (e) {
        return 0
      }
    },
    getTaxRate(obj) {
      try {
        return this.masterList.tax.find((item) => item.id === obj.id).rate
      } catch (e) {
        return 0
      }
    },
    changeJournal(obj, item, field) {
      item[field] = obj
      if (field === 'account_title') {
        item.sub_account = {}
      }
    },
    setBeforeItems() {
      const beforeItems = []
      const debitObject = { dc: 'debit' }
      const creditObject = { dc: 'credit' }
      for (const field of this.displayJournalFields) {
        debitObject[field] = this.journal[`debit_${field}`]
        creditObject[field] = this.journal[`credit_${field}`]
        if (this.getNamekey(field) === 'display_name') {
          if (this.journal[`debit_${field}`].name_code) {
            debitObject[field].display_name =
              this.journal[`debit_${field}`].name_code
          }
          if (this.journal[`credit_${field}`].name_code) {
            creditObject[field].display_name =
              this.journal[`credit_${field}`].name_code
          }
        }
      }
      debitObject.amount = currencyToNumber(this.journal.debit_amount)
      creditObject.amount = currencyToNumber(this.journal.credit_amount)

      beforeItems.push(debitObject, creditObject)
      this.beforeItems = beforeItems
    },
  },
}
</script>
