<template>
  <v-toolbar flat height="50" class="border-bottom-1">
    <!--v-btn
      small
      outlined
      color="blue"
      style="font-size:10px"
      v-text="'学習データ'"
    /-->
    <v-btn
      v-tooltip="getDepartmentTooltip(department)"
      small
      rounded
      :depressed="getDepartmentName(department) != '部門未指定'"
      :outlined="getDepartmentName(department) == '部門未指定'"
      :color="
        getDepartmentName(department) == '部門未指定'
          ? 'grey'
          : 'blue lighten-4'
      "
      style="font-size: 10px; width: 100px"
      @click="$emit('click:department')"
      v-text="getDepartmentName(department)"
    />

    <recurring-schedule-menu
      :disabled="editMode"
      :is-deleted="isDeleted"
      :is-avoid-bank-holiday="isAvoidBankHoliday"
      :recurring-schedule="recurringSchedule"
      @click-create="$emit('click-create-recurring-schedule', $event)"
      @click-update="$emit('click-update-recurring-schedule', $event)"
    />

    <v-btn
      v-show="viewableInbox"
      v-tooltip="'回収メニューで表示'"
      x-small
      fab
      depressed
      color="grey lighten-2"
      :loading="loadingViewInbox"
      class="ml-2"
      @click="$emit('click:view-inbox')"
    >
      <s-icon icon="feather-inbox" />
    </v-btn>
    <v-spacer />
    <v-btn
      v-shortkey="['ctrl', 'shift', 'b']"
      v-tooltip="{
        content:
          '<span class=v-tooltip_shortcutkey>Ctrl</span> <span class=v-tooltip_shortcutkey>Shift</span> <span class=v-tooltip_shortcutkey>B</span>',
        placement: 'bottom',
      }"
      x-small
      fab
      depressed
      color="grey lighten-2"
      class="mx-1"
      @click="$emit('click:toprev')"
      @shortkey="displayMode == 2 ? $emit('click:toprev') : ''"
    >
      <s-icon icon="feather-arrow-up" />
    </v-btn>
    <v-btn
      v-shortkey="['ctrl', 'shift', 'g']"
      v-tooltip="{
        content:
          '<span class=v-tooltip_shortcutkey>Ctrl</span> <span class=v-tooltip_shortcutkey>Shift</span> <span class=v-tooltip_shortcutkey>G</span>',
        placement: 'bottom',
      }"
      x-small
      fab
      depressed
      color="grey lighten-2"
      class="mx-1"
      @click="$emit('click:tonext')"
      @shortkey="displayMode == 2 ? $emit('click:tonext') : ''"
    >
      <s-icon icon="feather-arrow-down" />
    </v-btn>
    <v-btn
      v-if="!isDeleted"
      v-tooltip="'複製して新規追加'"
      x-small
      fab
      depressed
      color="grey lighten-2"
      class="mx-1"
      :disabled="editMode"
      @click="$emit('click:copy')"
    >
      <s-icon icon="feather-copy" />
    </v-btn>
    <v-chip v-if="isDeleted" small label outlined class="py-4 ml-4">
      <s-icon icon="feather-trash-2" class="mr-2" />
      削除済み
    </v-chip>
    <v-btn
      v-if="!isDeleted"
      v-shortkey="['ctrl', 'shift', 'e']"
      v-tooltip="{
        content:
          '<span class=v-tooltip_shortcutkey>Ctrl</span> <span class=v-tooltip_shortcutkey>Shift</span> <span class=v-tooltip_shortcutkey>E</span>',
        placement: 'bottom',
      }"
      small
      depressed
      :color="editMode ? 'grey' : 'primary'"
      class="ml-1"
      style="width: 80px"
      :disabled="!editable && !editMode"
      @click="$emit('click:edit')"
      @shortkey="
        displayMode == 2 && (editable || editMode) ? $emit('click:edit') : ''
      "
      v-text="editMode ? 'キャンセル' : '編集'"
    />
  </v-toolbar>
</template>

<script>
import RecurringScheduleMenu from './RecurringScheduleMenu/RecurringScheduleMenu'
import { mapGetters } from 'vuex'

export default {
  components: {
    RecurringScheduleMenu,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    department: {
      type: Object,
      required: true,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    isAvoidBankHoliday: {
      type: Boolean,
      default: false,
    },
    displayMode: {
      type: Number,
      required: true,
    },
    viewableInbox: {
      type: Boolean,
      default: false,
    },
    loadingViewInbox: {
      type: Boolean,
      default: false,
    },
    recurringSchedule: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['editMode']),
  },
  methods: {
    getDepartmentName(obj) {
      try {
        if (obj.name) {
          if (obj.name.length > 6) {
            return obj.name.slice(0, 6) + '..'
          } else {
            return obj.name
          }
        } else {
          return '部門未指定'
        }
      } catch (e) {
        return '部門未指定'
      }
    },
    getDepartmentTooltip(obj) {
      try {
        if (obj.name) {
          if (obj.name.length > 6) {
            return obj.name
          }
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
  },
}
</script>
