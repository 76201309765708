<template>
  <span v-tooltip="tooltip">
    <v-btn
      x-small
      fab
      depressed
      class="fw-bold"
      :dark="dark"
      :color="color"
      :disabled="disabled"
      @click="$emit('click')"
    >
      <i v-show="icon" :class="icon" class="fs-10 mr-1" />
      <span v-show="text">
        {{ text }}
      </span>
      <span v-show="image" class="mt-1">
        <img :src="image" width="20px" height="20px" />
      </span>
    </v-btn>
  </span>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    tooltip: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '',
    },
  },
}
</script>
