<template>
  <base-dialog
    ref="dialog"
    max-width="450"
    :title="$t('message.confirm')"
    :message="message"
    :cancel-text="$t('message.no')"
    :submit-text="$t('message.yes')"
    @cancel="$emit('cancel')"
    @submit="submit()"
  />
</template>

<script>
export default {
  props: {
    editingUsers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    message: function () {
      if (this.editingUsers.length > 0) {
        const userNames = this.editingUsers
          .map((obj) => obj.name)
          .join('さん、')
        return `${userNames}さんが編集中です。\n更新してよろしいですか？`
      } else {
        return ''
      }
    },
  },
  methods: {
    open() {
      this.$refs.dialog.open()
    },
    close() {
      this.$refs.dialog.close()
    },
    submit() {
      this.$emit('submit')
      this.close()
    },
  },
}
</script>
