<template>
  <div>
    <v-dialog
      :value="dialog"
      width="1000px"
      persistent
      @keydown.esc="$emit('close')"
      @input="$emit('close')"
      @click:outside="focusDialog()"
    >
      <v-row no-gutters>
        <v-col cols="12" style="border-left: 1px solid #eee">
          <!-- v-show -->
          <v-card class="sweeep-dialog" tile>
            <v-card-title> 学習データ変更 </v-card-title>
            <v-card-text style="height: calc(90vh - 108px); overflow: scroll">
              <v-card outlined class="pa-5 mb-5">
                <v-row no-gutters>
                  <v-icon class="feather-check mr-5 green--text" />
                  <div>
                    学習データと異なる内容の登録がありました。学習データを更新しますか？
                  </div>
                </v-row>
                <v-card
                  v-if="companyPatterns.length > 1"
                  outlined
                  class="mt-3 pa-5 grey lighten-4"
                >
                  <div class="fs-10 mb-1">変更されるパターン</div>
                  <span class="fw-bold fs-12">
                    {{ currentPattern.name }}
                  </span>
                  <v-chip
                    v-tooltip="'アップロードユーザ'"
                    label
                    small
                    class="ml-2"
                    color="green lighten-4"
                  >
                    <v-icon x-small left v-text="'feather-user'" />
                    {{
                      currentPattern.user_organization
                        ? currentPattern.user_organization.name
                        : '指定なし'
                    }}
                  </v-chip>
                  <v-chip
                    v-tooltip="'請求書内のキーワード'"
                    label
                    small
                    color="orange lighten-4"
                    class="ml-2"
                  >
                    <v-icon x-small left v-text="'feather-file-text'" />
                    {{
                      currentPattern.keyword
                        ? currentPattern.keyword
                        : '指定なし'
                    }}
                  </v-chip>
                </v-card>
              </v-card>
              <v-row no-gutters>
                <v-col cols="6">
                  <v-card class="mr-2" outlined>
                    <v-toolbar flat dark height="40">
                      変更前学習データ
                    </v-toolbar>
                    <v-card-text class="pa-6">
                      <marked-invoice
                        :company-name="company.company_name"
                        :invoice-issuer-number="company.invoice_issuer_number"
                        :pay-type-name="company.pay_type_name"
                        :pay-site-name="company.pay_site_name"
                        :gensenzei="company.gensenzei"
                        :gensen-total-targets="company.gensen_total_targets"
                        :learn-pay-site="invoice.learning_pay_site"
                        :gensen-code="company.gensen_code"
                      />
                      <marked-payment
                        :bank-name="company.bank_name"
                        :bank-branch-name="company.bank_branch_name"
                        :bank-account-type="company.bank_account_type"
                        :bank-account-number="company.bank_account_number"
                        :bank-account-name="company.bank_account_name"
                        :user-bank="getUserBankFullText(company.user_bank)"
                        :paid-by-customer="company.paid_by_customer"
                        :show-user-bank="showUserBank"
                      />
                      <marked-journals
                        v-if="formType !== 3"
                        :journals="company.journals"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col />

                <v-col cols="6">
                  <v-card class="ml-2" outlined>
                    <!-- 変更後 -->
                    <v-toolbar flat dark height="40">
                      変更後学習データ（この請求書の登録内容）
                    </v-toolbar>
                    <v-card-text class="pa-6">
                      <marked-invoice
                        :company-name="invoice.name"
                        :invoice-issuer-number="invoice.invoiceIssuerNumber"
                        :pay-type-name="formPayTypeName"
                        :pay-site-name="formPaySiteName"
                        :gensenzei="invoice.gensenzei"
                        :gensen-total-targets="invoice.gensenTotalTargets"
                        :gensen-code="invoice.gensenCode"
                        :learn-pay-site="invoice.learning_pay_site"
                        :mark-names="markInvoiceNames"
                      />
                      <marked-payment
                        :user-bank="getUserBankFullText(invoice.user_bank)"
                        :paid-by-customer="invoice.paid_by_customer"
                        :bank-name="invoice.bank_name"
                        :bank-branch-name="invoice.bank_branch_name"
                        :bank-account-type="invoice.bank_account_type"
                        :bank-account-number="invoice.bank_account_number"
                        :bank-account-name="invoice.bank_account_name"
                        :mark-names="markPaymentNames"
                        :show-user-bank="showUserBank"
                      />
                      <marked-journals
                        v-if="formType !== 3"
                        :journals="invoice.image_summary_journal"
                        :mark-names="markJournalNames"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                v-shortkey="dialog && !dialogAddPattern ? ['esc'] : ''"
                v-tooltip="'<span class=v-tooltip_shortcutkey>Esc</span>'"
                @click.native="close"
                @shortkey="close"
              >
                変更しない
              </v-btn>
              <v-btn
                v-shortkey="['ctrl', 'shift', 'f12']"
                v-tooltip="
                  '<span class=v-tooltip_shortcutkey>Ctrl</span> <span class=v-tooltip_shortcutkey>Shift</span> <span class=v-tooltip_shortcutkey>F12</span>'
                "
                @click.native="dialogAddPattern = true"
                @shortkey="dialog ? (dialogAddPattern = true) : ''"
              >
                追加登録（上級者向け）
              </v-btn>
              <v-btn
                v-shortkey="dialogAddPattern ? null : ['ctrl', 'shift', 's']"
                v-tooltip="
                  '<span class=v-tooltip_shortcutkey>Ctrl</span> <span class=v-tooltip_shortcutkey>Shift</span> <span class=v-tooltip_shortcutkey>S</span>'
                "
                color="primary"
                @click.native="submit({ createPattern: false })"
                @shortkey="updateWithShortCut({ createPattern: false })"
              >
                上書き変更
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
    <v-dialog
      :value="dialogAddPattern"
      width="1100px"
      @input="closeAddPatternDialog()"
      @click:outside="closeAddPatternDialog()"
    >
      <v-card class="sweeep-dialog">
        <v-card-title> 学習データの追加登録（仕訳パターン追加） </v-card-title>
        <v-card-text
          class="grey lighten-3 py-0"
          style="height: calc(90vh - 108px); overflow: scroll"
        >
          <v-card class="my-3">
            <v-card-title>
              仕訳パターン登録
              <v-spacer />
              <v-btn
                text
                class="has-link"
                @click="dialogListPatterns = true"
                v-text="'他の仕訳パターンを確認'"
              />
            </v-card-title>
            <v-card-text>
              <v-form ref="patternForm">
                <v-text-field
                  v-model="companyPatternInfo.name"
                  label="パターン名称"
                  :rules="patternRules.name"
                  filled
                  outlined
                  dense
                />
                <h5>パターン適用の条件</h5>
                <div class="fs-10 mb-3">
                  以下の全ての条件にマッチした場合に適用されます
                </div>
                <v-row no-gutters>
                  <v-col>
                    <v-autocomplete
                      v-model="companyPatternInfo.userOrganization"
                      :items="userOrganizations"
                      label="アップロードユーザ"
                      item-text="user.full_name"
                      item-value="id"
                      return-object
                      filled
                      outlined
                      dense
                      hide-details
                      clearable
                    />
                  </v-col>
                  <v-col class="pl-2">
                    <v-text-field
                      v-model="companyPatternInfo.keyword"
                      v-tooltip="
                        'キーワードを複数指定する場合は、スペースで区切ってください'
                      "
                      hint="指定したキーワードすべてがOCRにより見つかった場合に適用されます"
                      label="請求書内のキーワード"
                      :rules="patternRules.keyword"
                      filled
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
          <v-card class="pa-3">
            <div class="pa-3">上記の条件の時に以下の仕訳が適用されます。</div>
            <v-row no-gutters>
              <v-col cols="5" class="pl-2">
                <marked-journals
                  v-if="formType !== 3"
                  :journals="invoice.image_summary_journal"
                  :mark-names="[]"
                />
              </v-col>
              <v-col cols="7" class="pl-2">
                <pdf-view
                  :uri="pdfUrl"
                  hide-download
                  width="100%"
                  height="100%"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="closeAddPatternDialog()" v-text="'キャンセル'" />
          <v-btn
            v-tooltip="
              '<span class=v-tooltip_shortcutkey>Ctrl</span> <span class=v-tooltip_shortcutkey>Shift</span> <span class=v-tooltip_shortcutkey>S</span>'
            "
            v-shortkey="dialogAddPattern ? ['ctrl', 'shift', 's'] : null"
            color="primary"
            @shortkey="updateWithShortCut({ createPattern: true })"
            @click.native="submit({ createPattern: true })"
            v-text="'登録'"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogListPatterns" width="720">
      <v-card>
        <v-card-title>仕訳パターン一覧</v-card-title>
        <v-card-text>
          <div
            v-for="pattern in companyPatterns"
            :key="`${pattern.id}-pattern-list`"
            class="mt-2"
          >
            <company-pattern-card
              :name="pattern.name"
              :user-organization="pattern.user_organization || {}"
              :journals="pattern.company_journals"
              :keyword="pattern.keyword"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="mr-4" @click.native="dialogListPatterns = false">
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  createCompanyParam,
  createCompanyPatternParam,
} from '@/services/company/createCompanyParams'
import { getPaySiteName, getPayTypeName } from 'Helpers/invoice'
import { getPatternLabel, getJounralName } from 'Helpers/company'
import { mapGetters } from 'vuex'
import Validator from 'Helpers/rules'
import Snackbar from 'Helpers/snackbar'
import { getUserBankFullText } from '@/helpers/account/index'

import MarkedInvoice from './MarkedInvoice'
import MarkedPayment from './MarkedPayment'
import MarkedJournals from './MarkedJournals'
import PdfView from 'Components/Pdf/PdfView'
import CompanyPatternCard from '@/pages/master/Company/@components/@shared/CompanyPatternCard/CompanyPatternCard'

const getCompanyPatternInfo = () => {
  return {
    name: '',
    keyword: '',
    userOrganization: {},
  }
}

export default {
  components: {
    CompanyPatternCard,
    MarkedInvoice,
    MarkedPayment,
    MarkedJournals,
    PdfView,
  },

  props: {
    formType: {
      type: Number,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    invoice: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      default: () => {},
    },
    diffObjects: {
      type: Array,
      default: () => [],
    },
    userOrganizations: {
      type: Array,
      required: true,
    },
    pdfUrl: {
      type: String,
      required: true,
    },
    showUserBank: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      companyPatternInfo: getCompanyPatternInfo(),
      patternRules: Validator.companyPattern.rules,
      dialogAddPattern: false,
      // Ocr 選択のアクティブな電話番号 String or 0(blank)
      phoneRadioToggle: 0,
      dialogListPatterns: false,
    }
  },
  computed: {
    ...mapGetters(['definition', 'contactNumberList', 'ownCompanyNames']),

    companyPatterns() {
      if (this.company && this.company.patterns) {
        return this.company.patterns
      } else {
        return []
      }
    },
    currentPattern() {
      const currentPattern = this.companyPatterns.find(
        (pattern) => pattern.id === this.invoice.company_pattern_id
      )
      return currentPattern || {}
    },
    formPayTypeName: function () {
      try {
        return getPayTypeName(this.invoice.pay_type)
      } catch (e) {
        return ''
      }
    },
    formPaySiteName: function () {
      try {
        return getPaySiteName(this.invoice.pay_month, this.invoice.pay_day)
      } catch (e) {
        return ''
      }
    },
    markInvoiceNames: function () {
      try {
        return this.diffObjects
          .filter((obj) => {
            return [
              'company_name',
              'invoice_issuer_number',
              'pay_type',
              'pay_month',
              'pay_day',
              'gensenzei',
              'gensen_code',
              'gensen_total_targets',
            ].includes(obj.value)
          })
          .map((obj) => {
            if (['pay_month', 'pay_day'].includes(obj.value)) {
              return 'pay_site'
            } else {
              return obj.value
            }
          })
      } catch (e) {
        console.log(e)
        return []
      }
    },
    markPaymentNames: function () {
      try {
        return this.diffObjects
          .filter((obj) => {
            return [
              'user_bank',
              'paid_by_customer',
              'bank_name',
              'bank_branch_name',
              'bank_account_type',
              'bank_account_number',
              'bank_account_name',
            ].includes(obj.value)
          })
          .map((obj) => {
            return obj.value
          })
      } catch (e) {
        console.log(e)
        return []
      }
    },
    markJournalNames: function () {
      try {
        return this.diffObjects
          .filter((obj) => {
            return obj.value.includes('journals:')
          })
          .map((obj) => {
            return obj.value.replace('journals:', '')
          })
      } catch (e) {
        console.log(e)
        return []
      }
    },
  },
  watch: {
    dialog: function (opened) {
      if (opened) {
        this.$set(this, 'companyPatternInfo', getCompanyPatternInfo())
      } else {
        this.dialogAddPattern = false
        this.dialogListPatterns = false
      }
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    updateWithShortCut({ createPattern }) {
      if (!this.dialog) {
        return
      }
      this.submit({ createPattern })
    },
    async submit({ createPattern }) {
      /**
       * 1. マッチする -> 更新
       * 2. マッチしない
       *    1. inovice companyあり -> 更新 or 作成
       *    2. なし -> 作成
       */
      const patternId = createPattern ? 0 : this.invoice.company_pattern_id
      if (!(patternId || this.dialogAddPattern)) {
        // companyはあるが、pattern がない時
        this.dialogAddPattern = true
        await (async function () {
          setTimeout(() => {
            return
          }, 100)
        })()
      }
      const formIsValid = !!patternId || this.$refs.patternForm.validate()
      if (!formIsValid) {
        this.$nextTick(() =>
          Snackbar.error('仕訳パターン項目を正しく入力してください。')
        )
        return
      }
      const company = createCompanyParam(
        {
          ...this.invoice,
          company_id: this.company.id,
          scanned_company_name: this.invoice.scannedCompanyName,
          scanned_company_address: this.invoice.scannedCompanyAddress,
          scanned_company_phonenumber: this.invoice.scannedCompanyPhonenumber,
          bank_branch_code: this.$store.getters.getBankBranchCode(
            this.invoice.bank_name,
            this.invoice.bank_branch_name
          ),
          bank_code: this.$store.getters.getBankCode(this.invoice.bank_name),
        },
        this.company.company_phonenumber,
        this.company.company_address
      )

      const { name, keyword, userOrganization } = createPattern
        ? this.companyPatternInfo
        : {}

      const companyPattern = createCompanyPatternParam({
        journals: this.invoice.image_summary_journal,
        id: patternId,
        company_id: this.invoice.company.id,
        name,
        keyword,
        userOrganization,
      })
      const selectedCompanyPattern = this.companyPatterns.find(
        (pattern) => pattern.id === patternId
      )
      const datetimesJournalUpdated = selectedCompanyPattern
        ? selectedCompanyPattern.company_journals.map(
            (journal) => journal.date_updated
          )
        : []
      const valuesBeingViewed = {
        company: this.company.date_updated,
        pattern: selectedCompanyPattern
          ? selectedCompanyPattern.date_updated
          : undefined,
        journals: datetimesJournalUpdated,
      }

      this.$emit('submit', {
        company,
        companyPattern,
        invoiceId: this.invoice.invoice_id,
        valuesBeingViewed,
      })
    },
    closeAddPatternDialog() {
      this.dialogAddPattern = false
      this.$nextTick(() => {
        this.focusDialog()
      })
    },
    focusDialog() {
      document.getElementsByClassName('v-dialog__content--active')[0].focus()
    },
    getPatternLabel: (pattern) => getPatternLabel(pattern),
    getJounralName: (journal, attr) => getJounralName(journal, attr),
    getUserBankFullText: (userBank) => getUserBankFullText(userBank),
  },
}
</script>
