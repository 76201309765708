<template>
  <div>
    <freee-post-error-dialog
      ref="errorDialog"
      :errors="freeeErrorList"
      @close="close()"
    />

    <freee-waiting-modal ref="waitingModal" />

    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card class="sweeep-dialog">
        <v-card-title v-text="'freeeへの同期'" />
        <v-card-text>
          <div v-if="!freeeSeparateApproval">
            <v-avatar color="success" size="25" class="mr-2">
              <v-icon color="white" class="fw-bold fs-12"> ti-check </v-icon>
            </v-avatar>

            <span>仕訳を承認しました</span>

            <v-divider class="mt-4" />
          </div>

          <div class="py-3">承認した仕訳をfreeeに同期しますか？</div>
          <div class="sw-badge-primary">同期先アカウント</div>
          <div v-if="!freeeCompanyRegistered">
            デフォルトの連携事業所を選択してください。
          </div>
          <div class="fw-bold pl-1 pt-1">
            {{ freeeCompanyName }}
          </div>

          <v-autocomplete
            v-if="!freeeCompanyRegistered"
            v-model="freeeCompanyId"
            :items="freeeCompanyList"
            label=""
            single-line
            outline
            hide-details
            class="small-input"
            item-text="display_name"
            item-value="id"
          >
            <template slot="item" slot-scope="data">
              {{ data.item.display_name }}
            </template>
          </v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            v-show="freeeCompanyList.length > 0 && loading == false"
            @click.native="close"
          >
            {{ $t('message.modal_cancel_btn') }}
          </v-btn>
          <v-btn
            v-show="freeeCompanyList.length > 0 && loading == false"
            color="primary"
            @click.native="submit"
          >
            同期する
          </v-btn>
          <v-btn v-show="loading" :loading="true" color="primary">
            Uploading..
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import Snackbar from 'Helpers/snackbar/index'
import FreeePostErrorDialog from './FreeePostErrorDialog'
import FreeeWaitingModal from './FreeeWaitingModal'

export default {
  components: {
    FreeePostErrorDialog,
    FreeeWaitingModal,
  },
  props: {
    freeeSeparateApproval: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      multiple: false,
      localId: null,
      invoiceId: null,
      items: [],
      tokenExists: '',
      freeeCompanyId: '',
      freeeCompanyName: '',
      freeeCompanyList: [],
      freeeErrorList: [],
    }
  },
  computed: {
    freeeCompanyRegistered: function () {
      if (this.freeeCompanyId) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    open({ localId, items, invoiceId, multiple }) {
      try {
        this.localId = localId
        this.invoiceId = invoiceId
        this.items = items
        this.multiple = multiple

        const freeeTokenExists = this.$store.getters.freeeTokenExists
        if (!freeeTokenExists) {
          this.close()
          return
        }

        this.$refs.waitingModal.open()

        this.authorize().then((authorized) => {
          if (authorized) {
            this.$refs.waitingModal.close()
            this.dialog = true
          } else {
            this.close()
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.dialog = false
      this.loading = false
      this.multiple = false
      this.localId = null
      this.items = []
      this.freeeCompanyId = ''
      this.freeeCompanyName = ''
      this.freeeCompanyList = []
      this.$emit('close')
    },
    submit() {
      if (this.freeeCompanyRegistered) {
        this.postDeals()
      } else {
        this.$store
          .dispatch('postFreeeCompany', this.freeeCompanyId)
          .then((success) => {
            if (success) {
              Snackbar.success('デフォルト事業所の登録に成功しました')
              this.postDeals()
            }
          })
      }
    },
    authorize() {
      this.freeeCompanyId = ''
      this.freeeCompanyName = ''
      this.freeeCompanyList = []

      return new Promise((resolve) => {
        this.$store.dispatch('getFreeeAccessToken').then((payload) => {
          if (!payload) {
            return
          }
          this.tokenExists = payload.tokenExists
          this.freeeCompanyId = payload.companyId

          this.$store.dispatch('checkFreeeAccessToken').then((response) => {
            if (response.authorized) {
              this.freeeCompanyList = response.companyList

              if (this.freeeCompanyId) {
                const company = response.companyList.find(
                  (v) => v.id === this.freeeCompanyId
                )
                if (company) {
                  this.freeeCompanyName = company.display_name
                }
              }
              resolve(true)
            } else {
              resolve(false)
              Snackbar.error('freeeとの接続に失敗しました（トークンエラー）')
            }
          })
        })
      })
    },
    postDeals() {
      try {
        if (!this.tokenExists) {
          return
        }
        this.loading = true
        this.freeeErrorList.length = 0

        let action, payload

        if (this.multiple) {
          action = 'postMultiFreeeDeals'
          payload = {
            freeeCompanyId: this.freeeCompanyId,
            items: this.items,
            separateApproval: this.freeeSeparateApproval,
          }
        } else {
          action = 'postSingleFreeeDeal'
          payload = {
            freeeCompanyId: this.freeeCompanyId,
            localId: this.localId,
            invoiceId: this.invoiceId,
            separateApproval: this.freeeSeparateApproval,
          }
        }

        this.$store.dispatch(action, payload).then((result) => {
          if (result.status === 'error') {
            result.errors.forEach((err, index) => {
              this.$set(this.freeeErrorList, index, err)
            })
            this.$refs.errorDialog.open('error')
            this.loading = false
            this.dialog = false
            return
          } else if (result.status === 'warning') {
            const warnings = result.warnings
            warnings.forEach((warning, index) => {
              this.$set(this.freeeErrorList, index, warning)
            })
            this.$refs.errorDialog.open('warning')
          }
          const message =
            'freee上で仕訳登録とファイルボックス保存を行いました。<br> 2回目以降の仕訳承認の場合は仕訳は上書き、画像はファイルボックスに新規登録されます。（古い画像は削除されます。）'
          if (action === 'postSingleFreeeDeal') {
            this.$emit('posted:deal')
            Snackbar.success(message)
            // this.onSuccessPostFreeeDeals(this.localId, result.data[0].deal.id)
          } else if (action === 'postMultiFreeeDeals') {
            this.onSuccessPostFreeeDeals(1, 1) //一旦毎回ロードする
            Snackbar.success(message)
          }
          if (result.status === 'success') {
            this.close()
          }
          this.loading = false
          this.dialog = false
        })
      } catch (e) {
        console.log(e)
      }
    },
    onSuccessPostFreeeDeals(localId, freeeDealId) {
      this.$emit('posted:deal', { localId, freeeDealId })
    },
    checkIfStorageFreeUploadExists() {
      try {
        let user_id = this.$store.getters.getUserID
        if (localStorage.getItem('freeeUpload')) {
          let data = JSON.parse(localStorage.getItem('freeeUpload'))
          let found = false
          for (let x = 0; x < data.length; x++) {
            if (data[x].user_id === user_id) {
              found = true
              break
            }
          }
          if (found) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>
