export const createCompanyParam = (
  invoice,
  companyPhoneNumber,
  companyAddress
) => {
  /**
   * invoice: Object (formInvoice)
   * companyPhoneNumber: String
   * companyAddress: String
   * updatedAt: String (last datetime updated company)
   */
  const company = {}
  company.id = invoice.company_id
  company.company_name = invoice.name

  company.pay_month = invoice.learning_pay_site ? invoice.pay_month : undefined
  company.pay_day = invoice.learning_pay_site ? invoice.pay_day : undefined
  company.pay_type = invoice.pay_type || undefined

  company.bank_name = invoice.bank_name
  company.bank_code = invoice.bank_code
  company.bank_branch_name = invoice.bank_branch_name
  company.bank_branch_code = invoice.bank_branch_code
  company.bank_account_name = invoice.bank_account_name
  company.bank_account_number = invoice.bank_account_number
  company.bank_account_type = invoice.bank_account_type
  company.gensenzei = invoice.gensenzei
  company.gensen_total_targets = invoice.gensenTotalTargets
  company.gensen_code = invoice.gensenCode
  company.invoice_issuer_number = invoice.invoiceIssuerNumber

  company.user_bank_id =
    (invoice.user_bank && invoice.user_bank.id) || undefined
  company.paid_by_customer = invoice.paid_by_customer

  // 後々要件から削除
  company.company_address = companyAddress || ''
  company.company_phonenumber =
    typeof companyPhoneNumber === 'string'
      ? companyPhoneNumber.replace(/[-ー]/g, '')
      : ''

  company.scanned_company_name = invoice.scanned_company_name || undefined
  company.scanned_company_address = invoice.scanned_company_address || undefined
  company.scanned_company_phonenumber =
    invoice.scanned_company_phonenumber || undefined

  return company
}

const createJournals = (invoiceJournals) => {
  const getId = (master) => {
    if (!master) {
      return null
    }
    return master.id || null
  }
  const journalFields = [
    'debit_account_title',
    'debit_department',
    'debit_vendor',
    'debit_project',
    'debit_segment',
    'debit_item',
    'debit_tag',
    'debit_walletable',
    'debit_tax',
    'credit_account_title',
    'credit_department',
    'credit_vendor',
    'credit_project',
    'credit_segment',
    'credit_item',
    'credit_tag',
    'credit_walletable',
    'credit_tax',
  ]
  const journals = invoiceJournals.map((invoiceJournal) => {
    const companyJournal = {}
    for (const field of journalFields) {
      companyJournal[`${field}_id`] = getId(invoiceJournal[field])
    }
    companyJournal.debit_subject_id = getId(invoiceJournal.debit_sub_account)
    companyJournal.credit_subject_id = getId(invoiceJournal.credit_sub_account)
    companyJournal.description = invoiceJournal.description
    return companyJournal
  })
  return journals
}

export const createCompanyPatternParam = (pattern) => {
  const getUserOrganizationId = (userOrg) => {
    if (userOrg === undefined) {
      return undefined
    } else {
      return userOrg && userOrg.id
    }
  }
  const companyPattern = {}
  const { journals, id, company_id } = pattern
  companyPattern.name = pattern.name === undefined ? undefined : pattern.name
  companyPattern.user_organization_id = getUserOrganizationId(
    pattern.userOrganization
  )
  companyPattern.keyword =
    pattern.keyword === undefined ? undefined : pattern.keyword
  companyPattern.id = id || undefined
  companyPattern.company_id = company_id
  companyPattern.journals = createJournals(journals)
  return companyPattern
}
