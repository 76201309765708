import { abstractLogics } from './_logics'

export const emailRules = {
  logics: {
    ...abstractLogics,
    isCurrectFormat: (email) => {
      const isValid =
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
          email
        )
      return Boolean(isValid)
    },
  },
  getRules(name = '', required = true) {
    return [
      (email) => !required || this.logics.getRequiredRule(name)(email),
      (email) =>
        !email ||
        this.logics.isCurrectFormat(email) ||
        name + '正しい形式で入力して下さい。',
    ]
  },
}
