<template>
  <v-menu
    v-model="menu"
    transition="slide-x-transition"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :left="left"
    :bottom="bottom"
    :nudge-left="nudgeLeft"
    :nudge-bottom="nudgeBottom"
    :close-on-content-click="false"
    @update:return-value="close"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="display" :attrs="attrs" :on="on" :number="number" />
    </template>
    <v-card class="sweeep-popover" width="350">
      <v-card-title> 管理メモ{{ number }} </v-card-title>
      <v-card-text>
        <v-textarea
          v-show="editMode"
          v-model="text"
          filled
          outlined
          hide-details
          :readonly="!editable"
        />
        <div
          v-show="!editMode"
          class="pa-2 my-1 fs-12 fw-bold"
          style="
            border: #a5a7b2 1px solid;
            border-radius: 5px;
            min-height: 100px;
          "
          v-html="invoiceNoteWithHyperLink"
        />
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn
          v-show="editMode"
          small
          color="grey lighten-3"
          @click="editMode = !editMode"
          v-text="'キャンセル'"
        />
        <v-btn
          v-show="!editMode"
          small
          color="grey lighten-3"
          @click="close"
          v-text="'閉じる'"
        />
        <v-btn
          v-show="editMode"
          small
          color="blue"
          dark
          @click="
            $emit('click:update', text)
            editMode = !editMode
          "
          v-text="'更新'"
        />
        <v-btn
          v-show="!editMode && editable"
          small
          color="blue"
          dark
          @click="editMode = !editMode"
          v-text="'編集'"
        />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { escapeText, surroundUrlByTag } from 'Helpers/helpers'

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    number: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    offsetX: {
      type: Boolean,
      default: false,
    },
    offsetY: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    nudgeLeft: {
      type: Number,
      default: 0,
    },
    nudgeBottom: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menu: false,
      text: '',
      editMode: false,
    }
  },
  computed: {
    invoiceNoteWithHyperLink() {
      let text = escapeText(this.value)
      text = surroundUrlByTag(text)
      return text
    },
  },
  watch: {
    value: {
      handler(value) {
        this.text = value
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.menu = false
      this.text = this.value
    },
  },
}
</script>
